<template>
  <PageHeader title="Notifications"></PageHeader>
  <Card>
    <div class="notifications">
      <NotificationsList limit="15" :hide-viewed="false" />
    </div>
  </Card>
</template>

<script>
import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import NotificationsList from '@/components/notifications/NotificationsList.vue'
export default {
  components: {
    PageHeader,
    Card,
    NotificationsList
  }
}
</script>

<style>
.notifications .notification-item:first-of-type {
  border-top-left-radius: var(--rounded-md);
  border-top-right-radius: var(--rounded-md);
}

.notifications .notification-item:last-of-type {
  border-bottom-left-radius: var(--rounded-md);
  border-bottom-right-radius: var(--rounded-md);
}
</style>
