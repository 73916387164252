//This function takes in a UserID, and returns an object with data about the user's status.
// Reference to a JSON with the user statuses is made
import { useUserStore } from '@/stores/UserStore'
import userStatuses from '@/utilities/userStatuses.json'

const getUserStatus = userID => {
  const userStore = useUserStore()
  let user = userStore.usersPresence.find(u => u.uid == userID)
  let userStatusDisplayObj

  if (user) {
    userStatusDisplayObj = userStatuses[user.status]
  } else {
    user = { uid: userID, presence: 'offline', status: null }
    userStatusDisplayObj = userStatuses.offline
  }

  Object.assign(user, userStatusDisplayObj)

  return user
}

export default getUserStatus
