<template>
  <!-- {{ computedPeriods }} -->
  <!-- {{ computedSummarizedPeriods }} -->
  <div class="timeline-wrapper">
    <div v-if="showTimes" class="hour-markers">
      <div v-for="hour in hoursRange" :key="hour" class="hour-marker" :style="{ left: `${hour.position}px` }">
        {{ hour.label }}
      </div>
    </div>
    <div v-if="mode != 'summary'" class="timeline-container block">
      <div
        v-for="(period, index) in computedPeriods"
        :key="index"
        v-tippy="{
          content: `${period.statusTitle} for ${numberFormatter(
            $dayjs.duration(period.duration, 'milliseconds').as('minutes')
          )} min <br>${dayjs(period.start).format('HH:mm')}-${dayjs(period.end).format('HH:mm')}`
        }"
        :style="period.style"
        class="timeline-period"
      >
        <Icon v-if="parseFloat(period.style.width) > 10" :type="period.icon" fa-style="fas" class="text-xs text-white opacity-80" />
      </div>
    </div>
    <div v-else class="timeline-container flex">
      <div
        v-for="(status, index) in computedSummarizedPeriods"
        :key="index"
        v-tippy="{
          content: `${status.label} for ${numberFormatter($dayjs.duration(status.duration, 'milliseconds').as('minutes'))} min`
        }"
        :style="status.style"
        class="timeline-period"
      >
        <Icon v-if="parseFloat(status.style.width) > 1" :type="status.icon" fa-style="fas" class="text-sm text-white opacity-80" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import Icon from '@/components/icon/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      default: 'compact' // compact, relative, summary
    },
    startTime: {
      type: String,
      default: '09:00'
    },
    endTime: {
      type: String,
      default: '16:00'
    },
    showTimes: {
      type: Boolean,
      default: false
    },
    scale: {
      type: Number,
      default: 2 // Default scale: 1 pixel per minute
    }
  },
  setup(props) {
    const PIXELS_PER_MINUTE = ref(props.scale)

    // Watch for changes in the scale prop and update PIXELS_PER_MINUTE
    watch(
      () => props.scale,
      newScale => {
        PIXELS_PER_MINUTE.value = newScale
      }
    )

    function getStatusDetails(status) {
      let details = {}
      switch (status) {
        case 'online':
          details = {
            order: 1,
            // icon: 'check',
            label: 'Online',
            color: 'var(--bg-quaternary)'
          }
          break
        case 'offline':
          details = {
            order: 7,
            icon: 'circle-x',
            label: 'Offline',
            color: 'var(--bg-error-solid)'
          }
          break
        case 'idle':
          details = {
            order: 2,
            icon: 'zzz',
            label: 'Idle',
            color: 'var(--bg-warning-solid)'
          }
          break
        case 'inCall':
          details = {
            order: 3,
            icon: 'phone',
            label: 'In Call',
            color: 'var(--utility-pink-600)'
          }
          break
        case 'wrapUp':
          details = {
            order: 4,
            icon: 'turn-up',
            label: 'Wrap Up',
            color: 'var(--bg-info-solid)'
          }
          break
        case 'onBreak':
          details = {
            order: 5,
            icon: 'mug',
            label: 'On Break',
            color: 'var(--bg-error-solid)'
          }
          break
        case 'busy':
          details = {
            order: 6,
            icon: 'ban',
            label: 'Busy',
            color: 'var(--bg-error-solid)'
          }
          break
        default:
          details = {
            order: 8,
            icon: 'fa-user',
            label: 'Unknown',
            color: 'var(--bg-secondary)'
          }
      }

      return details
    }

    const computedSummarizedPeriods = computed(() => {
      const periods = Array.isArray(props.modelValue) ? props.modelValue : []

      const statuses = []

      if (periods.length > 0) {
        periods[periods.length - 1].end = periods[periods.length - 1].start
      }

      periods.forEach(period => {
        const duration = new Date(period.end).getTime() - new Date(period.start).getTime()
        const statusDetails = getStatusDetails(period.status)

        const statusIndex = statuses.findIndex(status => status.label === statusDetails.label)
        if (statusIndex === -1) {
          statuses.push({
            label: statusDetails.label,
            order: statusDetails.order,
            style: {
              backgroundColor: statusDetails.color,
              width: '0%'
            },
            icon: statusDetails.icon,
            duration: duration,
            start: period.start,
            end: period.end
          })
        } else {
          statuses[statusIndex].duration += duration
          statuses[statusIndex].end = period.end
        }
      })

      const totalDuration = statuses.reduce((acc, status) => acc + status.duration, 0)
      statuses.forEach(status => {
        status.style.width = ((status.duration / totalDuration) * 100).toFixed(2) + '%'
      })

      const orderedItems = statuses.sort((a, b) => a.order - b.order)

      return orderedItems
    })

    const computedPeriods = computed(() => {
      const periods = Array.isArray(props.modelValue) ? props.modelValue : []

      const dayContext = periods[0] ? periods[0].start.split('T')[0] : '1970-01-01'

      const timelineStart = new Date(`${dayContext}T${props.startTime}:00`).getTime()
      const timelineEnd = new Date(`${dayContext}T${props.endTime}:00`).getTime()

      return periods.map(period => {
        const periodStart = new Date(period.start).getTime()
        const periodEnd = new Date(period.end).getTime()
        const duration = periodEnd - periodStart

        const offset = periodStart - timelineStart

        const widthPixels = (duration / 60000) * PIXELS_PER_MINUTE.value
        const leftOffsetPixels = (offset / 60000) * PIXELS_PER_MINUTE.value

        let statusDetails = getStatusDetails(period.status)

        return {
          ...period,
          style: {
            position: 'absolute',
            left: `${leftOffsetPixels}px`,
            width: `${widthPixels}px`,
            backgroundColor: statusDetails.color
          },
          statusTitle: statusDetails.label,
          icon: statusDetails.icon,
          duration: duration
        }
      })
    })

    const hoursRange = computed(() => {
      const range = []
      const [startHour] = props.startTime.split(':').map(Number)
      const [endHour] = props.endTime.split(':').map(Number)

      let totalHours = endHour - startHour
      if (totalHours < 0) {
        totalHours += 24
      }

      for (let i = 0; i <= totalHours; i++) {
        let currentHour = (startHour + i) % 24
        const position = i * 60 * PIXELS_PER_MINUTE.value

        let label = `${currentHour < 10 ? '0' : ''}${currentHour}:00`
        range.push({
          label: label,
          position: position
        })

        if (i < totalHours) {
          label = `${currentHour < 10 ? '0' : ''}${currentHour}:30`
          range.push({
            label: label,
            position: position + 30 * PIXELS_PER_MINUTE.value
          })
        }
      }

      return range
    })

    return {
      computedPeriods,
      hoursRange,
      computedSummarizedPeriods
    }
  }
}
</script>

<style>
.timeline-wrapper {
  position: relative;
  overflow-x: visible;
  white-space: nowrap; /* Prevent wrapping of child elements */
}

.hour-markers {
  /* position: absolute; */
  width: 100%;
  height: 20px;
  top: 0;
  pointer-events: none; /* Allows clicks to pass through */
}

.hour-marker {
  position: absolute;
  transform: translateX(-50%);
  pointer-events: auto; /* Enable interaction, if needed */
  font-size: var(--text-xs);
}
.hour-marker::before {
  content: '';
  position: absolute;
  /* top: 0; */
  left: 50%;
  bottom: -5px;
  width: 1px;
  height: 6px;
  background-color: #535353;
}

.hour-marker:first-child {
  transform: translateX(0%); /* Adjusts so it's not cut off */
}
.hour-marker:first-child::before {
  left: 0;
}

.hour-marker:last-child {
  transform: translateX(-100%); /* Adjusts so it's not cut off */
}
.hour-marker:last-child::before {
  left: 95%;
}

.timeline-container {
  position: relative; /* Allows absolute positioning within */
  min-width: 100%;
  height: 30px; /* Adjust based on your design */
  background-color: #efefef;
}
.dark .timeline-container {
  background-color: var(--darkmode-2);
}

.timeline-period {
  height: 100%; /* This ensures each period has height and is visible */
  display: flex; /* Optional, depending on your design */
  align-items: center; /* Optional, centers content vertically */
  justify-content: center; /* Optional, centers content horizontally */
  box-sizing: border-box; /* Ensures padding and borders are included in the width */
  position: relative; /* Needed if you're positioning icons or other elements absolutely */
  border-right: 1px solid var(--utility-gray-50);
}

/* Additional styles for periods based on their status can go here */
</style>
