<template>
  <PageHeader
    :title="$t('ui.entities.comms.call', 2)"
    breadcrumb
    :items="[
      { label: $t('ui.entities.report.label', 2), to: '/reports' },
      { label: 'Engagement' },
      { label: $t('ui.entities.comms.call', 2), to: '/reports/engagement/calls' }
    ]"
  >
    <template #right>
      <div class="flex items-center gap-2">
        <DatePicker
          v-model="dateRange"
          selection-mode="range"
          max-range="31"
          position="bottom-right"
          :max-date="new Date()"
          :number-of-months="useBreakPoints({ xs: 1, sm: 2 })"
          @change-applied="onDateRangeChange"
        />
      </div>
    </template>
  </PageHeader>
  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      export-filename="Calls"
      :value="callsData"
      removable-sort
      sort-field="timestamp"
      :sort-order="-1"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :global-filter-fields="['dealershipId', 'dealershipName', 'user.name', 'customer.name', 'numberFrom', 'numberTo']"
      :rows="20"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-report-engagement-calls"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
    >
      <template #empty> No records found </template>

      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>
        </div>
        <div class="flex justify-between mt-4">
          <span>
            <Button label="CSV" type="button" severity="secondary" outlined icon="file-excel" @click="exportCSV($event)" />
          </span>
        </div>
      </template>

      <Column field="dealershipName" header="Dealership" hidden> </Column>
      <Column field="dealershipId" header="Dealership" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <div class="flex items-center gap-3">
            <Avatar v-if="data.dealershipId" :url="'https://cdn.retain.group/img/logos/manufacturers/' + data.manufacturerLogo" is-logo />
            <Avatar v-else icon="dash" />
            <div>
              <div class="font-semibold">
                {{ data.dealershipName || '-' }}
              </div>
              <div class="text-sm text-quaternary">{{ data.direction == 'outbound' ? data.numberFrom : data.numberTo }}</div>
            </div>
          </div>
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="dealershipsList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Any"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="direction">
        <template #body="{ data }">
          <Icon v-if="data.direction == 'inbound'" type="arrow-left" />
          <Icon v-if="data.direction == 'outbound'" type="arrow-right" />
        </template>
      </Column>
      <Column field="customer.name" header="Customer" :sortable="true">
        <template #body="{ data }">
          <router-link :to="data.customer.agreementId ? '/agreement/' + data.customer.agreementId : ''">
            <div class="flex items-center gap-3">
              <Avatar :icon="data.customer.agreementId ? 'user' : 'dash'" />
              <div>
                <div v-if="data.customer.name" class="font-semibold whitespace-nowrap">
                  {{ data.customer.name }}
                </div>
                <div v-else class="mb-2">
                  <Dropdown>
                    <template #triggerContent>
                      <Button label="Link to customer" size="xs" outlined />
                    </template>
                    <Select
                      v-model="selectedAgreement"
                      :items="agreementsList"
                      value-key="agreementID"
                      label-key="name"
                      :is-object="true"
                      :search="true"
                      :handle-search="true"
                      :search-term="searchTerm"
                      :can-clear="true"
                      class="px-2 min-w-64"
                      @select="linkToCustomer(data)"
                      @update:search-term="searchTerm = $event"
                    >
                      <template #option="{ option }">{{ option.name }} [{{ option.vrm }}]</template>
                    </Select>
                  </Dropdown>
                </div>

                <div class="text-sm text-quaternary">{{ data.direction == 'outbound' ? data.numberTo : data.numberFrom }}</div>
              </div>
            </div>
          </router-link>
        </template>
      </Column>

      <Column field="numberFrom" header="From" hidden :sortable="true"> </Column>
      <Column field="numberTo" header="To" hidden :sortable="true"> </Column>

      <Column field="reason" header="Reason">
        <template #body="{ data }">
          <Chip v-for="reason in data.reason" :key="reason" size="sm" class="mr-2">
            <div class="text-sm">{{ reason }}</div>
          </Chip>
        </template>
      </Column>

      <Column field="answeredBy" header="Answered By" :sortable="true">
        <template #body="{ data }">
          <div class="flex gap-2">
            <div v-if="data.answeredBy" class="flex gap-2">
              <Avatar
                v-tippy="{ content: data.answeredBy == 'machine' ? 'Voicemail' : 'Customer' }"
                :icon="data.answeredBy == 'machine' ? 'voicemail' : 'user'"
              />
            </div>
            <AudioPlayer v-if="data.recordingUrl" :audio-src="data.recordingUrl" compact />
          </div>
        </template>
      </Column>

      <Column field="duration" header="Length" :sortable="true">
        <template #body="{ data }">
          <div class="flex gap-2 items-center">
            <div>{{ $dayjs.duration(data.duration, 'seconds').format('mm:ss') }}</div>
          </div>
        </template>
      </Column>

      <Column field="user.id" header="User" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <div v-if="data.answeredBy" class="flex gap-2">
            <Avatar
              v-if="data.user.id"
              v-tippy="{ content: data.user.name ? data.user.name : 'Voicemail' }"
              :icon="data.voicemail ? 'voicemail' : ''"
              :url="'https://cdn.retain.group/img/profile-pics/' + data.user.profilePic"
              :text="data.user.initials"
            />
          </div>
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="usersList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Any"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="timestamp" header="Occurred" :sortable="true">
        <template #body="{ data }">
          <div v-tippy="dayjs(data.timestamp).format('h:mma Do MMM YYYY')" class="whitespace-nowrap">
            {{ dayjs().to(dayjs(data.timestamp)) }}
          </div>
        </template>
      </Column>

      <Column v-if="$can('manage_conversations')">
        <template #body="{ data }">
          <Dropdown position="bottom-right">
            <template #triggerContent>
              <Button icon="ellipsis-vertical" plain severity="secondary"></Button>
            </template>
            <DropdownItem @item-clicked="deleteCall(data.id)">
              <Icon type="trash" class="text-error" />
              <span class="w-full">{{ $t('ui.actions.delete') }}</span>
            </DropdownItem>
          </Dropdown>
        </template>
      </Column>
    </DataTable>
  </Card>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'

import Card from '@/components/card/Card.vue'
import PageHeader from '@/components/page/PageHeader.vue'
import DatePicker from '@/components/date-picker/DatePicker.vue'
import Input from '@/components/forms/Input.vue'
import Button from '@/components/button/Button.vue'
import Avatar from '@/components/avatar/Avatar.vue'
import Icon from '@/components/icon/Icon.vue'
import Chip from '@/components/chip/Chip.vue'
import Dropdown from '@/components/dropdown/Dropdown.vue'
import DropdownItem from '@/components/dropdown/DropdownItem.vue'
import AudioPlayer from '@/components/audio-player/AudioPlayer.vue'
import Select from '@/components/forms/Select.vue'

import useApiRequest from '@/composables/useApiRequest'
import { useBreakPoints } from '@/composables/useWindowSize'
import { useUserStore } from '@/stores/UserStore'
import notification from '@/utilities/notification'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'
import MultiSelect from 'primevue/multiselect'
import { MeiliSearch } from 'meilisearch'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'

const dt = ref()
const userStore = useUserStore()
const dealershipsAllowed = ref(userStore.accessAllowed.dealerships)
const dateRange = ref([dayjs().startOf('week').toDate(), dayjs().toDate()])
const callsApiRequest = useApiRequest()
const deleteCallApiRequest = useApiRequest()
const linkAgreementApiRequest = useApiRequest()

const callsData = ref([])
const users = ref([])
const filters = ref([])

const dealershipIDs = userStore.accessAllowed.dealerships.map(dealership => dealership.id)
const dealershipIDsString = dealershipIDs.join(',')
const selectedAgreement = ref(null)
const searchTerm = ref('')
const meiliSearchResults = ref([])
const meilisearchClient = ref()

onMounted(() => {
  meilisearchClient.value = new MeiliSearch({
    host: import.meta.env.VITE_MEILISEARCH_URL,
    apiKey: import.meta.env.VITE_MEILISEARCH_API_KEY
  })
})

const search = async query => {
  if (query) {
    meiliSearchResults.value = await meilisearchClient.value.index('customers').search(query, {
      filter: 'agreements.dealershipID IN [' + dealershipIDsString + ']'
    })
  } else {
    meiliSearchResults.value = []
  }
}

// Create a computed property to transform the data
const agreementsList = computed(() => {
  const transformedAgreements = []

  if (meiliSearchResults.value.hits != undefined) {
    for (const result of meiliSearchResults.value.hits) {
      for (const agreement of result.agreements) {
        const transformedAgreement = {
          ...result,
          ...agreement
        }

        delete transformedAgreement.agreements

        transformedAgreements.push(transformedAgreement)
      }
    }
  }

  return transformedAgreements
})

watch(searchTerm, newSearchTerm => {
  search(newSearchTerm)
})

function initFilters() {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    dealershipId: { value: null, matchMode: FilterMatchMode.IN },
    'user.id': { value: null, matchMode: FilterMatchMode.IN },
    wasDelivered: { value: null, matchMode: FilterMatchMode.IN }
  }
}
initFilters()

function clearFilters() {
  initFilters()
}

function fetchCallsData() {
  callsApiRequest
    .sendRequest({
      endpoint: 'v1/conversations/calls?',
      params: {
        start: dayjs(dateRange.value[0]).format('YYYY-MM-DD'),
        end: dayjs(dateRange.value[1]).format('YYYY-MM-DD')
      }
    })
    .then(response => {
      callsData.value = response.data.data
    })
}
fetchCallsData()

function deleteCall(id) {
  Swal.fire({
    title: 'Are you sure?',
    text: 'This action cannot be undone - not even by an admin!',
    icon: 'warning',
    reverseButtons: true,
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonText: 'Yes, delete this call',
    confirmButtonColor: 'var(--bg-error-solid)',
    cancelButtonText: 'Cancel'
  }).then(function (swalResponse) {
    if (swalResponse.isConfirmed && id) {
      try {
        deleteCallApiRequest.sendRequest({ endpoint: `/v1/conversations/calls/${id}`, method: 'DELETE' }).then(response => {
          if (response.data.success) {
            notification('Call deleted', null, 'success')

            // Remove call from table data
            let index = callsData.value.indexOf(call => call.id == id)
            if (index) {
              callsData.value.splice(index, 1)
            }
          }
        })
      } catch (err) {
        notification('Error', err.message)
      }
    }
  })
}

function onDateRangeChange() {
  if (dateRange.value[0] && dateRange.value[1]) {
    fetchCallsData()
  }
}

// Users List
const usersApiRequest = useApiRequest()
usersApiRequest.sendRequest({ endpoint: '/v1/users?userType=retain' }).then(response => {
  if (response.status == 200) {
    users.value = response.data.data
  }
})

const usersList = computed(() => {
  // if no users, return empty array
  if (!users.value) return []

  return users.value.map(u => {
    return {
      value: u.id,
      label: u.fullName,
      profilePicUrl: u.profilePicURL,
      initials: u.initials
    }
  })
})

const dealershipsList = computed(() => {
  return dealershipsAllowed.value.map(d => {
    return {
      value: d.id,
      label: d.name
    }
  })
})

function linkToCustomer(comm) {
  if (comm?.id && selectedAgreement.value.agreementID) {
    try {
      linkAgreementApiRequest
        .sendRequest({
          endpoint: '/v1/conversations/calls/' + comm.id,
          data: {
            agreementId: selectedAgreement.value.agreementID
          },
          method: 'PATCH'
        })
        .then(response => {
          if (response.data.success) {
            notification('Customer linked successfully', null, 'success')

            comm.customer.agreementId = selectedAgreement.value.agreementID
            comm.customer.name = selectedAgreement.value.name
          } else {
            notification('Error', 'Failed to link customer', 'danger')
          }
          selectedAgreement.value = null
        })
    } catch (err) {
      notification('Error', err.message, 'danger')
    }
  } else {
    notification('Error', 'Failed to link customer', 'danger')
  }
}

const exportCSV = () => {
  dt.value.exportCSV()
}
</script>

<style></style>
