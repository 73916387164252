import axios from 'axios'
import notification from '@/utilities/notification.js'
import socket from '@/services/socket.service.js'
import * as Sentry from '@sentry/vue'

import packageInfo from '/package.json'
let appVersion = packageInfo.version

import { fetchTodoCounter } from '@/scripts/counters.js'

import { defineStore } from 'pinia'

import useApiRequest from '@/composables/useApiRequest.js'

function updateSentryScope(data) {
  Sentry.configureScope(function (scope) {
    scope.setTag('user-type', data.type)
    scope.setUser({
      id: data.uid,
      email: data.email
    })
  })
}

async function setUserData($this, data) {
  // Get first character of forename and surname to create initials
  let initials = data.payload.userForename.charAt(0) + data.payload.userSurname.charAt(0)

  $this.details = {
    id: data.payload.uid,
    type: data.payload.ut,
    level: data.payload.ul,
    email: data.payload.email,
    name: data.payload.userName,
    forename: data.payload.userForename,
    surname: data.payload.userSurname,
    initials: initials,
    profilePicUrl: data.payload.profilePicUrl
  }

  // Temp: save token to local storage due to Safari not liking sending the httpOnly cookie // TODO: Remove
  localStorage.setItem('token', data.token)

  updateSentryScope(data.payload)

  $this.registerUserSocket()

  $this.outstandingTodos = await fetchTodoCounter(data.payload.uid)
}

export const useUserStore = defineStore('userStore', {
  state: () => ({
    usersPresence: [],
    socketConnected: [],
    userSettings: JSON.parse(localStorage.getItem('userSettingsLK')) ?? {}, //LK = Last Known, ie. the settings which were set before a page refresh or session timeout
    details: {
      id: null,
      type: null,
      email: null,
      name: null,
      forename: null,
      surname: null,
      initials: null,
      profilePicUrl: null
    },
    roles: [],
    accessAllowed: {
      regions: [],
      manufacturers: [],
      groups: [],
      dealerships: []
    },
    isLoggedIn: false,
    isAuthenticating: false,
    isVerifying: false,
    outstandingTodos: null
  }),
  actions: {
    updateUsersPresence(usersPresences) {
      this.usersPresence = usersPresences
    },
    updateSocketStatus(socketConnected) {
      this.socketConnected = socketConnected
    },
    setAllSettings(settingsFromDB) {
      //Function sets user's settings which have been set from the DB. Only called on MainLayout on login check
      this.userSettings = settingsFromDB
      //Set in LS, so that if the user reloads the page, the last settings are initially set before getting actual settings from API. This will avoid a split second of incorrect settings.
      localStorage.setItem('userSettingsLK', JSON.stringify(settingsFromDB))
    },
    updateUserSettings(newSettings) {
      // console.log("ACTION: updateUserSettings")
      let currentState = this.userSettings
      let settingsObj = {}

      // console.log(newSettings)

      //loop through each setting passed and update the state
      newSettings.forEach(setting => {
        // console.log("Updating setting: " + setting.name)

        currentState[setting.name] = setting.value
        settingsObj[setting.name] = setting.value
      })

      //Use AXIOS to commit the changes to the user's settings to the DB// AXIOS post new task
      try {
        axios
          .patch(import.meta.env.VITE_API_BASE_URL + '/v1/me/' + this.details.id + '/settings', settingsObj, {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          })
          .then(response => {
            // console.log('Response: ' + JSON.stringify(response.data))

            if (response.status != 200) {
              notification('Error', 'Failed updating settings', 'danger')
              if (response.status === 401) {
                notification('Error', '401 Unauthorized', 'danger')
                throw Error('401 Unauthorized')
              } else {
                notification('Error', response.message, 'danger')
                throw Error('Error patching data')
              }
            }

            if (response.data.response.status == 'error') {
              notification('Error', 'Failed updating settings', 'danger')
              throw Error('Error patching data. Setting does not exist')
            }

            this.setAllSettings(currentState)
          })
      } catch (err) {
        if (err.response) {
          console.log(err.response.status)
          console.log(err.response.data)
        }
      }
    },
    updateRoles(roles) {
      // console.log('UserStore - updateRoles()', roles)
      this.roles = roles
    },
    updateAccessAllowed(access) {
      // console.log('UserStore - updateAccessAllowed()', access)
      this.accessAllowed = access
    },
    async login(credentials) {
      // console.log('UserStore - login() - ', credentials)
      this.isAuthenticating = true

      try {
        // Use template literals and the URLSearchParams API for better query string handling
        const params = new URLSearchParams({
          email: credentials.e,
          password: credentials.p
        })

        const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/v1/auth?${params}`, {
          withCredentials: true
        })

        // console.log(response)

        if (response.data.response.status === 'error') {
          if (response.data.response.message === 'No account found' || response.data.response.message === 'Password Incorrect') {
            this.isAuthenticating = false
            this.isLoggedIn = false
            return {
              success: false,
              message: 'Credentials Incorrect'
            }
          } else {
            throw new Error(response.data.response.message)
          }
        }

        setUserData(this, response.data)

        this.isAuthenticating = false
        this.isLoggedIn = true
        return {
          success: true,
          message: null
        }
      } catch (err) {
        // Handle or log the error.
        console.error(err.message)
        this.isAuthenticating = false

        notification('Warning', 'There was an issue logging you out on our end', 'warning')
        return {
          success: false,
          message: err.message
        }
      } finally {
        // console.log('login() complete')
      }
    },
    async verify() {
      // console.log('UserStore - verify()')
      this.isVerifying = true

      let headers = {}

      // If localstorage item is set, and is not null
      if (localStorage.getItem('token') && localStorage.getItem('token') != 'null') {
        // Pass auth header if token is present
        headers = { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }

      try {
        let response = await axios.get(import.meta.env.VITE_API_BASE_URL + '/v1/verify', {
          withCredentials: true,
          headers: headers
        })

        // console.log(response)

        if (response.status === 503) {
          //Tell socket server that the user has logged out
          socket.emit('user-unregister')

          this.router.push('/maintenance')
          throw Error('503 Under Maintenance')
        }

        if (response.data.response.status != 'success') {
          if (response.status === 401) {
            //Tell socket server that the user has been logged out
            socket.emit('user-unregister')

            this.router.push('/login')
            throw Error('401 Unauthorized')
          } else {
            throw new Error('Error fetching data')
          }
        }

        // console.log('UserStore - verify() - Response: ', response.data)

        setUserData(this, response.data)

        //Get user settings from server
        try {
          // console.log('fetching users settings')

          useApiRequest()
            .sendRequest({ endpoint: '/v1/me/' + this.details.id + '/settings' })
            .then(response => {
              if (response.status != 200) {
                if (response.status === 401) {
                  throw Error('401 Unauthorized')
                } else {
                  throw Error('Error fetching data')
                }
              }

              // If everything is fine, Update Pinia userStore
              this.setAllSettings(response.data.data)
            })
        } catch (err) {
          console.log('ERROR: ' + err.message)
          notification('Error', err.message, 'danger')
        }

        this.isLoggedIn = true
        this.isVerifying = false
        return {
          success: true,
          message: null
        }
      } catch (err) {
        // console.error(err.message)
        this.isLoggedIn = false
        this.isVerifying = false

        notification('Warning', 'There was an error verifying your session ' + (err.response.status || ''), 'warning')
        // throw Error('400 - No token sent, therefore user not verified')

        return {
          success: false,
          message: err.message
        }
      } finally {
        // console.log('login() complete')
      }
    },
    async signOut(routeToLogin = true) {
      // console.log('signOut()')
      // console.log('Sending logout request to api')

      try {
        await axios.post(import.meta.env.VITE_API_BASE_URL + '/v1/logout', {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
      } catch (err) {
        // Handle or log the error.

        // If 400, then no token was sent, therefore user was not logged in in the first place
        if (err.response && err.response.status === 400) {
          // console.log('400 - No token sent, clearing all data')
        } else {
          notification('Warning', 'There was an issue verifying your session ' + (err.response.status || ''), 'warning')
          notification('Warning', 'There was an issue signing you out ' + (err.response.status || ''), 'warning')
        }

        // console.error('Error during logout:', err)
      } finally {
        // Cleanup activities regardless of server response.
        localStorage.removeItem('accessSelected')

        // Temp: save token to local storage due to Safari not liking sending the httpOnly cookie // TODO: Remove
        localStorage.setItem('token', null)

        socket.emit('user-logged-out')
        this.isLoggedIn = false
        if (routeToLogin) {
          this.router.push('/login')
        }
      }
    },
    registerUserSocket() {
      //Register user on socket.io
      // console.log('UserStore - Registering User on socket server')

      socket.emit('user-register', {
        uid: this.details.id,
        ut: this.details.type,
        appVersion: appVersion
      })
    }
  }
})
