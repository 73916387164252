<template>
  <div class="rating-items flex">
    <div
      v-for="item in ratingItems"
      :key="item.value"
      class="rating-item"
      :class="['rating-item-' + size, { 'item-selected': item.value <= modelValue }]"
    >
      <Icon v-tippy="{ content: item.tooltip }" :type="item.icon" fa-style="fas" @click="changeValue(item.value)" />
    </div>
  </div>
  <input type="hidden" :value="modelValue" @change="$emit('update:modelValue', $event.target.value)" />
</template>

<script>
import Icon from '@/components/icon/Icon.vue'
export default {
  components: {
    Icon
  },
  props: {
    modelValue: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    let ratingItems = [
      {
        icon: 'face-angry',
        tooltip: 'Angry',
        value: 1
      },
      {
        icon: 'face-frown-slight',
        tooltip: 'Unhappy',
        value: 2
      },
      {
        icon: 'face-meh',
        tooltip: 'Neutral',
        value: 3
      },
      {
        icon: 'face-smile',
        tooltip: 'Content',
        value: 4
      },
      {
        icon: 'face-awesome',
        tooltip: 'Extatic',
        value: 5
      }
    ]

    function changeValue(value) {
      // console.log("change to: " + value)
      props.modelValue = value

      emit('update:modelValue', value)
    }

    return { ratingItems, changeValue }
  }
}
</script>

<style>
.rating-items > .rating-item-sm {
  font-size: 1.4rem;
}
.rating-items > .rating-item-md {
  font-size: 1.8rem;
}
.rating-items > .rating-item-lg {
  font-size: 2.4rem;
}
.rating-items > .rating-item {
  color: var(--fg-quaternary);
  cursor: pointer;
}
.rating-items > .rating-item:hover {
  color: var(--fg-secondary);
}
.rating-items > .rating-item.item-selected {
  color: #f1c40f;
}
</style>
