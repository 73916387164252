<template>
  <Dropdown :padding="false" :position="position" :close-on-click-away="false">
    <template #triggerContent>
      <Input v-model="inputString" icon="calendar" class="min-w-[250px]" />
    </template>
    <DatePickerCalendar
      v-model="dates"
      :selection-mode="selectionMode"
      :max-date="maxDate"
      :number-of-months="numberOfMonths"
      @change-applied="applyChanges"
    />
  </Dropdown>
</template>

<script>
import { ref, watch } from 'vue'

import DatePickerCalendar from './DatePickerCalendar.vue'

import dayjs from 'dayjs'

import Dropdown from '@/components/dropdown/Dropdown.vue'
import Input from '@/components/forms/Input.vue'

export default {
  components: {
    DatePickerCalendar,
    Dropdown,
    Input
  },
  props: {
    modelValue: {
      type: null,
      required: true
    },
    selectionMode: {
      type: String,
      default: 'single'
    },
    position: {
      type: String,
      default: 'bottom-left'
    },
    reactiveDates: {
      type: Boolean,
      default: false
    },
    maxDate: {
      type: Date,
      default: null
    },
    numberOfMonths: {
      type: Number,
      default: 1
    }
  },
  emits: ['update:modelValue', 'changeApplied'],
  setup(props, { emit }) {
    const dates = ref(props.modelValue)
    const inputString = ref(null)

    function setInputValue() {
      if (dates.value) {
        if (props.selectionMode === 'single') {
          inputString.value = dayjs(dates.value).format('ll')
        } else if (props.selectionMode === 'range') {
          inputString.value = dayjs(dates.value[0]).format('ll') + ' - ' + dayjs(dates.value[1]).format('ll')
        }
      }
    }
    setInputValue()

    watch(dates, newValue => {
      setInputValue()

      if (props.reactiveDates) {
        emit('update:modelValue', newValue)
      }
    })

    function applyChanges() {
      emit('update:modelValue', dates.value)
      emit('changeApplied', dates.value)
    }

    return { dates, inputString, applyChanges }
  }
}
</script>

<style>
.p-datepicker {
  border: none;
}
</style>
