<template>
  <PageHeader
    :title="$t('ui.entities.finance.statement', 2)"
    breadcrumb
    :items="[
      { label: $t('ui.entities.finance.label'), to: '/finance' },
      { label: $t('ui.entities.finance.invoicing'), to: '/finance/invoicing' },
      { label: $t('ui.entities.finance.statement', 2), to: '/finance/invoicing/statements' }
    ]"
  >
  </PageHeader>

  <Card>
    <InvoiceStatementsTable />
  </Card>
</template>

<script>
import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'

import InvoiceStatementsTable from '@/components/unique/tables/InvoiceStatementsTable.vue'

export default {
  components: {
    PageHeader,
    Card,
    InvoiceStatementsTable
  },
  setup() {
    return {}
  }
}
</script>

<style></style>
