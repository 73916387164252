import { ref } from 'vue'
import axios from 'axios'
import notification from '@/utilities/notification'

export default function useApiRequest() {
  const apiResponse = ref(null)
  const responseData = ref(null)
  const isLoading = ref(false)
  const error = ref(null)

  const sendRequest = async ({
    baseURL = import.meta.env.VITE_API_BASE_URL,
    endpoint,
    method = 'GET',
    params = {},
    data = null,
    headers = {}
  }) => {
    // Additional debug logs
    if (!baseURL) {
      console.error(`Error: baseURL is undefined. Endpoint: ${endpoint}`)
      notification('Error', `baseURL is undefined for endpoint: ${endpoint}`, 'danger')
      return { error: true, message: `baseURL is undefined for endpoint: ${endpoint}` }
    }

    // console.log(baseURL, endpoint, method)

    isLoading.value = true

    try {
      const response = await axios({
        baseURL,
        url: endpoint,
        method,
        params,
        data,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // TODO: remove this temp fix for safari browsers not liking httpOnly cookies
          ...headers
        }
      })

      // console.log('useApiRequest() Response: ')
      // console.log(response)
      if (!response || !response.status) {
        error.value = 'Endpoint failed'
        throw Error('No response from API, endpoint failed: ' + endpoint)
      }

      if (response.status !== 200) {
        if (response.status === 401) {
          error.value = '401'
          throw Error('401 Unauthorized')
        } else {
          error.value = 'Error fetching data'
          throw Error('Error fetching data')
        }
      }

      // Check if api response error
      if (response.data.response.status == 'error') {
        error.value = response.data.response.status
        notification('Error', response.data.response.message, 'danger')
      }

      apiResponse.value = response.data
      responseData.value = response.data.data
      error.value = null
      return response // TODO: return response.data to avoid response.data.data in components
    } catch (err) {
      console.error(`Error while requesting endpoint ${endpoint}:`, err.message)
      notification('Error', err.message, 'danger')
      return { error: true, message: err.message } // Return an error response
    } finally {
      isLoading.value = false
      // console.log(responseData.value)
    }
  }

  return {
    response: apiResponse,
    data: responseData,
    isLoading,
    error,
    sendRequest
  }
}
