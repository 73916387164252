<template>
  <label class="input-custom-checkbox" :class="['input-custom-checkbox-' + size, 'text-' + size]">
    <input class="hidden" type="checkbox" :checked="currentValue" :disabled="readonly" @change="updateValue" />
    <div
      class="input-custom-checkbox-box"
      :class="[{ checked: currentValue, 'input-custom-checkbox-readonly': readonly }]"
      :style="
        checkboxColor && currentValue ? 'background-color: var(--' + checkboxColor + '); border-color: var(--' + checkboxColor + ');' : ''
      "
    ></div>
    <div class="select-none ml-3" :class="{ 'leading-5': size === 'sm', 'leading-6': size === 'md', 'leading-7': size === 'lg' }">
      <div v-if="label" class="flex gap-4 items-center">
        <div class="font-medium">{{ label }}</div>
      </div>
      <slot v-else name="label"></slot>
      <div v-if="description" class="text-tertiary max-w-[500px]">{{ description }}</div>
      <slot v-else name="description"></slot>
    </div>
  </label>
</template>

<script>
import { computed } from 'vue'

export default {
  props: {
    modelValue: {
      type: Boolean,
      default: null
    },
    isChecked: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'sm'
    },
    checkboxColor: {
      type: String,
      default: null
    },
    noMargin: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'change'],
  setup(props, context) {
    const currentValue = computed(() => {
      if (props.modelValue !== null) {
        return props.modelValue
      } else {
        return props.isChecked
      }
    })

    const updateValue = () => {
      if (props.modelValue !== null) {
        context.emit('update:modelValue', !props.modelValue)
      }
      context.emit('change', !props.isChecked)
    }

    return { updateValue, currentValue }
  }
}
</script>

<style>
.input-custom-checkbox {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
}
.input-custom-checkbox-box {
  border: 1px solid var(--border-primary);
  border-radius: 3px;
  display: inline-block;
}
.input-custom-checkbox-sm .input-custom-checkbox-box {
  height: 16px;
  width: 16px;
}
.input-custom-checkbox-md .input-custom-checkbox-box {
  height: 20px;
  width: 20px;
}
.input-custom-checkbox-lg .input-custom-checkbox-box {
  height: 24px;
  width: 24px;
}
.input-custom-checkbox-readonly {
  cursor: not-allowed;
  opacity: 0.5;
}
.input-custom-checkbox-box.checked {
  background-color: var(--fg-brand);
  border-color: var(--fg-brand);
  position: relative;
}
.input-custom-checkbox-box.checked::after {
  font-family: 'Font Awesome 6 Pro';
  content: '\f00c';
  font-weight: 900;
  color: #fff;
  position: absolute;
}

.input-custom-checkbox-sm .checked::after {
  font-size: 0.7rem;
  top: 0;
  left: 3px;
}
.input-custom-checkbox-md .checked::after {
  font-size: 0.75rem;
  top: 1px;
  left: 4px;
}
.input-custom-checkbox-lg .checked::after {
  font-size: 0.9rem;
  top: 2px;
  left: 6px;
}
</style>
