<template>
  <div></div>
</template>

<script>
export default {
  components: {},
  setup() {
    return {}
  }
}
</script>

<style></style>
