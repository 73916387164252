export const StockService = {
  getStockData() {
    return [
      {
        finance: {
          amount: 121,
          type: 'PCP'
        },
        id: 1,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/27e34e4c099d48b882a0fe1e43633a17.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/3fdae53e708b46e099caeaff82ca22d1.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/2631750783554e7590396a9d7f9941b1.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/5dc3a6766fd64ef59cbb888047477d18.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202307089425174?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=14&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Adventurer',
        price: 3500,
        retailer: 'Essex Motorcycles',
        retailer_location: 'Westcliff On Sea',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10018867/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/essex/westcliff-on-sea/essex-motorcycles-10018867?channel=bikes'
      },
      {
        finance: {
          amount: 287,
          type: 'PCP'
        },
        id: 2,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/2336310462cf411f8194e9c5acbb7338.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f03c045d672343149896629a163c9c9a.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/6944110fe9dc44dba45d521a1e8a7818.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/ad1655ed283044dfa87430d8d685a4f5.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202306138465529?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=6&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'America',
        price: 3589,
        retailer: 'SuperBike Factory Donington Park',
        retailer_location: 'Derby',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10029990/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/leicestershire/derby/superbike-factory-donington-park-10029990?channel=bikes'
      },
      {
        finance: {
          amount: 151,
          type: 'PCP'
        },
        id: 3,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/566da29d25b642ab9c1b231080dfe7d4.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/ada59f0281b544bda510213f197a8034.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/90dabfaca36e4bdbb17af525cf9465c6.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4b63456c564b4fefa9640aef066ddb43.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202306028078351?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=1&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'America',
        price: 3884,
        retailer: 'SuperBike Factory London',
        retailer_location: 'Kennington',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10019541/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/surrey/kennington/superbike-factory-london-10019541?channel=bikes'
      },
      {
        finance: {
          amount: 271,
          type: 'PCP'
        },
        id: 4,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/566da29d25b642ab9c1b231080dfe7d4.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/ada59f0281b544bda510213f197a8034.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/90dabfaca36e4bdbb17af525cf9465c6.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4b63456c564b4fefa9640aef066ddb43.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202302224523778?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=7&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'America',
        price: 3884,
        retailer: 'SuperBike Factory Buckinghamshire',
        retailer_location: 'Milton Keynes',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10035869/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/buckinghamshire/milton-keynes/superbike-factory-buckinghamshire-10035869?channel=bikes'
      },
      {
        finance: {
          amount: 299,
          type: 'PCP'
        },
        id: 5,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/566da29d25b642ab9c1b231080dfe7d4.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/ada59f0281b544bda510213f197a8034.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/90dabfaca36e4bdbb17af525cf9465c6.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4b63456c564b4fefa9640aef066ddb43.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202306028078351?journey=PROMOTED_LISTING_JOURNEY&amp;amp;sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=7&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'America',
        price: 3884,
        retailer: 'SuperBike Factory London',
        retailer_location: 'Kennington',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10019541/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/surrey/kennington/superbike-factory-london-10019541?channel=bikes'
      },
      {
        finance: {
          amount: 260,
          type: 'PCP'
        },
        id: 6,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/107b21465ab84b2abd21dc051a266e43.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/fa2363890c8546469a4d5c31db59adf8.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/fa61bc29b68f4cdc9416d7df69091d47.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/962d9951c5a7459fb11f2e64be380cf6.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308221077136?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=10&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'America',
        price: 4795,
        retailer: 'Hayball Motorcycles Ltd',
        retailer_location: 'Salisbury',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/76922/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/wiltshire/salisbury/hayball-motorcycles-ltd-76922?channel=bikes'
      },
      {
        finance: {
          amount: 232,
          type: 'PCP'
        },
        id: 7,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f7c05e6cba48473bbe4365af45390e89.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/433df03133d54d44832d49b6178f5844.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/9d145507e99e4bd982121ca99d537259.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/85c4972abd9a466d97bafba90c304e2c.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308100637579?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=3&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'America',
        price: 5250,
        retailer: 'Wooburn Green Motorcycles',
        retailer_location: 'High Wycombe',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10019488/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/buckinghamshire/high-wycombe/wooburn-green-motorcycles-10019488?channel=bikes'
      },
      {
        finance: {
          amount: 143,
          type: 'PCP'
        },
        id: 8,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/bcfe23f0545a46ff9fd01f73e3035b58.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/b68ef14df8134144aa6ed7a5f17e9691.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/5e808a6ef39548beb059cedabc58098c.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/906b87964cb848b6a5112baa3769a378.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308110666383?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=8&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'America',
        price: 5650,
        retailer: 'Wooburn Green Motorcycles',
        retailer_location: 'High Wycombe',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10019488/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/buckinghamshire/high-wycombe/wooburn-green-motorcycles-10019488?channel=bikes'
      },
      {
        finance: {
          amount: 229,
          type: 'PCP'
        },
        id: 9,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/fc1117ba6c384ee9ae66e370eb4efe32.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/71bbc66fc44c4b339f61a91db10d5a93.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/3a3be57363d8482ab98bf47c8b6dd7f3.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/7bb2d525679b4d559ca761b4728c1c37.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308030402942?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=4&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'America',
        price: 5950,
        retailer: 'Woodford Motor Co Ltd',
        retailer_location: 'Woodford Green',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/6702/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/greater-london/woodford-green/woodford-motor-co-ltd-6702?channel=bikes'
      },
      {
        finance: {
          amount: 242,
          type: 'PCP'
        },
        id: 10,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/fce3b087ee934a3497c0977b2375f756.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/c51a4d679a5e4e9f8758e7dde46b0955.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/35b0654dd7844306bfcf97f7d368a241.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/5c863e2c531747959a702feef7e8c1ea.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202303275675878?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=3&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Bobber',
        price: 7995,
        retailer: 'Pure Triumph Woburn',
        retailer_location: 'Milton Keynes',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/284180/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/buckinghamshire/milton-keynes/pure-triumph-woburn-284180?channel=bikes'
      },
      {
        finance: {
          amount: 280,
          type: 'PCP'
        },
        id: 11,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/3d119d3c3c894cd8beeb1b564937ac1d.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/996943e0ba15484fbbc50e07bc4a5e2a.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/be64e71b05134f0c8f1658e9ae052590.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/6f468dff03564266b952ca9014c006b7.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308190965295?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=5&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Bobber',
        price: 9995,
        retailer: 'Greenham Kawasaki',
        retailer_location: 'Thatcham',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10007609/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/berkshire/thatcham/greenham-kawasaki-10007609?channel=bikes'
      },
      {
        finance: {
          amount: 152,
          type: 'PCP'
        },
        id: 12,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/5bdca3395a474294a2f08f46c29ec950.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/d6c46d0de8ce43e59dbd59f127431a1c.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/c4bd3e5ede214838b528051a3bee6abe.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/ba9437a7b725421099401d0c68e989dc.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308110684619?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=4&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Bonneville 865',
        price: 3295,
        retailer: 'P &amp;amp; P Motorcycles',
        retailer_location: 'Hayling Island',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/27048/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/hampshire/hayling-island/p-p-motorcycles-27048?channel=bikes'
      },
      {
        finance: {
          amount: 154,
          type: 'PCP'
        },
        id: 13,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/dea629dcf62c4791b1390f53e61ad89a.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f7013629e2954fad80ecfb8fe2480f12.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/1eb49a8cfcc94190830fea9282254c7f.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/137f2697eb3b4320b079bdd00d3d176c.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202307109497631?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=1&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Bonneville 865',
        price: 3989,
        retailer: 'SuperBike Factory Buckinghamshire',
        retailer_location: 'Milton Keynes',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10035869/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/buckinghamshire/milton-keynes/superbike-factory-buckinghamshire-10035869?channel=bikes'
      },
      {
        finance: {
          amount: 242,
          type: 'PCP'
        },
        id: 14,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f62d4aed460b4558a8691de2ecb4e105.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/9eb357b185024a2097bbe626f69a5be0.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/b56c8efd6c824c2ca9db3972cacec84a.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/76a4abe2447447c5b4cebad791cb29c9.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202306299103109?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=10&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Bonneville 865',
        price: 4286,
        retailer: 'SuperBike Factory London',
        retailer_location: 'Kennington',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10019541/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/surrey/kennington/superbike-factory-london-10019541?channel=bikes'
      },
      {
        finance: {
          amount: 290,
          type: 'PCP'
        },
        id: 15,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/880a8962486142068e195dc4e76c491d.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/a9ce911868954d81949e42bad7c047b9.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/61e141df1166437587ebcd0b10e92054.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4b63456c564b4fefa9640aef066ddb43.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308180952745?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=14&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Bonneville 865',
        price: 4391,
        retailer: 'SuperBike Factory Cheshire',
        retailer_location: 'Macclesfield',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/602605/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/cheshire/macclesfield/superbike-factory-cheshire-602605?channel=bikes'
      },
      {
        finance: {
          amount: 236,
          type: 'PCP'
        },
        id: 16,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/a9c20f8246b447cd996a768a7bf659eb.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/30e4ebfd56224691a44fa86ce1227429.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/1e4af768b338496bae21c8def264a5f6.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/a534889ebdf24eb88df24f4906ccbf2b.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308241166653?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=3&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Bonneville 865',
        price: 4399,
        retailer: 'John W Groombridge Motorcycles Ltd',
        retailer_location: 'Heathfield',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10024493/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/east-sussex/heathfield/john-w-groombridge-motorcycles-ltd-10024493?channel=bikes'
      },
      {
        finance: {
          amount: 110,
          type: 'PCP'
        },
        id: 17,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/cfd40c6caf13403ca0749b09d464fba1.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/6932a5857c804b5d8e3fd12e826c98a4.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4971dc05780e403ca054bd88d4b997c3.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4c6faed1048b4f23a0f09d37faae50dd.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202305046990884?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=6&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Bonneville 865',
        price: 4598,
        retailer: 'Colchester Kawasaki',
        retailer_location: 'Colchester',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/549671/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/essex/colchester/colchester-kawasaki-549671?channel=bikes'
      },
      {
        finance: {
          amount: 232,
          type: 'PCP'
        },
        id: 18,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/ce0f417eaa2d4950b4b98ea3cd3f8e24.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/cd3f73ed845a46bf99cef9dbc90ba0dc.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/038d0456eab74f048d76492d527ad4f3.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4bd513e6189e415ca2860cb2a5f441f5.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202306078270150?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=7&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Bonneville 865',
        price: 4750,
        retailer: 'JRP Motorcycles',
        retailer_location: 'Ashtead',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10019744/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/surrey/ashtead/jrp-motorcycles-10019744?channel=bikes'
      },
      {
        finance: {
          amount: 174,
          type: 'PCP'
        },
        id: 19,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/942d9a850ad149f8858c6c92759610d4.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/3569c50e0594414984896162721a20cd.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/bee9cb3aa33e4ab184950d4a63d54306.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/935c621973ae4d11a0df901fb83d4386.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202306158556504?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=5&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Bonneville 865',
        price: 4790,
        retailer: 'Budget Bikes',
        retailer_location: 'Swindon',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/26816/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/wiltshire/swindon/budget-bikes-26816?channel=bikes'
      },
      {
        finance: {
          amount: 162,
          type: 'PCP'
        },
        id: 20,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/d7a82ea2179f42e9b8ef3ae5283e617c.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/d3d9c17ae4b54c18b8c10c9462ca8d4a.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/0aa73948d0524c77acc85678a609ac29.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4b63456c564b4fefa9640aef066ddb43.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202307119540607?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=13&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Bonneville 865',
        price: 4987,
        retailer: 'SuperBike Factory Buckinghamshire',
        retailer_location: 'Milton Keynes',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10035869/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/buckinghamshire/milton-keynes/superbike-factory-buckinghamshire-10035869?channel=bikes'
      },
      {
        finance: {
          amount: 125,
          type: 'PCP'
        },
        id: 21,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/0b51fe6d3c8943ccba84dcc54771e157.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/d52daf8c4b7545698f26fab6a7d51216.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/bfe669f426ee4375aa85181f02fd736a.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/eec6e67ba12645bc943dcf65b5171c90.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308060497329?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=4&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Bonneville T100',
        price: 3995,
        retailer: 'KOTI AUTOTALLI LTD',
        retailer_location: 'Chipping Campden',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10029655/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/gloucestershire/chipping-campden/koti-autotalli-ltd-10029655?channel=bikes'
      },
      {
        finance: {
          amount: 196,
          type: 'PCP'
        },
        id: 22,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/868cff3133a74a9e92c4a0457eb1f8f3.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/a9ce911868954d81949e42bad7c047b9.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/1306d9fb7746466fb61ebc04fe9e82b7.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4b63456c564b4fefa9640aef066ddb43.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308010302312?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=11&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Bonneville T100',
        price: 4591,
        retailer: 'SuperBike Factory Cheshire',
        retailer_location: 'Macclesfield',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/602605/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/cheshire/macclesfield/superbike-factory-cheshire-602605?channel=bikes'
      },
      {
        finance: {
          amount: 116,
          type: 'PCP'
        },
        id: 23,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/61ceb424548542729014771660f9d595.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/cc22e5ff0de4463bb8cd298c0e364603.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/93422204abaa48b9ab2d9da5ce17a7f8.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/5a7d8599ec4b46ec98d0257a99d292d6.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202302284710661?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=10&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Bonneville T100',
        price: 4650,
        retailer: 'Wooburn Green Motorcycles',
        retailer_location: 'High Wycombe',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10019488/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/buckinghamshire/high-wycombe/wooburn-green-motorcycles-10019488?channel=bikes'
      },
      {
        finance: {
          amount: 236,
          type: 'PCP'
        },
        id: 24,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/b7482624566348eb8a66079d18a5c762.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/471493d4657143559a807cdff69ae45e.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/bc475346349b48b48fdc30d3555b9802.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/0f9c84248a314a59ae5a52ebfd8708bd.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202303225503885?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=8&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Bonneville T100',
        price: 4899,
        retailer: 'Maverick Motorcycles',
        retailer_location: 'Croydon',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/47894/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/greater-london/croydon/maverick-motorcycles-47894?channel=bikes'
      },
      {
        finance: {
          amount: 154,
          type: 'PCP'
        },
        id: 25,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/aa27e3ebcc084684a74f191118467327.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/5728e74f2afb45b6b6f63f9f038440d0.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/70436658d9f94f9e9d68b31dec9da0e7.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4b63456c564b4fefa9640aef066ddb43.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202304056008054?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=14&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Bonneville T100',
        price: 5485,
        retailer: 'SuperBike Factory Buckinghamshire',
        retailer_location: 'Milton Keynes',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10035869/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/buckinghamshire/milton-keynes/superbike-factory-buckinghamshire-10035869?channel=bikes'
      },
      {
        finance: {
          amount: 145,
          type: 'PCP'
        },
        id: 26,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4f9d06b5c1294657bccf30522da660e0.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/7084373407004d30ba5d9d9f87eef2ad.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/5536f17c496840bba9e8e437213acf97.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/1f27a069e9434b73b46eeadcea0e6457.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202307049258176?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=12&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Bonneville T100',
        price: 5699,
        retailer: 'Hayball Motorcycles Ltd',
        retailer_location: 'Salisbury',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/76922/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/wiltshire/salisbury/hayball-motorcycles-ltd-76922?channel=bikes'
      },
      {
        finance: {
          amount: 265,
          type: 'PCP'
        },
        id: 27,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/3596acd449b04e29940c11e588057012.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/0bf9c47e16244ed9b66090805b40c2de.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/ee90b96402564ae5b30ce62c531e3c6a.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/a6defb5477d14699bfa522c53a129225.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308010316615?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=11&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Bonneville T100',
        price: 7189,
        retailer: 'SuperBike Factory Buckinghamshire',
        retailer_location: 'Milton Keynes',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10035869/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/buckinghamshire/milton-keynes/superbike-factory-buckinghamshire-10035869?channel=bikes'
      },
      {
        finance: {
          amount: 217,
          type: 'PCP'
        },
        id: 28,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/cd7a890f1f7d419c8f0a659880a1204e.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/46dfa67804b844619defbb620067508b.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/ffa0d28585a940ad9b8fb40a0772c0b6.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/29b60675860c4042b8cdc8880ea16acb.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308110700631?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=12&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Daytona 1200',
        price: 3295,
        retailer: 'Open Road Motorcycles',
        retailer_location: 'Farnborough',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/200153/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/hampshire/farnborough/open-road-motorcycles-200153?channel=bikes'
      },
      {
        finance: {
          amount: 167,
          type: 'PCP'
        },
        id: 29,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/3be8b9402df14f8b9a1f9e4756cadc6f.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/c63629dfde5a4d4c85a52fb1e38e61fb.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f2b0bdb9a2ad42db8cd9cb571e73c155.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/d2b8dfa7bbc74a4d8cd99788ba23a37c.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202304216536503?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=7&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Daytona 600',
        price: 3190,
        retailer: 'Budget Bikes',
        retailer_location: 'Swindon',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/26816/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/wiltshire/swindon/budget-bikes-26816?channel=bikes'
      },
      {
        finance: {
          amount: 214,
          type: 'PCP'
        },
        id: 30,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/1a6f8e6abfa4425fb310e4e164008c56.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/18f208adc54b4e8aa9d382a610cd1b23.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/2941be33d1c14a31aae326a81c516bc8.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/84a6d2e83b314929a34e42ccef977a58.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202306038101258?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=10&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Daytona 600',
        price: 3400,
        retailer: 'Powerbiking Ltd',
        retailer_location: 'High Wycombe',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/28602/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/buckinghamshire/high-wycombe/powerbiking-ltd-28602?channel=bikes'
      },
      {
        finance: {
          amount: 147,
          type: 'PCP'
        },
        id: 31,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4089762f60ea424091cf76fe519b3c68.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/a9ce911868954d81949e42bad7c047b9.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/2a299b207b4a4761a5a840f3359690df.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4b63456c564b4fefa9640aef066ddb43.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308241148719?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=2&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Daytona 675',
        price: 3991,
        retailer: 'SuperBike Factory Donington Park',
        retailer_location: 'Derby',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10029990/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/leicestershire/derby/superbike-factory-donington-park-10029990?channel=bikes'
      },
      {
        finance: {
          amount: 128,
          type: 'PCP'
        },
        id: 32,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/8fa0e0615da847388c7d9bf2d8fde5cf.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/903a9b17b4704f13a84f708df5eb9066.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/0a72f1fd86fc48f09d43159384e65e35.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/aa5bd60517b14752a5afe3912eff865d.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202303295745177?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=10&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Daytona 675',
        price: 4490,
        retailer: 'Budget Bikes',
        retailer_location: 'Swindon',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/26816/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/wiltshire/swindon/budget-bikes-26816?channel=bikes'
      },
      {
        finance: {
          amount: 212,
          type: 'PCP'
        },
        id: 33,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f7d4635d92b3443989e6054f48f0fdc9.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/ae0f3e740555441e863bb9efcae01956.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/889854f68d0f4978bd6a795e8feadfb5.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/b38a252efaf448bcaee3b70a9bda2e2b.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202306128414791?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=5&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Daytona 675',
        price: 6600,
        retailer: 'Woodford Motor Co Ltd',
        retailer_location: 'Woodford Green',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/6702/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/greater-london/woodford-green/woodford-motor-co-ltd-6702?channel=bikes'
      },
      {
        finance: {
          amount: 268,
          type: 'PCP'
        },
        id: 34,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f8b930bba5d5402a8b8f6a0cc769351f.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/0a19893148c348c1b3bcea21f357b54f.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/5019cd42fae54e8ca4c7ea29c10c6e03.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/140456e0c4d54ed2ae535787a10309d9.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202305277852488?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=6&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Daytona T595',
        price: 2999,
        retailer: 'Bennetts Motorcycle and Scooter LTD',
        retailer_location: 'Leicester',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10031942/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/leicestershire/leicester/bennetts-motorcycle-and-scooter-ltd-10031942?channel=bikes'
      },
      {
        finance: {
          amount: 298,
          type: 'PCP'
        },
        id: 35,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/c25d92209bb245f594ce963958e8b651.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/737ea31894f84005876d24bdbefe0198.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/2f24f94f919b4a7895d74da99f3f8d6f.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/b0c4ea46717140bd99972d648e061671.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202301133234983?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=10&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Daytona T595',
        price: 3999,
        retailer: 'Platinum Motorcycles',
        retailer_location: 'Hook',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/28957/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/hampshire/hook/platinum-motorcycles-28957?channel=bikes'
      },
      {
        finance: {
          amount: 132,
          type: 'PCP'
        },
        id: 36,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f27a2211888040738e392cd9e41a3756.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f4728d8e4fd4430f90d703d18bba1e9e.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/cedb4754cb374805a1515bf87e04af74.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/0ce36f2f261a4a8c942aff54144bf705.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308251200657?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=11&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Explorer',
        price: 4990,
        retailer: 'Budget Bikes',
        retailer_location: 'Swindon',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/26816/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/wiltshire/swindon/budget-bikes-26816?channel=bikes'
      },
      {
        finance: {
          amount: 271,
          type: 'PCP'
        },
        id: 37,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/c8ebdfa236c540cb91511917f8d4e6b7.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/7c9c89a69d634ae7ae633451ec5d81dc.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/30014539d4244dd5b65eaec5c5102581.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/feb0cbcd58fa460294012d19289e7d14.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308110666844?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=13&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Explorer',
        price: 4995,
        retailer: 'P &amp;amp; P Motorcycles',
        retailer_location: 'Hayling Island',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/27048/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/hampshire/hayling-island/p-p-motorcycles-27048?channel=bikes'
      },
      {
        finance: {
          amount: 157,
          type: 'PCP'
        },
        id: 38,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/a10c305de42440bbb5b0d833e2696b49.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/1d8a6ec57c2a4b3f8855d1a1d7694a1a.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4c5e3fe65dfa4b86a5d595429c159397.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/88afe4190fb9465a94c7946983dfa087.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202304246612452?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=5&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Explorer',
        price: 6249,
        retailer: 'Destination Triumph Washington',
        retailer_location: 'Pulborough',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/76474/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/west-sussex/pulborough/destination-triumph-washington-76474?channel=bikes'
      },
      {
        finance: {
          amount: 249,
          type: 'PCP'
        },
        id: 39,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/102da0df939347e3a8c97c2765f0590b.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/a9ce911868954d81949e42bad7c047b9.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/bcc30e443f184fe8b793a59b3cad84c4.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/05dd72a7257547fd8d8a6ab42c5c4ec0.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202307189801901?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=7&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Rocket III',
        price: 5984,
        retailer: 'SuperBike Factory London',
        retailer_location: 'Kennington',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10019541/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/surrey/kennington/superbike-factory-london-10019541?channel=bikes'
      },
      {
        finance: {
          amount: 100,
          type: 'PCP'
        },
        id: 40,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/dd61840d12d847809981214e7a414bdc.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/26caffda7dfc47dbae37b33aaba45baa.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/52872d02064b41109641c679239fba93.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/6f879c5be4ff4e10b839c2b07ae1b56f.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308080555070?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=12&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Rocket III',
        price: 15995,
        retailer: 'Sycamore Yamaha',
        retailer_location: 'Oakham',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10021278/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/rutland/oakham/sycamore-yamaha-10021278?channel=bikes'
      },
      {
        finance: {
          amount: 283,
          type: 'PCP'
        },
        id: 41,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/738a7b2265ca40c2a230509ded6031fe.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/fb3994f6f86343e9bc912e15fd1eedc8.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/038f54abc8c243caaeedaa55c270550c.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4335a7a4f6134d39a181ea8ec6b22d42.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308110676193?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=1&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Scrambler 900',
        price: 6250,
        retailer: 'Wooburn Green Motorcycles',
        retailer_location: 'High Wycombe',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10019488/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/buckinghamshire/high-wycombe/wooburn-green-motorcycles-10019488?channel=bikes'
      },
      {
        finance: {
          amount: 288,
          type: 'PCP'
        },
        id: 42,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/e9418887ee89496ea5bf3e67980c9cfc.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/2ed8810cc53c4d569c5c71bc708549ce.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/85f7a876f3f24dfe8bbb771dbf77f82e.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/fd09eeef0e29472fa7c017a56179a955.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308221058495?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=6&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Scrambler 900',
        price: 7750,
        retailer: 'Quality Cars',
        retailer_location: 'Epping',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/4555/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/essex/epping/quality-cars-4555?channel=bikes'
      },
      {
        finance: {
          amount: 280,
          type: 'PCP'
        },
        id: 43,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f39c5c2257944242af1dc67365d7fded.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/98be9d39d4ea48b489a0096b29bb9792.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4c6b8e24d5e64dff98223ac3453efdd0.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/361c690badc642a98e52b3e2d084f96a.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202307119540630?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=8&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Speed Four',
        price: 2586,
        retailer: 'SuperBike Factory London',
        retailer_location: 'Kennington',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10019541/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/surrey/kennington/superbike-factory-london-10019541?channel=bikes'
      },
      {
        finance: {
          amount: 264,
          type: 'PCP'
        },
        id: 44,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/97b774d6e85d48fbac3e3c7b48c98864.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/0399d5f10c3940a89d16d989049f1bec.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/fb0bf969d3334b519a3e79285348c040.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/1e3c45b46bec4077ae7aca5ae8da96f5.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202204204824744?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=12&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Speed Four',
        price: 2995,
        retailer: 'Newman &amp;amp; Reidy',
        retailer_location: 'Northampton',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/123070/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/northamptonshire/northampton/newman-reidy-123070?channel=bikes'
      },
      {
        finance: {
          amount: 274,
          type: 'PCP'
        },
        id: 45,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/2ed7a992cb5f4b5aa578b3a02634c732.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/e33661df0b2d422f8ec436547f26b28a.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/607775ba610e462a80e78bdaf8844e1d.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f629f294cb4b4893b875b3f600f16bc8.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202307280153387?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=3&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Speed Triple 1050',
        price: 4000,
        retailer: 'The Motorcycle Centre',
        retailer_location: 'Westerham',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/9659/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/kent/westerham/the-motorcycle-centre-9659?channel=bikes'
      },
      {
        finance: {
          amount: 171,
          type: 'PCP'
        },
        id: 46,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/1346605d5d6d4e20b136681e8c632619.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/78145953b9a044e6a46967dbeace57ed.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/9a52c809040d4c8fa3b5c67afeb1e60e.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/7b0672fea8d9416eaecb2c30e74aa995.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202302224539467?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=13&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Speed Triple 1050',
        price: 4184,
        retailer: 'SuperBike Factory South West',
        retailer_location: 'Bristol',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10035326/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/somerset/bristol/superbike-factory-south-west-10035326?channel=bikes'
      },
      {
        finance: {
          amount: 232,
          type: 'PCP'
        },
        id: 47,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/d0dbb3bce6d04f1490c0799eb3a47469.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f5492bbf4285428f862c0c196fd77692.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4ae3dd8ead64414fbe5bd4b235ad5b66.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/0935f43a92b3455b86b0b2d8408a8eeb.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308180928424?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=2&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Speed Triple 1050',
        price: 4995,
        retailer: 'Motorcycles UK (Swindon) Ltd',
        retailer_location: 'Swindon',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10024626/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/wiltshire/swindon/motorcycles-uk-swindon-ltd-10024626?channel=bikes'
      },
      {
        finance: {
          amount: 103,
          type: 'PCP'
        },
        id: 48,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/9948aeacd6fd45f38df5c22004396ac6.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/5be7d7d442164c7cbcf49ef2f2f23736.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/cd3724f0391e4807bf2b23623ab48e43.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/1bdce14e6be9402da784098f8879627a.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202307300233333?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=1&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Speed Triple 1050',
        price: 5495,
        retailer: 'Wooburn Green Motorcycles',
        retailer_location: 'High Wycombe',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10019488/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/buckinghamshire/high-wycombe/wooburn-green-motorcycles-10019488?channel=bikes'
      },
      {
        finance: {
          amount: 183,
          type: 'PCP'
        },
        id: 49,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/087ebca335f74de9bdbe7d34e0d3288f.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/1d48473dfbe34bbbb1bb29947d6acb06.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/dc0b08220117489a98dac0abaccb6c0f.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/06150001c8494168af589c3291427d09.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202306068195260?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=13&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Speed Triple 1050',
        price: 5495,
        retailer: 'A&amp;amp;T Motorcycles',
        retailer_location: 'Weston Super Mare',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10012363/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/avon/weston-super-mare/at-motorcycles-10012363?channel=bikes'
      },
      {
        finance: {
          amount: 149,
          type: 'PCP'
        },
        id: 50,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/65681064fc9a4b11809f1daff2256e39.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f978638fc8fa47b1b90af7489bd601bb.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/871f4ff7fe8c493f8f61e1d10d3e212d.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/d4d0f325a41544018a66c2d49141079a.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308200998551?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=7&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Speed Triple 1050',
        price: 5650,
        retailer: 'Wooburn Green Motorcycles',
        retailer_location: 'High Wycombe',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10019488/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/buckinghamshire/high-wycombe/wooburn-green-motorcycles-10019488?channel=bikes'
      },
      {
        finance: {
          amount: 151,
          type: 'PCP'
        },
        id: 51,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/49c7aa40c5bd4024b74959a91ecd395a.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/9f98a7cb8982495ab4a56504a9eefb74.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/3f7b334e8bd44d468b5b9ca2c6b578fc.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/166be9fe0d064c508de1829796b8c67b.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308241141414?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=9&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Speed Triple 1050',
        price: 6980,
        retailer: 'Moto Crave',
        retailer_location: 'Huntingdon',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10029761/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/cambridgeshire/huntingdon/moto-crave-10029761?channel=bikes'
      },
      {
        finance: {
          amount: 146,
          type: 'PCP'
        },
        id: 52,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/ed8c5c1dbbf54d01b3d3d0712ff62b3a.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f1a2c80d7c4642fca8152af687bdc826.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/6a89482fa070435e855444bc830d791b.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/8380d77600a44f32880d926a8790dcd7.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308231120864?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=14&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Speed Triple 1200',
        price: 10995,
        retailer: 'Triumph West London',
        retailer_location: 'West Byfleet',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10008892/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/surrey/west-byfleet/triumph-west-london-10008892?channel=bikes'
      },
      {
        finance: {
          amount: 279,
          type: 'PCP'
        },
        id: 53,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/821ce7f20d704e30a059df8c7d120b48.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/cc23217e348440bc96dbb6276569de48.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/78b805d42d3e4a00b34aee76c225c8dd.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f9dac0a5b6884d8f930c47c967d42c2b.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202207278179857?journey=FEATURED_LISTING_JOURNEY&amp;amp;sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=1&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Speed Triple 1200',
        price: 12191,
        retailer: 'Lings Motorcycles Ipswich',
        retailer_location: 'Ipswich',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10032295/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/suffolk/ipswich/lings-motorcycles-ipswich-10032295?channel=bikes'
      },
      {
        finance: {
          amount: 124,
          type: 'PCP'
        },
        id: 54,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/5d9146abf28a40a5a2e73a6c54a05be2.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/d141be5db42c4adc858f950f009c743d.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/1af04a7fdbc6428c9d9d46bdf8727549.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/5c6ffb5a39354322ac72582978aefb29.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202203073290568?journey=FEATURED_LISTING_JOURNEY&amp;amp;sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=3&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Speed Triple 1200',
        price: 12199,
        retailer: 'Lings Triumph Essex',
        retailer_location: 'Dunmow',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10032297/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/essex/dunmow/lings-triumph-essex-10032297?channel=bikes'
      },
      {
        finance: {
          amount: 144,
          type: 'PCP'
        },
        id: 55,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/b4918e1cc7234a9082d5d8bd1d19cf32.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/c45995da1d984e5f8adb9521e2760338.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/a25151e9b43246ad9c2b755eb78897be.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/c651b05a3b644b06ae5ff6f5da9d9e30.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202205105552730?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=6&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Speed Triple 955',
        price: 1995,
        retailer: 'John Riley Motorcycles',
        retailer_location: 'Dartford',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/23925/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/kent/dartford/john-riley-motorcycles-23925?channel=bikes'
      },
      {
        finance: {
          amount: 133,
          type: 'PCP'
        },
        id: 56,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/eec7c608f97e4bee99310b85d9e6d11c.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/117d62262e1242a7809dc57388935d64.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/b851550aa72a4b59bc67beaa39cb0cc4.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/7550d9c0ec4f41d3b000e6e5cae3f4aa.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308110690001?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=1&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Speed Twin',
        price: 7795,
        retailer: 'Triumph West London',
        retailer_location: 'West Byfleet',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10008892/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/surrey/west-byfleet/triumph-west-london-10008892?channel=bikes'
      },
      {
        finance: {
          amount: 135,
          type: 'PCP'
        },
        id: 57,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/efe430cb33cd41b6b28fec0acf1e534e.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/48c5eff7b5224fe19860d9783db49d76.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/fd3ad32660084618b751e8b6faeb8bc5.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/bf9ec0b1a65840cf8b2a63c639bc2982.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202307280155580?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=14&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Speedmaster',
        price: 4295,
        retailer: 'Motorcycles UK (Swindon) Ltd',
        retailer_location: 'Swindon',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10024626/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/wiltshire/swindon/motorcycles-uk-swindon-ltd-10024626?channel=bikes'
      },
      {
        finance: {
          amount: 224,
          type: 'PCP'
        },
        id: 58,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/30e3db7c07754c49bc18437e4ec49ac4.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4bc31f45afb947dc8b2876cc09f65a28.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/adc1a02dc0ac441b9aaafd5a3e3492c8.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4b63456c564b4fefa9640aef066ddb43.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202302214503442?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=4&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Speedmaster 865',
        price: 4983,
        retailer: 'SuperBike Factory Buckinghamshire',
        retailer_location: 'Milton Keynes',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10035869/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/buckinghamshire/milton-keynes/superbike-factory-buckinghamshire-10035869?channel=bikes'
      },
      {
        finance: {
          amount: 252,
          type: 'PCP'
        },
        id: 59,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/1bf6c2734a3b450f878540ef8916999c.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/80db5f4f3e4e4bc5ae879c09ccc4ae09.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/b9e46f7375784e5595d3ac0e06b9a9a7.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/c6a251dd8a254942ae953448432aac34.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308080560551?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=9&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Speedmaster 865',
        price: 4989,
        retailer: 'SuperBike Factory South West',
        retailer_location: 'Bristol',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10035326/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/somerset/bristol/superbike-factory-south-west-10035326?channel=bikes'
      },
      {
        finance: {
          amount: 185,
          type: 'PCP'
        },
        id: 60,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/d84da51015304f099aefd40e05e7f17b.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/5912fef4ec1a4d54a4b1ea4e6ece720f.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/17551fac23764bd88160d18c9a051d3e.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/d8f77f87486f4aabb01f1d50cfd30db7.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308040438746?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=10&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Speedmaster 865',
        price: 4992,
        retailer: 'Destination Triumph Dorset',
        retailer_location: 'Christchurch',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10028207/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/dorset/christchurch/destination-triumph-dorset-10028207?channel=bikes'
      },
      {
        finance: {
          amount: 285,
          type: 'PCP'
        },
        id: 61,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/2b14ed6e41c24922bd813b5386d1098f.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/a291104c8eb94ee5a290fc2a64d28739.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/8a5e20999e5b4ba8958d11bd8fcad095.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/466a865be07f4a8c935534a7ce61add1.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308150816050?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=12&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Sprint 900',
        price: 2695,
        retailer: 'B S Bikes Limited T/A Staffordshire Triumph',
        retailer_location: 'Stoke On Trent',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/23336/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/staffordshire/stoke-on-trent/b-s-bikes-limited-ta-staffordshire-triumph-23336?channel=bikes'
      },
      {
        finance: {
          amount: 249,
          type: 'PCP'
        },
        id: 62,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/7463a9d58ba84b8098f8da765b149506.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/52bb1c06937341558f43f962e317e1dd.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/a12816acbfdc4194a686adc07aff7045.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/3488628d62cc41499466ca7c3fdadfbc.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308050472764?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=8&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Sprint GT 1050',
        price: 4995,
        retailer: 'Triumph North London',
        retailer_location: 'Watford',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10020026/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/hertfordshire/watford/triumph-north-london-10020026?channel=bikes'
      },
      {
        finance: {
          amount: 109,
          type: 'PCP'
        },
        id: 63,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/87bdadd33e2e470bb88a66f9ef132d05.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/c6c0f2656c674b35acbd995d303faf6a.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/da8c10978485424fbe770cc2b1f12c96.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/a045fe4a177b4514896362c626c376a4.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308251198657?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=9&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Sprint GT 1050',
        price: 6495,
        retailer: 'Destination Triumph Dorset',
        retailer_location: 'Christchurch',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10028207/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/dorset/christchurch/destination-triumph-dorset-10028207?channel=bikes'
      },
      {
        finance: {
          amount: 153,
          type: 'PCP'
        },
        id: 64,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/5347a1322450414aa2dfa5c10513567a.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/74ebb4d899de4f74bb5d155c760fd833.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/9e4da26bbee048a384db587449963071.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/3450f212e9b54dc78d226d1a0c3edec2.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308110672029?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=5&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Sprint ST 1050',
        price: 1795,
        retailer: 'Ken Urwin Motorcycles',
        retailer_location: 'Doncaster',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/12001/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/south-yorkshire/doncaster/ken-urwin-motorcycles-12001?channel=bikes'
      },
      {
        finance: {
          amount: 180,
          type: 'PCP'
        },
        id: 65,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/667afb2b94b140f59a0e2efa91ddec1d.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/63ef0d39d67a405394a0f4959563961d.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/21716e9701bd4a109ea6ab831be10fc5.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/1f9f65becb67486aa485f11a8437e37d.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202307129579140?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=4&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Sprint ST 1050',
        price: 2599,
        retailer: 'Motobox UK',
        retailer_location: 'Crowborough',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10008844/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/east-sussex/crowborough/motobox-uk-10008844?channel=bikes'
      },
      {
        finance: {
          amount: 235,
          type: 'PCP'
        },
        id: 66,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/d0e4637ce26f4614b9bcf92d6536610c.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/0bfbccc6b19a469fa555491ea56aec22.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/2bd417e551c64cd9afbbe93fdc76b902.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/abf90cb6a26d43eda3970927cfe57491.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202306248910820?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=2&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Sprint ST 1050',
        price: 2786,
        retailer: 'SuperBike Factory London',
        retailer_location: 'Kennington',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10019541/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/surrey/kennington/superbike-factory-london-10019541?channel=bikes'
      },
      {
        finance: {
          amount: 260,
          type: 'PCP'
        },
        id: 67,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/7a2e0109945c488c8e4acd5eb0350cea.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f72e1dedb0454ffd8c7db21977d6e129.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/84fc75e9edf6489393a74a06b122769c.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/d0f8ee77fae04aaa97279a172545008f.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308030378562?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=2&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Sprint ST 1050',
        price: 2899,
        retailer: 'Powerbiking Ltd',
        retailer_location: 'High Wycombe',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/28602/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/buckinghamshire/high-wycombe/powerbiking-ltd-28602?channel=bikes'
      },
      {
        finance: {
          amount: 162,
          type: 'PCP'
        },
        id: 68,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4bef54ccf33a40d1a39f8adbfda2051d.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/8535937345294171958eb003a67334dc.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/46027c30e5024e8eb381a2e2fd90b455.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/d5aaaeade9d547e7bce1d83f88551493.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308170909362?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=12&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Sprint ST 1050',
        price: 2950,
        retailer: 'Moto Me',
        retailer_location: 'Doncaster',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/1307092/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/south-yorkshire/doncaster/moto-me-1307092?channel=bikes'
      },
      {
        finance: {
          amount: 218,
          type: 'PCP'
        },
        id: 69,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/666d17e4b28e41e2a51ec1e0ed47fe2a.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/7b26f6ad5363494f9e013829acf26709.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/8081233234ab4d3eb477822b08569d05.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/2e1c69f97ff34f33ad22a3993cce523d.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202307290203886?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=9&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Sprint ST 1050',
        price: 3295,
        retailer: 'The Bike Depot',
        retailer_location: 'Worksop',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/82442/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/nottinghamshire/worksop/the-bike-depot-82442?channel=bikes'
      },
      {
        finance: {
          amount: 181,
          type: 'PCP'
        },
        id: 70,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/862e3a91104844e48cc5460af5659e07.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/a9ce911868954d81949e42bad7c047b9.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/fdecf739ae234cee85b903f01f3b9041.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4b63456c564b4fefa9640aef066ddb43.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308211043963?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=8&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Sprint ST 1050',
        price: 3491,
        retailer: 'SuperBike Factory Donington Park',
        retailer_location: 'Derby',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10029990/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/leicestershire/derby/superbike-factory-donington-park-10029990?channel=bikes'
      },
      {
        finance: {
          amount: 189,
          type: 'PCP'
        },
        id: 71,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/8af9cd87dc704415bdd2470bde766590.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/a9ce911868954d81949e42bad7c047b9.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/5bbd9dda54f84aa789c13377b7fafb8c.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4b63456c564b4fefa9640aef066ddb43.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308211043914?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=4&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Sprint ST 1050',
        price: 3591,
        retailer: 'SuperBike Factory South West',
        retailer_location: 'Bristol',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10035326/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/somerset/bristol/superbike-factory-south-west-10035326?channel=bikes'
      },
      {
        finance: {
          amount: 281,
          type: 'PCP'
        },
        id: 72,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/82d63743c83d46a98084ff2edc7db902.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f3195088319043b9aa4655eedc75ec98.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/0f3f0e9a0333480380293bd37f926d98.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4b63456c564b4fefa9640aef066ddb43.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308060506162?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=5&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Sprint ST 1050',
        price: 3786,
        retailer: 'SuperBike Factory London',
        retailer_location: 'Kennington',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10019541/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/surrey/kennington/superbike-factory-london-10019541?channel=bikes'
      },
      {
        finance: {
          amount: 123,
          type: 'PCP'
        },
        id: 73,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/358fd656a95e40feb4b72fa054a94f4d.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/02247a2e2fd947afa88c9ffde863651b.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f0a03364befc4c3c87732028cb79d45d.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4b63456c564b4fefa9640aef066ddb43.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308060506158?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=14&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Sprint ST 1050',
        price: 4186,
        retailer: 'SuperBike Factory London',
        retailer_location: 'Kennington',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10019541/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/surrey/kennington/superbike-factory-london-10019541?channel=bikes'
      },
      {
        finance: {
          amount: 179,
          type: 'PCP'
        },
        id: 74,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/254844c8f6004cac8c0ea0b9aafcac21.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/3d3703902da44c5e81549a72c12a1346.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/54989d3f815540d5b327ed234095fe83.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/c6f87f5d481b4f2fb46952c5498ede7a.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308170900639?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=9&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Sprint ST 955',
        price: 1995,
        retailer: 'Team Roberts',
        retailer_location: 'Conisbrough',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/8422/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/south-yorkshire/conisbrough/team-roberts-8422?channel=bikes'
      },
      {
        finance: {
          amount: 138,
          type: 'PCP'
        },
        id: 75,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/47e82e183d884a8d98eff4c02df215cd.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/24770b22d3d24140a018bb85c2307247.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/6c1bb4ea7d714b73a7e8cae90fddeca2.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/09d820d4e7b7401595371be07867d7c0.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308231116801?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=1&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Sprint ST 955',
        price: 2195,
        retailer: 'Triumph West London',
        retailer_location: 'West Byfleet',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10008892/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/surrey/west-byfleet/triumph-west-london-10008892?channel=bikes'
      },
      {
        finance: {
          amount: 229,
          type: 'PCP'
        },
        id: 76,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/8005f4b255d74a36af6bfaf586790ba3.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/bba9f3a683af421a90da2ef24781123d.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/6c1323af289948edb9e5419614e91e9e.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/2145748a918349ba91c235af7a61f70c.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308201004167?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=5&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Street Scrambler',
        price: 7350,
        retailer: 'Wooburn Green Motorcycles',
        retailer_location: 'High Wycombe',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10019488/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/buckinghamshire/high-wycombe/wooburn-green-motorcycles-10019488?channel=bikes'
      },
      {
        finance: {
          amount: 186,
          type: 'PCP'
        },
        id: 77,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/47f995dedb0c494a9c3f3c4554900f2f.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/9fbd0648ce8c4997be386c7c3b0b3777.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/77d63b04c576427aa95fa153541381db.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/8daf4678a36e4c969778d8e523d41d29.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202307260073758?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=11&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Street Scrambler',
        price: 8950,
        retailer: 'Blade Triumph Stratford',
        retailer_location: 'Stratford Upon Avon',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/422837/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/warwickshire/stratford-upon-avon/blade-triumph-stratford-422837?channel=bikes'
      },
      {
        finance: {
          amount: 208,
          type: 'PCP'
        },
        id: 78,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/e4a713eb41114dfbb7e1926609f4e113.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/a9ce911868954d81949e42bad7c047b9.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/67380cec5262433ebcf7aae1ac9d9d3f.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4b63456c564b4fefa9640aef066ddb43.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308100638856?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=9&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Street Triple 675',
        price: 4090,
        retailer: 'SuperBike Factory Buckinghamshire',
        retailer_location: 'Milton Keynes',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10035869/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/buckinghamshire/milton-keynes/superbike-factory-buckinghamshire-10035869?channel=bikes'
      },
      {
        finance: {
          amount: 152,
          type: 'PCP'
        },
        id: 79,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/08d9c6cb8c194b2196b8a2f34532a1fa.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/460dd032ac494fa78813fdff43392b40.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/143a73d2c6c9410e94759b5ed553e6a1.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4a2499e207eb4a97a9c9585a1ff9524f.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202306218779492?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=3&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Street Triple 675',
        price: 4299,
        retailer: 'Fins Motorcycles Of Redhill',
        retailer_location: 'Redhill',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/13055/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/surrey/redhill/fins-motorcycles-of-redhill-13055?channel=bikes'
      },
      {
        finance: {
          amount: 237,
          type: 'PCP'
        },
        id: 80,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/fb276fd4887c47c89b07bf7f14affabd.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f110c6fdfca44bc4bdf2f67f38960811.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/3cf7eb7b51954da3abbccf7f4ef98b8d.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/fb7928345c67438893703d1eaf2492a8.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202306289027598?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=7&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Street Triple 675',
        price: 4350,
        retailer: 'Adam Bikes',
        retailer_location: 'Luton',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10031353/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/bedfordshire/luton/adam-bikes-10031353?channel=bikes'
      },
      {
        finance: {
          amount: 138,
          type: 'PCP'
        },
        id: 81,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/40b1d1306ef24ba3beae7fd29c11eac2.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/27751a1e7deb4b5ca417687490e57ae5.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/8d336426aa7143d2ad862be7c7e6a073.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/80e2c820086d4587b12d6c3922f853cb.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202305187504603?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=8&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Street Triple 675',
        price: 4595,
        retailer: 'LONDON MOTORCYCLES LTD',
        retailer_location: 'Hammersmith',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10025472/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/middlesex/hammersmith/london-motorcycles-ltd-10025472?channel=bikes'
      },
      {
        finance: {
          amount: 253,
          type: 'PCP'
        },
        id: 82,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/d23a4666d2e9483286ecb37c8e52d21e.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/a9ce911868954d81949e42bad7c047b9.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/da051aef1f0d4b8a9a7c5f31751eafc0.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4b63456c564b4fefa9640aef066ddb43.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308030384703?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=11&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Street Triple 675',
        price: 4691,
        retailer: 'SuperBike Factory Donington Park',
        retailer_location: 'Derby',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10029990/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/leicestershire/derby/superbike-factory-donington-park-10029990?channel=bikes'
      },
      {
        finance: {
          amount: 273,
          type: 'PCP'
        },
        id: 83,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/04b2924b2ef54e6da2590a002df41f0e.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/c4794606deb04ee9950a27fdab9b1bfb.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/65fc1c0ac45e45d7888e16400874a80a.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4c989d7e502a4230a61cb2e2cb3609b0.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308221071875?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=2&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Street Triple 675',
        price: 4995,
        retailer: 'Reading Harley-Davidson',
        retailer_location: 'Reading',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/1864450/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/berkshire/reading/reading-harley-davidson-1864450?channel=bikes'
      },
      {
        finance: {
          amount: 239,
          type: 'PCP'
        },
        id: 84,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/04b2924b2ef54e6da2590a002df41f0e.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/c4794606deb04ee9950a27fdab9b1bfb.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/65fc1c0ac45e45d7888e16400874a80a.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4c989d7e502a4230a61cb2e2cb3609b0.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308221071875?journey=PROMOTED_LISTING_JOURNEY&amp;amp;sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=13&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Street Triple 675',
        price: 4995,
        retailer: 'Reading Harley-Davidson',
        retailer_location: 'Reading',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/1864450/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/berkshire/reading/reading-harley-davidson-1864450?channel=bikes'
      },
      {
        finance: {
          amount: 207,
          type: 'PCP'
        },
        id: 85,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/bdfb5c83dcf943d3ba61e6c7a219f47c.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/a9ce911868954d81949e42bad7c047b9.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4696a32f484d4bdeb608b02aeec8ba4e.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4b63456c564b4fefa9640aef066ddb43.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308110693501?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=11&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Street Triple 675',
        price: 5290,
        retailer: 'SuperBike Factory Buckinghamshire',
        retailer_location: 'Milton Keynes',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10035869/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/buckinghamshire/milton-keynes/superbike-factory-buckinghamshire-10035869?channel=bikes'
      },
      {
        finance: {
          amount: 190,
          type: 'PCP'
        },
        id: 86,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/c3ead080c7a449688926f2cd7f52ae27.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/a9ce911868954d81949e42bad7c047b9.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/d90d713be55f4b8ab64abd23a76651c7.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4b63456c564b4fefa9640aef066ddb43.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308160873773?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=6&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Street Triple 675',
        price: 5590,
        retailer: 'SuperBike Factory South West',
        retailer_location: 'Bristol',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10035326/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/somerset/bristol/superbike-factory-south-west-10035326?channel=bikes'
      },
      {
        finance: {
          amount: 236,
          type: 'PCP'
        },
        id: 87,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/e62249e415ac4762b53e7fb07ae0f676.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/23fafde892f943938d1eead14521d2aa.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/cd6e88ae31584ebd919ab1815652c19e.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/39f8894bb21e4ab698e3733f59379227.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202305057047376?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=11&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Street Triple 675',
        price: 5795,
        retailer: 'LONDON MOTORCYCLES LTD',
        retailer_location: 'Hammersmith',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10025472/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/middlesex/hammersmith/london-motorcycles-ltd-10025472?channel=bikes'
      },
      {
        finance: {
          amount: 207,
          type: 'PCP'
        },
        id: 88,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/ba1884f60c4543279ccd68beb9c586d9.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4e59482bcc12490db7b6e5d122b8ba05.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/04408cfd14a04f4ea91aba3108257d79.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/08a617e2526e47cf8d45ea2252ce7a61.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202307250052336?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=14&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Street Triple 765',
        price: 6499,
        retailer: 'The Motorcycle Centre',
        retailer_location: 'Westerham',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/9659/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/kent/westerham/the-motorcycle-centre-9659?channel=bikes'
      },
      {
        finance: {
          amount: 125,
          type: 'PCP'
        },
        id: 89,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/7f3ec11de5104d0c94dd1263b8167100.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4b9f4255f8c14320ab18ef37cda9c1fc.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/93324a0487874238b42f91d0a65cc90c.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/d686694a21e64de69fdf08bd85ea27fc.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202305247742803?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=3&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Street Triple 765',
        price: 6995,
        retailer: 'Destination Triumph Washington',
        retailer_location: 'Pulborough',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/76474/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/west-sussex/pulborough/destination-triumph-washington-76474?channel=bikes'
      },
      {
        finance: {
          amount: 130,
          type: 'PCP'
        },
        id: 90,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/66d608e8a45d43fd9b30b2ea233dba85.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/99129a812fd7422e9deb6c26be49781f.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/2e9af84468c4487d8b99414c3f69f5ec.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/cd9e099f740b444ba959d448518ce3a3.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308100627402?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=6&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Street Triple 765',
        price: 7699,
        retailer: 'On Yer Bike',
        retailer_location: 'Aylesbury',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/23583/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/buckinghamshire/aylesbury/on-yer-bike-23583?channel=bikes'
      },
      {
        finance: {
          amount: 121,
          type: 'PCP'
        },
        id: 91,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/66d608e8a45d43fd9b30b2ea233dba85.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/99129a812fd7422e9deb6c26be49781f.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/2e9af84468c4487d8b99414c3f69f5ec.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/cd9e099f740b444ba959d448518ce3a3.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308100627402?journey=PROMOTED_LISTING_JOURNEY&amp;amp;sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=9&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Street Triple 765',
        price: 7699,
        retailer: 'On Yer Bike',
        retailer_location: 'Aylesbury',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/23583/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/buckinghamshire/aylesbury/on-yer-bike-23583?channel=bikes'
      },
      {
        finance: {
          amount: 267,
          type: 'PCP'
        },
        id: 92,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/2d1cc346c3b84efebd95cf6a28220de9.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/a5975edc58964e65abcf86eb1328aefe.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/13be2adb7bda4236997101aec36ce8bb.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/dc3d57e117ef489fa67760a4ca2e533f.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202307129576618?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=12&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Street Triple 765',
        price: 8895,
        retailer: 'Destination Triumph Washington',
        retailer_location: 'Pulborough',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/76474/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/west-sussex/pulborough/destination-triumph-washington-76474?channel=bikes'
      },
      {
        finance: {
          amount: 196,
          type: 'PCP'
        },
        id: 93,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/7514ae23dc1040e98dd0d844438860b5.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/c5686ec38aff45c59bf1df1c1f1b38eb.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/9d9ebb7a5aff4c3db329a305a9fea942.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4ffe84d7d07e456c84b1a345cc690df2.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308261227949?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=2&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Street Triple 765',
        price: 10597,
        retailer: 'Ashford Triumph',
        retailer_location: 'Ashford',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/146542/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/kent/ashford/ashford-triumph-146542?channel=bikes'
      },
      {
        finance: {
          amount: 181,
          type: 'PCP'
        },
        id: 94,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/e10a36e53f2740c1a23c48da388390f9.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/d5caefabbb69443496a87368a73fc319.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/915fdd74a2e04505bd77223742a6cb67.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/84ea01431bec4ab2aa0bbfbb0358a650.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308100635334?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=4&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Street Triple 765',
        price: 10999,
        retailer: 'Destination Triumph Solent',
        retailer_location: 'Fareham',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/348903/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/hampshire/fareham/destination-triumph-solent-348903?channel=bikes'
      },
      {
        finance: {
          amount: 147,
          type: 'PCP'
        },
        id: 95,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/deb6ce9b025f487fa7bcea6b7a4c60e9.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f8165d6150de45ecb311ce2077d82250.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/9d63c72230e7417eaaab9638b73be60b.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/75a1ac3b894946f1b983657ba4801942.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308221076043?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=10&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Street Twin',
        price: 5495,
        retailer: 'Triumph West London',
        retailer_location: 'West Byfleet',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10008892/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/surrey/west-byfleet/triumph-west-london-10008892?channel=bikes'
      },
      {
        finance: {
          amount: 268,
          type: 'PCP'
        },
        id: 96,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/8a55714f2f914d7bbb8f113c32105c53.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/14a6d891cd0a405fb424975a6bdde55e.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/28f095be70c940f89e1bbe66759f9a4d.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4b63456c564b4fefa9640aef066ddb43.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202307260099417?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=8&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Thruxton 900',
        price: 4189,
        retailer: 'SuperBike Factory Cheshire',
        retailer_location: 'Macclesfield',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/602605/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/cheshire/macclesfield/superbike-factory-cheshire-602605?channel=bikes'
      },
      {
        finance: {
          amount: 186,
          type: 'PCP'
        },
        id: 97,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/5d8ce4d31d354795a4a8a2f14c9a2a74.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/de5c784f3fb14a6798afdd94636734c2.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/e929892fad6d4276a0bc560c1b5e2bbe.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/0f35a9ce21e742c1bf961329743d3873.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308040427577?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=8&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Thruxton 900',
        price: 4699,
        retailer: 'Motobox UK',
        retailer_location: 'Crowborough',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10008844/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/east-sussex/crowborough/motobox-uk-10008844?channel=bikes'
      },
      {
        finance: {
          amount: 107,
          type: 'PCP'
        },
        id: 98,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/d84ec74a246949d0ba70226664411e6d.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/7de1d8f442a84efeb34f4ac9b9316f62.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f211148ec24d4f5cba78ce9a50f78a4c.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4b63456c564b4fefa9640aef066ddb43.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202306028078232?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=2&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Thruxton 900',
        price: 4884,
        retailer: 'SuperBike Factory London',
        retailer_location: 'Kennington',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10019541/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/surrey/kennington/superbike-factory-london-10019541?channel=bikes'
      },
      {
        finance: {
          amount: 298,
          type: 'PCP'
        },
        id: 99,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/df9de2606cb94b699916c2fda2534ef7.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/cb57bb82bb2b4162b193a25a02dd8195.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/17beb1668edb45e3acff814474e888e0.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/c389ef47014a4133b0bf112edad197d8.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202209229998104?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=3&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Thruxton 900',
        price: 4995,
        retailer: 'Seymour Pope',
        retailer_location: 'Kings Langley',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/14745/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/hertfordshire/kings-langley/seymour-pope-14745?channel=bikes'
      },
      {
        finance: {
          amount: 171,
          type: 'PCP'
        },
        id: 100,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/69726a096de842ada4246ab21919d12d.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/3eae9a48f31d4318b255843299606917.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/1e26c26d64fd4c5aa31100e2cfceb73b.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/5df5647e551945bca6d1564466286e2f.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202212092398928?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=9&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Thruxton 900',
        price: 4995,
        retailer: 'LONDON MOTORCYCLES LTD',
        retailer_location: 'Hammersmith',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10025472/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/middlesex/hammersmith/london-motorcycles-ltd-10025472?channel=bikes'
      },
      {
        finance: {
          amount: 234,
          type: 'PCP'
        },
        id: 101,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/30a4ca292d3148a896f470d565c711a6.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/40a2d6e4eab04c26a501cf7759d6f6f2.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/7e66036389614d0d8ba061ac9a1ca6db.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f79686132b31417ea77693b917f6e496.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308100657775?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=12&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Thruxton 900',
        price: 6295,
        retailer: 'RNC Motorcycles',
        retailer_location: 'Bushey',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10037932/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/hertfordshire/bushey/rnc-motorcycles-10037932?channel=bikes'
      },
      {
        finance: {
          amount: 150,
          type: 'PCP'
        },
        id: 102,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/29379c7f94194d9d97dd43e9545c883d.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/217201ad599e495287083d5eb7b08044.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/d1bca41c85d54e20892560990d48a6c6.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4b63456c564b4fefa9640aef066ddb43.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202211241963181?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=8&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Thunderbird 1600',
        price: 5984,
        retailer: 'SuperBike Factory Donington Park',
        retailer_location: 'Derby',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10029990/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/leicestershire/derby/superbike-factory-donington-park-10029990?channel=bikes'
      },
      {
        finance: {
          amount: 269,
          type: 'PCP'
        },
        id: 103,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/de0d4fc59a7d4da7b81839492c2d9e08.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/61e12e08612f41b385adcbc4c353e120.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/99a5590d0d4e4a87b0cbc3cbeb05f0d8.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4b63456c564b4fefa9640aef066ddb43.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202305117251882?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=13&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Thunderbird 900',
        price: 3487,
        retailer: 'SuperBike Factory Cheshire',
        retailer_location: 'Macclesfield',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/602605/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/cheshire/macclesfield/superbike-factory-cheshire-602605?channel=bikes'
      },
      {
        finance: {
          amount: 226,
          type: 'PCP'
        },
        id: 104,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/6b1bae13ca66428aae38330c9b1df4d4.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/6b2b12f8054646be8181cbeb250af06e.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f03820b6c1da4e24bfcd68a604d229c2.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f3ae6e7af8df42c689519b7005fe042c.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308150813862?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=2&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Thunderbird 900',
        price: 4598,
        retailer: 'Doble Motorcycles',
        retailer_location: 'Coulsdon',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/23540/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/surrey/coulsdon/doble-motorcycles-23540?channel=bikes'
      },
      {
        finance: {
          amount: 238,
          type: 'PCP'
        },
        id: 105,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/b5f5662384f94f869fbd00e69987421a.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/a1580cd94db04aaf9797308bef05e82a.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/0873d8eb448949a894d360b21bb96a8d.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/126d2dfa8a5c4a9eb6d62c2723bf3b32.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202210251065053?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=6&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Thunderbird 900',
        price: 5299,
        retailer: 'Haslemere Motorcycles',
        retailer_location: 'Bordon',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10018636/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/hampshire/bordon/haslemere-motorcycles-10018636?channel=bikes'
      },
      {
        finance: {
          amount: 152,
          type: 'PCP'
        },
        id: 106,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/59f19c3402694c47936d7b0aab581029.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/e282590fa5f14a5e9c589e90c363edf0.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/ad5542957b824cdc9f2307d9d6f08742.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/8b51da4473634f20a7ec6c23b14b0473.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202205135670235?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=3&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Tiger',
        price: 1990,
        retailer: 'Budget Bikes',
        retailer_location: 'Swindon',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/26816/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/wiltshire/swindon/budget-bikes-26816?channel=bikes'
      },
      {
        finance: {
          amount: 275,
          type: 'PCP'
        },
        id: 107,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/c1b24da750c044ee83785a2aab31e730.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/e3317f471efa4db284befc93f546c597.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/037c2d5aa579429fa95fb29fb9ccbdd5.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4db85e9d84924f50b7daed984e65c89d.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202307019155907?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=3&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Tiger',
        price: 3995,
        retailer: 'Poole Moto Bike',
        retailer_location: 'Poole',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10032373/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/dorset/poole/poole-moto-bike-10032373?channel=bikes'
      },
      {
        finance: {
          amount: 232,
          type: 'PCP'
        },
        id: 108,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/2a6e149ada0f4b5a8c91bfef7ae79130.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/fd1d1bcf99e542bb92ce47925534cede.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/6138fae40080469ba714909fe492b4af.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4b63456c564b4fefa9640aef066ddb43.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202307199843840?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=14&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Tiger 1050',
        price: 3886,
        retailer: 'SuperBike Factory Buckinghamshire',
        retailer_location: 'Milton Keynes',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10035869/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/buckinghamshire/milton-keynes/superbike-factory-buckinghamshire-10035869?channel=bikes'
      },
      {
        finance: {
          amount: 126,
          type: 'PCP'
        },
        id: 109,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/5469d577520246ceb8a78b6df1dd2f59.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/a273aa2f6ee2429e9141405198793110.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/a72d73854e62477aadbdad257575f0d1.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4b63456c564b4fefa9640aef066ddb43.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202306138482413?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=14&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Tiger 1050',
        price: 3887,
        retailer: 'SuperBike Factory Buckinghamshire',
        retailer_location: 'Milton Keynes',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10035869/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/buckinghamshire/milton-keynes/superbike-factory-buckinghamshire-10035869?channel=bikes'
      },
      {
        finance: {
          amount: 104,
          type: 'PCP'
        },
        id: 110,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/dda1c5f0165949f3a60885075cd4cb57.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f6fb49edf8d145a5ad66675cd14797e3.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/779c36cbd9914479aafbd467075d45ff.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/793d5604a3cf486980b5cbd612c59f8d.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202305317999987?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=12&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Tiger 1050',
        price: 4650,
        retailer: 'Wooburn Green Motorcycles',
        retailer_location: 'High Wycombe',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10019488/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/buckinghamshire/high-wycombe/wooburn-green-motorcycles-10019488?channel=bikes'
      },
      {
        finance: {
          amount: 252,
          type: 'PCP'
        },
        id: 111,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/63cdee66aff54a91b9e6b41c06535ec6.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/c412aaffce13404f991170ddff56b154.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/02e606c83abe4205938d283120215e39.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/8ebde4a49374437187dfe808600fbe27.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308231116856?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=3&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Tiger 1050',
        price: 5295,
        retailer: 'Triumph West London',
        retailer_location: 'West Byfleet',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10008892/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/surrey/west-byfleet/triumph-west-london-10008892?channel=bikes'
      },
      {
        finance: {
          amount: 276,
          type: 'PCP'
        },
        id: 112,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f75f3ecb6ab04ae3af87af6eefb1429d.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/a9ce911868954d81949e42bad7c047b9.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/34618a91bce348318c7a7ce37fbc0cff.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4b63456c564b4fefa9640aef066ddb43.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202307280163052?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=9&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Tiger 1200',
        price: 8690,
        retailer: 'SuperBike Factory Buckinghamshire',
        retailer_location: 'Milton Keynes',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10035869/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/buckinghamshire/milton-keynes/superbike-factory-buckinghamshire-10035869?channel=bikes'
      },
      {
        finance: {
          amount: 194,
          type: 'PCP'
        },
        id: 113,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/b080cafef6ce451c8c1ce160a82f14a5.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/70a71a92d4654d37a681a8012979506a.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/b8721bfb4d104a6dbebf772d8e7b0a42.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/05d735a3c5c64edab35dec6ccc1e695f.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202207217985761?journey=YOU_MAY_ALSO_LIKE_JOURNEY&amp;amp;sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=14&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Tiger 1200',
        price: 10591,
        retailer: 'Lings Motorcycles Ipswich',
        retailer_location: 'Ipswich',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10032295/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/suffolk/ipswich/lings-motorcycles-ipswich-10032295?channel=bikes'
      },
      {
        finance: {
          amount: 131,
          type: 'PCP'
        },
        id: 114,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/efadd1bc506b4c8693d37d7c7c757c63.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/fcc19ee43bd84ccf9bfb059c920ecca0.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/74b90491309b49f1ba705a155e5c1c57.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/d1eb8b7bdc024a47b2910d1cdded9320.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202307280180739?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=13&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Tiger 800',
        price: 4000,
        retailer: 'Red Lodge Autos',
        retailer_location: 'Fleet',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/26145/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/hampshire/fleet/red-lodge-autos-26145?channel=bikes'
      },
      {
        finance: {
          amount: 164,
          type: 'PCP'
        },
        id: 115,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/10c13386031b401b872605b55b5ec68d.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/22f04354b6e14360ac53e52a680d542f.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f3ac2163d8814aed8cbe09611d1927a6.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/afb1bc1df23f4135beb010d9288b663c.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308140765347?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=8&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Tiger 800',
        price: 5000,
        retailer: 'DG MOTORCYCLE',
        retailer_location: 'Hove',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10029428/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/east-sussex/hove/dg-motorcycle-10029428?channel=bikes'
      },
      {
        finance: {
          amount: 255,
          type: 'PCP'
        },
        id: 116,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f2f7662597584afb888da1af790efba0.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/bdbca7578411446bba4bb2593b372129.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/934f526071224a2fbf781539f1243553.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4b63456c564b4fefa9640aef066ddb43.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202305117269842?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=13&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Tiger 800',
        price: 5985,
        retailer: 'SuperBike Factory Buckinghamshire',
        retailer_location: 'Milton Keynes',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10035869/images/searchlogo.gif',
        retailer_stock_url:
          'https://www.autotrader.co.uk/dealers/buckinghamshire/milton-keynes/superbike-factory-buckinghamshire-10035869?channel=bikes'
      },
      {
        finance: {
          amount: 158,
          type: 'PCP'
        },
        id: 117,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/0ab66569d69a46af928937405c70ddcd.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/736c75591b2043cf8a723799fe2b82e7.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/a273b4aaf4f549ceabbb0de6ac51a616.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/daacc3ed49eb4df5ba4b8d1a40c92f0b.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308040421696?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=10&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Tiger 800',
        price: 5995,
        retailer: 'Motorcycles UK (Swindon) Ltd',
        retailer_location: 'Swindon',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10024626/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/wiltshire/swindon/motorcycles-uk-swindon-ltd-10024626?channel=bikes'
      },
      {
        finance: {
          amount: 112,
          type: 'PCP'
        },
        id: 118,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/323faeea47864f18a87841643c73c4e4.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/6b07249dde574022abb2857678bb2aff.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/53c7c0b4d2914895b69dc65f04273631.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/b4653c8640bc48279f0c85df55cc3413.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308231113249?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=2&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Tiger 850',
        price: 8495,
        retailer: 'Grafton Motorcycles',
        retailer_location: 'Milton Keynes',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/23850/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/buckinghamshire/milton-keynes/grafton-motorcycles-23850?channel=bikes'
      },
      {
        finance: {
          amount: 269,
          type: 'PCP'
        },
        id: 119,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/e9775cffa0e548ea99b531d6521d8688.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/110d0805941c407eb9b9c5200bf5b742.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/2d00b4aefd314b378907f8775bb3a74a.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/3f0821c8aa58467faf9333c8536dc6f0.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308150820892?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=7&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Tiger 850',
        price: 8995,
        retailer: 'Triumph West London',
        retailer_location: 'West Byfleet',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10008892/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/surrey/west-byfleet/triumph-west-london-10008892?channel=bikes'
      },
      {
        finance: {
          amount: 217,
          type: 'PCP'
        },
        id: 120,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/9bb9bfc813cd492aab0066a8ebf44973.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/fc6eb210af714a5fb561e38f11e69746.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f0ea920302284decafcc4fc6366b2caa.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/1fd6929249b740918e71c4407c85da6b.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308221066070?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=7&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Tiger 900',
        price: 9599,
        retailer: 'Destination Triumph Solent',
        retailer_location: 'Fareham',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/348903/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/hampshire/fareham/destination-triumph-solent-348903?channel=bikes'
      },
      {
        finance: {
          amount: 163,
          type: 'PCP'
        },
        id: 121,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/c3aea5ef683d49efb4b83096a87e9827.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/1e4a8205093547c480f877e83d5cb1d8.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/d1bce078b9eb492794e658ea0b17362e.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f2b41f6102a54f59baf5f5595921e137.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308110690646?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=1&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Tiger 955',
        price: 1495,
        retailer: 'Motorbike Shack Ltd',
        retailer_location: 'High Wycombe',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10030895/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/buckinghamshire/high-wycombe/motorbike-shack-ltd-10030895?channel=bikes'
      },
      {
        finance: {
          amount: 104,
          type: 'PCP'
        },
        id: 122,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/1506faa8c5e3471ea671f90353f3c8b2.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f80a7e3b27bf4b4aa4b8e24fab243e40.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/9b05b67b24b24c658a2382d5a36ac8ad.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/b4b5b545807a4330a5e9ae7aecea9a62.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202305097172158?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=13&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Tiger 955',
        price: 2690,
        retailer: 'Motorbikes 4 All',
        retailer_location: 'Malvern',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/328743/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/worcestershire/malvern/motorbikes-4-all-328743?channel=bikes'
      },
      {
        finance: {
          amount: 276,
          type: 'PCP'
        },
        id: 123,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/1fd9576f974b43fa926f07ad52a06e14.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/561ad329e03640f3b0906935f3bbfe1d.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/45811c139b4a4ff48e39490160b3b007.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f7ce751ab69340aabbc5c139b1ba49f0.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202304216525029?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=4&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Tiger Sport 660',
        price: 6495,
        retailer: 'Motorcycles UK (Swindon) Ltd',
        retailer_location: 'Swindon',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10024626/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/wiltshire/swindon/motorcycles-uk-swindon-ltd-10024626?channel=bikes'
      },
      {
        finance: {
          amount: 116,
          type: 'PCP'
        },
        id: 124,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/801ea525710545dbbf47df3c49207a5e.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/0a69752c4aab4a9a96c1dc687254992b.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f36d146d464e48db95bbf723387761d8.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/427e95eb4d6041ee9fbf799f223d3666.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308110676318?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=6&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Tiger Sport 660',
        price: 7395,
        retailer: 'Motorcycles UK (Swindon) Ltd',
        retailer_location: 'Swindon',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10024626/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/wiltshire/swindon/motorcycles-uk-swindon-ltd-10024626?channel=bikes'
      },
      {
        finance: {
          amount: 125,
          type: 'PCP'
        },
        id: 125,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/cbd07cb7a15046cabb0a963257d2993c.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/92113854010b427d8abd90def9556386.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/b528a82df7b345878317c4c7b420baae.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/63c2b15f67a24ab097cf8ca80067974f.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308100649119?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=13&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Tiger Sport 660',
        price: 8599,
        retailer: 'Destination Triumph Solent',
        retailer_location: 'Fareham',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/348903/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/hampshire/fareham/destination-triumph-solent-348903?channel=bikes'
      },
      {
        finance: {
          amount: 223,
          type: 'PCP'
        },
        id: 126,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/7d36572f69f44ea7a8a6c00786c999d3.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/ff69537f605545dc94b05c94f874854a.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f17a88f935e64519b89d017a9f286eb0.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f8de8c4e23a343249bacc2461ee7ebc4.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308150826572?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=10&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Tiger Sport 660',
        price: 8999,
        retailer: 'Destination Triumph Solent',
        retailer_location: 'Fareham',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/348903/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/hampshire/fareham/destination-triumph-solent-348903?channel=bikes'
      },
      {
        finance: {
          amount: 121,
          type: 'PCP'
        },
        id: 127,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/51a0c2b57f0e490eb5ea175236621647.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/68a81641bd1e408d94421aa59963ad58.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/299a023250a24404acc97eb3a24ba8ea.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/5e0cb94ad3f840169dd9ffc561a7c66e.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308261222668?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=14&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Trident',
        price: 6695,
        retailer: 'Johns Of Romford',
        retailer_location: 'Romford',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/23935/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/essex/romford/johns-of-romford-23935?channel=bikes'
      },
      {
        finance: {
          amount: 138,
          type: 'PCP'
        },
        id: 128,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/6598a3b206b54d5fa246dbf0b90cd132.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/5393ae3384df4f03bb7c3e9ce41e7f9d.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/c5406d2f469a4b4f85a50e0dc6e235d7.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/5b04d9ce25a747afa85608b9b66001e1.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308221072927?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=13&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Trident',
        price: 6699,
        retailer: 'On Yer Bike',
        retailer_location: 'Aylesbury',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/23583/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/buckinghamshire/aylesbury/on-yer-bike-23583?channel=bikes'
      },
      {
        finance: {
          amount: 261,
          type: 'PCP'
        },
        id: 129,
        images: [
          {
            isMainImage: true,
            url: {
              isMainImage: true,
              url: 'https://m.atcdn.co.uk/a/media/w800h600/ee4371e3c6ab479bb64aabc4eddf8d30.jpg'
            }
          },
          {
            isMainImage: false,
            url: {
              isMainImage: false,
              url: 'https://m.atcdn.co.uk/a/media/w800h600/6e4d03e3431c458eb976afa226831a64.jpg'
            }
          },
          {
            isMainImage: false,
            url: {
              isMainImage: false,
              url: 'https://m.atcdn.co.uk/a/media/w800h600/765f4c531da14bebb282188738137b83.jpg'
            }
          },
          {
            isMainImage: false,
            url: {
              isMainImage: false,
              url: 'https://m.atcdn.co.uk/a/media/w800h600/5e2307b3651f4db984166c755edca248.jpg'
            }
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308120719483?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=4&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Trident',
        price: 6750,
        retailer: 'Destination Triumph Washington',
        retailer_location: 'Pulborough',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/76474/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/west-sussex/pulborough/destination-triumph-washington-76474?channel=bikes'
      },
      {
        finance: {
          amount: 116,
          type: 'PCP'
        },
        id: 130,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/08550422cbc8457a89480123c22364a1.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/28eabdfa70cf496ab0c7a801ff778cfe.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/91a09e0ae5fe4c78915a9f1865cec2b3.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4713c5d4b7404808a96cce2898e406b1.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202308241159396?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=11&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Trident',
        price: 6799,
        retailer: 'Destination Triumph Solent',
        retailer_location: 'Fareham',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/348903/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/hampshire/fareham/destination-triumph-solent-348903?channel=bikes'
      },
      {
        finance: {
          amount: 239,
          type: 'PCP'
        },
        id: 131,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/44284119967d4f1c877207388d29b121.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/6602542c68e9465c9c0b0ca6f691a431.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/f41c21a1b5004d8f9fd7292e6c24269c.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/e15dbfaf5bad4563bf0f308fd8a7b045.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202307290193463?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=5&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Trident',
        price: 7599,
        retailer: 'Destination Triumph Solent',
        retailer_location: 'Fareham',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/348903/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/hampshire/fareham/destination-triumph-solent-348903?channel=bikes'
      },
      {
        finance: {
          amount: 188,
          type: 'PCP'
        },
        id: 132,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/997b5088659a4156aad33e9633c9e592.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/d27b83c7a9484f7eb606f191ffd00d0e.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/5662469cfadd43c09eca75e659ac8c94.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/205c116a521f49c6b62b405793e36017.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202301123188397?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=5&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Trophy',
        price: 1290,
        retailer: 'Motorbikes 4 All',
        retailer_location: 'Malvern',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/328743/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/worcestershire/malvern/motorbikes-4-all-328743?channel=bikes'
      },
      {
        finance: {
          amount: 166,
          type: 'PCP'
        },
        id: 133,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/b5c6a8b783b6449293a523820c97411f.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/a4228ab16e3b4a5ab863836715c83f06.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/64dbca6ecbcd4e828705232739f3e880.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/6f6d3a26e3fa4654b72678ab6bddcc02.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202307270114574?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=11&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Trophy',
        price: 2195,
        retailer: 'C &amp;amp; G Motorcycles',
        retailer_location: 'Cwmbran',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/10026004/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/gwent/cwmbran/c-g-motorcycles-10026004?channel=bikes'
      },
      {
        finance: {
          amount: 160,
          type: 'PCP'
        },
        id: 134,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/4cdccd6daf1a45ac821702d33390bce6.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/532615ffbb98409e92b72cbe91f73927.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/7df0fccece8f4716bff3dcd27a225fc0.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/612a542661b64e09bed405ae0c03ee8f.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202307240018223?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=7&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'Trophy',
        price: 6098,
        retailer: 'Doble Motorcycles',
        retailer_location: 'Coulsdon',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/23540/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/surrey/coulsdon/doble-motorcycles-23540?channel=bikes'
      },
      {
        finance: {
          amount: 113,
          type: 'PCP'
        },
        id: 135,
        images: [
          {
            isMainImage: true,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/42dacd5d98214ee198388bdb9c40c923.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/fe6c500a148d46b7b9949fb0dc11975f.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/9566a438f18b4ee5aa71793ce9ac704b.jpg'
          },
          {
            isMainImage: false,
            url: 'https://m.atcdn.co.uk/a/media/w800h600/3d02384fff624cc5a0118a52aaecf04f.jpg'
          }
        ],
        listing_url:
          'https://www.autotrader.co.uk/bike-details/202306208727095?sort=relevance&amp;amp;advertising-location=at_bikes&amp;amp;include-delivery-option=on&amp;amp;make=Triumph&amp;amp;page=9&amp;amp;postcode=sl65jb&amp;amp;fromsra',
        make: 'Triumph',
        model: 'TT600',
        price: 2195,
        retailer: 'Beechdale Motorcycles',
        retailer_location: 'Nottingham',
        retailer_logo_url: 'https://dealerlogo.atcdn.co.uk/at2/adbranding/20608/images/searchlogo.gif',
        retailer_stock_url: 'https://www.autotrader.co.uk/dealers/nottinghamshire/nottingham/beechdale-motorcycles-20608?channel=bikes'
      }
    ]
  },

  getStock() {
    return Promise.resolve(this.getStockData().slice(0, 30))
  }
}
