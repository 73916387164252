<template>
  <h1 class="page-title">{{ $t('ui.entities.flow.label', 1) }}</h1>
  <Card>
    <CardBody>
      <CardTitle :title="$t('ui.common.priority', 1)" :no-margin="true" />
      <div v-for="(item, index) in itemsPriority" :key="item.agreementID" class="record flex flex-row">
        <div class="basis-3/12">
          <h5 class="font-medium">{{ item.customerName }}</h5>
          <div class="text-sm inline-block">{{ item.latestStatus }}</div>
        </div>
        <div class="basis-3/12">
          <div v-tippy="{ content: $t('ui.entities.flow.sentences.reason-in-flow') }" class="inline-block">{{ item.reasonForFlow }}</div>
        </div>
        <div class="basis-2/12">
          <div v-tippy="{ content: 'Term: ' + item.termLength + '<br>End Date: ' + item.endDate }" class="flex items-center">
            <Avatar icon="hourglass-end" size="sm" /><span class="ml-2">{{
              dayjs(item.endDate).diff(dayjs(), 'month') > 12
                ? dayjs(item.endDate).diff(dayjs(), 'months') + $t('ui.common.date-time.month', 2)
                : dayjs(item.endDate).fromNow(true)
            }}</span>
          </div>
        </div>
        <div class="basis-2/12">
          <div v-tippy="{ content: 'Make: ' + item.vehicleManufacturer + '<br>Model: ' + item.vehicleModel }" class="flex items-center">
            <Avatar icon="car" size="sm" /><span class="ml-2">{{ item.vrm }}</span>
          </div>
        </div>
        <div class="basis-2/12 flex justify-end">
          <div class="flex items-center">
            <div v-if="item.timeRemaining === 0" class="mr-2">
              <Button size="sm" severity="secondary" outlined icon="comment-sms" @click="startTimer(item, dayjs())" />
            </div>
            <div v-else v-tippy="{ content: $t('ui.entities.flow.sentences.pre-call-sms-countdown') }" class="mr-5 w-20">
              <Icon type="comment-sms" />
              <span
                :class="[
                  'font-bold',
                  {
                    timerWarning: item.timeRemaining <= 30 * 60,
                    timerDanger: item.timeRemaining <= 10 * 60,
                    timerDangerPulse: item.timeRemaining <= 60
                  }
                ]"
                >{{ item.timeSince }}</span
              >
            </div>
            <Button v-tippy="{ content: $t('ui.common.more') }" size="sm" severity="secondary" outlined icon="ellipsis" class="mr-2" />
            <Button
              v-tippy="{ content: $t('ui.common.postpone') }"
              size="sm"
              severity="secondary"
              outlined
              icon="right-to-line"
              @click="postponeItem(index)"
            />
          </div>
        </div>
      </div>
    </CardBody>
  </Card>
  <Card>
    <CardBody>
      <CardTitle :title="$t('ui.common.date-time.daily')" :no-margin="true" />
    </CardBody>
  </Card>
</template>

<script>
import Button from '@/components/button/Button.vue'
import Card from '@/components/card/Card.vue'
import CardBody from '@/components/card/CardBody.vue'
import CardTitle from '@/components/card/CardTitle.vue'
import Avatar from '@/components/avatar/Avatar.vue'
import Icon from '@/components/icon/Icon.vue'
import { ref } from 'vue'
import { computed, onMounted } from 'vue'
import dayjs from 'dayjs'
export default {
  components: {
    Button,
    Card,
    CardBody,
    CardTitle,
    Avatar,
    Icon
  },
  setup() {
    let items = ref([])

    items.value = [
      {
        customerName: 'John Anderson',
        latestStatus: 'Contact Attempted',
        reasonForFlow: '3m since attempted contact',
        termRemaining: '',
        termLength: '48 Months',
        endDate: '2022/06/15',
        vrm: 'LB71 HLU',
        vehicleManufacturer: 'Tesla',
        vehicleModel: 'Model 3',
        presmsSentTimestamp: '2022-05-25 09:16:00',
        isPriority: true
      },
      {
        customerName: 'Rebecca Anderson',
        latestStatus: 'Idle',
        reasonForFlow: 'Under 12 months',
        termRemaining: '',
        termLength: '24 Months',
        endDate: '2022/09/15',
        vrm: 'YH16 XYN',
        vehicleManufacturer: 'Tesla',
        vehicleModel: 'Model 3',
        presmsSentTimestamp: '2022-05-25 09:37:00',
        isPriority: true
      },
      {
        customerName: 'Lea Boyes',
        latestStatus: 'Contact Attempted',
        reasonForFlow: '3m since appointment',
        termRemaining: '',
        termLength: '36 Months',
        endDate: '2023/01/15',
        vrm: 'RO20 KWL',
        vehicleManufacturer: 'Tesla',
        vehicleModel: 'Model 3',
        presmsSentTimestamp: '2022-05-24 09:28:00',
        isPriority: true
      }
    ]

    onMounted(() => {
      items.value.forEach(item => {
        item.timeRemaining = 0
        // console.log(item.customerName)

        //Check if the presmsSentTimestamp is greater that 1 hour
        if (dayjs().diff(item.presmsSentTimestamp, 'minute') < 60) {
          if (item.presmsSentTimestamp) {
            startTimer(item, item.presmsSentTimestamp)
          }
        }
      })
    })

    function postponeItem(itemIndex) {
      items.value.splice(itemIndex, 1)
    }

    function fancyTimeFormat(duration) {
      // Hours, minutes and seconds
      var hrs = ~~(duration / 3600)
      var mins = ~~((duration % 3600) / 60)
      var secs = ~~duration % 60

      // Output like "1:01" or "4:03:59" or "123:03:59"
      var ret = ''

      if (hrs > 0) {
        ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
      }

      ret += '' + mins + ':' + (secs < 10 ? '0' : '')
      ret += '' + secs
      return ret
    }

    function startTimer(item, timestamp) {
      let end = dayjs(timestamp).add(60, 'minute')
      // console.log('End: ' + end)

      item.timer = setInterval(() => {
        //Check if timestamp in the past
        if (dayjs(end).isBefore(dayjs())) {
          // console.log('Stop Timer!')
          clearInterval(item.timer)
        }

        item.timeRemaining = end.diff(dayjs(), 'second')
        item.timeSince = dayjs().diff(timestamp, 'second')
        item.timeSince = fancyTimeFormat(item.timeSince)
      }, 1000)
    }

    const itemsPriority = computed(() => {
      let filteredArray = []

      filteredArray = items.value.filter(item => item.isPriority)
      return filteredArray
    })

    return { items, itemsPriority, postponeItem, startTimer }
  }
}
</script>

<style>
.record {
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid var(--border-secondary);
  padding: 10px 5px;
}

.record:last-of-type {
  border: none;
}

.record > * {
  flex: 1 1;
  /* border: 1px solid grey; */
}

.timerWarning {
  color: var(--warning);
}

.timerDanger {
  color: var(--danger);
  text-shadow: 0px 0px 7px var(--success);
}

.timerDangerPulse {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    /* transform: scale(0.95); */
    text-shadow: 0px 0px 7px var(--danger);
  }

  70% {
    /* transform: scale(1); */
    text-shadow: none;
  }

  100% {
    /* transform: scale(0.95); */
    text-shadow: 0px 0px 7px var(--danger);
  }
}
</style>
