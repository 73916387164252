<template>
  <div class="notifications-list">
    <NotificationItem
      v-for="notification in notificationsFiltered"
      :key="notification.id"
      :type="notification.type"
      :is-viewed="notification.isViewed"
      :avatar-url="notification.avatarUrl"
      :avatar-text="notification.avatarText"
      :avatar-color="notification.avatarColor"
      :description="notification.description"
    />
  </div>
</template>

<script>
import NotificationItem from '@/components/notifications/NotificationItem.vue'
import { ref } from 'vue'
import { computed } from 'vue'
export default {
  components: {
    NotificationItem
  },
  props: {
    limit: {
      type: Number,
      default: 5
    },
    hideViewed: {
      trype: Boolean,
      default: true
    }
  },
  setup(props) {
    //Get notifications for this user
    let notifications = ref([
      {
        id: 1,
        type: 'todo-new_assigned',
        description: 'Emma has assigned a new task to you',
        avatarUrl: 'https://core.retain.group/images/profile-pics/Emma_Taylor.jpg',
        avatarText: '',
        isViewed: false
      },
      {
        id: 2,
        type: 'target-hit',
        isViewed: false
      },
      {
        id: 3,
        type: 'target-not_hit',
        isViewed: true
      },
      {
        id: 4,
        type: 'todo-new_assigned',
        description: 'Emma has assigned a new task to you',
        avatarUrl: 'https://core.retain.group/images/profile-pics/Emma_Taylor.jpg',
        avatarText: '',
        isViewed: false
      },
      {
        id: 5,
        type: 'target-hit',
        isViewed: false
      },
      {
        id: 6,
        type: 'target-not_hit',
        isViewed: true
      },
      {
        id: 7,
        type: 'target-hit',
        description: 'Test Notification',
        avatarText: 'KM',
        isViewed: false
      }
    ])

    const notificationsFiltered = computed(() => {
      let filteredList = notifications.value

      if (props.hideViewed) {
        filteredList = filteredList.filter(item => !item.isViewed)
      }

      return filteredList
    })

    return { notificationsFiltered }
  }
}
</script>

<style>
.notifications-list > .notification-item {
  border-bottom: 1px solid var(--border-secondary);
}

.notifications-list > .notification-item:last-of-type {
  border-bottom: none;
}
</style>
