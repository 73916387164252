import { ref } from 'vue'

//3 states
// Locked Open
// Expanded
// Collapsed

let initialState

if (localStorage.getItem('mainMenuState') !== null) {
  initialState = localStorage.getItem('mainMenuState')
} else {
  localStorage.setItem('mainMenuState', 'lockedOpen')
  initialState = 'lockedOpen'
}

export const state = ref(initialState)

export const mobileMenuIsOpen = ref(false)
