<template>
  <div class="form-group" :class="{ 'mb-4': !noMargin }">
    <Label v-if="label" :for="labelFor">
      {{ label }}<span v-if="isRequired" class="ml-1 text-quaternary">*</span>
      <Icon v-if="info" v-tippy="{ content: info }" class="ml-1" fa-style="fal" type="info-circle" />
    </Label>
    <slot></slot>
  </div>
</template>

<script>
import Label from '@/components/forms/Label.vue'
import Icon from '@/components/icon/Icon.vue'
export default {
  components: {
    Label,
    Icon
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    labelFor: {
      type: String,
      default: null
    },
    info: {
      type: String,
      default: null
    },
    isRequired: {
      type: [Boolean, Number, String, Object],
      default: false
    },
    noMargin: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
/* Style for flatpickr when input has error. Flatpickr hides original input so cannot have class assigned. */
.form-group.has-error input {
  border-color: var(--danger);
}
</style>
