<template>
  <DataTable
    ref="dt"
    export-filename="Dealership Targets"
    :value="targets"
    removable-sort
    :rows="100"
    data-key="id"
    column-resize-mode="fit"
    :paginator="true"
    responsive-layout="scroll"
    filter-display="menu"
    :rows-per-page-options="[10, 20, 50]"
    state-key="dt-dealership-targets"
    state-storage="session"
    current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
  >
    <template #empty> No records found </template>

    <ColumnGroup type="header">
      <Row>
        <Column :colspan="2" />
        <Column header="Calls" :colspan="4" />
        <Column header="Appointments" :colspan="5" />
      </Row>
      <Row>
        <Column field="dealershipName" header="Dealership" :sortable="true"> </Column>
        <Column field="paymentPlan" header="Plan" :sortable="true" />
        <Column field="callsTarget" header="Target" :sortable="true"> </Column>
        <Column field="callsHours" header="Hours" :sortable="true"> </Column>
        <Column field="callsMade" header="Made" :sortable="true"> </Column>
        <Column field="callsDifference" header="Difference" :sortable="true"> </Column>

        <Column field="appointmentsTarget" header="Target" :sortable="true"> </Column>
        <Column field="appointmentsCarriedForward" header="CF" :sortable="true"> </Column>
        <Column field="appointmentsMade" header="Made" :sortable="true"> </Column>
        <Column field="appointmentsDifference" header="Difference" :sortable="true"> </Column>
      </Row>
    </ColumnGroup>
    <Column field="dealershipName" header="Dealership"> </Column>
    <Column field="paymentPlan" header="Plan" />
    <Column field="callsTarget" header="Target"> </Column>
    <Column field="callsHours" header="Hours"> </Column>
    <Column field="callsMade" header="Made"> </Column>
    <Column field="callsDifference" header="Difference"> </Column>

    <Column field="appointmentsTarget" header="Target"> </Column>
    <Column field="appointmentsCarriedForward" header="CF"> </Column>
    <Column field="appointmentsMade" header="Made"> </Column>
    <Column field="appointmentsDifference" header="Difference"> </Column>
  </DataTable>
</template>

<script>
import Button from '@/components/button/Button.vue'
import ButtonGroup from '@/components/button/ButtonGroup.vue'
import Icon from '@/components/icon/Icon.vue'
import Input from '@/components/forms/Input.vue'
import Card from '@/components/card/Card.vue'
import CardBody from '@/components/card/CardBody.vue'
import CardTitle from '@/components/card/CardTitle.vue'
import Badge from '@/components/badge/Badge.vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup' //optional for column grouping
import Row from 'primevue/row' //optional for row
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import MultiSelect from 'primevue/multiselect'
import { ref } from 'vue'

export default {
  components: {
    MultiSelect,
    Card,
    CardTitle,
    CardBody,
    Input,
    Button,
    Icon,
    DataTable,
    Column,
    ColumnGroup,
    Row,
    Badge,
    ButtonGroup
  },
  setup() {
    const targets = ref([
      {
        id: 1,
        dealershipName: 'Caffyns VW Brighton',
        paymentPlan: 'Fixed',
        callsTarget: 1,
        callsHours: 1,
        callsMade: 1,
        callsDifference: 1,
        appointmentsTarget: 1,
        appointmentsMade: 1,
        appointmentsDifference: 1
      },
      {
        id: 2,
        dealershipName: 'Caffyns VW Worthing',
        paymentPlan: 'Fixed',
        callsTarget: 1,
        callsHours: 1,
        callsMade: 1,
        callsDifference: 1,
        appointmentsTarget: 1,
        appointmentsMade: 1,
        appointmentsDifference: 1
      }
    ])

    const dt = ref({ processedData: [] })

    return { targets }
  }
}
</script>

<style></style>
