<template>
  <div class="main-menu" :class="[classes, { 'mobile-open': mobileMenuIsOpen }]" @mouseover="expandMenu" @mouseleave="collapseMenu">
    <div class="header select-none">
      <div class="brand">
        <img class="logo" :src="$cdnBaseUrl + '/img/logos/retain/icon.png'" />
        <div class="text">&nbsp;</div>
      </div>
      <div class="lockMenu cursor-pointer">
        <div v-if="isMobile">
          <Icon type="times" @click="closeMobileMenu" />
        </div>
        <div v-else v-tippy="state === 'lockedOpen' ? 'Collapse Sidebar' : 'Expand Sidebar'">
          <Icon v-if="state === 'lockedOpen'" type="sidebar" @click="toggleMenuLock" />
          <Icon v-else type="sidebar" @click="toggleMenuLock" />
        </div>
      </div>
    </div>
    <ul class="main-nav">
      <li
        v-for="item in navItems"
        :key="item.name"
        class="main-nav-item"
        :class="{
          'has-sub': item.children,
          open: item.isOpen || $route.matched.some(route => route.path === item.route || route.path.startsWith(item.route + '/'))
        }"
        :style="canAccess(item) ? 'display:block;' : ''"
      >
        <!-- If Item does not have children - Level 1 -->
        <router-link v-if="!item.children" :to="item.route">
          <Icon :type="item.icon" />
          <span class="main-nav-item-title">{{ $t(item.localeKey, item.localePluralization) }}</span>
          <Badge
            v-if="state != 'collapsed' && item.id === 'todo' && userStore.outstandingTodos > 0"
            rounded
            bordered
            inline
            :content="userStore.outstandingTodos"
          ></Badge>
        </router-link>
        <!-- ELSE if item does have children - Level 2-->
        <a v-else href="#" @click="subMenuToggle(item)">
          <Icon :type="item.icon" />
          <span class="main-nav-item-title">{{ $t(item.localeKey, item.localePluralization) }}</span>
        </a>
        <ul v-if="item.children" class="main-nav-submenu">
          <li
            v-for="childItem in item.children"
            :key="childItem.name"
            :class="{
              'has-sub': childItem.children,
              open:
                childItem.isOpen ||
                $route.matched.some(route => route.path === childItem.route || route.path.startsWith(childItem.route + '/'))
            }"
            class="main-nav-item"
            :style="canAccess(childItem) ? 'display:block;' : ''"
          >
            <!-- If childItem does not have children -->
            <router-link v-if="!childItem.children" :to="childItem.route">
              <Icon type="circle" fa-style="far" />
              <span class="main-nav-item-title">{{ $t(childItem.localeKey, childItem.localePluralization) }}</span>
            </router-link>
            <!-- ELSE if childItem does have children - Level 3-->
            <a v-else href="#" @click="subMenuToggle(childItem)">
              <Icon type="circle" fa-style="far" />
              <span class="main-nav-item-title">{{ $t(childItem.localeKey, childItem.localePluralization) }}</span>
            </a>
            <ul v-if="childItem.children" class="main-nav-submenu">
              <li
                v-for="childItem2 in childItem.children"
                :key="childItem2.name"
                class="main-nav-item"
                :style="canAccess(childItem2) ? 'display:block;' : ''"
              >
                <!-- Last level. No more possible -->
                <router-link :to="childItem2.route">
                  <Icon type="circle" fa-style="far" />
                  <span class="main-nav-item-title">{{ $t(childItem2.localeKey, childItem2.localePluralization) }}</span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <div class="px-6 text-center flex flex-col">
      <div v-tippy="{ content: env }" class="my-3 text-xs">{{ appVersion }}</div>
    </div>
  </div>
  <div v-if="mobileMenuIsOpen" class="main-menu-overlay" @click="closeMobileMenu"></div>
</template>

<script>
import { state, mobileMenuIsOpen } from './state'
import { ref, watch, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { inject } from 'vue'
import packageInfo from '/package.json'

import { useUserStore } from '@/stores/UserStore'
import { useAbility } from '@casl/vue'

import { getNavItems, navItems } from '@/utilities/nav-items.js'

import Badge from '@/components/badge/Badge.vue'
import Icon from '@/components/icon/Icon.vue'

export default {
  components: {
    Icon,
    Badge
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const route = useRoute()
    const userStore = useUserStore()
    const classes = ref('')

    const { can } = useAbility()

    const env = import.meta.env.NODE_ENV

    let appVersion = packageInfo.version

    const emitter = inject('emitter')

    emitter.on('counter-todos-total', value => {
      // *Listen* for event
      // console.log('Todos counter will be updated mm!', `value: ${value}`)
      userStore.outstandingTodos = value
    })

    function setClasses() {
      // console.log('toggle mobile func')
      if (props.isMobile) {
        // console.log('Is Mobile')
        classes.value = 'expanded mobile'
        state.value = 'expanded'
      } else {
        // console.log('Is Desktop/Tablet')
        if (state.value === 'lockedOpen') {
          classes.value = 'lockedOpen expanded'
        }
        if (state.value === 'expanded') {
          classes.value = 'expanded'
        }
        if (state.value === 'collapsed') {
          classes.value = 'collapsed'
        }
      }

      // console.log(classes.value);
    }

    getNavItems(userStore.roles)

    const canAccess = item => {
      // If canGate or rolesAllowed is undefined, return true
      if (item.canGate === undefined) {
        return true
      } else {
        return can(item.canGate)
      }
    }

    // If menu is not locked open, reset to collapsed. On page reload, the menu should not be stuck open
    if (state.value !== 'lockedOpen') {
      state.value = 'collapsed'
    }

    // console.log(navItems);
    function expandMenu() {
      //If menu is locked open, do nothing, else expand the menu
      if (state.value !== 'lockedOpen') {
        state.value = 'expanded'
      }
      setClasses()
    }
    function collapseMenu() {
      //If menu is locked open, do nothing, else expand the menu
      if (state.value !== 'lockedOpen') {
        state.value = 'collapsed'

        navItems.value.forEach(item => {
          item.isOpen = false
        })
      }
      setClasses()
    }
    function toggleMenuLock() {
      if (state.value !== 'lockedOpen') {
        state.value = 'lockedOpen'
      } else {
        // console.log('Unlocking, and Expanding Menu');
        state.value = 'expanded'
      }
    }

    function subMenuToggle(item) {
      // console.log('Toggled item: ' + item.title);

      item.isOpen = !item.isOpen
    }

    function closeMobileMenu() {
      mobileMenuIsOpen.value = false
    }

    onMounted(() => {
      setClasses()
    })

    watch(
      () => route.name,
      (currentRoute, newRoute) => {
        if (currentRoute != newRoute) {
          // console.log(route)

          //close mobile menu
          mobileMenuIsOpen.value = false

          navItems.value.forEach(item => {
            item.isOpen = false
          })
        }
      }
    )

    watch(
      () => state.value,
      () => {
        localStorage.setItem('mainMenuState', state.value)
      }
    )

    watch(
      () => props.isMobile,
      (newVal, oldVal) => {
        if (oldVal) {
          //If was mobile, but changing to desktop
          state.value = 'collapsed'
        }
        // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
        setClasses()
      }
    )

    return {
      env,
      appVersion,
      state,
      classes,
      expandMenu,
      collapseMenu,
      toggleMenuLock,
      subMenuToggle,
      navItems,
      setClasses,
      mobileMenuIsOpen,
      closeMobileMenu,
      userStore,
      canAccess
    }
  }
}
</script>

<style>
/* MainMenu.vue */
.main-menu {
  color: var(--text-primary);
  background-color: var(--bg-primary_alt);

  /* box-shadow: 0 0 15px 0 rgb(34 41 47 / 5%); */
  border-right: 1px solid var(--border-color);

  float: left;
  position: fixed;
  z-index: 105;
  top: 0;
  left: 0;
  bottom: 0;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  transition: width 0.3s ease-in-out;
}

.main-menu.expanded {
  width: 260px;
}

.main-menu.collapsed {
  width: 80px;
}

.main-menu.mobile {
  transform: translate(-260px);
}

.main-menu.mobile.mobile-open {
  transform: none;
}

.main-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(34, 41, 47, 0.5);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  z-index: 100;
}

.main-menu > .header {
  padding: 1rem 1rem 0.3rem 1.8rem;
  /* color: var(--fg-brand); */
  display: flex;
  align-items: center;
  overflow: hidden;
}

.main-menu > .header > .brand {
  height: 30px;
  display: flex;
  align-content: center;
}

.main-menu > .header > .brand > .logo {
  width: 30px;
  height: 30px;
}

.main-menu > .header > .brand > .text {
  padding: 0.6rem 1rem 1rem 0.7rem;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2rem;
  font-family: 'Axiforma';
  font-weight: 800;
}
.dark .main-menu > .header > .brand > .text {
}

.main-menu > .header > .lockMenu {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: 1.2rem;
}

.main-menu.collapsed .header > .brand > .text,
.main-menu.collapsed .header > .lockMenu {
  display: none;
  /* transition: 0.2s ease-in-out; */
}

.main-nav-item {
  position: relative;
  white-space: nowrap;
  font-size: var(--text-md);
  font-weight: 500;
  text-transform: capitalize;
  display: none;
}

.main-nav-item.has-sub > a::after {
  font-family: 'Font Awesome 6 Pro';
  content: '\f078';
  position: absolute;
  right: 30px;
  top: 15px;
  font-size: 0.8rem;
  color: var(--fg-quaternary);

  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition:
    transform 0.25s ease,
    -webkit-transform 0.25s ease-in-out;
}

.main-nav {
  margin-top: 0.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.main-nav-item a {
  margin: 0px 16px 4px;
  padding: 10px 13px;
  color: var(--text-primary);
  line-height: 1.45;
  border-radius: 5px;
  display: flex;
  align-items: center;
  min-height: 2rem;
}

.main-nav-item a > * {
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition:
    transform 0.25s ease,
    -webkit-transform 0.25s ease-in-out;
}

.main-nav-item-title {
  display: inline-block;
  flex: 1;
}

.main-nav-item .icon {
  margin-right: var(--s-4);
  flex-shrink: 0;
  font-size: 1.2rem;
}

/* If has children */
.main-nav-item.has-sub .main-nav-submenu {
  display: none;
  margin-top: 5px;
}

.main-nav-item.has-sub .main-nav-item > a {
  padding-left: 35px;
  background: none;
}

.dark .main-nav-item.has-sub .main-nav-item > a {
  background: none;
}

.main-nav-item.has-sub .main-nav-item .icon {
  font-size: 0.5rem;
}

.main-nav-item:hover > a {
  background: var(--bg-tertiary) !important;
}

.dark .main-nav-item:hover > a {
  background: var(--gray-800) !important;
}

/* Opening Sub Menus */
.main-nav-item.open > a {
  background: var(--bg-tertiary);
}

.dark .main-nav-item.open > a {
  background: var(--gray-800);
}

.main-nav-item.open > a::after {
  transform: rotate(-180deg);
}

.main-nav-item.open > .main-nav-submenu {
  display: block;
}

/* Router & active menu items */
.main-nav-item > a.router-link-exact-active {
  background: var(--bg-tertiary) !important;
  /* color: var(--fg-brand); */
}

/* styles when collapsed */
.main-menu.collapsed .main-nav-submenu {
  display: none;
}

.main-menu.collapsed .main-nav-item.has-sub > a::after {
  display: none;
}

.main-menu.collapsed .main-nav-item-title {
  display: none;
}

.main-menu.collapsed .main-nav-item > a.router-link-exact-active {
  background: var(--bg-tertiary) !important;
}

.dark .main-menu.collapsed .main-nav-item > a.router-link-exact-active {
  background: var(--gray-800);
}

.collapsed .main-nav-item .badge {
  transform: translate(-13px, 0px);
  font-size: 0.7rem;
  min-width: 20px;
}
</style>
