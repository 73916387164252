// statusMapper.js
const mapping = {
  agreementStatus: [
    {
      key: 'Idle',
      text: 'Idle',
      icon: 'circle-small',
      color: 'primary'
    },
    {
      key: 'Unresponsive',
      text: 'Unresponsive',
      icon: 'phone-missed',
      color: 'warning'
    },
    {
      key: 'Unreachable',
      text: 'Unreachable',
      icon: 'ban',
      color: 'danger'
    },
    {
      key: 'Uncontactable',
      text: 'Uncontactable',
      icon: 'ban',
      color: 'danger'
    },
    {
      key: 'Contacted',
      text: 'Contacted',
      icon: 'phone',
      color: 'primary'
    },
    {
      key: 'Appointed',
      text: 'Appointed',
      icon: 'calendar',
      color: 'info'
    },
    {
      key: 'Complete',
      text: 'Complete',
      icon: 'check',
      color: 'success'
    },
    {
      key: 'Dealt',
      text: 'Dealt',
      icon: 'circle-check',
      color: 'success'
    },
    {
      key: 'Settled',
      text: 'Settled',
      icon: 'circle-check',
      color: 'success'
    },
    {
      key: 'Refinanced',
      text: 'Refinanced',
      icon: 'circle-check',
      color: 'success'
    },
    {
      key: 'Handed Back',
      text: 'Handed Back',
      icon: 'circle-check',
      color: 'success'
    },
    {
      key: 'Sold',
      text: 'Sold',
      icon: 'circle-check',
      color: 'success'
    },
    {
      key: 'Not Sold',
      text: 'Not Sold',
      icon: 'circle-xmark',
      color: 'danger'
    },
    {
      key: 'ERROR',
      text: 'ERROR',
      icon: 'bug',
      color: 'danger'
    }
  ],
  appointmentStatus: [
    {
      key: 'unassigned',
      localeKey: 'ui.entities.appointment.status.unassigned',
      color: 'utility-gray-600'
    },
    {
      key: 'assigned',
      localeKey: 'ui.entities.appointment.status.assigned',
      color: 'blue-800'
    },
    {
      key: 'cancelled',
      localeKey: 'ui.entities.appointment.status.cancelled',
      color: 'utility-error-600'
    },
    {
      key: 'no-show',
      localeKey: 'ui.entities.appointment.status.no-show',
      color: 'utility-error-600'
    },
    {
      key: 'completed',
      localeKey: 'ui.entities.appointment.status.completed',
      color: 'utility-success-500'
    },
    {
      key: 'ongoing',
      localeKey: 'ui.entities.appointment.status.ongoing',
      color: 'utility-purple-600'
    },
    {
      key: 'ERROR',
      localeKey: 'dev.data-mapping-error',
      icon: 'bug',
      color: 'danger'
    }
  ],
  appointmentMethod: [
    {
      key: 'physical',
      localeKey: 'ui.entities.appointment.method.physical',
      icon: 'car-building'
    },
    {
      key: 'phone',
      localeKey: 'ui.entities.appointment.method.phone',
      icon: 'phone'
    },
    {
      key: 'video',
      localeKey: 'ui.entities.appointment.method.video',
      icon: 'video'
    },
    {
      key: 'video_external',
      localeKey: 'ui.entities.appointment.method.video',
      icon: 'video'
    },
    {
      key: 'ERROR',
      localeKey: 'dev.data-mapping-error',
      icon: 'bug',
      color: 'danger'
    }
  ],
  appointmentOutcome: [
    {
      key: 'no-show',
      localeKey: 'ui.entities.appointment.status.no-show',
      color: 'danger',
      icon: 'ban'
    },
    {
      key: 'completed',
      localeKey: 'ui.entities.appointment.status.completed',
      color: 'success',
      icon: 'check'
    },
    {
      key: 'ongoing',
      localeKey: 'ui.entities.appointment.status.ongoing',
      color: 'info',
      icon: 'arrows-rotate'
    },
    {
      key: 'ERROR',
      localeKey: 'dev.data-mapping-error',
      icon: 'bug',
      color: 'danger'
    }
  ],
  appointmentDecision: [
    {
      key: 'part_exchanged',
      label: 'Part-Exchanged',
      localeKey: 'ui.entities.appointment.decision.part-exchanged',
      applicableOutcomeTypes: ['completed'],
      applicableContractTypes: ['PCP', 'HP']
    },
    {
      key: 'refinanced',
      label: 'Refinanced',
      localeKey: 'ui.entities.appointment.decision.refinanced',
      applicableOutcomeTypes: ['completed'],
      applicableContractTypes: ['PCP']
    },
    {
      key: 'settled',
      label: 'Settled',
      localeKey: 'ui.entities.appointment.decision.settled',
      applicableOutcomeTypes: ['completed'],
      applicableContractTypes: ['PCP']
    },
    {
      key: 'handed_back',
      label: 'Handed Back',
      localeKey: 'ui.entities.appointment.decision.handed-back',
      applicableOutcomeTypes: ['completed'],
      applicableContractTypes: ['PCP', 'HP', 'CH', 'PCH', 'BCH']
    },
    {
      key: 'buy_back',
      label: 'Buy Back',
      localeKey: 'ui.entities.appointment.decision.buy-back',
      applicableOutcomeTypes: ['completed'],
      applicableContractTypes: ['PCP']
    },
    {
      key: 'new_lease',
      label: 'New Lease',
      localeKey: 'ui.entities.appointment.decision.new-lease',
      applicableOutcomeTypes: ['completed'],
      applicableContractTypes: ['CH', 'PCH', 'BCH']
    },
    {
      key: 'extended',
      label: 'Extended',
      localeKey: 'ui.entities.appointment.decision.extended',
      applicableOutcomeTypes: ['completed'],
      applicableContractTypes: ['CH', 'PCH', 'BCH']
    },
    {
      key: 'keeping',
      label: 'Keeping',
      localeKey: 'ui.entities.appointment.decision.keeping',
      applicableOutcomeTypes: ['completed'],
      applicableContractTypes: ['HP']
    },
    {
      key: 'undecided',
      label: 'Undecided',
      localeKey: 'ui.entities.appointment.decision.undecided',
      applicableOutcomeTypes: ['ongoing'],
      applicableContractTypes: ['PCP', 'HP', 'CH', 'PCH', 'BCH']
    },
    {
      key: 'not_ready',
      label: 'Not Ready',
      localeKey: 'ui.entities.appointment.decision.not-ready',
      applicableOutcomeTypes: ['ongoing'],
      applicableContractTypes: ['PCP', 'HP', 'CH', 'PCH', 'BCH']
    },
    {
      key: 'poor_offer',
      label: 'Poor Offer',
      localeKey: 'ui.entities.appointment.decision.poor-offer',
      applicableOutcomeTypes: ['ongoing'],
      applicableContractTypes: ['PCP', 'HP', 'CH', 'PCH', 'BCH']
    },
    {
      key: 'ERROR',
      localeKey: 'dev.data-mapping-error',
      icon: 'bug',
      color: 'danger'
    }
  ],
  callAgentBadges: [
    {
      key: 'streak',
      localeKey: 'ui.gamification.badges.streak',
      ringColor: '#FFA57E',
      icon: 'flame',
      iconColor: '#F9782F'
    },
    {
      key: 'achievements',
      localeKey: 'ui.gamification.badges.achievement',
      localePluralIndex: 2,
      ringColor: '#FFDCA7',
      icon: 'medal',
      iconColor: '#FFC874'
    },
    {
      key: 'leaderboard',
      localeKey: 'ui.gamification.badges.leaderboard',
      ringColor: '#799DDE',
      icon: 'wreath-laurel',
      iconColor: '#799DDE'
    },
    {
      key: 'ERROR',
      localeKey: 'dev.data-mapping-error',
      icon: 'bug',
      color: 'danger'
    }
  ],
  phoneNumberTypes: [
    {
      key: 'MOBILE',
      localeKey: 'ui.entities.comms.phone-number-type.mobile'
    },
    {
      key: 'FIXED_LINE',
      localeKey: 'ui.entities.comms.phone-number-type.fixed-line'
    },
    {
      key: 'FIXED_LINE_OR_MOBILE',
      localeKey: 'ui.entities.comms.phone-number-type.fixed-line-or-mobile'
    },
    {
      key: 'TOLL_FREE',
      localeKey: 'ui.entities.comms.phone-number-type.toll-free'
    },
    {
      key: 'PREMIUM_RATE',
      localeKey: 'ui.entities.comms.phone-number-type.premium-rate'
    },
    {
      key: 'SHARED_COST',
      localeKey: 'ui.entities.comms.phone-number-type.shared-cost'
    },
    {
      key: 'VOIP',
      localeKey: 'ui.entities.comms.phone-number-type.voip'
    },
    {
      key: 'PERSONAL_NUMBER',
      localeKey: 'ui.entities.comms.phone-number-type.personal-number'
    },
    {
      key: 'PAGER',
      localeKey: 'ui.entities.comms.phone-number-type.pager'
    },
    {
      key: 'UAN',
      localeKey: 'ui.entities.comms.phone-number-type.uan'
    },
    {
      key: 'VOICEMAIL',
      localeKey: 'ui.entities.comms.phone-number-type.voicemail'
    },
    {
      key: 'UNKNOWN',
      localeKey: 'ui.entities.comms.phone-number-type.unknown'
    },
    {
      key: 'ERROR',
      localeKey: 'dev.data-mapping-error',
      icon: 'bug',
      color: 'danger'
    }
  ]
}

/**
 * Retrieves the properties of a resource item based on the provided key.
 *
 * @param {string} resource - The resource to search for the item.
 * @param {string} key - The key to match the item.
 * @returns {object} - The properties of the matching item, or an error object if no match is found.
 */
export function getProperties(resource, key) {
  const errorObject = { key: 'ERROR', text: 'ERROR', localeKey: 'dev.data-mapping-error', icon: 'bug', color: 'danger' }

  // check that both arguments are provided
  if (!resource || !key) {
    console.error('DataMapper.js: Missing arguments: resource and key')
    return errorObject
  }

  let resourceItems = mapping[resource]
  if (!resourceItems) {
    console.error(`DataMapper.js: Resource ${resource} not found`)
    return { ...errorObject, message: `Resource ${resource} not found` }
  }

  // Find the item with the matching key
  let item = resourceItems.find(item => item.key === key)
  if (!item) {
    console.error(`DataMapper.js: Key ${key} not found in resource ${resource}`)
    return { ...errorObject, message: `Key ${key} not found in resource ${resource}` }
  }

  // Return the properties
  return item
}

// Example Usage
// <Avatar size="md" :icon="getProperties('appointmentMethod', app.method).icon" rounding="rounded" color="secondary" />
