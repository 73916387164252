<template>
  <PageHeader
    :title="$t('ui.entities.campaign')"
    breadcrumb
    :items="[{ label: $t('ui.entities.campaign', 2), to: '/customers/campaigns' }, { label: campaignData?.name }]"
  >
    <template #right>
      <div v-if="campaignData">
        <Button
          v-if="$can('manage_campaigns')"
          label="Delete"
          outlined
          icon="trash"
          severity="danger"
          class="mr-3"
          @click="deleteCampaign"
        />
        <!-- <Button label="Edit" icon="plus" class="mr-3" /> -->
      </div>
    </template>
  </PageHeader>

  <Card>
    <DataTable
      v-if="campaignIsLoading || campaignData"
      ref="dt"
      v-model:filters="filters"
      :value="prospectsComputed"
      removable-sort
      sort-mode="multiple"
      :sort-order="1"
      :rows="20"
      data-key="id"
      :paginator="true"
      filter-display="menu"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-campaign-prospects-list"
      state-storage="session"
      :loading="campaignIsLoading"
      :global-filter-fields="['id', 'name']"
    >
      <template #empty> No records found </template>
      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>
        </div>
      </template>

      <!-- <Column selection-mode="multiple" header-style="width: 3em"></Column> -->
      <!-- <Column field="id" header="#" :sortable="true">
        <template #body="{ data }">
          <a class="link" :href="'/agreement/' + data.agreementID">{{ data.agreementID }}</a>
        </template>
      </Column> -->
      <Column field="name" header="Customer" :sortable="true">
        <template #body="{ data }">
          <div class="flex">
            <router-link :to="'/agreement/' + data.agreementID" class="font-medium link-hover">{{ data.name }}</router-link>
            <Icon v-if="data.isArchived" type="box-archive" class="ml-2" />
          </div>
        </template>
      </Column>
      <Column v-if="1 == 0" field="model" header="Vehicle" :sortable="true">
        <template #body="{ data }">
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <img v-tippy="{ content: data.make }" :src="data.manufacturerLogo" class="max-h-4 w-4 mr-2" />
              {{ data.model }}
            </div>

            <CopyText>
              <LicensePlate
                :plate="data.vrm"
                country="uk"
                :design="data.fuelType == 'electric' ? 'ev' : ''"
                greyscale
                size="xs"
                class="ml-2"
              />
            </CopyText>
          </div>
        </template>
      </Column>

      <Column
        field="contractType"
        header="Finance Product"
        :sortable="false"
        :show-filter-match-modes="false"
        :show-filter-operator="false"
        :filter-menu-style="{ 'max-width': '24rem' }"
      >
        <template #body="{ data }">
          {{ data.contractType }}
        </template>
        <template #filter="{ filterModel }">
          <div class="mb-3 font-bold">Contract Type</div>
          <MultiSelect v-model="filterModel.value" :options="contractTypesList" placeholder="Any" class="p-column-filter">
            <template #option="slotProps">
              <div class="p-multiselect-representative-option">
                <span class="image-text">{{ slotProps.option }}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>

      <!-- <Column field="endDate" header="End Date" :sortable="true">
        <template #body="{ data }">
          <div class="whitespace-nowrap">{{ dayjs(data.endDate).format('L') }}</div>
        </template>
      </Column> -->

      <Column field="timeRemaining" header="End Date" :sortable="true">
        <template #body="{ data }">
          <div v-tippy="{ content: dayjs(data.endDate).format('ll') }" class="whitespace-nowrap inline">
            {{ dayjs(data.endDate).fromNow() }}
          </div>
        </template>
      </Column>

      <Column
        field="status"
        header="Status"
        :sortable="true"
        :show-filter-match-modes="false"
        :show-filter-operator="false"
        :filter-menu-style="{ 'max-width': '24rem' }"
      >
        <template #body="{ data }">
          <Chip
            :severity="getProperties('agreementStatus', data.status)?.color"
            label
            :icon="getProperties('agreementStatus', data.status)?.icon"
            >{{ data.status }}</Chip
          >
        </template>
        <template #filter="{ filterModel }">
          <div class="mb-3 font-bold">Status</div>
          <MultiSelect v-model="filterModel.value" :options="statusesList" placeholder="Any" class="p-column-filter">
            <template #option="slotProps">
              <div class="p-multiselect-representative-option">
                <span class="image-text">{{ slotProps.option }}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>

      <Column
        field="tags"
        header="Tags"
        :sortable="false"
        :show-filter-match-modes="false"
        :show-filter-operator="false"
        :filter-menu-style="{ 'max-width': '24rem' }"
      >
        <template #body="{ data }">
          <Chip v-for="tag in data.tags" :key="tag" class="mr-1" size="sm" :severity="tag.displayStyle">{{ tag.title }}</Chip>
        </template>
        <template #filter="{ filterModel }">
          <div class="mb-3 font-bold">Tags</div>
          <MultiSelect
            v-model="filterModel.value"
            option-label="title"
            option-value="id"
            :options="tagsList"
            display="chip"
            placeholder="Any"
            class="p-column-filter"
          >
            <template #option="slotProps">
              <div class="p-multiselect-representative-option">
                <span class="image-text">{{ slotProps.option.title }}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>

      <Column field="lastCalled" header="Last Called" :sortable="true">
        <template #body="{ data }">
          <span v-tippy="{ content: data.lastCallData.tippy }" class="whitespace-nowrap" :class="'text-' + data.lastCallData.color">{{
            data.lastCallData.text
          }}</span>
        </template>
      </Column>

      <Column field="latestAppointment" header="Appointment" :sortable="true">
        <template #body="{ data }">
          <span v-tippy="{ content: data.latestAppointment }" class="whitespace-nowrap">{{
            data.latestAppointment ? dayjs(data.latestAppointment).format('ll') : ''
          }}</span>
        </template>
      </Column>
    </DataTable>
    <CardBody v-else>Campaign Not Found</CardBody>
  </Card>
</template>

<script>
import { computed, ref, onMounted } from 'vue'

import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import CardBody from '@/components/card/CardBody.vue'
import Button from '@/components/button/Button.vue'
import Chip from '@/components/chip/Chip.vue'
import Icon from '@/components/icon/Icon.vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode, FilterService } from 'primevue/api'
import MultiSelect from 'primevue/multiselect'

import CopyText from '@/components/CopyText.vue'
import LicensePlate from '@/components/license-plate/LicensePlate.vue'

import Input from '@/components/forms/Input.vue'

// import notification from '@/utilities/notification'

import useApiRequest from '@/composables/useApiRequest'

import { getProperties } from '@/utilities/dataMapper'

import { useRoute, useRouter } from 'vue-router'
import dayjs from 'dayjs'

import Swal from 'sweetalert2'

export default {
  components: {
    PageHeader,
    Card,
    CardBody,
    Icon,
    DataTable,
    Column,
    Chip,
    CopyText,
    LicensePlate,
    Input,
    Button,
    MultiSelect
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const campaignID = ref(route.params.campid)
    // console.log(campaignID)

    const allAgreementTags = useApiRequest()

    const dt = ref({ processedData: [] })

    const { isLoading: campaignIsLoading, data: campaignData, sendRequest: campaignFetchData } = useApiRequest()

    onMounted(() => {
      allAgreementTags.sendRequest({ endpoint: '/v1/tags' })
      campaignFetchData({ endpoint: '/v1/campaigns/' + campaignID.value })

      FilterService.register(tagsFilter.value, (value, filter) => {
        // console.log('Tags Filter: ')
        // console.log(value)
        // console.log(filter)
        // Assuming `value` is the array of tag objects in each row
        // and `filter` is the selected tag(s) from your dropdown
        if (!filter.length) {
          return true
        }

        return value.some(tag => filter.includes(tag.id))
      })
    })

    const tagsFilter = ref('Tags Filter')

    const filters = ref([])

    function initFilters() {
      filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        contractType: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: ['Idle', 'Unresponsive', 'Uncontactable', 'Contacted', 'Appointed'], matchMode: FilterMatchMode.IN },
        tags: { value: null, matchMode: tagsFilter.value }
      }
    }
    initFilters()

    function clearFilters() {
      initFilters()
    }

    const prospectsComputed = computed(() => {
      // console.log('campaignData:', campaignData.value)

      if (campaignData.value && campaignData.value.prospects?.length) {
        // Loop through each agreement
        campaignData.value.prospects.forEach(prospect => {
          //Tags
          if (!prospect.tags) {
            prospect.tags = []
          }
          prospect.tagIDs.forEach(tagID => {
            if (allAgreementTags.data.value) {
              let tagData = allAgreementTags.data.value.find(tag => tag.id === tagID)

              // If a matching tag data is found, add it to the matchedTags array
              if (tagData) {
                prospect.tags.push(tagData)
              }
            }
          })

          // Months Remaining
          prospect.timeRemaining = dayjs(prospect.endDate).diff()

          //Last Contact
          prospect.lastCallData = {
            text: '',
            tippy: 'Not called yet',
            color: 'secondary'
          }
          if (prospect.lastCalled) {
            let diff = dayjs(dayjs()).diff(dayjs(prospect.lastCalled), 'day')
            let color = ''
            if (diff >= 30 && diff < 60) {
              color = 'warning-primary'
            }
            if (diff > 60) {
              color = 'error-primary'
            }
            prospect.lastCallData = {
              text: dayjs(prospect.lastCalled).fromNow(),
              tippy: dayjs(prospect.lastCalled).format('DD-MM-YYYY HH:MM'),
              color: color
            }
          }
        })
      }

      return campaignData.value?.prospects
    })

    const contractTypesList = ref(['PCP', 'HP', 'CH'])
    const statusesList = ref([
      'Idle',
      'Unresponsive',
      'Uncontactable',
      'Contacted',
      'Appointed',
      'Dealt',
      'Refinanced',
      'Settled',
      'Handed Back'
    ])
    const tagsList = ref(allAgreementTags.data)
    // const tagsList = ref(['Do not contact (Unverified)'])

    function deleteCampaign() {
      console.log('Delete Campaign')
      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        reverseButtons: true,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, delete!',
        confirmButtonColor: 'var(--bg-error-solid)',
        cancelButtonText: 'Cancel'
      }).then(function (response) {
        if (response.isConfirmed) {
          useApiRequest()
            .sendRequest({ endpoint: '/v1/campaigns/' + campaignID.value, method: 'DELETE' })
            .then(() => {
              router.push('/customers/campaigns')
            })
        }
      })
    }

    return {
      dt,
      clearFilters,
      campaignData,
      campaignIsLoading,
      prospectsComputed,
      filters,
      getProperties,
      contractTypesList,
      statusesList,
      tagsList,
      deleteCampaign
    }
  }
}
</script>

<style></style>
