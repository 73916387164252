// src/timeFormatterUtil.js
//INFO - We use this function instead of dayJS as this considers timezone.
//INFO   Else we would need to add the localstorage item to everycomponent

import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(timezone)

let userTimezone = dayjs.tz.guess()
dayjs.tz.setDefault(userTimezone)

export function getTimezone() {
  return userTimezone
}

export function getTimezoneDateFormat(timezone) {
  // If timezone starts with 'America/', use the short format
  if (timezone.startsWith('America/')) {
    return 'MM/DD/YYYY'
  } else {
    return 'DD/MM/YYYY'
  }
}

/**
 * Converts a timestamp to a readable time format using dayjs library.
 * @param {number} timestamp - The timestamp to be converted.
 * @param {string} [format='HH:mm:ss'] - The format of the output time string.
 * @returns {string} The formatted time string.
 */
export function toReadableTime(timestamp, format = 'HH:mm:ss', timezone = getTimezone()) {
  return dayjs(timestamp).tz(timezone).format(format)
}

/**
 * Converts a timestamp to a readable date time format using dayjs library.
 * @param {number} timestamp - The timestamp to be converted.
 * @param {string} partsReturned - date-time, date, or time
 * @param {string} [format=null] - Set specific return format
 * @returns {string} The formatted date time string.
 */
export function toReadableDateTime(timestamp, partsReturned = 'date-time', format = null, timezone = getTimezone()) {
  if (format === null) {
    format = getTimezoneDateFormat(timezone)
    if (partsReturned === 'date-time') {
      return dayjs(timestamp)
        .tz(timezone)
        .format(format + ' HH:mm')
    } else if (partsReturned === 'date') {
      return dayjs(timestamp).tz(timezone).format(format)
    } else if (partsReturned === 'time') {
      return dayjs(timestamp).tz(timezone).format('HH:mm')
    }
  } else {
    return dayjs(timestamp).tz(timezone).format(format)
  }
}

/**
 * Converts a timestamp to the current timezone.
 * @param {number} timestamp - The timestamp to convert.
 * @returns {Date} - The converted date in the current timezone.
 */
export function toTimezone(timestamp, timezone = null) {
  // If timezone is specified, convert to that timezone
  if (timezone) {
    return dayjs(timestamp).tz(timezone)
  } else if (localStorage.getItem('TZ')) {
    return dayjs(timestamp).tz(localStorage.getItem('TZ')).format()
  } else {
    return dayjs(timestamp)
  }
}

/**
 * Calculates the time difference between two dates and formats it as a human-readable string.
 * @param {string} from - The starting date.
 * @param {string} [to] - The ending date. Defaults to the current date and time.
 * @returns {string} The formatted time difference.
 */
export function timeFrom(from, to = dayjs()) {
  return dayjs(from).from(to)
}

/**
 * Formats a timestamp into elapsed time parts.
 *
 * @param {Date} timestamp - The timestamp to format.
 * @param {number} [length=2] - The number of elapsed time parts to include.
 * @param {boolean} [ago=false] - Whether to append "ago" to the formatted elapsed time.
 * @returns {string} The formatted elapsed time.
 */
export function formatTimestampToParts(timestamp, length = 2, ago = false) {
  const now = dayjs()
  const elapsed = dayjs.duration(now.diff(timestamp))

  const parts = [
    { value: elapsed.years(), label: 'y' },
    { value: elapsed.months(), label: 'mo' },
    { value: elapsed.days(), label: 'd' },
    { value: elapsed.hours(), label: 'h' },
    { value: elapsed.minutes(), label: 'm' },
    { value: elapsed.seconds(), label: 's' }
  ].filter(part => part.value > 0)

  let elapsedTime = parts
    .slice(0, length)
    .map(part => `${part.value}${part.label}`)
    .join(' ')

  if (ago) {
    elapsedTime += ' ago'
  }

  return elapsedTime
}

/**
 * Formats total seconds into elapsed time parts.
 * @param {number} totalSeconds - The total number of seconds to format.
 * @param {number} [length=2] - The maximum number of time parts to include in the formatted string.
 * @param {boolean} [ago=false] - Whether to append "ago" to the formatted string.
 * @returns {string} The formatted elapsed time string.
 */
export function formatSecondsToParts(totalSeconds, length = 2, ago = false) {
  const elapsed = dayjs.duration(totalSeconds, 'seconds')

  const parts = [
    { value: elapsed.years(), label: 'y' },
    { value: elapsed.months(), label: 'mo' },
    { value: elapsed.days(), label: 'd' },
    { value: elapsed.hours(), label: 'h' },
    { value: elapsed.minutes(), label: 'm' },
    { value: elapsed.seconds(), label: 's' }
  ].filter(part => part.value > 0)

  let elapsedTime = parts
    .slice(0, length)
    .map(part => `${part.value}${part.label}`)
    .join(' ')

  if (ago) {
    elapsedTime += ' ago'
  }

  return elapsedTime
}
