<template>
  <div class="flex flex-col sm:flex-row gap-4 items-center justify-between mb-6">
    <div class="flex flex-col gap-4 w-full">
      <div v-if="breadcrumb">
        <Breadcrumb :items="items" />
      </div>
      <div class="flex justify-between">
        <div>
          <h1 v-if="title" class="text-2xl font-semibold leading-7 sm:truncate sm:text-3xl sm:tracking-tight">{{ title }}</h1>
          <div v-if="description" class="text-md text-tertiary">{{ description }}</div>
        </div>
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/page/Breadcrumb.vue'

export default {
  components: {
    Breadcrumb
  },
  props: {
    align: {
      type: String,
      default: 'left' // center, left
    },
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    breadcrumb: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const home = {
      icon: 'pi pi-home',
      to: '/'
    }

    return { home }
  }
}
</script>

<style></style>
