<template>
  <div class="avatar-group flex -space-x-2.5 overflow-hidden">
    <slot />
  </div>
</template>

<style>
.avatar-group .avatar {
  outline: 1.5px solid var(--white);
}
.dark .avatar-group .avatar {
  outline-color: var(--darkmode-3);
}
</style>
