<template>
  <div ref="scrollContainer" class="overflow-y-scroll">
    <div v-for="timelineItem in timeline" :key="timelineItem.date">
      <div class="w-full flex justify-center mt-4">
        <div class="timeline-date-pill">{{ $dayjs(timelineItem.date).format('MMM D YYYY') }}</div>
      </div>
      <ul>
        <ConversationsTimelineItem
          v-for="comm in timelineItem.conversations"
          :key="comm.id"
          :comm-item="comm"
          @scroll-to-in-reply-to="scrollToInReplyTo"
          @reply-to="replyTo"
        ></ConversationsTimelineItem>
      </ul>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted, watch, nextTick } from 'vue'
import ConversationsTimelineItem from '@/components/conversations/timeline/ConversationsTimelineItem.vue'
export default {
  components: {
    ConversationsTimelineItem
  },
  props: {
    conversations: {
      type: Object,
      default: () => ({})
    },
    heightSubtraction: {
      //This is used for if there is another sibling element to this timeline, and the scroll function needs to know the actual height
      type: Number,
      default: 0
    }
  },
  emits: ['replyTo'],
  setup(props, { emit }) {
    const scrollContainer = ref(null)

    function scrollToBottom() {
      const parentHeight = scrollContainer.value.parentNode.offsetHeight

      // console.log('SCROLLING!')
      // console.log('parent: ' + parentHeight)
      // console.log('scrollContainer: ')
      // console.log(scrollContainer.value)
      // console.log('containerHeight: ' + scrollContainer.value.scrollHeight)

      // Scroll to the bottom of the scroll container on load
      scrollContainer.value.scrollTop = scrollContainer.value.scrollHeight - (parentHeight - props.heightSubtraction)
    }

    onMounted(() => {
      scrollToBottom()
    })

    watch(
      () => props.conversations,
      () => {
        // console.log('CHANGE IN PROP VALUE!')
        // console.log(props.conversations)
        nextTick(() => {
          scrollToBottom()
        })
      }
    )

    // Define a computed property that sorts and groups the communication items by date
    const timeline = computed(() => {
      if (props.conversations) {
        if (Object.keys(props.conversations).length != 0) {
          // Create a map to group the communication items by date
          const timelineMap = new Map()
          for (const comm of props.conversations) {
            // Parse the createdAt string into a Date object
            const createdAt = new Date(comm.createdAt)
            const dateKey = createdAt.toISOString().split('T')[0]
            if (!timelineMap.has(dateKey)) {
              timelineMap.set(dateKey, [])
            }
            timelineMap.get(dateKey).push(comm)
          }

          // Convert the map to an array of timeline items, sorted by date
          const timelineItems = Array.from(timelineMap, ([dateKey, conversations]) => ({
            date: new Date(dateKey),
            conversations: conversations.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
          }))
          timelineItems.sort((a, b) => a.date - b.date)

          return timelineItems
        }
      }
      return []
    })

    function replyTo(item) {
      emit('replyTo', item)
    }

    function scrollToItem(itemID) {
      const targetElement = document.getElementById(itemID)
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' })

        const commContent = targetElement.querySelector('.comm-content')
        commContent.classList.add('ripple')

        // Force reflow to reset the animation
        commContent.style.animation = 'none'
        commContent.offsetHeight // Trigger a reflow by reading the offsetHeight property
        commContent.style.animation = null

        // Add the ripple class to apply the animation to the comm-container element's pseudo-element
        commContent.classList.add('ripple')

        // Remove the ripple class after the animation is done
        setTimeout(() => {
          commContent.classList.remove('ripple')
        }, 1000)
      }
    }

    function scrollToInReplyTo(targetMessageID) {
      // console.log('Scrolling to email with message ID of: ' + targetMessageID)

      //find the item which is being scrolled to
      const targetItem = props.conversations.filter(i => i.emailMessageID == targetMessageID)

      scrollToItem(targetItem[0].id)
    }

    return {
      scrollContainer,
      timeline,
      replyTo,
      scrollToInReplyTo
    }
  }
}
</script>

<style>
.timeline-date-pill {
  display: inline-block;
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-secondary);
  border-radius: 20px;
  padding: 3px 10px;
  font-size: 0.8rem;
  color: #555555;
  margin-right: 10px;
  font-weight: 500;
}

.comm-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  opacity: 0;
  pointer-events: none;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 var(--fg-brand);
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    opacity: 0;
  }
}

.ripple::before {
  animation: ripple 1s linear;
}
</style>
