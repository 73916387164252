<template>
  <div class="bg-container">
    <img class="blob blob-1" :src="$cdnBaseUrl + '/img/graphics/blobs/blob-1.svg'" />
    <img class="blob blob-2" :src="$cdnBaseUrl + '/img/graphics/blobs/blob-2.svg'" />
    <img class="blob blob-3" :src="$cdnBaseUrl + '/img/graphics/blobs/blob-3.svg'" />
  </div>
  <div class="auth-wrapper">
    <div class="auth-card">
      <form v-if="!emailSent" @submit.prevent>
        <h3 class="mb-5 font-medium text-gray-600">Forgot Password 🔒</h3>
        <div class="mb-6 text-secondary">Enter the email associated with your account</div>

        <FormGroup label="Email">
          <Input v-model="formObject.email" required type="text" />
          <InputError :has-error="v$.email.$error">{{ v$.email.$errors[0]?.$message }}</InputError>
        </FormGroup>

        <Button label="Send" type="submit" block :is-loading="isSubmitting" class="mt-5" @click="submit" />
      </form>
      <div v-else class="text-center">
        <Icon type="circle-check" class="text-success mb-4 text-4xl" />
        <div class="text-3xl">Email Sent</div>
        <div class="text-secondary mt-2">Check your inbox for reset instructions</div>
        <router-link to="/login">
          <Button label="Back to Login" type="submit" outlined block class="mt-8" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import axios from 'axios'

import Icon from '@/components/icon/Icon.vue'
import Button from '@/components/button/Button.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Input from '@/components/forms/Input.vue'
import InputError from '@/components/forms/InputError.vue'

import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default {
  name: 'PasswordReset',
  components: {
    FormGroup,
    Input,
    InputError,
    Button,
    Icon
  },
  setup() {
    const isSubmitting = ref(false)
    const emailSent = ref(false)

    const formObject = ref({
      email: null
    })

    const formRules = {
      email: {
        required,
        email
      }
    }

    const v$ = useVuelidate(formRules, formObject)

    async function submit() {
      const isFormCorrect = await v$.value.$validate()

      // console.log('FormCorrect:' + isFormCorrect, v$.value)

      if (isFormCorrect) {
        // Axios POST to API
        isSubmitting.value = true

        await axios.post(
          import.meta.env.VITE_API_BASE_URL + '/v1/auth/password/forgot',
          { email: formObject.value.email },
          {
            HTTP_X_API_ACCESS_TOKEN:
              'Ri1-8Jy0!VW8OV=zUtbFEJ/5MY-Jc1glEU1fvGookb9U=x1xrBq1mV8/y!IJTvXpO0h4Up=gauDV!QIS8iPIanK=7xO?uWvWHuTl?zvGS1w26Anvo0-EFEKwPO!tavY91M-kc=weoMaRycDQjz9ImJCF-?ZvD-P/sODQge7eZ9GZXkhIX8r8pQpuPoHCznL8dJ0TVOyzzmMmLew-S6ZPtPL!DRWa60PVpkSk4iU84I7E5KZ0I6njX4l!4?aM1BIB'
          }
        )
        emailSent.value = true

        isSubmitting.value = false
      }
    }

    return { formObject, v$, submit, isSubmitting, emailSent }
  }
}
</script>

<style scoped>
body,
html {
  height: 100%;
  font-size: 14px;
}

#app {
  height: 100%;
}

.auth-wrapper {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ecf8fc;
  height: 100vh;
}

.auth-card {
  background: #fff;
  min-width: 400px;
  max-width: 400px;
  margin: 1rem 0rem;
  padding: 3rem;
  box-shadow: var(--shadow-md);
  border-radius: 5px;
  z-index: 1;
  box-shadow: 0px 10px 10px 10px rgba(0, 0, 0, 0.04);
}

.bg-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.blob {
  position: absolute;
}

.blob-1 {
  top: -20%;
  right: 0;
  width: 40%;
  animation: moveBlobs1 8s infinite;
}

.blob-2 {
  top: -20%;
  left: 0;
  width: 20%;
  animation: moveBlobs2 7s infinite;
}

.blob-3 {
  bottom: -50%;
  left: 0;
  width: 80%;
  animation: moveBlobs3 6s infinite;
}

@keyframes moveBlobs1 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(10px, 10px) scale(1.01);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes moveBlobs2 {
  0% {
    transform: translate(0, 0) scale(1.01);
  }
  50% {
    transform: translate(-10px, 10px);
  }
  100% {
    transform: translate(0, 0) scale(1.01);
  }
}

@keyframes moveBlobs3 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(20px, -10px) scale(1.01);
  }
  100% {
    transform: translate(0, 0);
  }
}
</style>
