<template>
  <div class="border border-border-secondary rounded-md min-w-64 bg-primary">
    <div v-if="!listingData && displayStyle == 'grid'" class="w-full">
      <Skeleton height="16rem" width="100%" />
      <div class="flex flex-col gap-3 p-5">
        <div class="flex justify-between">
          <Skeleton height="1.5rem" width="6rem" />
          <Skeleton height="1.5rem" width="3rem" />
        </div>
        <Skeleton height="3rem" width="100%" />
        <Skeleton height="3rem" width="100%" />
        <Skeleton height="3rem" width="100%" />
      </div>
    </div>

    <div v-if="!listingData && displayStyle == 'list'" class="w-full flex">
      <Skeleton height="15rem" width="24rem" />
      <div class="flex flex-col gap-3 p-5 w-[60%]">
        <Skeleton height="1.5rem" width="12rem" />
        <Skeleton height="3rem" width="90%" />
        <Skeleton v-if="showMarketValues" height="3rem" width="16rem" />
        <Skeleton height="3rem" width="16rem" />
      </div>
    </div>

    <div v-else-if="listingData && displayStyle == 'grid'">
      <router-link :to="'/vehicles/reauction/' + listingData.id">
        <div class="relative h-[220px] overflow-hidden">
          <div class="w-full h-full flex justify-center overflow-hidden items-center rounded-t-md">
            <img
              v-if="listingData.images"
              :src="listingData.images[imageIndex] || 'https://cdn.retain.group/img/vehicles/default-car.svg'"
              class="object-cover w-full h-full"
              draggable="false"
            />
            <img v-else src="https://cdn.retain.group/img/vehicles/default-car.svg" class="w-full h-auto p-12" draggable="false" />
          </div>
          <div class="absolute top-2 left-2">
            <Chip v-if="listingData.location.town" v-tippy="listingData.location.county" icon="location-dot" size="xs" class="opacity-90">{{
              listingData.location.town
            }}</Chip>
          </div>
          <div
            v-if="listingData.images"
            class="bg-gray-200 w-6 h-6 flex items-center justify-center opacity-80 rounded-full absolute top-[50%] right-2 hover:cursor-pointer hover:scale-110 hover:bg-gray-100 transition duration-150 ease-in-out"
            @click.prevent="toggleImages('right')"
          >
            <Icon type="chevron-right" class="text-sm" />
          </div>
          <div
            v-if="listingData.images"
            class="bg-gray-200 w-6 h-6 flex items-center justify-center opacity-80 rounded-full absolute top-[50%] left-2 hover:cursor-pointer hover:scale-110 hover:bg-gray-100 transition duration-150 ease-in-out"
            @click.prevent="toggleImages('left')"
          >
            <Icon type="chevron-left" class="text-sm" />
          </div>
        </div>

        <div class="p-5 flex flex-col gap-4">
          <div class="flex flex-col gap-2">
            <div class="flex gap-2">
              <img :src="listingData.vehicle.manufacturerLogoUrl" class="w-5" />

              <div class="text-lg font-medium w-full">{{ listingData.vehicle.model }}</div>
              <div>
                <LicensePlate
                  :plate="listingData.vehicle.vrm"
                  country="uk"
                  :design="listingData.vehicle.fuelType == 'electric' ? 'ev' : ''"
                  greyscale
                  size="xs"
                />
              </div>
            </div>

            <div v-if="listingData.vehicle.derivative">{{ listingData.vehicle.derivative }}</div>
            <div class="flex items-center gap-1 flex-wrap mt-1">
              <Chip v-if="listingData.vehicle.fuelType" size="xs" label>{{ listingData.vehicle.fuelType }}</Chip>
              <Chip v-if="listingData.vehicle.odometer" size="xs" label>{{ numberFormatter(listingData.vehicle.odometer) }} mi</Chip>
              <Chip v-if="listingData.vehicle.registrationDate" size="xs" label>
                {{ dayjs(listingData.vehicle.registrationDate).format('YYYY') }}
              </Chip>
              <Chip v-if="listingData.vehicle.transmission" size="xs" label>{{ listingData.vehicle.transmission }}</Chip>
            </div>
          </div>

          <div class="flex flex-col gap-4">
            <div v-if="showMarketValues && listingData.capValuation">
              <div class="flex justify-between">
                <div>CAP clean</div>
                <div class="flex items-center">
                  <div>
                    {{
                      listingData.capValuation.valuationList.tradeAverage
                        ? numberFormatter(listingData.capValuation.valuationList.tradeAverage, 'currency', 2, listingData.currencyCode)
                        : '-'
                    }}
                  </div>
                </div>
              </div>
              <div class="flex justify-between">
                <div>RRP</div>
                <div class="flex items-center">
                  <div>
                    {{
                      listingData.capValuation.valuationList.tradeRetail
                        ? numberFormatter(listingData.capValuation.valuationList.tradeRetail, 'currency', 2, listingData.currencyCode)
                        : '-'
                    }}
                  </div>
                </div>
              </div>
            </div>

            <Separator />

            <div class="flex gap-4 w-full">
              <div class="flex justify-between w-full gap-2 items-center">
                <div v-if="listingData.type == 'sell'" class="text-tertiary">{{ listingData.sale.offers?.length || 0 }} offers</div>
                <div v-if="isListingExpired" class="text-error">Expired</div>
                <div v-else-if="listingData.sale.currentWindowExpiresAt" class="flex gap-1 items-center">
                  <Icon type="clock" fa-style="far" class="text-sm" :class="timerColor" />
                  <div :class="timerColor">
                    {{ listingTimeLeft }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>

    <div v-else-if="displayStyle == 'list'" class="flex">
      <router-link
        v-if="listingData.id"
        :to="isListingExpired ? '' : '/vehicles/reauction/' + listingData.id"
        :class="{ 'cursor-auto': isListingExpired }"
        class="w-full flex"
      >
        <div class="relative max-w-96">
          <div class="h-[240px] w-full overflow-hidden flex justify-center items-center">
            <img
              v-if="listingData.images"
              :src="listingData.images[imageIndex]"
              class="rounded-l-[4px] object-cover w-full h-full min-w-96"
              draggable="false"
            />
            <img
              v-else
              src="https://cdn.retain.group/img/vehicles/default-car.svg"
              class="rounded-l-[4px] h-full w-auto min-w-96 p-24"
              draggable="false"
            />
          </div>
          <div class="absolute top-2 left-2">
            <Chip v-if="listingData.location.town" v-tippy="listingData.location.county" icon="location-dot" size="xs" class="opacity-90">{{
              listingData.location.town
            }}</Chip>
          </div>
          <div
            v-if="listingData.images"
            class="bg-gray-200 w-6 h-6 flex items-center justify-center opacity-80 rounded-full absolute top-[50%] right-2 hover:cursor-pointer hover:scale-110 hover:bg-gray-100 transition duration-150 ease-in-out"
            @click.prevent="toggleImages('right')"
          >
            <Icon type="chevron-right" class="text-sm" />
          </div>
          <div
            v-if="listingData.images"
            class="bg-gray-200 w-6 h-6 flex items-center justify-center opacity-80 rounded-full absolute top-[50%] left-2 hover:cursor-pointer hover:scale-110 hover:bg-gray-100 transition duration-150 ease-in-out"
            @click.prevent="toggleImages('left')"
          >
            <Icon type="chevron-left" class="text-sm" />
          </div>
        </div>

        <div class="p-5 flex flex-col gap-4 w-full h-full">
          <div class="flex flex-col gap-2">
            <div class="flex gap-2">
              <img :src="listingData.vehicle.manufacturerLogoUrl" class="w-5" />

              <div class="text-xl font-medium w-full">{{ listingData.vehicle.model }}</div>
              <div>
                <LicensePlate
                  :plate="listingData.vehicle.vrm"
                  country="uk"
                  :design="listingData.vehicle.fuelType == 'electric' ? 'ev' : ''"
                  greyscale
                  size="sm"
                />
              </div>
            </div>

            <div v-if="listingData.vehicle.derivative">{{ listingData.vehicle.derivative }}</div>

            <div class="grid grid-cols-6 items-center gap-2">
              <div v-if="listingData.vehicle.fuelType" v-tippy="'Fuel Type'" class="vehicle-listing-card__detail-box">
                <Icon type="gas-pump" class="text-xs" />
                <div>{{ listingData.vehicle.fuelType }}</div>
              </div>
              <div v-if="listingData.vehicle.odometer" v-tippy="'Mileage'" class="vehicle-listing-card__detail-box">
                <Icon type="gauge" class="text-xs" />
                <div>{{ listingData.vehicle.odometer }}</div>
              </div>
              <div v-if="listingData.vehicle.registrationDate" v-tippy="'Registration Date'" class="vehicle-listing-card__detail-box">
                <Icon type="calendar" class="text-xs" />
                <div>{{ dayjs(listingData.vehicle.registrationDate).format('YYYY') }}</div>
              </div>
              <div v-if="listingData.vehicle.transmission" v-tippy="'Transmission'" class="vehicle-listing-card__detail-box">
                <Icon type="car-wrench" class="text-xs" />
                <div>{{ listingData.vehicle.transmission }}</div>
              </div>
              <div v-if="listingData.vehicle.doors" v-tippy="'Doors'" class="vehicle-listing-card__detail-box">
                <Icon type="door-open" class="text-xs" />
                <div>{{ listingData.vehicle.doors }}</div>
              </div>
              <div v-if="listingData.vehicle.previousOwners" v-tippy="'Prev. Owners'" class="vehicle-listing-card__detail-box">
                <Icon type="users" class="text-xs" />
                <div>{{ listingData.vehicle.previousOwners }}</div>
              </div>
            </div>

            <div v-if="showMarketValues && listingData.capValuation" class="max-w-52">
              <div class="flex justify-between">
                <div>CAP clean</div>

                <div class="flex items-center">
                  <div>
                    {{
                      listingData.capValuation.valuationList.tradeAverage
                        ? numberFormatter(listingData.capValuation.valuationList.tradeAverage, 'currency', 2, listingData.currencyCode)
                        : '-'
                    }}
                  </div>
                </div>
              </div>
              <div class="flex justify-between">
                <div>RRP</div>
                <div class="flex items-center">
                  <div>
                    {{
                      listingData.capValuation.valuationList.tradeRetail
                        ? numberFormatter(listingData.capValuation.valuationList.tradeRetail, 'currency', 2, listingData.currencyCode)
                        : '-'
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-3 justify-between h-full">
            <Separator v-if="showMarketValues && listingData.capValuation" />

            <div class="flex justify-between items-baseline">
              <div v-if="listingData.type == 'sell'" class="text-secondary">{{ listingData.sale.offers?.length || 0 }} offers</div>
              <div v-if="isListingExpired" class="text-error">Expired</div>
              <div v-else-if="listingData.sale.currentWindowExpiresAt" class="flex gap-1 items-center">
                <Icon type="clock" fa-style="far" class="text-sm" :class="timerColor" />
                <div :class="timerColor">
                  {{ listingTimeLeft }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { onMounted } from 'vue'

import Chip from '@/components/chip/Chip.vue'
import Icon from '@/components/icon/Icon.vue'
import Separator from '@/components/separator/Separator.vue'
import LicensePlate from '@/components/license-plate/LicensePlate.vue'

import Skeleton from 'primevue/skeleton'
import dayjs from 'dayjs'

const props = defineProps({
  listingData: {
    type: Object,
    default: null
  },
  displayStyle: {
    type: String,
    default: 'grid' //grid, list
  },
  showMarketValues: {
    type: Boolean,
    default: true
  }
})

const imageIndex = ref(0)
const listingTimeLeft = ref(null)
const isListingExpired = ref(false)
const timerColor = ref(null)

function toggleImages(direction) {
  if (direction == 'right') {
    if (imageIndex.value == props.listingData.images.length - 1) {
      imageIndex.value = 0
    } else {
      imageIndex.value++
    }
  } else if (direction == 'left') {
    if (imageIndex.value == 0) {
      imageIndex.value = props.listingData.images.length - 1
    } else {
      imageIndex.value--
    }
  }
}

function listingTimer() {
  listingTimeLeft.value = dayjs().to(dayjs(props.listingData.sale.currentWindowExpiresAt), true)
  if (dayjs(props.listingData.sale.currentWindowExpiresAt).isBefore(dayjs())) {
    isListingExpired.value = true
  }
  if (dayjs(props.listingData.sale.currentWindowExpiresAt).diff(dayjs(), 'minutes', true) < 60) {
    timerColor.value = 'text-error'
  } else if (dayjs(props.listingData.sale.currentWindowExpiresAt).diff(dayjs(), 'hours', true) < 6) {
    timerColor.value = 'text-warning'
  } else {
    timerColor.value = 'text-success'
  }
}

const timerInterval = setInterval(listingTimer, 5000)

watch(isListingExpired => {
  if (isListingExpired.value == true) {
    clearInterval(timerInterval)
  }
})

onMounted(() => {
  listingTimer()
})
</script>

<style>
.vehicle-listing-card__detail-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--s-1) var(--s-2);
  border: 1px solid var(--border-secondary);
  border-radius: var(--rounded-md);
  background-color: var(--bg-tertiary);
}
</style>
