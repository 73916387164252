<template>
  <div class="table-wrapper" :class="{ bordered: bordered, 'row-hover': rowHover }">
    <table class="table-basic" :class="{ 'tb-striped': striped, 'tb-rounded-top': roundedTop, 'tb-rounded-bottom': roundedBottom }">
      <slot></slot>
    </table>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TableBasic',
  props: {
    bordered: {
      type: Boolean,
      default: false
    },
    striped: {
      type: Boolean,
      default: false
    },
    rowHover: {
      type: Boolean,
      default: false
    },
    roundedTop: {
      type: Boolean,
      default: false
    },
    roundedBottom: {
      type: Boolean,
      default: true
    }
  }
})
</script>

<style>
.table-wrapper {
  overflow: auto;
}
.table-basic {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
}
.table-wrapper.bordered {
  border: 1px solid #ddd;
  border-radius: var(--rounded-xl);
}
.dark .table-wrapper.bordered {
  border-color: var(--border-secondary);
}

.table-basic th {
  padding: var(--s-3) var(--s-6);
  background-color: var(--bg-secondary);
  font-weight: 500;
  font-size: var(--text-sm);
  color: var(--secondary);
  border-bottom: 1px solid var(--border-secondary);
  text-align: left;
}
.table-basic.tb-rounded-top thead th:first-of-type {
  border-top-left-radius: var(--rounded-xl);
}
.table-basic.tb-rounded-top thead th:last-of-type {
  border-top-right-radius: var(--rounded-xl);
}

.table-basic.tb-striped tr:nth-child(odd) {
  background-color: var(--bg-secondary_alt);
}
.row-hover tr:hover {
  background-color: #f5f5f5;
}

.table-basic td {
  padding: var(--s-4) var(--s-6);
  border-bottom: 1px solid var(--border-secondary);
  text-align: left;
}

.table-basic tr:last-of-type > td {
  border-bottom: none;
}

.table-basic .highlight {
  background-color: #eaf6ff;
}

.table-basic .selected {
  background-color: #cce5ff;
}

.table-basic .disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
