<template>
  <div :class="classes">
    <input
      :id="id"
      :type="type"
      :placeholder="placeholder"
      :value="modelValue"
      :class="[{ 'has-error': hasError, 'input-with-icon': icon }, 'form-control w-full']"
      :iconPosition="iconPosition"
      :readonly="readonly"
      :pattern="pattern"
      :min="min"
      :max="max"
      @input="updateValue"
      @focus="inputFocus"
      @blur="inputBlur"
    />
    <Icon v-if="icon" class="input-icon" :class="['input-icon-' + iconPosition]" :type="icon" />
  </div>
</template>

<script>
//TODO Rename this component to InputText
import Icon from '@/components/icon/Icon.vue'
export default {
  components: {
    Icon
  },
  props: {
    placeholder: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    modelValue: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    min: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    },
    pattern: {
      type: String,
      default: null
    },
    hasError: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    iconPosition: {
      type: String,
      default: 'left'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    readonlyStyling: {
      type: Boolean,
      default: true
    },
    classes: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue', 'focus', 'blur'],
  setup(props, context) {
    const updateValue = event => {
      context.emit('update:modelValue', event.target.value)
    }

    const inputFocus = event => {
      context.emit('focus', event.target.value)
    }
    const inputBlur = event => {
      context.emit('blur', event.target.value)
    }

    return { updateValue, inputFocus, inputBlur }
  }
}
</script>

<style>
.input-with-icon {
  position: relative;
}

.input-icon {
  color: var(--text-secondary);
  position: relative;
  z-index: 2;
}

.input-icon-left {
  float: left;
  margin-left: 11px;
  margin-top: -28px;
}

.input-icon-right {
  float: right;
  margin-right: 11px;
  margin-top: -28px;
}

.input-with-icon[iconPosition='left'] {
  padding-left: 35px !important;
}

.input-with-icon[iconPosition='right'] {
  padding-right: 35px !important;
}
</style>
