<template>
  <div class="dropdown-wrapper">
    <div @click="toggleDropdown">
      <slot v-if="$slots['triggerContent']" name="triggerContent" />
      <Button v-else :label="text" :severity="variant" is-dropdown @click.prevent />
    </div>

    <transition name="dropdown-menu" :style="[padding ? 'padding: 0.5rem 0;' : '']">
      <ul v-if="isOpen" v-click-away="clickAway" class="dropdown-menu" :class="position" @click="clickWithin">
        <slot />
      </ul>
    </transition>
  </div>
</template>

<script>
import { provide, ref } from 'vue'

import Button from '@/components/button/Button.vue'

export default {
  name: 'Dropdown',
  components: {
    Button
  },
  props: {
    variant: {
      type: String,
      default: 'primary'
    },
    text: {
      type: String,
      default: null
    },
    position: {
      type: String,
      default: 'bottom-left'
    },
    split: {
      type: Boolean,
      default: false
    },
    padding: {
      type: Boolean,
      default: true
    },
    closeOnClickAway: {
      type: Boolean,
      default: true
    },
    closeOnClickWithin: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const isOpen = ref(false)

    function closeDropdown() {
      isOpen.value = false
    }

    provide('isOpen', isOpen)

    provide('closeDropdown', () => {
      closeDropdown()
    })

    function toggleDropdown() {
      if (!props.isDisabled) {
        isOpen.value = !isOpen.value
      }
    }

    function clickAway() {
      if (props.closeOnClickAway) {
        closeDropdown()
      }
    }
    function clickWithin(event) {
      const isLink = event.target.matches('a[href]')
      if (props.closeOnClickWithin && !isLink) {
        closeDropdown()
      }
    }

    return { toggleDropdown, isOpen, clickWithin, clickAway }
  }
}
</script>

<style>
.dropdown-wrapper {
  position: relative;
  display: inline-block;
  /* width: 100%; */
}

.btn-dropdown-toggle {
  padding-right: 2.8rem;
}

.btn-dropdown-toggle::after {
  font-family: 'Font Awesome 6 Pro';
  content: '\f078';
  position: absolute;
  right: 18px;
}

.nav-item .dropdown-menu {
  margin-top: 20px;
}

.dropdown-menu {
  list-style: none;
  border-radius: var(--rounded-lg);
  box-shadow: var(--shadow-lg);
  margin: 0;
  background-color: var(--bg-primary_alt);
  background-clip: padding-box;
  border: 1px solid var(--border-secondary);
  position: absolute;
  top: 100%;
  z-index: 10;
  /* display: none; */
  min-width: 10rem;
  font-size: 1rem;
  margin-top: 0.5rem;
}

.dropdown-menu.bottom-right {
  right: 0;
}
.dropdown-menu.bottom-left {
  left: 0;
}
.dropdown-menu.right {
  top: 0;
  margin-top: 0;
  left: 100%;
}
/* Transitions */
.dropdown-menu-enter-from {
  transform: translateY(20px);
  opacity: 0;
}

.dropdown-menu-enter-to {
  transform: translateY(0px);
  opacity: 1;
}

.dropdown-menu-enter-active {
  transition: all 0.3s ease-in-out;
}

.dropdown-menu-leave-from {
  transform: translateY(0px);
  opacity: 1;
}

.dropdown-menu-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.dropdown-menu-leave-active {
  transition: all 0.3s ease-in-out;
}

/* .dropdown-menu-enter-active,
.dropdown-menu-leave-active {
  transform: translateY(30px);
  transition: all 1s ease-in-out;
}

.dropdown-menu-enter-from,
.dropdown-menu-leave-to {
  transform: translateY(0px);
} */
</style>
