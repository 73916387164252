<template>
  <Modal :is-open="isOpen" :buttons="[]" :show-dismiss-button="false" size="fullscreen" @close-modal="closeHandler">
    <div class="flex justify-between mb-4">
      <h1>Proposal</h1>
      <div>
        <ButtonGroup>
          <Button label="Email" severity="secondary" outlined icon="paper-plane" />
          <Button label="PDF" severity="secondary" outlined icon="download" />
          <Button label="View" severity="secondary" outlined icon="eye" />
        </ButtonGroup>
        <Button label="Save" icon="save" class="ml-6" />
        <Button label="Close" severity="secondary" outlined icon="xmark" class="ml-2" @click="closeHandler" />
      </div>
    </div>
    <!-- <div class="text-secondary">
      <div v-if="dayjs('2023-08-05').isAfter(dayjs())">Expires: {{ dayjs('2023-08-05').format('DD/MM/YYYY') }}</div>
      <div v-else class="text-danger">Propsal Expired</div>
    </div> -->
    <div class="comparison-table">
      <div class="ct-header-row ct-actions">
        <div class="ct-fixed-col ct-fixed-col-1">&nbsp;</div>
        <div class="ct-fixed-col ct-current-item ct-comparing-item">
          <div class="text-secondary mt-2">Current Agreement</div>
        </div>
        <div v-for="item in deals" :key="item.id" class="ct-comparing-item">
          <ButtonGroup>
            <Button v-tippy="{ content: 'Edit Deal' }" size="sm" severity="secondary" outlined icon="pencil" />
            <Button
              v-tippy="{ content: 'Delete Deal' }"
              size="sm"
              severity="secondary"
              outlined
              icon="trash"
              @click="deleteDeal(item.id)"
            />
          </ButtonGroup>
          <!-- <div class="text-danger cursor-pointer"><Icon type="trash" /> Remove</div> -->
          <!-- <div class="text-xs text-secondary mt-1">
            <div v-if="dayjs(item.dealExpiration).isAfter(dayjs())">Expires: {{ dayjs(item.dealExpiration).format('DD/MM/YYYY') }}</div>
            <div v-else class="text-danger">Deal Expired</div>
          </div> -->
        </div>
        <div v-if="deals.length < 3" class="ct-add-new-col">
          <Button label="Add Deal" size="sm" icon="plus" @click="newDeal" />
          <!-- <div class="text-primary cursor-pointer"><Icon type="plus-circle" /> Add Deal</div> -->
        </div>
      </div>

      <div class="ct-header-row ct-header-image">
        <div class="ct-fixed-col ct-fixed-col-1">&nbsp;</div>
        <div class="ct-fixed-col ct-current-item ct-comparing-item relative">
          <img class="h-full m-auto opacity-50" :src="currentItem.imageUrl" />
          <!-- <Chip class="!absolute top-2 right-2" size="sm">{{ currentItem.newUsed }}</Chip> -->
        </div>
        <div v-for="item in deals" :key="item.id" class="ct-comparing-item relative">
          <img class="h-full m-auto" :src="item.imageUrl" />
          <!-- <Chip class="!absolute top-2 right-2" size="sm">{{ item.newUsed }}</Chip> -->
        </div>
        <div v-if="deals.length < 3" class="ct-add-new-col">&nbsp;</div>
      </div>

      <div class="ct-header-row ct-header-title">
        <div class="ct-fixed-col ct-fixed-col-1">&nbsp;</div>
        <div class="ct-fixed-col ct-current-item ct-comparing-item">
          <div class="text-secondary -mb-1">{{ currentItem.make }}</div>
          <div class="font-bold">{{ currentItem.model }} {{ currentItem.trim }}</div>
          <Chip class="" size="sm">{{ currentItem.newUsed }}</Chip>
        </div>
        <div v-for="item in deals" :key="item.id" class="ct-comparing-item">
          <div class="text-secondary -mb-1">{{ item.make }}</div>
          <div class="font-bold">{{ item.model }} {{ item.trim }}</div>
          <Chip class="" size="sm">{{ item.newUsed }}</Chip>
        </div>
        <div v-if="deals.length < 3" class="ct-add-new-col">&nbsp;</div>
      </div>

      <div v-for="row in dataMapper" :key="row.id" class="ct-data-row">
        <div class="ct-fixed-col ct-fixed-col-1">{{ row.label }}</div>
        <div class="ct-fixed-col ct-current-item ct-comparing-item">{{ formatData(row.id, currentItem[row.id]) }}</div>
        <div v-for="item in deals" :key="item.id" class="ct-comparing-item">
          {{ formatData(row.id, item[row.id]) }}
        </div>
        <div v-if="deals.length < 3" class="ct-add-new-col">&nbsp;</div>
      </div>

      <div class="ct-footer-row ct-summary">
        <div class="ct-fixed-col ct-fixed-col-1">Monthly Payment</div>
        <div class="ct-fixed-col ct-current-item ct-comparing-item">
          <div class="text-lg">
            {{ formatData('monthlyPayment', currentItem.monthlyPayment) }}
          </div>
        </div>
        <div v-for="item in deals" :key="item.id" class="ct-comparing-item">
          <div class="text-lg font-semibold">
            {{ formatData('monthlyPayment', item.monthlyPayment) }}
          </div>
        </div>
        <div v-if="deals.length < 3" class="ct-add-new-col">&nbsp;</div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import Button from '@/components/button/Button.vue'
import ButtonGroup from '@/components/button/ButtonGroup.vue'

import Modal from '@/components/modal/Modal.vue'
import Chip from '@/components/chip/Chip.vue'
import Icon from '@/components/icon/Icon.vue'

export default {
  name: 'ProposalBuilder',
  components: { Modal, Chip, ButtonGroup, Button, Icon },
  props: {
    proposal: {
      type: String,
      default: null
    },
    isOpen: {
      type: String,
      default: null
    }
  },
  emits: ['closeHandler'],
  setup(props, { emit }) {
    const dataMap = ref([
      { id: 'productType', label: 'Product', icon: '', showInList: true, numberFormat: null },
      { id: 'term', label: 'Term', icon: '', showInList: true, numberFormat: null },
      {
        id: 'otrPrice',
        label: 'On-The-Road Price',
        icon: '',
        showInList: true,
        numberFormat: { type: 'currency', config: {} }
      },
      { id: 'customerDeposit', label: 'Customer Deposit', icon: '', showInList: true, numberFormat: { type: 'currency', config: {} } },
      {
        id: 'depositContribution',
        label: 'Deposit Contribution',
        icon: '',
        showInList: true,
        numberFormat: { type: 'currency', config: {} }
      },
      { id: 'amountFinanced', label: 'Amount Financed', icon: '', showInList: true, numberFormat: { type: 'currency', config: {} } },
      { id: 'chargeForCredit', label: 'Charge for Credit', icon: '', showInList: true, numberFormat: { type: 'currency', config: {} } },
      {
        id: 'totalAmountPayable',
        label: 'Total Amount Payable',
        icon: '',
        showInList: true,
        numberFormat: { type: 'currency', config: {} }
      },
      { id: 'advance', label: 'Advance', icon: '', showInList: true, numberFormat: { type: 'currency', config: {} } },
      { id: 'monthlyPayment', label: 'Monthly Payment', icon: '', showInList: false, numberFormat: { type: 'currency', config: {} } },
      { id: 'totalMonthlyPayments', label: 'Monthly Payments', icon: '', showInList: false, numberFormat: null },
      {
        id: 'finalRepayment',
        label: 'Optional Final Repayment',
        icon: '',
        showInList: true,
        numberFormat: { type: 'currency', config: {} }
      },
      {
        id: 'optionToPurchaseFee',
        label: 'Option to Purchase Fee',
        icon: '',
        showInList: true,
        numberFormat: { type: 'currency', config: {} }
      },
      { id: 'apr', label: 'APR %', icon: '', showInList: true, numberFormat: { type: 'percent', config: { minimumFractionDigits: 2 } } },
      {
        id: 'interestRateFixed',
        label: 'Interest Rate (fixed)',
        icon: '',
        showInList: true,
        numberFormat: { type: 'percent', config: { minimumFractionDigits: 2 } }
      },
      { id: 'annualMileage', label: 'Mileage per annum', icon: '', showInList: true, numberFormat: { type: 'default', config: {} } },
      {
        id: 'excessMileageCharge',
        label: 'Excess Mileage Charge',
        icon: '',
        showInList: true,
        numberFormat: { type: 'currency', config: {} }
      }
    ])

    const dataMapper = computed(() => {
      return dataMap.value.filter(i => i.showInList)
    })

    const itemsToCompare = ref([
      {
        id: 1,
        make: 'Triumph',
        model: 'Street Triple 765',
        trim: 'RS',
        derivative: 'Street Triple 765 RS 765cc',
        newUsed: 'New',
        imageUrl: 'https://cdn.retain.group/img/vehicles/triumph/triumph-street-triple-rs_2020.png',
        productType: 'PCP',
        term: 37,
        otrPrice: 11495.0,
        customerDeposit: 2299.0,
        depositContribution: 0,
        amountFinanced: 9196.0,
        chargeForCredit: 2566.44,
        totalAmountPayable: 14061.44,
        advance: null,
        monthlyPayment: 139.04,
        totalMonthlyPayments: 36,
        finalRepayment: 6757.0,
        optionToPurchaseFee: 10,
        apr: 10.9,
        interestRateFixed: 10.36,
        annualMileage: 4000,
        excessMileageCharge: 7.2,
        dealExpiration: '2023-08-20'
      },
      {
        id: 2,
        make: 'Triumph',
        model: 'Street Triple 765',
        trim: 'RS',
        derivative: 'Street Triple 765 RS 765cc',
        newUsed: 'New',
        imageUrl: 'https://cdn.retain.group/img/vehicles/triumph/triumph-street-triple-rs_2020.png',
        productType: 'PCP',
        term: 37,
        otrPrice: 11495.0,
        customerDeposit: 2299.0,
        depositContribution: 0,
        amountFinanced: 9196.0,
        chargeForCredit: 2566.44,
        totalAmountPayable: 14061.44,
        advance: null,
        monthlyPayment: 139.04,
        totalMonthlyPayments: 36,
        finalRepayment: 6757.0,
        optionToPurchaseFee: 10,
        apr: 10.9,
        interestRateFixed: 10.36,
        annualMileage: 4000,
        excessMileageCharge: 7.2,
        dealExpiration: '2023-09-20'
      }
    ])

    // const itemsToCompare = ref([
    //   {
    //     id: 1,
    //     make: 'Triumph',
    //     model: 'Street Triple 765',
    //     trim: 'RS',
    //     derivative: 'Triumph Street Triple 765 RS 765cc',
    //     productType: 'PCP',
    //     newUsed: 'New',
    //     annualMileage: 11000,
    //     otrPrice: 19312.0,
    //     customerDeposit: 3200.0,
    //     advance: 0,
    //     amountFinanced: 14000.0,
    //     term: 48,
    //     apr: 9.2,
    //     finalRepayment: 13415.21,
    //     monthlyPayment: 231.0
    //   },
    //   {
    //     id: 2,
    //     make: 'Volkswagen',
    //     model: 'Golf',
    //     trim: 'Style',
    //     derivative: '1.4 TSI 8.7kWh GTE Advance DSG Euro 6 (s/s) 5dr',
    //     productType: 'PCP',
    //     newUsed: 'New',
    //     annualMileage: 10000,
    //     otrPrice: 16293.0,
    //     customerDeposit: 2800.0,
    //     advance: 0,
    //     amountFinanced: 11000.0,
    //     term: 48,
    //     apr: 9.4,
    //     finalRepayment: 19281.93,
    //     monthlyPayment: 323.0
    //   },
    //   {
    //     id: 3,
    //     make: 'Volkswagen',
    //     model: 'Golf',
    //     trim: 'R-Line',
    //     derivative: '1.4 TSI 8.7kWh GTE Advance DSG Euro 6 (s/s) 5dr',
    //     newUsed: 'New',
    //     productType: 'PCP',
    //     annualannualMileage: 10000,
    //     otrPrice: 16293.0,
    //     customerDeposit: 2800.0,
    //     advance: 0,
    //     amountFinanced: 11000.0,
    //     term: 48,
    //     apr: 9.4,
    //     finalRepayment: 19281.93,
    //     monthlyPayment: 323.0
    //   },
    //   {
    //     id: 4,
    //     make: 'Volkswagen',
    //     model: 'Golf',
    //     trim: 'GTE Advance',
    //     derivative: '1.4 TSI 8.7kWh GTE Advance DSG Euro 6 (s/s) 5dr',
    //     newUsed: 'New',
    //     productType: 'PCP',
    //     annualMileage: 10000,
    //     otrPrice: 16293.0,
    //     customerDeposit: 2800.0,
    //     advance: 0,
    //     amountFinanced: 11000.0,
    //     term: 48,
    //     apr: 9.4,
    //     finalRepayment: 19281.93,
    //     monthlyPayment: 323.0
    //   }
    // ])

    const currentItem = ref({
      make: 'Triumph',
      model: 'Tiger 800',
      trim: 'Xca',
      derivative: 'Tiger 800 Xca (17my)',
      newUsed: 'New',
      imageUrl: 'https://cdn.retain.group/img/vehicles/triumph/triumph-tiger-800-xca_2020.png',
      productType: 'PCP',
      term: 37,
      otrPrice: 8800.0,
      customerDeposit: 1000.0,
      depositContribution: 0,
      amountFinanced: 7800.0,
      chargeForCredit: 1823.81,
      totalAmountPayable: 9405.44,
      advance: null,
      monthlyPayment: 134.39,
      totalMonthlyPayments: 36,
      finalRepayment: 4874.0,
      optionToPurchaseFee: 10,
      apr: 10.2,
      interestRateFixed: 10.36,
      annualMileage: 4000,
      excessMileageCharge: 7.2
    })

    // const currentItem = ref({
    //   make: 'Volkswagen',
    //   model: 'Golf',
    //   trim: 'GTE',
    //   newUsed: 'Used',
    //   productType: 'PCP',
    //   annualMileage: 8000,
    //   otrPrice: 29500.0,
    //   customerDeposit: 4350.0,
    //   advance: 0,
    //   amountFinanced: 21728.0,
    //   term: 36,
    //   apr: 4,
    //   finalRepayment: 11481.48,
    //   monthlyPayment: 204.11
    // })

    const formatData = (id, value) => {
      const item = dataMap.value.find(i => i.id === id)
      if (item && value && item.numberFormat) {
        if (item.numberFormat.type === 'percent') {
          value = value / 100
        }
        return useI18n().n(value, item.numberFormat.type, item.numberFormat.config)
      }
      return value
    }

    const deals = computed(() => {
      return itemsToCompare.value
    })

    function closeHandler() {
      emit('closeHandler')
    }

    function deleteDeal(id) {
      itemsToCompare.value = itemsToCompare.value.filter(item => item.id !== id)
    }

    function newDeal() {
      itemsToCompare.value.push({
        id: Math.random(),
        make: 'Triumph',
        model: 'Street Triple 765',
        trim: 'RS',
        derivative: 'Street Triple 765 RS 765cc',
        newUsed: 'New',
        imageUrl: 'https://cdn.retain.group/img/vehicles/triumph/triumph-street-triple-rs_2020.png',
        productType: 'PCP',
        term: 37,
        otrPrice: 11495.0,
        customerDeposit: 2299.0,
        depositContribution: 0,
        amountFinanced: 9196.0,
        chargeForCredit: 2566.44,
        totalAmountPayable: 14061.44,
        advance: null,
        monthlyPayment: 139.04,
        totalMonthlyPayments: 36,
        finalRepayment: 6757.0,
        optionToPurchaseFee: 10,
        apr: 10.9,
        interestRateFixed: 10.36,
        annualMileage: 4000,
        excessMileageCharge: 7.2,
        dealExpiration: '2023-08-20'
      })
    }

    return {
      dataMapper,
      currentItem,
      deals,
      formatData,
      closeHandler,
      deleteDeal,
      newDeal
    }
  }
}
</script>

<style>
.comparison-table > * > div {
  /* outline: 1px solid gainsboro; */
}

.comparison-table {
  font-size: var(--text-sm);
}
.comparison-table .ct-header-row {
  display: flex;
}
.ct-header-image .ct-comparing-item {
  height: 100px;
  padding-top: var(--s-2);
}

.ct-fixed-col {
}

.ct-fixed-col-1 {
  width: 190px;
}

.ct-header-title > div {
  padding: var(--s-2) var(--s-2) var(--s-2) var(--s-2);
}

.ct-data-row {
  display: flex;
}
.ct-data-row:hover > div:not(.ct-add-new-col) {
  /* color: var(--fg-brand); */
  background-color: var(--extra-light);
}

.ct-data-row > div {
  padding: var(--s-1) var(--s-2);
}
.ct-data-row > div:not(.ct-add-new-col) {
  border-bottom: 1px solid gainsboro;
}
.ct-data-row:nth-child(even) {
  /* background-color: #f7f7f7; */
}
.ct-current-item {
  background-color: var(--extra-light);
  /* background-color: #f7f7f7; */
}

/* .ct-header-row:first-of-type > .ct-current-item::before {
  content: 'Current Agreement';
  position: absolute;
  top: -22px;
  left: 0;
  right: 0;
  color: var(--text-secondary);
} */

.ct-header-row:first-of-type > .ct-current-item,
.ct-header-row:last-of-type > .ct-add-new-col {
  position: relative;
  border-radius: var(--rounded-md) var(--rounded-md) 0 0;
}

.ct-comparing-item {
  width: 200px;
  text-align: center;
}

.ct-add-new-col {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}
.ct-header-row:first-of-type .ct-add-new-col {
  border-top: 2px dashed var(--bg-tertiary);
  border-top-left-radius: var(--rounded-md);
  border-top-right-radius: var(--rounded-md);
}
.ct-add-new-col {
  border-left: 2px dashed var(--bg-tertiary);
  border-right: 2px dashed var(--bg-tertiary);
}
.ct-footer-row:last-of-type .ct-add-new-col {
  border-bottom: 2px dashed var(--bg-tertiary);
  border-bottom-left-radius: var(--rounded-md);
  border-bottom-right-radius: var(--rounded-md);
}

.comparison-table .ct-footer-row {
  display: flex;
}
.comparison-table .ct-footer-row.ct-summary > div {
  padding: var(--s-4) var(--s-2);
}

.ct-actions > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: var(--s-4) var(--s-2) var(--s-1) var(--s-2);
}

.ct-footer-row:last-of-type > .ct-current-item {
  /* background-color: blue; */
  border-radius: 0 0 var(--rounded-md) var(--rounded-md);
}
</style>
