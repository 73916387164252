<template>
  <div
    v-if="showIcon"
    v-clipboard:copy="toCopy"
    v-clipboard:success="onCopy"
    v-tippy="{ content: copied ? 'Copied!' : 'Copy to clipboard', trigger: 'click' }"
  >
    <slot></slot>
    <Icon type="copy" class="text-light dark:text-secondary ml-2 copy-icon" />
  </div>
  <div
    v-else
    v-clipboard:copy="toCopy"
    v-clipboard:success="onCopy"
    v-tippy="{ content: copied ? 'Copied!' : 'Copy to clipboard', trigger: 'mouseenter click' }"
    class="copy-text"
  >
    <slot></slot>
  </div>
</template>

<script>
import Icon from '@/components/icon/Icon.vue'
import { ref, toRefs, onMounted } from 'vue'

export default {
  components: {
    Icon
  },
  props: {
    showIcon: {
      type: Boolean,
      default: false
    },
    explicitTextToCopy: {
      type: String,
      default: null
    }
  },
  setup(props, context) {
    const toCopy = ref('Nothing To Paste')
    const copied = ref(false)

    onMounted(() => {
      const slot = context.slots.default?.()[0]
      if (props.explicitTextToCopy) {
        toCopy.value = props.explicitTextToCopy // Replace this line to process the raw text if needed
      } else if (slot && slot.children) {
        toCopy.value = slot.children // Replace this line to process the raw text if needed
      }
    })

    function onCopy() {
      // console.log('Copied to clipboard.')
      copied.value = true
      setTimeout(() => {
        copied.value = false
      }, 2000)
    }

    return { ...toRefs(props), toCopy, onCopy, copied }
  }
}
</script>

<style>
.copy-icon {
  font-size: 1rem;
}

.copy-icon:hover,
.copy-text:hover {
  color: var(--fg-brand);
  cursor: pointer;
}
</style>
