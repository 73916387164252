<template>
  <Sidebar :is-open="isOpen" @close-sidebar="closeSidebar">
    <SidebarHeader :title="isNewShift ? $t('ui.actions.add_item', { item: 'shift' }) : $t('ui.actions.update_item', { item: 'Shift' })" />
    <SidebarContent>
      <form ref="form" @submit.prevent="shiftHandlerSubmit">
        <FormGroup :label="$t('ui.common.user')" label-for="user">
          <Multiselect
            v-model="state.userID"
            mode="single"
            label="fullName"
            :close-on-select="true"
            :searchable="true"
            :hide-selected="false"
            :options="users"
            track-by="fullName"
            value-prop="id"
            placeholder="Select a User"
          >
            <template #placeholder>
              <div class="multiselect-placeholder"><Icon type="user" size="sm" class="mr-3" /> Select a user</div>
            </template>
            <template #singlelabel="{ value }">
              <div class="multiselect-single-label">
                <Avatar :url="value.profilePicURL" :text="value.initials" size="xs" class="mr-3" />
                {{ value.fullName }}
              </div>
            </template>

            <template #option="{ option }">
              <Avatar :url="option.profilePicURL" :text="option.initials" size="sm" class="mr-3" />
              {{ option.fullName }}
            </template>
          </Multiselect>
          <InputError :has-error="v$.userID.$error">{{ v$.userID.$errors[0].$message }}</InputError>
        </FormGroup>

        <FormGroup :label="$t('ui.common.type')" label-for="type">
          <Multiselect
            v-model="state.type"
            mode="single"
            label="type"
            :close-on-select="true"
            :searchable="true"
            :hide-selected="false"
            :options="shiftTypes"
            track-by="type"
            value-prop="id"
          >
            <template #singlelabel="{ value }">
              <div class="multiselect-single-label">
                <Icon type="circle" :style="{ color: value.color, '--fa-secondary-opacity': 1 }" class="mr-2" />
                {{ value.name }}
              </div>
            </template>

            <template #option="{ option }">
              <Icon type="circle" :style="{ color: option.color, '--fa-secondary-opacity': 1 }" class="mr-2" />
              {{ option.name }}
            </template>
          </Multiselect>
          <InputError :has-error="v$.type.$error">{{ v$.type.$errors[0].$message }}</InputError>
        </FormGroup>

        <div>
          <div class="flex">
            <FormGroup :label="$t('ui.common.start')" label-for="start" :class="{ 'has-error': v$.start.$error }">
              <DatePicker v-model="state.start" :config="datePickerConfig" :has-error="v$.start.$error" />
              <InputError :has-error="v$.start.$error">{{ v$.start.$errors[0].$message }}</InputError>
            </FormGroup>
            <Icon type="arrow-right" class="mx-5" style="margin-top: 30px" />
            <FormGroup :label="$t('ui.common.end')" label-for="end" :class="{ 'has-error': v$.end.$error }">
              <DatePicker v-model="state.end" :config="datePickerConfig" :has-error="v$.end.$error" />
              <InputError :has-error="v$.end.$error">{{ v$.end.$errors[0].$message }}</InputError>
            </FormGroup>
          </div>
        </div>

        <div>
          <div class="flex">
            <FormGroup :label="$t('ui.entities.schedule.break') + ' (min)'" label-for="break" class="mr-3">
              <Input
                id="break"
                v-model.number="state.break"
                type="text"
                icon="coffee-pot"
                :value="state.break"
                :has-error="v$.break.$error"
              />
              <InputError :has-error="v$.break.$error">{{ v$.break.$errors[0].$message }}</InputError>
            </FormGroup>

            <FormGroup
              v-tippy="{ content: 'Calculated' }"
              :label="$t('ui.entities.schedule.total-hours')"
              label-for="totalShiftHours"
              class="mr-3"
            >
              <Input
                id="totalShiftHours"
                v-model.number="state.totalShiftHours"
                type="text"
                icon="hourglass-clock"
                readonly
                :value="state.totalShiftHours"
              />
            </FormGroup>
            <FormGroup :label="$t('ui.entities.schedule.productive')" label-for="productiveHours" class="mr-3">
              <Input
                id="productiveHours"
                v-model.number="state.productiveHours"
                type="text"
                icon="business-time"
                :value="state.productiveHours"
                :has-error="v$.productiveHours.$error"
              />
              <InputError :has-error="v$.productiveHours.$error">{{ v$.productiveHours.$errors[0].$message }} </InputError>
            </FormGroup>
          </div>
        </div>

        <div>
          <div class="flex">
            <FormGroup
              :label="$t('ui.entities.report.target') + ' (' + $t('ui.entities.comms.call', 2) + ')'"
              label-for="callTarget"
              class="mr-3"
            >
              <Input
                id="callTarget"
                v-model.number="state.callTarget"
                type="text"
                icon="phone"
                :value="state.callTarget"
                :has-error="v$.callTarget.$error"
              />
              <InputError :has-error="v$.callTarget.$error">{{ v$.callTarget.$errors[0].$message }}</InputError>
            </FormGroup>
            <FormGroup
              :label="$t('ui.entities.report.target') + ' (' + $t('ui.entities.appointment.label', 2) + ')'"
              label-for="appointmentTarget"
            >
              <Input
                id="appointmentTarget"
                v-model.number="state.appointmentTarget"
                type="text"
                icon="calendar"
                :value="state.appointmentTarget"
                :has-error="v$.appointmentTarget.$error"
              />
              <InputError :has-error="v$.appointmentTarget.$error">{{ v$.appointmentTarget.$errors[0].$message }} </InputError>
            </FormGroup>
          </div>
        </div>

        <div class="mt-5 border-t pt-5">
          <h3 class="mb-3">Job Card</h3>
          <div class="flex items-center">
            <ProgressBar
              :value="jobCardCalc.allocatedPercentage"
              :show-value="true"
              style="height: 1.5rem"
              class="w-full mr-3"
              :class="[jobCardCalc.allocatedPercentage != 100 ? 'jobcard-progress-danger' : 'jobcard-progress-success']"
            >
              {{ jobCardCalc.allocatedHours }}hrs
            </ProgressBar>
            <div class="w-1/4 text-center">{{ state.productiveHours }}hrs</div>
          </div>

          <div class="mt-5">
            <div v-for="(jobCard, index) in state.jobCards" :key="jobCard.id">
              <FormGroup class="flex items-center">
                <div class="w-full">
                  <Multiselect
                    v-if="jobCard.type === 'dealership'"
                    v-model="jobCard.dealershipID"
                    mode="single"
                    label="dealership"
                    :close-on-select="true"
                    :searchable="true"
                    :hide-selected="false"
                    :options="dealerships"
                    track-by="name"
                    value-prop="id"
                  >
                    <template #singlelabel="{ value }">
                      <div class="multiselect-single-label">
                        <img :src="value.manufacturerLogo" class="max-h-6 max-w-6 mr-3" />
                        {{ value.nameShortNoManufacturer }}
                      </div>
                    </template>

                    <template #option="{ option }">
                      <img :src="option.manufacturerLogo" class="max-h-6 max-w-6 mr-3" />
                      {{ option.nameShortNoManufacturer }}
                    </template>
                  </Multiselect>
                  <Input v-else-if="jobCard.type === 'text'" v-model="jobCard.text" type="text" />
                </div>

                <Input v-model.number="jobCard.hours" classes="w-20 ml-3" type="text" />
                <Button icon="trash" severity="danger" outlined size="sm" class="ml-3" @click.prevent="removeJobCardItem(index)" />
              </FormGroup>
            </div>
          </div>
          <div class="flex">
            <Dropdown variant="outline-primary" text="Add">
              <DropdownItem @item-clicked="addJobCardItem('dealership')">Dealership Specific</DropdownItem>
              <DropdownItem @item-clicked="addJobCardItem('text')">General</DropdownItem>
            </Dropdown>
          </div>
        </div>
      </form>
    </SidebarContent>
    <SidebarFooter>
      <div class="flex justify-between">
        <Button label="Delete" size="sm" severity="danger" plain @click="deleteShift" />
        <div class="flex justify-end gap-2">
          <Button :label="$t('ui.actions.close')" severity="secondary" outlined class="ml-3" type="button" @click="closeSidebar" />
          <Button :label="isNewShift ? $t('ui.actions.add') : $t('ui.actions.save')" type="submit" @click="shiftHandlerSubmit" />
        </div>
      </div>
    </SidebarFooter>
  </Sidebar>
</template>

<script>
import { ref, watch } from 'vue'
import axios from 'axios'

import Sidebar from '@/components/sidebar/Sidebar.vue'
import SidebarHeader from '@/components/sidebar/SidebarHeader.vue'
import SidebarContent from '@/components/sidebar/SidebarContent.vue'
import SidebarFooter from '@/components/sidebar/SidebarFooter.vue'
import Icon from '@/components/icon/Icon.vue'
import Avatar from '@/components/avatar/Avatar.vue'

import FormGroup from '@/components/forms/FormGroup.vue'
import Input from '@/components/forms/Input.vue'
import InputError from '@/components/forms/InputError.vue'
import Button from '@/components/button/Button.vue'
import Multiselect from '@vueform/multiselect'
import DatePicker from '@/components/forms/DatePicker.vue'
import ProgressBar from 'primevue/progressbar'
import Dropdown from '@/components/dropdown/Dropdown.vue'
import DropdownItem from '@/components/dropdown/DropdownItem.vue'

import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { computed } from 'vue'

import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import notification from '../../utilities/notification'

import getShiftTypes from '@/composables/getShiftTypes'
import { useUserStore } from '@/stores/UserStore'

export default {
  components: {
    Sidebar,
    SidebarHeader,
    SidebarContent,
    SidebarFooter,
    Icon,
    FormGroup,
    Input,
    InputError,
    Button,
    Multiselect,
    Avatar,
    DatePicker,
    ProgressBar,
    Dropdown,
    DropdownItem
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    shift: {
      type: Object,
      default: () => {}
    },
    users: {
      type: Array,
      default: () => []
    },
    dealerships: {
      type: Array,
      default: () => []
    }
  },
  emits: ['closeShiftHandler', 'deleteShift', 'addNewShiftSubmit', 'updateShiftSubmit'],
  setup(props, { emit }) {
    const userStore = useUserStore()
    const datePickerConfig = {
      enableTime: true,
      noCalendar: true,
      dateFormat: 'H:i:ss',
      allowInput: true,
      altInput: true,
      altFormat: 'H:i'
    }

    const sessionUserID = userStore.details.id

    const hasChanges = ref(false)

    //Instatiate state
    const isNewShift = ref(true)
    const state = ref({
      id: null,
      userID: null,
      type: 1,
      period: null,
      start: null,
      end: null,
      break: 0,
      totalShiftHours: 0,
      productiveHours: 0,
      callTarget: 0,
      appointmentTarget: 0,
      jobCards: [],
      timestamp: null
    })

    const inputRules = {
      userID: { required },
      type: { required },
      start: { required },
      end: { required },
      break: { required },
      productiveHours: { required },
      callTarget: { required },
      appointmentTarget: { required }
    }
    const v$ = useVuelidate(inputRules, state)

    const { shiftTypes, loadShiftTypes } = getShiftTypes()
    loadShiftTypes()

    if (props.shift?.id) {
      //Shift passed, so we should update.
      // console.log('Updating Shift')
      isNewShift.value = false

      state.value.id = props.shift.id
      state.value.userID = props.shift.userID
      state.value.type = props.shift.type
      state.value.period = props.shift.period
      state.value.start = props.shift.start
      state.value.end = props.shift.end
      state.value.break = props.shift.break
      state.value.totalShiftHours = props.shift.totalShiftHours
      state.value.productiveHours = props.shift.productiveHours
      state.value.callTarget = props.shift.callTarget
      state.value.appointmentTarget = props.shift.appointmentTarget
      state.value.jobCards = props.shift.jobCards
      state.value.timestamp = props.shift.timestamp
    } else {
      //New Shift
      // console.log('New Shift')
      state.value.userID = props.shift?.userID
      state.value.period = props.shift?.period
    }
    hasChanges.value = false
    const stateUnchanged = ref({ ...state.value })

    function closeSidebar() {
      if (hasChanges.value) {
        Swal.fire({
          title: 'Unsaved Changes',
          text: 'Changes made will not be saved if you continue',
          icon: 'warning',
          reverseButtons: true,
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: 'Discard',
          confirmButtonColor: 'var(--bg-error-solid)',
          cancelButtonText: 'Cancel'
        }).then(function (response) {
          if (response.isConfirmed) {
            emit('closeShiftHandler')
          }
        })
      } else {
        emit('closeShiftHandler')
      }
    }

    function clearForm() {
      state.value.type = 1
      state.value.start = null
      state.value.end = null
      state.value.break = null
      state.value.totalShiftHours = null
      state.value.productiveHours = null
      state.value.callTarget = null
      state.value.appointmentTarget = null
    }

    async function shiftHandlerSubmit() {
      // console.log('Is New Shift? : ' + isNewShift.value)

      const isFormCorrect = await v$.value.$validate()
      if (!isFormCorrect) {
        // If errors in form
        console.log('Errors in form!')
      } else {
        // console.log('Processing for submission')

        const data = JSON.stringify({
          id: state.value.id,
          period: state.value.period,
          type: state.value.type,
          userID: state.value.userID,
          start: state.value.start,
          end: state.value.end,
          break: state.value.break,
          totalShiftHours: state.value.totalShiftHours,
          productiveHours: state.value.productiveHours,
          callTarget: state.value.callTarget,
          appointmentTarget: state.value.appointmentTarget,
          jobCards: state.value.jobCards
        })

        // console.log(data)

        if (isNewShift.value) {
          // AXIOS post new task
          // console.log('Axios - Adding new Shift /v1/users/schedule/current')
          try {
            axios.post(import.meta.env.VITE_API_BASE_URL + '/v1/users/schedule/current', data, { withCredentials: true }).then(response => {
              // console.log('Response: ' + JSON.stringify(response.data))

              if (response.data != '' && response.data.response.status == 'success') {
                // Emit event to Schedule view with json, which will then update the task in the DOM
                emit('addNewShiftSubmit', response.data.data[0])

                notification('Shift Added', 'The shift was successfully added', 'success')
              } else {
                notification('Failed', 'There was an error adding the shift!', 'danger')
                throw Error('Error posting data')
              }
            })
          } catch (err) {
            if (err.response) {
              console.log(err.response.status)
              console.log(err.response.data)
            }
          }
        } else {
          // Update existing task
          // console.log('Axios - Updating Shift /v1/users/schedule/current/' + state.value.id)
          // AXIOS post new task{withCredentials: true}
          try {
            axios
              .patch(import.meta.env.VITE_API_BASE_URL + '/v1/users/schedule/current/' + state.value.id, data, { withCredentials: true })
              .then(response => {
                // console.log('Response: ' + JSON.stringify(response.data))

                if (response.data != '' && response.data.response.status == 'success') {
                  // Emit event to Schedule view with json, which will then update the task in the DOM
                  emit('updateShiftSubmit', state.value)

                  notification('Shift Updated', 'The shift was successfully updated', 'success')
                } else {
                  notification('Update Failed', 'There was an error!', 'danger')
                  throw Error('Error patching data')
                }
              })
          } catch (err) {
            if (err.response) {
              console.log(err.response.status)
              console.log(err.response.data)
            }
          }
        }
      }
    }

    const deleteShift = () => {
      emit('deleteShift', state.value)
    }

    const totalShiftHoursCalculated = computed(() => {
      let diff = 0
      if (state.value.start && state.value.end) {
        let start = dayjs(state.value.start, 'hh:m:s')
        let end = dayjs(state.value.end, 'hh:m:s').subtract(state.value.break, 'minute')
        diff = end.diff(start, 'hour', true)
      } else {
        diff = 0
      }
      return diff
    })

    const jobCardCalc = computed(() => {
      let productiveHours = state.value.productiveHours
      let allocatedHours = state.value.jobCards.reduce((total, item) => total + item.hours, 0)
      let allocatedPercentage = 0

      if (allocatedHours == productiveHours) {
        allocatedPercentage = 100
      } else {
        allocatedPercentage = (allocatedHours / productiveHours) * 100
      }

      return { allocatedHours, allocatedPercentage }
    })

    function addJobCardItem(type) {
      // console.log('Adding JC of type: ' + type)
      let emptyItem = {
        type: type,
        dealershipID: 0,
        dealershipName: '',
        text: '',
        hours: 0
      }
      state.value.jobCards.push(emptyItem)
    }

    function removeJobCardItem(index) {
      state.value.jobCards.splice(index, 1)
    }

    watch(state.value, () => {
      if (state.value != stateUnchanged.value) {
        // console.log(state.value, stateUnchanged.value);
        hasChanges.value = true
      }
    })

    watch(totalShiftHoursCalculated, () => {
      state.value.totalShiftHours = totalShiftHoursCalculated.value
    })

    return {
      sessionUserID,
      closeSidebar,
      isNewShift,
      shiftHandlerSubmit,
      state,
      v$,
      datePickerConfig,
      totalShiftHoursCalculated,
      jobCardCalc,
      addJobCardItem,
      removeJobCardItem,
      clearForm,
      deleteShift,
      hasChanges,
      shiftTypes
    }
  }
}
</script>

<style>
.jobcard-progress-danger > .p-progressbar-value {
  background-color: var(--danger);
}

.jobcard-progress-success > .p-progressbar-value {
  background-color: var(--success);
}
</style>
