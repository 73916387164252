<template>
  <div v-if="!isEditing" style="display: inherit" @click="openEditor">
    <slot name="default"></slot>
  </div>

  <div v-else style="display: flex; width: inherit">
    <slot name="edit"></slot>
    <Button severity="primary" icon="check" class="ml-2" @click="closeEditor" />
  </div>
</template>

<script>
import { ref } from 'vue'
import Button from '@/components/button/Button.vue'

export default {
  components: { Button },
  emits: ['editComplete'],
  setup(props, { emit }) {
    let isEditing = ref(false)
    function openEditor() {
      isEditing.value = true
      // console.log('Editing True')
    }
    function closeEditor() {
      isEditing.value = false
      // console.log('Editing False - Sending event to parent component')
      emit('editComplete', { oldValue: 0, newValue: 1 })
    }
    return { isEditing, openEditor, closeEditor }
  }
}
</script>

<style></style>
