<template>
  <div class="overflow-hidden rounded-[--rounded-md]">
    <!-- <div class="flex items-center justify-between h-[20%] bg-gray-50 px-4"> -->
    <div
      v-tippy="{ content: 'Customer\'s Journey' }"
      class="flex items-center h-[20%] border border-b-0 border-secondary rounded-t-[--rounded-lg] px-4"
    >
      <Icon type="location-arrow" />
      <div class="flex items-center justify-between ml-2 w-full text-sm">
        <div :class="duration.value < 1200 ? 'text-success' : 'text-warning'">{{ duration.text }}</div>
        <div class="text-secondary text-xs">{{ distance.text }}</div>
      </div>
    </div>
    <GoogleMap
      v-if="from && to"
      ref="mapRef"
      :disable-default-ui="mapOptions.disableDefaultUi"
      :zoom-control="mapOptions.zoomControl"
      :styles="mapOptions.styles"
      api-key="AIzaSyCZHkYPG75ygU9wbygdd7O3Z2tXQF2oF-8"
      style="width: 100%; height: 80%"
      :center="center"
      :zoom="15"
      :libraries="['geometry']"
    >
      <Marker v-if="fromMarkerOptions.position" :options="fromMarkerOptions" />
      <Marker v-if="toMarkerOptions.position" :options="toMarkerOptions" />
      <Polyline v-if="polylineOptions.path.length" :options="polylineOptions" />
    </GoogleMap>
  </div>
</template>

<script>
import { ref, watch, defineComponent, onMounted } from 'vue'
import axios from 'axios'
import { GoogleMap, Marker, Polyline } from 'vue3-google-map'
import Icon from '@/components/icon/Icon.vue'

import { useUserStore } from '@/stores/UserStore'

export default defineComponent({
  components: { GoogleMap, Marker, Polyline, Icon },
  props: {
    from: {
      type: String,
      default: null,
      required: true
    },
    to: {
      type: String,
      default: null,
      required: true
    },
    coordinates: {
      type: Object,
      default: () => ({
        start: {
          lat: 0,
          lng: 0
        },
        end: {
          lat: 0,
          lng: 0
        }
      })
    }
  },
  emits: ['update:coordinates'],
  setup(props, { emit }) {
    const mapRef = ref(null)

    const userStore = useUserStore()

    const coordinates = ref({
      start: {
        lat: 0,
        lng: 0
      },
      end: {
        lat: 0,
        lng: 0
      }
    })

    let mapStyling = [
      {
        elementType: 'geometry',
        stylers: [
          {
            color: '#f5f5f5'
          }
        ]
      },
      {
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161'
          }
        ]
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#f5f5f5'
          }
        ]
      },
      {
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'administrative.land_parcel',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#bdbdbd'
          }
        ]
      },
      {
        featureType: 'administrative.neighborhood',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'poi',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee'
          }
        ]
      },
      {
        featureType: 'poi',
        elementType: 'labels.text',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575'
          }
        ]
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5'
          }
        ]
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e'
          }
        ]
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            color: '#ffffff'
          }
        ]
      },
      {
        featureType: 'road',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575'
          }
        ]
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
          {
            color: '#dadada'
          }
        ]
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161'
          }
        ]
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e'
          }
        ]
      },
      {
        featureType: 'transit',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5'
          }
        ]
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee'
          }
        ]
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: '#caebf4'
          }
        ]
      },
      {
        featureType: 'water',
        elementType: 'labels.text',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e'
          }
        ]
      }
    ]

    // Determine may style based on userStore.userSettings.darkmode
    if (userStore.userSettings.darkmode === 'dark') {
      mapStyling = [
        {
          elementType: 'geometry',
          stylers: [
            {
              color: '#212121'
            }
          ]
        },
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575'
            }
          ]
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#212121'
            }
          ]
        },
        {
          featureType: 'administrative',
          elementType: 'geometry',
          stylers: [
            {
              color: '#757575'
            }
          ]
        },
        {
          featureType: 'administrative.country',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e'
            }
          ]
        },
        {
          featureType: 'administrative.land_parcel',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'administrative.locality',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#bdbdbd'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575'
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [
            {
              color: '#181818'
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161'
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#1b1b1b'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#2c2c2c'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#8a8a8a'
            }
          ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [
            {
              color: '#373737'
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [
            {
              color: '#3c3c3c'
            }
          ]
        },
        {
          featureType: 'road.highway.controlled_access',
          elementType: 'geometry',
          stylers: [
            {
              color: '#4e4e4e'
            }
          ]
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161'
            }
          ]
        },
        {
          featureType: 'transit',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575'
            }
          ]
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#000000'
            }
          ]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#3d3d3d'
            }
          ]
        }
      ]
    }

    const mapOptions = {
      disableDefaultUi: true,
      // zoomControl: true,
      styles: mapStyling
    }

    // reference variables
    const center = ref({ lat: 0, lng: 0 })
    const fromMarkerOptions = ref({ position: center })
    const toMarkerOptions = ref({ position: center })

    const polylineOptions = ref({
      path: [],
      strokeColor: '#5bc0de',
      strokeOpacity: 1,
      strokeWeight: 5,
      geodesic: true
    })

    const distance = ref('')
    const duration = ref('')

    const getDirections = async () => {
      const directions_url = `${import.meta.env.VITE_API_BASE_URL}/v1/utilities/googlemaps/directions?from=${props.from}&to=${props.to}`
      // console.log('Getting directions: ' + directions_url)

      try {
        const response = await axios.get(directions_url)
        // console.log(response)
        coordinates.value.start = response.data.routes[0].legs[0].start_location
        coordinates.value.end = response.data.routes[0].legs[0].end_location
        // console.log(coordinates.value)
        // console.log(coordinates.value.start.lat, coordinates.value.start.lng)
        // console.log(coordinates.value.end.lat, coordinates.value.end.lng)

        // If coordinates are available, emit update:coordinates to parent
        if (coordinates.value.start.lat) {
          emit('update:coordinates', coordinates.value)
        }

        const { data } = response
        const routes = data.routes[0]
        const legs = routes.legs[0]
        const overview_polyline = routes.overview_polyline

        let decodedPath = mapRef.value.api.geometry.encoding.decodePath(overview_polyline.points)
        decodedPath = decodedPath.map(coord => ({
          lat: coord.lat(),
          lng: coord.lng()
        }))

        polylineOptions.value.path = decodedPath

        const { distance: dist, duration: dur } = legs
        distance.value = dist
        duration.value = dur

        // After getting directions, it's now time to set markers
        setMarkers(legs)
      } catch (err) {
        console.log(err)
      }
    }

    const setMarkers = legs => {
      fromMarkerOptions.value = {
        position: { lat: legs.start_location.lat, lng: legs.start_location.lng },
        title: 'Customer',
        icon: {
          url: 'https://cdn.retain.group/img/map-markers/customer.svg',
          scaledSize: new mapRef.value.api.Size(30, 30)
        }
      }
      toMarkerOptions.value = {
        position: { lat: legs.end_location.lat, lng: legs.end_location.lng },
        title: 'Dealership',
        icon: {
          url: 'https://cdn.retain.group/img/map-markers/dealership.svg',
          scaledSize: new mapRef.value.api.Size(30, 30)
        }
      }

      // Create a new LatLngBounds instance
      const bounds = new mapRef.value.api.LatLngBounds()
      bounds.extend(fromMarkerOptions.value.position)
      bounds.extend(toMarkerOptions.value.position)

      // Update the map's center and bounds
      mapRef.value.map.fitBounds(bounds)
    }

    // Third pattern: watch for "ready" then do something with the API or map instance
    onMounted(() => {
      if (!props.from || !props.to) {
        console.log('From or To prop is missing. Not fetching directions.')
        return
      } else {
        watch(
          () => mapRef.value.ready,
          ready => {
            if (ready) {
              // console.log('ready')
              getDirections()
            } else {
              // console.log('not ready')
            }
            // do something with the api using `mapRef.value.api`
            // or with the map instance using `mapRef.value.map`
          }
        )
      }
    })

    return { mapRef, mapOptions, center, fromMarkerOptions, toMarkerOptions, polylineOptions, distance, duration }
  }
})
</script>

<style></style>
