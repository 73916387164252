<template>
  <div class="custom-toast flex">
    <div class="custom-toast_icon rounded-full" :style="'background-color: var(--' + getIconColor() + ')'">
      <Icon :type="icon" />
    </div>
    <div>
      <h4>{{ title }}</h4>
      {{ text }}
    </div>
  </div>
</template>

<script>
import Icon from '@/components/icon/Icon.vue'
export default {
  components: {
    Icon
  },
  props: {
    title: String,
    text: String,
    variant: {
      type: String,
      default: 'success'
    },
    icon: {
      type: String,
      default: 'check'
    }
  },
  setup(props) {
    function getIconColor() {
      switch (props.variant) {
        case 'success':
          return 'bg-success-solid'
        case 'danger':
          return 'bg-error-solid'
        case 'warning':
          return 'bg-warning-solid'
        case 'info':
          return 'bg-info-solid'
        default:
          return 'bg-brand-solid'
      }
    }
    return { getIconColor }
  }
}
</script>

<style>
.custom-toast_icon {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-right: 15px;
  background-color: var(--bg-brand-solid);
}
</style>
