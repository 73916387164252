<template>
  <div class="accordion-item">
    <div class="accordion-title cursor-pointer py-3 flex items-center justify-between hover:text-primary" @click="toggleItem">
      <div class="text-2xl">{{ title }}</div>
      <Icon class="text-xl" :type="accordionIsOpen ? 'chevron-up' : 'chevron-down'"></Icon>
    </div>
    <div v-if="accordionIsOpen" class="accordion-item-content pb-5">
      <!-- <div class="accordion-item-content pb-5"> -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/icon/Icon.vue'
import { ref } from 'vue'
export default {
  name: 'AccordionItem',
  components: {
    Icon
  },
  props: {
    title: {
      type: String,
      default: 'Accordion Title'
    },
    isOpen: Boolean
  },
  setup(props) {
    const accordionIsOpen = ref(props.isOpen)
    function toggleItem() {
      accordionIsOpen.value = !accordionIsOpen.value
    }

    return { accordionIsOpen, toggleItem }
  }
}
</script>

<style>
.accordion-item {
  border-bottom: 1px solid var(--border-secondary);
}
.accordion > .accordion-item:last-of-type {
  border-bottom: none;
}
</style>
