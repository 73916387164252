<template>
  <hr class="dropdown-menu-item-separator" :style="[noMargin ? '' : 'margin: 5px 0']" />
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    noMargin: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
.dropdown-menu-item-separator {
  border-top: 1px solid var(--border-secondary);
}
</style>
