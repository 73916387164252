<template>
  <!-- {{ socketUsers }} -->
  <Card class="m-10 p-5">
    <table v-if="usersSorted.length > 0">
      <tr class="text-left">
        <th>User ID</th>
        <th>User</th>
        <th>Last Active</th>
        <th>App Version</th>
        <th>Sockets</th>
        <th>Can Make Calls</th>
        <th>Can Receive Calls</th>
        <th>Is Free</th>
        <th>NONE</th>
      </tr>
      <tr v-for="user in usersSorted" :key="user.id">
        <td>{{ user.id }}</td>
        <td>
          <div class="flex items-center my-1">
            <Avatar :url="user.profilePicURL" :text="user.initials" :user-presence-user-id="user.id"></Avatar>
            <div>
              <div class="ml-3">{{ user.fullName }}</div>
              <div class="ml-3 text-xs text-gray-400 -mt-1">{{ user.jobTitle }}</div>
            </div>
          </div>
        </td>
        <td>
          <span v-if="user.server.timeoutStartTime">{{ dayjs(user.server.timeoutStartTime).fromNow(false) }}</span>
        </td>
        <td>
          <Chip v-if="user.server.appVersion">{{ user.server.appVersion }}</Chip>
        </td>
        <td>
          <div v-for="socket in user.server.sockets" :key="socket">{{ socket }}</div>
        </td>
        <td><Icon v-if="user.server.canMakeCalls" type="check" /></td>
        <td><Icon v-if="user.server.canReceiveCalls" type="check" /></td>
        <td>
          <Icon :type="user.server.canReceiveCalls && user.server.status === 'online' ? 'check' : 'xmark'" />
        </td>
        <td>
          <!-- <Icon :type="user.server.icon" fa-style="fas" /> -->
          <span class="capitalize ml-1">{{ user.server.timeSince }}</span>
        </td>
      </tr>
    </table>
  </Card>
</template>

<script>
import Card from '@/components/card/Card.vue'
import Icon from '@/components/icon/Icon.vue'
import Avatar from '@/components/avatar/Avatar.vue'
import Chip from '@/components/chip/Chip.vue'

import { useUserStore } from '../../stores/UserStore'
import { computed } from 'vue'

import getUsers from '@/composables/getUsers'

export default {
  components: {
    Card,
    Icon,
    Avatar,
    Chip
  },
  setup() {
    const socketUsers = useUserStore()

    const { users, errorUsers, loadUsers } = getUsers({
      userType: 'retainUsers',
      showDisabled: false
    })
    loadUsers()

    function fancyTimeFormat(duration) {
      // Hours, minutes and seconds
      var hrs = ~~(duration / 3600)
      var mins = ~~((duration % 3600) / 60)
      var secs = ~~duration % 60

      // Output like "1:01" or "4:03:59" or "123:03:59"
      var ret = ''

      if (hrs > 0) {
        ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
      }

      ret += '' + mins + ':' + (secs < 10 ? '0' : '')
      ret += '' + secs
      return ret
    }

    const usersSorted = computed(() => {
      //Loop through each user in the list and check if they are in the sockets array
      users.value.forEach(user => {
        let socketRecord = socketUsers.usersPresence.find(u => u.uid == user.id) ?? []
        user.server = socketRecord

        // user.timer = setInterval(() => {
        //     user.server.timeSince = fancyTimeFormat(dayjs().diff(user.server.statusStartTime, "second"))

        //     console.log("timer")
        // }, 1000)
      })

      return users.value
    })

    return { usersSorted, socketUsers }
  }
}
</script>

<style scoped></style>
