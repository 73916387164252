<template>
  <main class="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
    <div class="text-center">
      <p class="text-base font-semibold text-primary">503</p>
      <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Under Maintenance</h1>
      <p class="mt-6 text-base leading-7 text-gray-600">We are currently making changes and improvements to our app!</p>
      <div class="mt-10 flex items-center justify-center gap-x-6">
        <Button label="Go back" @click="$router.go(-1)" />
        <a href="#" class="text-sm font-semibold text-gray-900">Contact support <Icon type="arrow-right" /></a>
      </div>
    </div>
  </main>
</template>

<script>
import Button from '@/components/button/Button.vue'
import Icon from '@/components/icon/Icon.vue'
export default {
  components: { Button, Icon },
  setup() {
    return {}
  }
}
</script>

<style></style>
