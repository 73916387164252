<template>
  <!-- {{ calls }} -->
  <div class="p-5">
    <Card class="p-5">
      <table>
        <tr class="text-left">
          <th>Call SID</th>
          <th>Date</th>
          <th>Timestamps</th>
          <th>Direction</th>
          <th>From</th>
          <th>To</th>
          <th>Status</th>
          <th>QueueResult</th>
          <th>Agent</th>
          <th>AMD</th>
          <th><Icon type="arrow-turn-up" /></th>
        </tr>
        <tr v-for="call in calls" :key="call.CallSid">
          <td>{{ call.parentCallSid }}</td>
          <td>{{ dayjs(call.timestampInitiated).isToday() ? 'TODAY' : dayjs(call.timestampInitiated).format('ddd D MM') }}</td>
          <td>
            <div>
              Initiated: <span v-if="call.timestampInitiated">{{ dayjs(call.timestampInitiated).format('HH:mm:ss') }}</span>
            </div>
            <div>
              Queued: <span v-if="call.timestampQueued">{{ dayjs(call.timestampInitiated).format('HH:mm:ss') }}</span>
            </div>
            <div>
              Connected: <span v-if="call.timestampConnected">{{ dayjs(call.timestampConnected).format('HH:mm:ss') }}</span>
            </div>
            <div>
              Hangup: <span v-if="call.timestampHangup">{{ dayjs(call.timestampHangup).format('HH:mm:ss') }}</span>
            </div>
          </td>
          <td>{{ call.direction }}</td>
          <td>{{ call.from }}</td>
          <td>{{ call.to }}</td>
          <td>{{ call.status }}</td>
          <td>{{ call.queueResult }}</td>
          <td>{{ call.agentID }}</td>
          <td>
            <Icon v-if="call.amdOutcome?.includes('machine')" type="voicemail" />
            <Icon v-else-if="call.amdOutcome?.includes('human')" type="user" />
            <Icon v-else-if="call.amdOutcome?.includes('unknown')" type="question" />
            <div v-else>{{ call.amdOutcome }}</div>
          </td>
          <td>{{ call.wrappedUp }}</td>
          <td><Icon type="trash" class="cursor-pointer" @click="removeCall(call)" /></td>
        </tr>
      </table>
    </Card>
  </div>
</template>

<script>
import Card from '@/components/card/Card.vue'
import Icon from '@/components/icon/Icon.vue'

import socket from '@/services/socket.service.js'

import { ref } from 'vue'

export default {
  components: {
    Card,
    Icon
  },
  setup() {
    let calls = ref([])

    socket.on('phone-calls-all', activeCalls => {
      // console.log(JSON.stringify(activeCalls))
      calls.value = activeCalls
    })

    function removeCall(call) {
      socket.emit('phone-call-remove', call.parentCallSid)
      // Remove call from calls array based on parentCallSid
      calls.value = calls.value.filter(c => c.parentCallSid !== call.parentCallSid)
    }

    //Listen to emitted events from IO server
    return { calls, removeCall }
  }
}
</script>

<style scoped>
table td {
  border-bottom: 1px solid rgb(207, 207, 207);
}
</style>
