// canny.js
// Download Canny SDK
;(function (w, d, i, s) {
  function l() {
    if (!d.getElementById(i)) {
      var f = d.getElementsByTagName(s)[0],
        e = d.createElement(s)
      e.type = 'text/javascript'
      e.async = true
      e.src = 'https://canny.io/sdk.js'
      f.parentNode.insertBefore(e, f)
    }
  }

  if (typeof w.Canny !== 'function') {
    var c = function () {
      c.q.push(arguments)
    }
    c.q = []
    w.Canny = c
    if (d.readyState === 'complete') {
      l()
    } else if (w.attachEvent) {
      w.attachEvent('onload', l)
    } else {
      w.addEventListener('load', l, false)
    }
  }
})(window, document, 'canny-jssdk', 'script')

// Function to initialize Canny.io with user data
function initializeCanny(userData) {
  Canny('identify', {
    appID: '643e36375d719208fb429416',
    user: {
      email: userData.email || null,
      name: userData.name || null,
      id: userData.id || null,
      avatarURL: userData.profilePicUrl || null
      // created: userData.created ? new Date(userData.created).toISOString() : null
    }
  })
}

export default initializeCanny
