import { createI18n as _createI18n } from 'vue-i18n'

import enGB from './locales/en-gb.json'
import enUS from './locales/en-us.json'
import es from './locales/es.json'
import fr from './locales/fr.json'

export const SUPPORT_LOCALES = ['en-GB', 'en-US', 'es', 'fr']

const numberFormats = {
  'en-GB': {
    default: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    },
    currency: {
      style: 'currency',
      currency: 'GBP',
      notation: 'standard'
    },
    percent: {
      style: 'percent',
      useGrouping: false
    }
  },
  'en-US': {
    default: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    },
    currency: {
      style: 'currency',
      currency: 'USD',
      notation: 'standard'
    },
    percent: {
      style: 'percent',
      useGrouping: false
    }
  }
}

export function createI18n(initialLocale) {
  return _createI18n({
    legacy: false,
    globalInjection: true,
    locale: initialLocale,
    fallbackLocale: 'en',
    messages: {
      en: enGB,
      'en-GB': enGB,
      'en-US': enUS,
      es: es,
      fr: fr
    },
    numberFormats
  })
}
