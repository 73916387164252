import axios from 'axios'
import { ref } from 'vue'
import notification from '@/utilities/notification'

const getBillingAccounts = (params = { showArchived: false, treeView: false }) => {
  let billingAccounts = ref([])
  let error = ref(null)

  const load = async () => {
    // console.log('Getting Data Axios')
    let url =
      import.meta.env.VITE_API_BASE_URL +
      '/v1/finance/billing-accounts?showArchived=' +
      params.showArchived +
      '&treeView=' +
      params.treeView

    // console.log(url)

    try {
      let data = await axios.get(url, { withCredentials: true })
      // console.log(data.data.data)

      if (data.status != 200) {
        if (data.status === 401) {
          error.value = '401'
          throw Error('401 Unauthorized')
        } else {
          error.value = 'Error fetching data'
          throw Error('Error fetching data')
        }
      }
      //If everything is fine
      billingAccounts.value = data.data.data

      // console.log(data.data.data)
    } catch (err) {
      console.log(err.message)
      notification('Error', err.message, 'danger')
    }
  }

  return { billingAccounts, error, load }
}
export default getBillingAccounts
