<template>
  <DataTable
    ref="dt"
    v-model:selection="selectedUsers"
    v-model:filters="filters"
    export-filename="Core Users"
    :value="modelValue"
    :loading="isLoading"
    removable-sort
    sort-field="id"
    :sort-order="-1"
    :rows="20"
    data-key="id"
    column-resize-mode="fit"
    :paginator="true"
    responsive-layout="scroll"
    filter-display="menu"
    :rows-per-page-options="[10, 20, 50]"
    state-key="dt-settings-users"
    state-storage="session"
    current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
    :global-filter-fields="['id', 'fullName', 'jobTitle', 'email', 'rolesNamed.name']"
    class="dt-overflow-unset"
  >
    <template #empty> No records found </template>
    <template #header>
      <div class="flex justify-between">
        <span class="flex justify-between gap-4">
          <span>
            <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
          </span>
          <Button
            :label="$t('ui.actions.clear', 1)"
            severity="secondary"
            type="button"
            outlined
            icon="filter-slash"
            @click="clearFilter()"
          />
        </span>
      </div>
    </template>

    <!-- <Column selection-mode="multiple" header-style="width: 3em"></Column> -->
    <Column field="id" header="#" :sortable="true" hidden>
      <template #body="{ data }">
        <a class="link" :href="'/settings/users/' + data.id">{{ data.id }}</a>
      </template>
    </Column>
    <Column field="fullName" header="User" :sortable="true">
      <template #body="{ data }">
        <AvatarLabelGroup
          :label="data.fullName"
          :description="data.jobTitle"
          :avatar-url="data.profilePicURL"
          :avatar-text="data.initials"
          :label-link="'/settings/users/' + data.id"
        />
      </template>
    </Column>
    <Column field="type" header="Type" :sortable="true">
      <template #body="{ data }">
        <Chip :severity="data.userType === 1 ? 'brand' : 'primary'" label size="sm">{{ data.userTypeNamed }}</Chip>
      </template>
    </Column>
    <Column field="rolesNamed" header="Roles" :sortable="false">
      <template #body="{ data }">
        <Chip v-for="role in data.rolesNamed" :key="role.id" :color="role.color" size="sm" class="mr-1 my-[0.1rem]">{{ role.name }}</Chip>
      </template>
    </Column>
    <Column field="email" header="Email" :sortable="true">
      <template #body="{ data }">
        {{ data.email }}
      </template>
    </Column>
    <Column field="accountVerified" header="Verified" :sortable="false">
      <template #body="{ data }">
        <Icon v-if="data.accountVerified" type="circle-check" class="text-success" />
        <Icon v-else type="circle-xmark" class="text-danger" />
        <!-- <Button v-else label="Send Verification" size="sm" outlined /> -->
      </template>
    </Column>
    <Column field="lastActive" header="Last Active" :sortable="true">
      <template #body="{ data }">
        <div v-tippy="$dayjs(data.lastActive).format('LLL')" class="whitespace-nowrap">
          {{ data.lastActive ? $dayjs(data.lastActive).from($dayjs()) : 'Never' }}
        </div>
      </template>
    </Column>
    <Column field="status" header="Status" :sortable="true">
      <template #body="{ data }">
        <Chip v-if="data.active" label outlined size="sm" dot severity="success">Active</Chip>
        <Chip v-else label outlined size="sm" dot severity="danger">Disabled</Chip>
      </template>
    </Column>
    <Column header="" :sortable="false">
      <template #body="slotProps">
        <div class="flex">
          <!-- <Button v-tippy="'Roles and Permissions'" icon="shield-halved" plain severity="secondary"></Button> -->
          <Button
            v-tippy="$t('ui.common.details')"
            icon="cog"
            plain
            severity="secondary"
            @click="$router.push('/settings/users/' + slotProps.data.id)"
          ></Button>
          <!-- <Button
          v-tippy="'Access'"
          icon="building-shield"
          plain
          severity="secondary"
          @click="openAccessModal(slotProps.data)"
        ></Button> -->
          <Dropdown position="bottom-right">
            <template #triggerContent>
              <Button icon="ellipsis-vertical" plain severity="secondary"></Button>
            </template>
            <!-- <DropdownItem @item-clicked="$router.push('/settings/users/' + slotProps.data.id)">
            <Icon type="cog" />
            <span class="w-full">{{ $t('ui.common.details') }}</span>
          </DropdownItem> -->
            <DropdownItem @item-clicked="openAccessModal(slotProps.data)">
              <Icon type="building-shield" />
              <span class="w-full">{{ $t('ui.common.access') }}</span>
            </DropdownItem>
            <!-- <DropdownItem disabled @item-clicked="$router.push('/user/' + slotProps.data.id)">
              <Icon type="user-circle" />
              <span class="w-full">{{ $t('ui.entities.profile.label') }}</span>
            </DropdownItem> -->
            <DropdownSeparator />
            <DropdownItem @item-clicked="resetUserStatus(slotProps.data.id)">
              <Icon type="rotate-right" />
              <span class="w-full">{{ $t('ui.actions.reset', { item: $t('ui.common.status') }) }}</span>
            </DropdownItem>
            <DropdownItem
              v-if="slotProps.data.active"
              class="text-error"
              @item-clicked="toggleActiveState(slotProps.data.id, 'deactivate')"
            >
              <Icon type="ban" />
              <span class="w-full">{{ $t('ui.actions.deactivate') }}</span>
            </DropdownItem>
            <DropdownItem v-else class="text-success" @item-clicked="toggleActiveState(slotProps.data.id, 'reactivate')">
              <Icon type="circle-check" />
              <span class="w-full">{{ $t('ui.actions.reactivate') }}</span>
            </DropdownItem>
          </Dropdown>
        </div>
      </template>
    </Column>
  </DataTable>

  <teleport to="#modals-container">
    <Modal
      :is-open="accessModalIsOpen"
      title="Access"
      description="Update user access permissions"
      icon="building-shield"
      :buttons="['ok', 'close']"
      close-text="Cancel"
      ok-text="Save"
      :show-loader-on-confirm="true"
      :is-confirming="false"
      min-width="600"
      @close-modal="accessModalIsOpen = false"
      @ok-modal="submitAccessModal"
    >
      <UserAccessSelector v-model="userBeingActioned.access" />
    </Modal>
  </teleport>
</template>

<script>
import Button from '@/components/button/Button.vue'
import Icon from '@/components/icon/Icon.vue'
import Input from '@/components/forms/Input.vue'
import Chip from '@/components/chip/Chip.vue'
import Dropdown from '@/components/dropdown/Dropdown.vue'
import DropdownItem from '@/components/dropdown/DropdownItem.vue'
import Modal from '@/components/modal/Modal.vue'
import AvatarLabelGroup from '@/components/avatar/AvatarLabelGroup.vue'

import UserAccessSelector from '@/components/unique/users/UserAccessSelector.vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'

import { ref } from 'vue'
import notification from '@/utilities/notification'

import useApiRequest from '@/composables/useApiRequest'
import { useUserStore } from '@/stores/UserStore'
import socket from '@/services/socket.service.js'
import DropdownSeparator from '@/components/dropdown/DropdownSeparator.vue'

export default {
  components: {
    Input,
    Button,
    AvatarLabelGroup,
    Icon,
    DataTable,
    Column,
    Chip,
    Dropdown,
    DropdownItem,
    Modal,
    UserAccessSelector,
    DropdownSeparator
  },
  props: {
    modelValue: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  emits: ['user-updated'],
  setup(props, { emit }) {
    const userStore = useUserStore()

    const dt = ref({ processedData: [] })

    const accessModalIsOpen = ref(false)

    const selectedUsers = ref([])
    const onRowSelect = event => {
      console.log('Selected ' + event.data.name)
    }

    const onRowUnselect = event => {
      console.log('Unselected ' + event.data.name)
    }

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      userTypeNamed: { value: null, matchMode: FilterMatchMode.IN },
      rolesNamed: { value: null, matchMode: FilterMatchMode.IN }
    })

    const clearFilter = () => {
      // console.log('Clearing Filter')
      filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        userTypeNamed: { value: null, matchMode: FilterMatchMode.IN },
        rolesNamed: { value: null, matchMode: FilterMatchMode.IN }
      }
    }

    const userBeingActioned = ref({
      id: null,
      access: {
        regions: [],
        regionsAll: false,
        manufacturers: [],
        manufacturersAll: false,
        groups: [],
        groupsAll: false,
        dealerships: [],
        dealershipsAll: false
      }
    })

    function openAccessModal(userObj) {
      userBeingActioned.value = userObj
      accessModalIsOpen.value = true
    }

    const updateUserAccess = useApiRequest()
    const updateUserActiveState = useApiRequest()

    function submitAccessModal() {
      let access = userBeingActioned.value.access
      let newAccessData = {
        regions: access.regionsAll ? '*' : access.regions,
        manufacturers: access.manufacturersAll ? '*' : access.manufacturers,
        groups: access.groupsAll ? '*' : access.groups,
        dealerships: access.dealershipsAll ? '*' : access.dealerships
      }

      updateUserAccess
        .sendRequest({
          endpoint: '/v1/users/' + userBeingActioned.value.id + '/access',
          method: 'PUT',
          data: newAccessData
        })
        .then(response => {
          // console.log(response.data.response)
          if (response.data.response.status == 'success') {
            notification('Access Updated', null, 'success')
            userBeingActioned.value = null
            accessModalIsOpen.value = false
          }
        })
    }

    function toggleActiveState(userId, action) {
      updateUserActiveState
        .sendRequest({
          endpoint: '/v1/users/' + userId + '/' + action,
          method: 'PATCH'
        })
        .then(response => {
          if (response.data.response.status == 'success') {
            // Emit event to parent to update user status
            emit('user-updated', userId, { active: response.data.data.active })

            //Notification
            notification('Updated')
          } else {
            notification('Error', response.data.response.message, 'error')
          }
        })
    }

    function resetUserStatus(userId) {
      socket.emit('reset-specific-users-status', userId)
    }

    return {
      dt,
      filters,
      clearFilter,
      selectedUsers,
      onRowSelect,
      onRowUnselect,
      accessModalIsOpen,
      userBeingActioned,
      openAccessModal,
      submitAccessModal,
      userStore,
      resetUserStatus,
      toggleActiveState
    }
  }
}
</script>
