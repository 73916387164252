import axios from 'axios'
import { ref } from 'vue'
import notification from '@/utilities/notification'

const getCommTemplates = (params = { types: [], dealershipID: '' }) => {
  let commTemplates = ref([])
  let errorCommTemplates = ref(null)

  params.types = params.types.join(',')

  // console.log(params)

  const loadCommTemplates = async () => {
    // console.log('Getting CommTemplate - Axios')

    let urlparams = new URLSearchParams(params).toString()
    // let url = import.meta.env.VITE_API_BASE_URL + '/v1/comms/canned?types=' + params.types + '&dealershipID=' + params.dealershipID //Temp disabled to allow this template to be used by all dealerships //TODO - change
    let url = import.meta.env.VITE_API_BASE_URL + '/v1/comms/canned?types=' + params.types

    // console.log('Fetching: ')
    // console.log(url)

    // console.log('Fetching: ' + url)
    try {
      let data = await axios.get(url, { withCredentials: true })
      // console.log(data.data.data)

      if (data.status != 200) {
        if (data.status === 401) {
          errorCommTemplates.value = '401'
          throw Error('401 Unauthorized')
        } else {
          errorCommTemplates.value = 'Error fetching data'
          throw Error('Error fetching data')
        }
      }
      //If everything is fine
      commTemplates.value = data.data.data

      commTemplates.value.sort((a, b) => (a.name > b.name ? 1 : -1))

      // console.log(commTemplates.value)

      // console.log(CommTemplate.value)
    } catch (err) {
      console.log(err.message)
      notification('Error', err.message, 'danger')
    }
  }

  return { commTemplates, errorCommTemplates, loadCommTemplates }
}
export default getCommTemplates
