<template>
  <div
    class="list-group-item"
    :class="{
      'list-group-item-link': route,
      'flex justify-between items-center': hasChevron,
      'hover:bg-extra-light cursor-pointer': hoverEffect
    }"
    @click="routeTo(route)"
  >
    <slot></slot>
    <Icon v-if="hasChevron" type="chevron-right" />
  </div>
</template>

<script>
import { useRouter } from 'vue-router'

import Icon from '@/components/icon/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    route: {
      type: String,
      default: null
    },
    hasChevron: {
      type: Boolean,
      default: false
    },
    hoverEffect: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const router = useRouter()

    function routeTo(route) {
      // console.log('Routing to: ' + route)
      if (route) {
        router.push(route)
      }
    }
    return { routeTo }
  }
}
</script>

<style>
.list-group-item {
  padding: var(--s-4);
  border-bottom: 1px solid var(--border-secondary);
}

.list-group-item:last-of-type {
  border-bottom: none;
}

.list-group-item-link:hover {
  cursor: pointer;
  background-color: var(--bg-primary_hover);
}
</style>
