<template>
  <div class="message-container" :class="[variant]">
    <div class="message-container-body" :class="['message-container-body-' + variant]">
      <Icon v-if="icon" :type="icon" class="mr-3 text-xl"></Icon>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/icon/Icon.vue'
export default {
  components: { Icon },
  props: {
    size: {
      type: String,
      default: 'md'
    },
    variant: {
      type: String,
      default: 'secondary'
    },
    icon: {
      type: String,
      default: null
    }
  },
  setup() {
    return {}
  }
}
</script>

<style>
.message-container {
  margin-bottom: 10px;
  border-radius: var(--rounded-md);
  z-index: 1;
}
.message-container.secondary {
  background-color: var(--text-secondary);
}
.message-container.primary {
  background-color: var(--fg-brand);
}
.message-container.success {
  background-color: var(--success);
}
.message-container.warning {
  background-color: var(--warning);
}
.message-container.danger {
  background-color: var(--danger);
}
.message-container.info {
  background-color: var(--info);
}
.message-container-body {
  padding: 10px 15px;
  margin-left: 5px;
  color: var(--text-primary);
  border-top-right-radius: var(--rounded-md);
  border-bottom-right-radius: var(--rounded-md);
  display: flex;
  align-items: center;
}
.message-container.secondary .message-container-body {
  background-color: var(--bg-secondary);
}
.message-container.primary .message-container-body {
  background-color: var(--bg-brand-solid);
}
.message-container.success .message-container-body {
  background-color: var(--success-extra-light);
}
.message-container.warning .message-container-body {
  background-color: var(--warning-extra-light);
}
.message-container.danger .message-container-body {
  background-color: var(--danger-extra-light);
}
.message-container.info .message-container-body {
  background-color: var(--info-extra-light);
}

.dark .message-container.secondary .message-container-body {
  background-color: rgb(49, 68, 90);
}
.dark .message-container.primary .message-container-body {
  background-color: rgb(44, 64, 71);
}
.dark .message-container.success .message-container-body {
  background-color: rgb(43, 60, 51);
}
.dark .message-container.warning .message-container-body {
  background-color: rgb(67, 60, 48);
}
.dark .message-container.danger .message-container-body {
  background-color: rgb(79, 51, 55);
}
.dark .message-container.info .message-container-body {
  background-color: rgb(58, 50, 67);
}
</style>
