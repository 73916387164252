// src/utils/healthCheck.js
import axios from 'axios'

export async function healthCheck() {
  try {
    console.log('Checking health...')
    // Replace these URLs with your actual API and CDN health check endpoints
    const apiResponse = await axios.get('https://api.retain.group/healthcheck.php')
    const cdnResponse = await axios.get('https://cdn.retain.group/healthcheck.php')

    console.log('results:', apiResponse.status, cdnResponse.status)

    if (apiResponse.status === 200 && cdnResponse.status === 200) {
      return { api: true, cdn: true }
    } else {
      return { api: apiResponse.status === 200, cdn: cdnResponse.status === 200 }
    }
  } catch (error) {
    return { api: false, cdn: false }
  }
}
