export const NewStockService = {
  getModelsData() {
    return [
      {
        id: '8b586bf0-f1b6-4778-8856-a1a2c8ee9188',
        model: 'TIGER SPORT 660',
        price: 8945,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/adventure-touring/my22/ll1 tiger sport 660/base images/korosi red graphite/tiger-sport-660-korosi-red-graphite-updated-size-rhs-1080.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/adventure/tiger-sport-660/tiger-sport-660'
      },
      {
        id: 'edde3425-6111-4bcd-bde7-7bfd3b7d712c',
        model: 'TIGER 850 SPORT',
        price: 10095,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/adventure-touring/my24/cx7_tiger_850_sport/tiger 850 sport_my24_jet black_rhs.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/adventure/tiger-850-sport/850-sport'
      },
      {
        id: '2ad1e197-3adb-4d01-a921-715c2c1fe125',
        model: 'Tiger 900 GT Aragón Edition',
        price: 13695,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/adventure-touring/my24/aragon editions/web_cgi/1080/tiger 900 gt aragon edition_my24_diablo red_rhs.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/adventure/tiger-900-gt/gt-aragon-edition-2024'
      },
      {
        id: '7d2b4872-7c28-434a-aeda-9a69a4d86f1b',
        model: 'TIGER 900 GT',
        price: 12195,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/adventure-touring/2020 tiger 900/cgi/tiger-900-gt_sapphire-black_rhs-1080.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/adventure/tiger-900-gt/gt'
      },
      {
        id: 'e7d71a3f-0329-4747-89a0-7779d2c4991f',
        model: 'TIGER 900 GT LOW',
        price: 12195,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/adventure-touring/2020 tiger 900/updated white frame gt variant images/cgi/cgis/gt low/tiger-900-gt-low-pure-white-rhs-1920x1080.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/adventure/tiger-900-gt/gt-low'
      },
      {
        id: '3de3f2d3-e0af-46e9-8b6b-1b9bbad77c57',
        model: 'TIGER 900 GT PRO',
        price: 13895,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/adventure-touring/2020 tiger 900/cgi/my23 colours/caspian blue/gt pro/tiger-900-gt-pro_my23_caspian-blue_rhs_1080px.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/adventure/tiger-900-gt/gt-pro'
      },
      {
        id: '77099730-cdd8-4a9d-8441-c46715f1ce9e',
        model: 'Tiger 900 Rally Aragón Edition',
        price: 13995,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/adventure-touring/my24/aragon editions/web_cgi/1080/tiger 900 rally aragon edition_my24_matt phantom black_rhs.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/adventure/tiger-900-rally/rally-aragon-edition-2024'
      },
      {
        id: 'f322fcab-9799-476f-98d6-a093315ee20e',
        model: 'TIGER 900 RALLY',
        price: 12795,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/adventure-touring/2020 tiger 900/cgi/new_tiger-900-rally_pure-white_rhs-1080.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/adventure/tiger-900-rally/rally'
      },
      {
        id: '6f62a99c-0f5c-48ce-bd78-2eca15617715',
        model: 'TIGER 900 RALLY PRO',
        price: 14195,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/adventure-touring/2020 tiger 900/cgi/tiger-900-rally-pro_matt-khaki_rhs-1080.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/adventure/tiger-900-rally/rally-pro'
      },
      {
        id: 'b80c9f60-7dc7-4b21-b00c-d008cbbe913e',
        model: 'NEW TIGER 1200 GT ',
        price: 14995,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/adventure-touring/my22/2022 tiger 1200/base images/gt/my22-tiger-1200-gt-snowdonia-white-rhs-1080.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/adventure/tiger-1200-gt/tiger-1200-gt-2022'
      },
      {
        id: 'e36d29c8-0db3-4cee-a0f6-4de141c6ea81',
        model: 'NEW TIGER 1200 GT PRO',
        price: 17095,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/adventure-touring/my22/2022 tiger 1200/base images/gt pro/my22-tiger-1200-gt-pro-lucerne-blue-rhs-1080.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/adventure/tiger-1200-gt/tiger-1200-gt-pro-2022'
      },
      {
        id: 'fac36baf-ce09-4937-ad2a-01664815bb1e',
        model: 'NEW TIGER 1200 GT EXPLORER',
        price: 18495,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/adventure-touring/my22/2022 tiger 1200/base images/gt explorer/my22-tiger-1200-gt-explorer-lucerne-blue-rhs-1080.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/adventure/tiger-1200-gt/tiger-1200-gt-explorer-2022'
      },
      {
        id: '3368a7e0-9e38-4001-9d22-cd98e3aa8880',
        model: 'NEW TIGER 1200 RALLY PRO',
        price: 18095,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/adventure-touring/my22/2022 tiger 1200/base images/rally pro/my22-tiger-1200-rally-pro-matt-khaki-rhs-1080.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/adventure/tiger-1200-rally/tiger-1200-rally-pro-2022'
      },
      {
        id: '01d66264-2cd6-4353-a2c4-f91243fd86c5',
        model: 'NEW TIGER 1200 RALLY EXPLORER',
        price: 19495,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/adventure-touring/my22/2022 tiger 1200/base images/rally explorer/my22-tiger-1200-rally-explorer-matt-khaki-rhs-1080.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/adventure/tiger-1200-rally/tiger-1200-rally-explorer-2022'
      },
      {
        id: '945470f9-26d7-4902-abab-b24d2c7c8c95',
        model: 'SPEED TWIN 900',
        price: 8795,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/modern-classics/my24 colours/dpz_speed_twin_900/speed twin 900_my24_competition green_rhs_1080px.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/classic/bonneville-speed-twin-900/speed-twin-900-2023'
      },
      {
        id: 'd7fb378c-c0c9-4ab0-a203-aa74d0fef4b1',
        model: 'SPEED TWIN 900 CHROME EDITION',
        price: 9145,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/modern-classics/chrome editions/speed twin 900/base images/legacy/speed-twin-900--chrome-edition-rhs-1920x1080.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/classic/bonneville-speed-twin-900/speed-twin-900-chrome-edition-2023'
      },
      {
        id: 'c3e69b70-1265-43f7-8a45-c65fd6cdd15c',
        model: 'BONNEVILLE BOBBER',
        price: 12995,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/modern-classics/my23 colours/bobber/bonneville-bobber_my23_red-hopper_rhs_1080px.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/classic/bonneville-bobber/bobber-2023'
      },
      {
        id: '0fa778e9-43ae-4dd4-8962-036af93e5f2d',
        model: 'BONNEVILLE BOBBER CHROME EDITION',
        price: 13795,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/modern-classics/chrome editions/bobber/base images/legacy/bonneville-bobber-chrome-edition-rhs-1920x1080.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/classic/bonneville-bobber/bobber-chrome-edition-2023'
      },
      {
        id: 'bd76d4f9-730b-479d-bb12-c85d5dd656ac',
        model: 'SCRAMBLER 1200 XC',
        price: 12695,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/modern-classics/my24 colours/dr4_ds4_scrambler_1200/dr4/scrambler_1200_xc_my24_matt_sandstorm_rhs_1080px.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/classic/bonneville-scrambler-1200/scrambler-1200-xc-2023'
      },
      {
        id: 'c7526787-2ca3-42f6-bf50-4f283690ff1d',
        model: 'SCRAMBLER 1200 XE',
        price: 13695,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/modern-classics/my24 colours/dr4_ds4_scrambler_1200/ds4/scrambler_1200_xe_my24_matt_sandstorm_rhs_1080px.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/classic/bonneville-scrambler-1200/scrambler-1200-xe-2023'
      },
      {
        id: '70ae5fb7-9d32-43a8-84d0-b0378a0397d2',
        model: 'SCRAMBLER 1200 XE CHROME EDITION',
        price: 14495,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/modern-classics/chrome editions/scrambler 1200 xe/base images/legacy/scrambler-1200-xe--chrome-edition-rhs-1920x1080.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/classic/bonneville-scrambler-1200/scrambler-1200-xe-chrome-edition-2023'
      },
      {
        id: '2b4bba61-de33-42c0-addf-c92cd28d7a73',
        model: 'BONNEVILLE SPEEDMASTER',
        price: 12995,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/modern-classics/my23 colours/speedmaster/cordovan red/bonneville-speedmaster_my23_cordovan-red_rhs_1080px.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/classic/bonneville-speedmaster/speedmaster-2023'
      },
      {
        id: '7eb7f522-dbc0-4a7b-a83f-c1058a00d069',
        model: 'BONNEVILLE SPEEDMASTER CHROME EDITION',
        price: 13795,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/modern-classics/chrome editions/speedmaster/base images/legacy/speedmaster-chrome-edition-rhs-1920x1080.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/classic/bonneville-speedmaster/speedmaster-chrome-edition-2023'
      },
      {
        id: 'fd9542e0-1d97-4d0c-b289-e8c0b21dec1b',
        model: 'SPEED TWIN 1200',
        price: 11795,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/modern-classics/my24 colours/dd4_speed_twin_1200/speed_twin_1200_my24_carnival_red_rhs_1080px.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/classic/bonneville-speed-twin-1200/speed-twin-1200-2023'
      },
      {
        id: '7373a081-52e0-460b-bc3c-f82c25b65a84',
        model: 'SCRAMBLER 900',
        price: 9795,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/modern-classics/my24 colours/dcz_scrambler_900/scrambler 900_my24_cosmic yellow_rhs_1080px.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/classic/bonneville-scrambler-900/scrambler-900-2023'
      },
      {
        id: '7fb9cf3a-b500-4bf0-81b4-1bfa71fd908b',
        model: 'SCRAMBLER 900 CHROME EDITION',
        price: 10145,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/modern-classics/chrome editions/scrambler 900/base images/legacy/scrambler-900--chrome-edition-rhs-1920x1080.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/classic/bonneville-scrambler-900/scrambler-900-chrome-edition-2023'
      },
      {
        id: 'a639f25a-d8e5-4505-907f-2f913a0e529f',
        model: 'BONNEVILLE T100 CHROME EDITION',
        price: 9945,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/modern-classics/chrome editions/t100/base images/legacy/t100--chrome-edition-rhs-1920x1080.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/classic/bonneville-t100/t100-chrome-edition-2023'
      },
      {
        id: '229b21e9-7a91-4f41-808c-0266eb45e3e2',
        model: 'BONNEVILLE T100',
        price: 9595,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/modern-classics/my24 colours/db4_t100/bonneville-t100_my24_competition-green-ironstone_rhs_1080px.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/classic/bonneville-t100/t100-2023'
      },
      {
        id: 'b65c9f87-24fe-4ca2-aefc-868232ed773d',
        model: 'BONNEVILLE T120',
        price: 11795,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/modern-classics/my24 colours/du4_dt4_t120_range/du4/bonneville-t120_my24_jet-black-fusion-white_rhs_1080px.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/classic/bonneville-t120/t120-2023'
      },
      {
        id: '527aea3e-7c8d-42fd-b8e0-63181e452ad7',
        model: 'BONNEVILLE T120 CHROME EDITION',
        price: 12595,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/modern-classics/chrome editions/t120/base images/legacy/t120--chrome-edition-rhs-1920x1080.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/classic/bonneville-t120/t120-chrome-edition-2023'
      },
      {
        id: 'a19461d6-eef2-4734-bb5b-d77b0a34f3e7',
        model: 'BONNEVILLE T120 BLACK',
        price: 11795,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/modern-classics/my24 colours/du4_dt4_t120_range/dt4/bonneville-t120-black_my24_graphite_rhs_1080px.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/classic/bonneville-t120/t120-black-2023'
      },
      {
        id: 'e67d3225-251a-42da-8a7e-19ba87b05c7d',
        model: 'THRUXTON RS',
        price: 14195,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/modern-classics/my24 colours/df4_thruxton_rs/thruxton rs_my24_jet black_silver ice_rhs_1080px.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/classic/thruxton-rs/thruxton-rs-2023'
      },
      {
        id: '9af37e30-9dcf-4e05-9a6c-3f98df1b8767',
        model: 'THRUXTON RS CHROME EDITION',
        price: 14995,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/modern-classics/chrome editions/thruxton rs/base images/legacy/thruxton-rs-chrome-edition-rhs-1920x1080.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/classic/thruxton-rs/thruxton-rs-chrome-edition-2023'
      },
      {
        id: '0c168767-063d-4190-8000-fb98a59b95bc',
        model: 'STREET TRIPLE 765 R',
        price: 9595,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/roadsters-supersports/my23/street triple/765 r variant/base images/silver ice/my23-street-triple-r-silver-ice-rhs-1920x1080.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/roadsters/street-triple-765/street-triple-765-r-2023'
      },
      {
        id: 'e2f74608-78a5-46fa-bca0-dd30fc3fc318',
        model: 'STREET TRIPLE 765 RS',
        price: 11295,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/roadsters-supersports/my23/street triple/765 rs variant/colours/carnival red/my23-street-triple-rs-carnival-red-rhs-1920x1080.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/roadsters/street-triple-765/street-triple-765-rs-2023'
      },
      {
        id: '52b79a27-2d5f-465b-8ae0-0fb8bada4b43',
        model: 'STREET TRIPLE 765 MOTO2™ EDITION',
        price: 13795,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/roadsters-supersports/my23/moto2 street triple/colours/triumph racing yellow/my23-street-triple-moto2-triumph-racing-yellow-rhs-1920x1080.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/roadsters/street-triple-moto2/street-triple-moto2-editon-2023'
      },
      {
        id: '6de37ca3-d386-4ffe-b4c8-bcbbdd50862b',
        model: 'TRIDENT 660',
        price: 7895,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/roadsters-supersports/my24/lg1_trident_660/trident_my24_jet black_rhs_1080px.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/roadsters/trident/trident-660'
      },
      {
        id: '0b8ad5ab-3c2e-4062-b5b8-b203a7a719cf',
        model: 'STREET TRIPLE R',
        price: 9495,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/roadsters-supersports/my23 colours/street triple r/street-triple-r_my22_matt-carbon-black_rhs_1080px.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/roadsters/street-triple/r'
      },
      {
        id: 'f590ea55-943f-42e9-ba4e-dc5718948bf7',
        model: 'STREET TRIPLE R LOW',
        price: 9495,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/roadsters-supersports/my23 colours/street triple r low/street-triple-r-_my22_matt-carbon-black_rhs_1080px.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/roadsters/street-triple/r-low'
      },
      {
        id: 'bb6d4b9d-07e2-4209-9ca7-d8d2a5b17860',
        model: 'STREET TRIPLE RS',
        price: 10995,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/roadsters-supersports/my23 colours/street triple rs/street-triple-rs_my22_carbon-black_rhs_1080px.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/roadsters/street-triple/rs'
      },
      {
        id: '4e893653-a830-4a84-a0b8-4932d7bd8167',
        model: 'SPEED TRIPLE 1200 RS',
        price: 15795,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/roadsters-supersports/my24/pb1_speed_triple_rs/speed_triple_my24_carnival_red_rhs_1080px.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/roadsters/speed-triple-1200/speed-triple-1200-rs'
      },
      {
        id: '02e5e86e-8b34-493e-b2ca-b39145a1a722',
        model: 'SPEED TRIPLE 1200 RR',
        price: 17950,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/roadsters-supersports/my22/speed triple 2022/base images/crystal white-storm grey/speed-triple-1200-rr-crystal-white-storm-grey-updates-size-rhs-1080.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/roadsters/speed-triple-1200-rr/speed-triple-1200-rr'
      },
      {
        id: '04ca9ae0-cf15-4174-bcf2-a91209aab064',
        model: 'ROCKET 3 R',
        price: 21995,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/rocket 3/my23/rocket 3 r/rocket-3-r_my23_matt-silver-ice_rhs_1080px.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/rocket-3/rocket-3/rocket-3-r'
      },
      {
        id: 'ca71d893-4420-491a-9bd4-52a1a25a6167',
        model: 'ROCKET 3 GT',
        price: 22695,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/rocket 3/my23/rocket 3 gt/rocket-3-gt_my23_carnival-red_rhs_1080px.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/rocket-3/rocket-3/rocket-3-gt'
      },
      {
        id: '7b37c81a-5f62-46de-a176-fbdc4495ec98',
        model: 'ROCKET 3 R 221',
        price: 0,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/modern-classics/2022 special editions/rocket 3 r 221/base images/my22-rocket-3-r-221-rhs-1080.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/rocket-3/rocket-3/rocket-3-r-221-edition-2022'
      },
      {
        id: '64b80272-2d9b-43af-8ee6-a082a33b649d',
        model: 'ROCKET 3 GT CHROME EDITION',
        price: 23595,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/rocket 3/my23/chrome editions/rocket 3 gt/base images/legacy/rocket-3-gt-chrome-edition-rhs-1920x1080.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/rocket-3/rocket-3/rocket-3-gt-chrome-edition-2023'
      },
      {
        id: 'f1e314a4-398a-4160-bd16-fd798017c709',
        model: 'ROCKET 3 R CHROME EDITION',
        price: 22895,
        images: [
          {
            url: 'https://media.triumphmotorcycles.co.uk/image/upload/q_auto/SitecoreMediaLibrary/media-library/images/motorcycles/rocket 3/my23/chrome editions/rocket 3 r/colours/legacy/rocket-3-r-chrome-edition-rhs-1920x1080.png',
            isMainImage: true
          }
        ],
        url: '/motorcycles/rocket-3/rocket-3/rocket-3-r-chrome-edition-2023'
      }
    ]
  },

  getModels() {
    return Promise.resolve(this.getModelsData())
  }
}
