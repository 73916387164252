<template>
  <div class="input-group-addon form-control" :class="[width ? `!w-${width}` : '', { 'read-only': readonly }]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    width: {
      type: String,
      default: 'auto'
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {}
  }
}
</script>

<style></style>
