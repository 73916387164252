<template>
  <form @submit.prevent="handleSubmit">
    <slot></slot>
  </form>
</template>

<script setup>
const emits = defineEmits(['submit'])

const handleSubmit = event => {
  emits('submit', event)
}
</script>
