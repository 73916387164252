<template>
  <div v-click-away="clickAway">
    <div class="flex">
      <!-- <div class="p-4">&nbsp;</div> -->
      <div class="max-w-[600px]">
        <Calendar
          v-model="dates"
          inline
          :selection-mode="selectionMode"
          :number-of-months="numberOfMonths"
          :max-date="maxDate"
          breakpoint="1000"
          @click.stop
        />
      </div>
    </div>
    <div class="flex justify-between p-4 pt-0 items-center">
      <div class="text-secondary">{{ inputString ? inputString : '&nbsp;' }}</div>
      <div class="flex gap-4">
        <Button label="Cancel" text severity="secondary" @click="closePicker" />
        <Button label="Apply" severity="primary" @click="applyChanges" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, inject } from 'vue'
import Calendar from 'primevue/calendar'
import Button from '@/components/button/Button.vue'

export default {
  components: {
    Calendar,
    Button
  },
  props: {
    modelValue: {
      type: null,
      required: true
    },
    selectionMode: {
      type: String,
      default: 'single'
    },
    maxDate: {
      type: Date,
      default: null
    },
    numberOfMonths: {
      type: Number,
      default: 1
    }
  },
  emits: ['update:modelValue', 'changeApplied'],
  setup(props, { emit }) {
    const initDates = ref(props.modelValue)
    const dates = ref(props.modelValue)
    const inputString = ref(null)

    const closeDropdown = inject('closeDropdown')

    watch(dates, newValue => {
      emit('update:modelValue', newValue)
    })

    function applyChanges() {
      emit('changeApplied', dates.value)
      initDates.value = dates.value
      closeDropdown()
    }

    function closePicker() {
      console.log('closePicker()')

      // Set dates back to initDates
      dates.value = initDates.value
      emit('update:modelValue', initDates.value)

      // Close the dropdown
      closeDropdown()
    }

    function clickAway() {
      console.log('clickAway()')
      closePicker()
    }

    return { dates, inputString, applyChanges, initDates, closePicker, clickAway }
  }
}
</script>

<style>
.p-datepicker {
  border: none;
}
</style>
