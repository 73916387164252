<template>
  <PageHeader
    :title="$t('ui.common.user', 2)"
    breadcrumb
    :items="[
      { label: $t('ui.entities.setting', 2), to: '/settings' },
      { label: $t('ui.common.user', 2), to: '/settings/users' }
    ]"
  >
    <template #right>
      <Button
        v-if="$can('create_users')"
        :label="$t('ui.crud.new', { item: $t('ui.common.user') })"
        severity="primary"
        icon="plus"
        @click="newUserModalIsOpen = true"
      />
    </template>
  </PageHeader>

  <Card>
    <UsersTable v-model="usersComputed" :is-loading="usersAreLoading" @user-updated="userDataUpdated" />
  </Card>

  <teleport to="#modals-container">
    <Modal
      :is-open="newUserModalIsOpen"
      :buttons="[]"
      :has-unsaved-changes="newUserHasUnsavedChanges"
      :is-confirming="newUserModalIsSubmitting"
      min-width="550"
      @close-modal="(newUserModalIsOpen = false), (newUserHasUnsavedChanges = false)"
    >
      <NewUser
        :roles-list="rolesList"
        @form-updated="newUserHasUnsavedChanges = true"
        @is-submitting="
          value => {
            newUserModalIsSubmitting = value
          }
        "
        @completed="handleNewUserSubmitCompleted"
      />
    </Modal>
  </teleport>
</template>

<script>
import { ref, computed } from 'vue'
import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import Button from '@/components/button/Button.vue'
import Modal from '@/components/modal/Modal.vue'

import UsersTable from '@/components/unique/tables/UsersTable.vue'
import NewUser from '@/components/unique/forms/NewUser.vue'

import useApiRequest from '@/composables/useApiRequest'
import { useUserStore } from '@/stores/UserStore'

export default {
  components: {
    PageHeader,
    Card,
    UsersTable,
    Button,
    Modal,
    NewUser
  },
  setup() {
    const userStore = useUserStore()

    const newUserModalIsOpen = ref(false)
    const newUserHasUnsavedChanges = ref(false)
    const newUserModalIsSubmitting = ref(false)

    const users = useApiRequest()
    const casl = useApiRequest()

    const userQueryparams = ref({
      userType: 'all',
      showDisabled: true
    })

    if (userStore.details.type == 2) {
      const dealershipIDs = userStore.accessAllowed.dealerships.map(dealership => dealership.id)
      const dealershipIDsString = dealershipIDs.join(',')

      userQueryparams.value.userType = 'externalUsers'
      userQueryparams.value.showDisabled = false
      userQueryparams.value.level = userStore.details.level
      userQueryparams.value.dealerships = dealershipIDsString
      // userQueryparams.value.dealerships = 3
    }

    casl.sendRequest({ endpoint: '/v1/casl' }).then(() => {
      console.log(userQueryparams.value)
      users
        .sendRequest({
          endpoint: '/v1/users',
          params: userQueryparams.value
        })
        .then(() => {})
    })

    const usersComputed = computed(() => {
      let usersArray = users.data.value
      if (!usersArray) {
        return []
      }
      console.log(usersArray)
      // Loop through each role that the user has, and replace array of ids with array of roles
      usersArray.forEach(user => {
        user.rolesNamed = []
        user.roles.forEach(userRole => {
          user.rolesNamed.push(casl.data.value.roles.find(role => role.id == userRole))
        })

        switch (user.userType) {
          case 1:
            user.userTypeNamed = 'Internal'
            break
          case 2:
            user.userTypeNamed = 'External'
            break
          case 3:
            user.userTypeNamed = 'SAAS'
            break

          default:
            user.userTypeNamed = 'Unknown'
            break
        }

        //Convert user access to nicer format
        let convertedAccess = {}

        if (user.access.regions == '*') {
          convertedAccess.regions = []
          convertedAccess.regionsAll = true
        } else {
          convertedAccess.regions = user.access.regions
          convertedAccess.regionsAll = false
        }

        if (user.access.manufacturers == '*') {
          convertedAccess.manufacturers = []
          convertedAccess.manufacturersAll = true
        } else {
          convertedAccess.manufacturers = user.access.manufacturers
          convertedAccess.manufacturersAll = false
        }

        if (user.access.groups == '*') {
          convertedAccess.groups = []
          convertedAccess.groupsAll = true
        } else {
          convertedAccess.groups = user.access.groups
          convertedAccess.groupsAll = false
        }

        if (user.access.dealerships == '*') {
          convertedAccess.dealerships = []
          convertedAccess.dealershipsAll = true
        } else {
          convertedAccess.dealerships = user.access.dealerships
          convertedAccess.dealershipsAll = false
        }

        user.access = convertedAccess
      })

      return usersArray
    })

    const rolesList = computed(() => {
      let rolesArray = casl.data.value?.roles
      let sessionUserLevel = userStore.details.level

      if (sessionUserLevel == null) {
        //User is of type: internal, so allow all roles to be in the list
        return rolesArray
      }

      // remove roles that the user doesn't have access to
      // levels: region, manufacturer, group, dealership. allow access to all roles that are equal or lower than the user's level
      rolesArray = rolesArray?.filter(role => role.level <= sessionUserLevel)
      return rolesArray
    })

    const handleNewUserSubmitCompleted = () => {
      newUserModalIsOpen.value = false
      newUserHasUnsavedChanges.value = false
      newUserModalIsSubmitting.value = false
      users.sendRequest({
        endpoint: '/v1/users',
        params: userQueryparams.value
      })
    }

    function userDataUpdated(userId, newData) {
      console.log('User data updated')
      console.log(userId, newData)

      let user = users.data.value.find(user => user.id == userId)
      // Merge updated data with existing data
      Object.assign(user, newData)
    }

    return {
      usersAreLoading: users.isLoading,
      usersComputed,
      newUserModalIsOpen,
      newUserHasUnsavedChanges,
      newUserModalIsSubmitting,
      casl,
      rolesList,
      handleNewUserSubmitCompleted,
      userDataUpdated
    }
  }
}
</script>

<style></style>
