import { io } from 'socket.io-client'
import { ref } from 'vue'

import * as Sentry from '@sentry/vue'

import { useUserStore } from '@/stores/UserStore'

import notification from '@/utilities/notification.js'

// console.log('Running Socket Service File')
// console.log('Socket Server: ' + import.meta.env.VITE_SOCKET_IO_SERVER_URL)

// const socket = io("http://localhost:30000", { transports: ["websocket"] }) //INFO: use this for dev server testing
const socket = io(import.meta.env.VITE_SOCKET_IO_SERVER_URL, {
  transports: ['websocket'],
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: 50
})

let usersPresence = ref([])
let socketIsConnected = ref(false)

socket.on('connect', () => {
  // console.log(`Socket IO Connected! SocketID: ${socket.id}`)

  const userStore = useUserStore()

  //Update socket status in user store
  userStore.updateSocketStatus(socket.connected)
  // userStore.registerUserSocket()
  socketIsConnected.value = socket.connected

  //Listen to the socket.io heartbeat for debugging
  // socket.io.engine.on('heartbeat', function () {
  //   console.log('heartbeat')
  // })

  socket.on('users-presence', users => {
    // console.log('User Presence EMITTED from server')
    usersPresence.value = users
    // console.log('SERVICE: ' + usersPresence.value)
    userStore.updateUsersPresence(usersPresence.value)
  })

  socket.on('user-clear-localstorage', () => {
    // console.log('User Clear Local Storage request received')

    localStorage.clear()
    window.location.reload()
  })

  socket.on('user-signout', () => {
    // console.log('User SignOut request received')

    userStore.signOut()
  })
})

socket.on('disconnect', reason => {
  console.log(`Socket IO disconnected! Reason: ${reason}`)
  // Sentry.captureMessage(`Socket IO disconnected! Reason: ${reason}`, 'debug')

  const userStore = useUserStore()

  //Update socket status in user store
  userStore.updateSocketStatus(socket.connected)
  socketIsConnected.value = socket.connected

  if (userStore.details.type === 1) {
    notification('Reconnecting', 'Disconnected from server', 'info', null, null, false)
  } else {
    // notification('Disconnected from server', 'Attempting to reconnect', 'warning')
  }
  //Remove all users from user store
  usersPresence.value = []
  userStore.updateUsersPresence(usersPresence.value)
})

// Focus the first item when the dropdown is opened
// watch(socketIsConnected => {
//   if (!socketIsConnected) {

//   }
// })

export default socket
