import axios from 'axios'

// console.log('counters.js')

//Function to get todo counter
async function fetchTodoCounter(userId) {
  try {
    // console.log(`${import.meta.env.VITE_API_BASE_URL}/v1/todos?userID=${userId}&outstandingOnly=true`)
    const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/v1/todos?userID=${userId}&outstandingOnly=true`, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })

    return response.data.data.length
  } catch (error) {
    console.error('Unable to fetch outstanding todos')
    // Sentry.captureException(new Error('Unable to fetch outstanding todos'))
    return 0
  }
}

export { fetchTodoCounter }
