<template>
  <!-- parentValue: {{ modelValue }}<br />
  flatpickrValue: {{ flatpickrValue }}<br />
  config: {{ config }}<br /> -->

  <div class="flatpickr-wrapper">
    <Icon class="flatpickr-icon input-icon" :type="iconToShow" />
    <flat-pickr
      v-model="flatpickrValue"
      :config="config"
      class="flatpickr form-control w-full"
      :class="{ 'has-error': hasError, readonly: readonly }"
      :placeholder="placeholder"
      :disabled="readonly"
      @on-change="updateValue"
    />
    <Icon
      v-if="flatpickrValue && canClear"
      class="flatpickr-clear-button cursor-pointer text-sm"
      type="xmark"
      fa-style="far"
      @click="clearDate"
    />
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

import Icon from '@/components/icon/Icon.vue'

export default {
  components: { flatPickr, Icon },
  props: {
    modelValue: {
      type: String,
      default: '',
      required: true
    },
    config: {
      type: Object,
      default: () => {}
    },
    placeholder: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    canClear: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const flatpickrValue = ref(props.modelValue)

    const updateValue = (selectedDates, dateStr) => {
      // console.log('updateValue to: ', dateStr)
      context.emit('update:modelValue', dateStr)
    }

    const iconToShow = computed(() => {
      let hasTime = props.config.enableTime
      let hasDate = !props.config.noCalendar
      let icon = 'calendar'

      if (hasTime && hasDate) {
        icon = 'calendar-clock'
      }
      if (hasTime && !hasDate) {
        icon = 'clock'
      }
      if (!hasTime && hasDate) {
        icon = 'calendar'
      }

      return icon
    })

    // enableTime: true,
    //   noCalendar: true,

    const clearDate = () => {
      console.log('clearDate')
      context.emit('update:modelValue', null)
      flatpickrValue.value = null
    }

    return { flatpickrValue, updateValue, iconToShow, clearDate }
  }
}
</script>

<style>
/* Wrapper for icon */
.flatpickr-wrapper {
  display: block;
}

.flatpickr-wrapper .form-control {
  padding-left: 35px;
}

.flatpickr-wrapper .flatpickr-icon {
  position: absolute;
  top: 7px;
  left: 10px;
}
.flatpickr-wrapper .flatpickr-clear-button {
  position: absolute;
  top: 9px;
  right: 10px;
}
</style>
