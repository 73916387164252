import { useToast } from 'vue-toastification'
import CustomToast from '@/components/toast/Toast.vue'

/**
 * Displays a notification toast with the given parameters.
 *
 * @param {string} title - The title of the notification.
 * @param {string} text - The text of the notification.
 * @param {string} variant - The variant of the notification (success, info, danger, warning).
 * @param {string} icon - The icon of the notification.
 * @param {string} link - The link to be opened when the notification is clicked.
 * @param {boolean} [persist=false] - Whether the notification should persist or disappear after a timeout.
 */
export default function notification(title, text, variant, icon, link, persist = false) {
  const toast = useToast()
  if (variant) {
    switch (variant) {
      case 'success':
        icon = 'check'
        break
      case 'info':
        icon = 'info'
        break
      case 'danger':
        icon = 'exclamation'
        break
      case 'warning':
        icon = 'triangle-exclamation'
        break
      default:
        icon = 'bell'
        break
    }
  }

  const config = {
    position: 'top-center',
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: 'button',
    icon: false,
    rtl: false,
    transition: 'Vue-Toastification__fade'
  }

  if (persist) {
    config.timeout = false
    config.closeButton = false
    config.closeOnClick = false
  }

  toast(
    {
      component: CustomToast,
      props: {
        title: title,
        text: text,
        icon: icon,
        variant: variant,
        link: link
      },
      listeners: {
        click: () => console.log('Clicked!')
      }
    },
    config
  )
}
