<template>
  <div ref="btnGroup" class="btn-group">
    <slot />
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue'

export default {
  setup() {
    const btnGroup = ref(null)

    const addClasses = () => {
      const buttons = btnGroup.value.querySelectorAll('.btn')
      if (buttons.length > 0) {
        buttons.forEach((btn, index) => {
          btn.classList.remove('first-btn', 'middle-btn', 'last-btn')
          if (index === 0) {
            btn.classList.add('first-btn')
          } else if (index === buttons.length - 1) {
            btn.classList.add('last-btn')
          } else {
            btn.classList.add('middle-btn')
          }
        })
      }
    }

    onMounted(addClasses)
    watch(() => btnGroup.value, addClasses)

    return {
      btnGroup
    }
  }
}
</script>

<style>
.btn-group {
  position: relative;
  display: inline-flex;
}

.btn-group .btn.first-btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.btn-group .btn.last-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group .btn.middle-btn {
  border-radius: 0;
  border-right: none;
}
</style>
