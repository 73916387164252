// TODO: Merge this component with InvoiceItem.vue, as 95% of the code is the same
<template>
  <div class="invoice-item invoice-item-new grid grid-cols-12 gap-4">
    <div class="col-span-6">
      <Dropdown
        v-model="itemSelected"
        filter
        :options="items"
        option-label="label"
        placeholder="Select an item to add"
        class="w-full"
        @change="onItemSelected"
      >
        <template #option="slotProps">
          <div class="w-full flex gap-4 justify-between">
            <div>
              <div>{{ slotProps.option.label }}</div>
              <div class="text-muted max-w-xs whitespace-normal">{{ slotProps.option.description }}</div>
            </div>
            <div>£{{ slotProps.option.rate }}</div>
          </div>
        </template>
      </Dropdown>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'

import Dropdown from 'primevue/dropdown'
import useApiRequest from '@/composables/useApiRequest'

export default {
  components: {
    Dropdown
  },
  props: {
    invoiceTemplateId: {
      type: Number,
      required: true
    },
    dealershipId: {
      type: Number,
      required: true
    }
  },
  emits: ['item-added'],
  setup(props, { emit }) {
    const itemsRequest = useApiRequest()
    const itemSelected = ref(null)

    const state = ref({
      dealershipId: props.dealershipId,
      itemId: null,
      name: null,
      description: null,
      saleRate: null,
      overrideRate: null,
      quantity: 1,
      discountUnit: 'percent',
      discountValue: null
    })

    itemsRequest
      .sendRequest({
        endpoint: '/v1/finance/invoice-items-catalog'
      })
      .then(response => {
        items.value = response.data.data
      })

    // convert items to a format that the multiselect component can understand (label, value)
    const items = computed(() => {
      if (!itemsRequest.data.value) return []
      return itemsRequest.data.value.map(item => {
        return {
          label: item.name,
          value: item.id,
          description: item.description,
          rate: item.saleRate
        }
      })
    })

    function onItemSelected() {
      // console.log(itemSelected.value)

      // Add new item to the DP using useApiRequest
      useApiRequest()
        .sendRequest({
          endpoint: `/v1/finance/invoice-templates/${props.invoiceTemplateId}/items`,
          method: 'POST',
          data: JSON.stringify({
            dealershipId: props.dealershipId,
            itemId: itemSelected.value.value,
            overrideRate: null,
            quantity: 1,
            discountUnit: 'percent',
            discountValue: null
          })
        })
        .then(response => {
          if (response.data.response.status === 'error' || response.data.data.id <= 0) {
            // console.log('Error adding item to invoice')
            return
          }

          // Find the full item object from the items array
          let catalogItem = itemsRequest.data.value.find(item => item.id === itemSelected.value.value)

          // Then, build the new item, and emit an event to the parent component to add the new item to the items array
          let item = {
            id: response.data.data.id,
            invoiceTemplateId: props.invoiceTemplateId,
            invoiceTemplateDealershipId: props.dealershipId,
            quantity: 1,
            discountUnit: 'percent',
            discountValue: null,
            catalogItemId: catalogItem.id,
            overrideRate: null,
            catalogItem: catalogItem
          }

          // console.log('item', item)

          emit('item-added', item)

          // Reset the selected item
          itemSelected.value = null
        })
    }

    return { items, itemSelected, onItemSelected, state }
  }
}
</script>

<style>
.invoice-item-new {
  background-color: var(--bg-secondary);
}
</style>
