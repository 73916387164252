<template>
  <div class="term-tracker flex flex-col">
    <div class="font-medium mb-5 flex justify-between w-full">
      <div>{{ numberFormatter(calculated.elapsedPercent, 2) }}% Elapsed</div>
      <!-- <div v-if="dayjs(endDate).isAfter(dayjs())">{{ dayjs(endDate).fromNow(true) }} left</div> -->
      <div v-if="dayjs(endDate).isAfter(dayjs())">{{ getRelativeTimeInMonthsAndDays(endDate) }}</div>
      <div v-else>Ended</div>
    </div>
    <div class="term-bar">
      <!-- <div v-tippy="{ content: 'Start Date' }" class="term-track-marker" :style="'left:' + 0 + '%;'">&nbsp;</div> -->
      <div
        v-if="calculated.twelvePercent <= 45 || calculated.twelvePercent >= 55"
        v-tippy="{ content: calculated.mtDate }"
        class="term-track-marker"
        :style="'left:' + calculated.mtPercent + '%;'"
      >
        MT
      </div>
      <div v-tippy="{ content: calculated.twelveDate }" class="term-track-marker" :style="'left:' + calculated.twelvePercent + '%;'">
        12m
      </div>
      <div v-tippy="{ content: calculated.sixDate }" class="term-track-marker" :style="'left:' + calculated.sixPercent + '%;'">6m</div>
      <!-- <div v-tippy="{ content: 'End Date' }" class="term-track-marker" :style="'left:' + 100 + '%;'">&nbsp;</div> -->
      <div class="term-track">
        <div class="term-track-progress" :style="{ width: calculated.elapsedPercent + '%' }"></div>
      </div>
      <div class="flex justify-between mt-2">
        <div>
          <div class="text-xs text-quaternary -mb-1">Start Date</div>
          <div>{{ dayjs(startDate).format('DD/MM/YYYY') }}</div>
        </div>
        <div class="text-right">
          <div class="text-xs text-quaternary -mb-1">End Date</div>
          <div>{{ dayjs(endDate).format('DD/MM/YYYY') }}</div>
        </div>
      </div>
    </div>
    <!-- <div class="ml-3">
      <div class="text-xs text-quaternary -mb-1">Remaining</div>
      <div>{{ calculated.remaining }}</div>
    </div> -->
  </div>
</template>

<script>
import { computed } from 'vue'

import dayjs from 'dayjs'

export default {
  components: {},
  props: {
    startDate: {
      type: String,
      default: null
    },
    endDate: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const calculated = computed(() => {
      let startDate = dayjs(props.startDate)
      let endDate = dayjs(props.endDate)

      //Calculate term in days
      let termInDays = endDate.diff(startDate, 'day')

      let elapsedPercent = 100 - (endDate.diff(dayjs(), 'day') / termInDays) * 100
      if (elapsedPercent > 100) {
        // if elapsed is greater than 100%, dont allow the track to grow more than 100%
        elapsedPercent = 100
      }

      let mtDate = dayjs(startDate)
        .add(termInDays / 2, 'day')
        .format('DD/MM/YYYY')
      let mtPercent = 50
      let twelveDate = dayjs(endDate).subtract(12, 'month').format('DD/MM/YYYY')
      let twelvePercent = ((termInDays - 365) / termInDays) * 100
      let sixDate = dayjs(endDate).subtract(6, 'month').format('DD/MM/YYYY')
      let sixPercent = ((termInDays - 182.5) / termInDays) * 100

      let remaining = dayjs().to(endDate, true)

      return { elapsedPercent, mtDate, mtPercent, twelveDate, twelvePercent, sixDate, sixPercent, remaining }
    })

    const getRelativeTimeInMonthsAndDays = date => {
      const now = dayjs()
      const targetDate = dayjs(date)
      const diffInMonths = now.diff(targetDate, 'month')
      const remainingDays = now.diff(targetDate.add(diffInMonths, 'month'), 'day')

      const monthsText = `${Math.abs(diffInMonths)}m`
      const daysText = `${Math.abs(remainingDays)}d`

      const suffix = targetDate.isBefore(now) ? 'ago' : 'left'

      // if 6m or less, show days, else only show months
      if (diffInMonths <= 6) {
        return `${monthsText} ${daysText} ${suffix}`
      }

      return `${monthsText} ${suffix}`
    }

    return { calculated, getRelativeTimeInMonthsAndDays }
  }
}
</script>

<style>
.term-bar {
  width: 100%;
  margin-top: var(--s-3);
  margin-right: var(--s-3);
  position: relative;
}

.term-track {
  background-color: var(--bg-quaternary);
  height: 7px;
  border-radius: var(--rounded-md);
  position: relative;
}

.term-track-progress {
  border-radius: var(--rounded-md);
  height: 100%;
  width: 50%;
  background-color: var(--bg-brand-solid);
}

.term-track-marker {
  border-left: 2px solid var(--bg-quaternary);
  position: absolute;
  top: -20px;
  text-align: right;
  font-size: var(--text-xs);
  padding-left: var(--s-1);
  padding-bottom: var(--s-1);
  color: var(--text-quaternary);
}
</style>
