<template>
  <div class="mileage-tracker mt-5 flex justify-between">
    <div class="mileage-bar">
      <div class="mileage-track">
        <div class="mileage-track-marker-line" :style="'left:' + calculated.expectedPercent + '%;'"></div>
        <div
          class="mileage-track-progress"
          :class="odometerData.current > calculated.expected ? 'bg-error-solid' : 'bg-brand-solid'"
          :style="{ width: calculated.currentPercent + '%' }"
        ></div>
      </div>
      <div class="mileage-track-marker" :style="calculated.expectedPosition">
        <div class="text-xs text-quaternary -mb-1">Expected</div>
        <div>
          {{
            numberFormatter(
              calculated.expected > odometerData.allowanceTotal ? odometerData.allowanceTotal : calculated.expected,
              'normal',
              0
            )
          }}
          mi
        </div>
      </div>
    </div>
    <div style="min-width: 100px; height: fit-content" class="flex">
      <InlineEdit @edit-complete="mileageUpdated()">
        <template #default>
          <div>
            <div class="text-xs text-quaternary -mb-1">Current</div>
            <div>{{ numberFormatter(odometerData.current, 'normal', 0) }} mi</div>
          </div>
          <div class="mt-2 ml-2">
            <Icon type="pencil" class="hover:text-primary cursor-pointer" />
          </div>
        </template>
        <template #edit>
          <Input v-model="odometerData.current" />
        </template>
      </InlineEdit>
    </div>
  </div>
</template>

<script>
import { toRefs } from 'vue'
import { computed } from 'vue'

import Icon from '@/components/icon/Icon.vue'
import InlineEdit from '@/components/inlineEdit/InlineEdit.vue'
import Input from '@/components/forms/Input.vue'

export default {
  components: {
    Icon,
    InlineEdit,
    Input
  },
  props: {
    odometerData: {
      type: Object,
      default: null
    },
    agreementID: {
      type: Number,
      default: null
    }
  },
  emits: ['mileageUpdated'],
  setup(props, { emit }) {
    const { odometerData } = toRefs(props)

    const calculated = computed(() => {
      let expected = 0
      let currentPercent = 0
      let expectedPercent = 0
      let expectedPosition = 'left'

      if (odometerData.value.expected < odometerData.value.allowanceTotal) {
        //If expected is less than total allowance
        expectedPercent = Math.round((odometerData.value.expected / odometerData.value.allowanceTotal) * 100)
        expected = odometerData.value.expected
      } else {
        expectedPercent = 100
        expected = odometerData.value.allowanceTotal
      }

      if (odometerData.value.current > odometerData.value.allowanceTotal) {
        currentPercent = 100
      } else {
        currentPercent = Math.round((odometerData.value.current / odometerData.value.allowanceTotal) * 100)
      }

      if (expectedPercent < 50) {
        //If less than 50%
        expectedPosition = 'left: ' + expectedPercent + '%;text-align:left;'
      }
      if (expectedPercent > 50 && expectedPercent <= 100) {
        // if between 50% and 100%
        expectedPosition = 'right: ' + (100 - expectedPercent) + '%;text-align:right;'
      }
      if (expectedPercent >= 100) {
        //If 100% or more
        expectedPosition = 'right: 0%;text-align:right;'
      }

      return { expected, currentPercent, expectedPercent, expectedPosition }
    })

    function mileageUpdated() {
      emit('mileageUpdated')
    }
    return { calculated, mileageUpdated }
  }
}
</script>

<style>
.mileage-bar {
  width: 100%;
  margin-top: 10px;
  margin-right: 10px;
  position: relative;
  height: 45px;
}

.mileage-track {
  background-color: var(--bg-tertiary);
  height: 7px;
  border-radius: var(--rounded-md);
  position: relative;
}

.mileage-track-marker-line {
  border-left: 2px solid var(--gray-500);
  height: 15px;
  position: absolute;
  top: -3px;
  left: 80%;
}

.mileage-track-progress {
  border-radius: var(--rounded-full);
  height: 100%;
  width: 50%;
}

.mileage-track-marker {
  width: 80px;
  position: absolute;
  margin-top: 5px;
  right: 20%;
  text-align: right;
}
</style>
