<template>
  <div>
    <img
      class="absolute top-0 right-0 w-full select-none"
      style="z-index: -1"
      draggable="false"
      :src="$cdnBaseUrl + '/img/graphics/dashboard-gradient.png'"
    />
  </div>
  <div>
    <component :is="dashboardComponent" />
  </div>
</template>

<script>
import { shallowRef, onMounted } from 'vue'
import { useRoute } from 'vue-router'

import { getDashboardByRoute } from '@/utilities/dashboards'
import { watch } from 'vue'

export default {
  setup() {
    const route = useRoute()
    const dashboardComponent = shallowRef(null)

    const loadDashboard = async () => {
      const pathArray = route.params.pathMatch
      if (!pathArray) {
        return
      }
      let path = '/dashboard/' + pathArray.join('/')

      const dashboardObj = getDashboardByRoute(path)

      try {
        const dashboard = await import(`./dashboards/${dashboardObj.componentFolder}/${dashboardObj.componentName}.vue`)
        dashboardComponent.value = dashboard.default
      } catch (error) {
        console.error(`Error loading dashboard: ${error}`)
      }
    }

    onMounted(loadDashboard)

    watch(() => route.params.pathMatch, loadDashboard)

    return { dashboardComponent }
  }
}
</script>
