<template>
  <PageHeader
    :title="$t('ui.entities.reloop.reloop-submission', 2)"
    breadcrumb
    :items="[
      { label: $t('ui.entities.report.label', 2), to: '/reports' },
      { label: $t('ui.entities.reloop.reloop-submission', 2), to: '/reports/reloop/submissions' }
    ]"
  >
    <template #right>
      <div class="flex items-center gap-4">
        <ButtonGroup v-if="userStore.details.type === 1">
          <Button
            icon="file-csv"
            label="Export Answers"
            :is-loading="submissionsDownloading"
            outlined
            severity="secondary"
            @click="exportAnswers('byDateRange')"
          />
          <Dropdown position="bottom-left">
            <template #triggerContent>
              <Button icon="chevron-down" outlined severity="secondary" />
            </template>
            <DropdownItem @click="exportAnswers('allAnswers')">All</DropdownItem>
            <DropdownItem @click="exportAnswers('allAnswers')">Within date range</DropdownItem>
          </Dropdown>
        </ButtonGroup>
        <DatePicker
          v-model="dateRange"
          selection-mode="range"
          position="bottom-right"
          :max-date="new Date()"
          :number-of-months="useBreakPoints({ xs: 1, sm: 2 })"
          @change-applied="onDateRangeChange"
        />
      </div>
    </template>
  </PageHeader>
  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      filter-display="menu"
      :global-filter-fields="['id', 'customerName', 'dealershipId', 'dealershipName']"
      data-key="id"
      :value="reloops"
      sort-field="createdAt"
      :sort-order="-1"
      removable-sort
      :paginator="true"
      :rows="20"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-reloop-submissions"
      state-storage="session"
      :loading="reloopsApiRequest.isLoading.value"
    >
      <template #empty>No records found</template>
      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>
        </div>
      </template>

      <!-- <Column selection-mode="multiple" header-style="width: 3em"></Column> -->

      <!-- <Column field="id" header="#" :sortable="true">
        <template #body="{ data }">
          <a class="link" :href="'/agreement/' + data.agreementId + '/appointments/' + data.appointmentId">{{ data.id }}</a>
        </template>
      </Column> -->

      <Column field="customerName" header="Customer" :sortable="true">
        <template #body="{ data }">
          <a class="link" :href="'/agreement/' + data.agreementId + '/appointments/' + data.appointmentId">{{ data.customerName }}</a>
        </template>
      </Column>

      <Column field="dealershipId" header="Dealership" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          {{ data.dealershipName }}
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="dealershipsList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Any"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>

      <Column field="attended" header="Attended" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">{{ data.attended ? 'Yes' : 'No' }}</template>
        <template #filter="{ filterModel }">
          <PrimevueDropdown
            v-model="filterModel.value"
            :options="[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' }
            ]"
            option-value="value"
            option-label="label"
            placeholder="All"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </PrimevueDropdown>
        </template>
      </Column>

      <Column field="scores.totals.totalPercent" header="Score" :sortable="true">
        <template #body="{ data }">
          <Chip v-if="data.scores.totals.totalPercent != null" :severity="getScoreColor(data.scores.totals.totalPercent)"
            >{{ data.scores.totals.totalPercent }}%</Chip
          >
        </template>
      </Column>

      <Column field="satisfaction" header="Satisfaction" :sortable="true">
        <template #body="{ data }">
          <Chip v-if="data.satisfaction != null" :severity="getScoreColor(data.satisfaction * 10)">{{ data.satisfaction }}</Chip>
        </template>
      </Column>

      <Column field="alertManagement" header="Alert" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <Icon v-if="data.alertManagement" v-tippy="'Customer requested to alert management'" type="warning" class="text-warning" />
        </template>
        <template #filter="{ filterModel }">
          <PrimevueDropdown
            v-model="filterModel.value"
            :options="[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' }
            ]"
            option-value="value"
            option-label="label"
            placeholder="All"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </PrimevueDropdown>
        </template>
      </Column>

      <Column field="createdBy" header="Submitted By" :sortable="true" class="capitalize" />

      <Column field="createdAt" header="Submitted At" :sortable="true">
        <template #body="{ data }">
          <span v-tippy="data.createdAt">{{ dayjs(data.createdAt).format('ll') }}</span>
        </template>
      </Column>
    </DataTable>
  </Card>
</template>

<script>
import { ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import Input from '@/components/forms/Input.vue'
import Button from '@/components/button/Button.vue'
import Chip from '@/components/chip/Chip.vue'
import Icon from '@/components/icon/Icon.vue'
import DatePicker from '@/components/date-picker/DatePicker.vue'
import Dropdown from '@/components/dropdown/Dropdown.vue'
import DropdownItem from '@/components/dropdown/DropdownItem.vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'

import dayjs from 'dayjs'

import MultiSelect from 'primevue/multiselect'
import PrimevueDropdown from 'primevue/dropdown'

import { useUserStore } from '@/stores/UserStore'
import useApiRequest from '@/composables/useApiRequest'
import { useBreakPoints } from '@/composables/useWindowSize'

import axios from 'axios'
import notification from '@/utilities/notification.js'
import ButtonGroup from '@/components/button/ButtonGroup.vue'

export default {
  components: {
    PageHeader,
    Card,
    DataTable,
    Column,
    Input,
    Button,
    MultiSelect,
    Dropdown,
    PrimevueDropdown,
    DropdownItem,
    ButtonGroup,
    Chip,
    Icon,
    DatePicker
  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const userStore = useUserStore()

    const dt = ref(null)

    const dealershipsAllowed = ref(userStore.accessAllowed.dealerships)

    const dateRange = ref([dayjs().subtract(30, 'day').toDate(), dayjs().toDate()])

    const reloopsApiRequest = useApiRequest()

    const reloops = ref([])

    const filters = ref([])

    function initFilters() {
      filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        dealershipId: { value: null, matchMode: FilterMatchMode.IN },
        attended: { value: null, matchMode: FilterMatchMode.EQUALS },
        alertManagement: { value: null, matchMode: FilterMatchMode.EQUALS }
      }
    }
    initFilters()

    function clearFilters() {
      //remove from session storage
      sessionStorage.removeItem('dt-reloop-submissions')
      initFilters()
    }

    function setRouteFilters() {
      if (route.query && Object.keys(route.query).length > 0) {
        clearFilters()
        for (const [key, value] of Object.entries(route.query)) {
          if (key === 'dealerships') {
            // convert value (csl) string to array of numbers
            filters.value.dealershipId.value = value.split(',').map(Number)
            console.log('dealerships', filters.value.dealershipId.value)
          }
          if (key === 'alertManagement') {
            filters.value.alertManagement.value = value === 'true'
            console.log('alertManagement', filters.value.alertManagement.value)
          }
          if (key === 'attended') {
            filters.value.attended.value = value === 'true'
            console.log('attended', filters.value.attended.value)
          }
          if (key === 'dateRange') {
            dateRange.value = value.split(',').map(d => new Date(d))
            console.log('dateRange', dateRange.value)
          }
        }
      }
      // Remove url query params  using router replace
      router.replace({ query: {} })
    }

    setRouteFilters()

    function fetchReloops() {
      reloopsApiRequest
        .sendRequest({
          endpoint: '/v1/reloop',
          params: {
            startDate: dayjs(dateRange.value[0]).format('YYYY-MM-DD'),
            endDate: dayjs(dateRange.value[1]).format('YYYY-MM-DD'),
            dealerships: dealershipsAllowed.value.map(d => d.id).join(',')
          }
        })
        .then(response => {
          reloops.value = response.data.response.data
        })
    }
    fetchReloops()

    const dealershipsList = computed(() => {
      return dealershipsAllowed.value.map(d => {
        return {
          value: d.id,
          label: d.name
        }
      })
    })

    function getScoreColor(score) {
      if (score <= 70) {
        return 'danger'
      } else if (score <= 80) {
        return 'warning'
      } else {
        return 'success'
      }
    }

    function onDateRangeChange() {
      // Refetch reloops
      fetchReloops()
    }

    const submissionsDownloading = ref(null)

    async function exportAnswers(range) {
      try {
        submissionsDownloading.value = true

        let url = ''
        if (range == 'byDateRange') {
          url =
            import.meta.env.VITE_API_BASE_URL +
            '/v1/reloop/export?start=' +
            dayjs(dateRange.value[0]).format('YYYY-MM-DD') +
            '&end=' +
            dayjs(dateRange.value[1]).format('YYYY-MM-DD')
        } else if (range == 'allAnswers') {
          url = import.meta.env.VITE_API_BASE_URL + '/v1/reloop/export'
        }

        let headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
        let data = await axios(url, { withCredentials: false, headers, responseType: 'blob' })

        if (data.status != 200) {
          if (data.status === 401) {
            throw Error('401 Unauthorized')
          } else {
            throw Error('Error fetching data')
          }
        }

        //If everything is fine
        var fileURL = window.URL.createObjectURL(new Blob([data.data]))
        var fURL = document.createElement('a')

        fURL.href = fileURL
        fURL.setAttribute('download', 'reloop_submissions.csv')
        // fURL.setAttribute('target', '_blank');
        document.body.appendChild(fURL)

        fURL.click()
      } catch (err) {
        console.log(err.message)
        notification('Error', err.message)
      }
      submissionsDownloading.value = false
    }

    return {
      useBreakPoints,
      userStore,
      dateRange,
      onDateRangeChange,
      dt,
      reloopsApiRequest,
      reloops,
      filters,
      clearFilters,
      dealershipsList,
      getScoreColor,
      exportAnswers,
      submissionsDownloading
    }
  }
}
</script>

<style></style>
