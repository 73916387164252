<template>
  <div v-if="text" class="text-sm text-secondary px-[16px] mt-2">{{ text }}</div>
  <div class="px-3"><slot></slot></div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    text: {
      type: String,
      default: null
    }
  }
}
</script>

<style>
.dropdown-menu-item-separator {
  border-top: 1px solid var(--border-secondary);
}
</style>
