<template>
  <v-offline
    class="network-status-overlay"
    online-class="network-online"
    offline-class="network-offline"
    @detected-condition="onNetworkChange"
  >
    <template v-if="!online">
      <div class="flex w-full h-full justify-center items-center flex-col">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          height="200"
          viewBox="0 0 717.67004 516.8656"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <path
            d="M120.42945,496.14264a2.80742,2.80742,0,0,1-2.03464-4.86594l.19236-.76477q-.038-.09188-.07643-.18359A7.5391,7.5391,0,0,0,104.604,490.38c-2.27452,5.47809-5.17037,10.96552-5.8834,16.75773a22.30391,22.30391,0,0,0,.39152,7.67062A89.41957,89.41957,0,0,1,90.978,477.66962a86.3069,86.3069,0,0,1,.53535-9.6283q.44349-3.93127,1.23055-7.80652A90.45546,90.45546,0,0,1,110.682,421.89749a24.07259,24.07259,0,0,0,10.0118-10.38736,18.3625,18.3625,0,0,0,1.67-5.01791c-.48739.06393-1.83779-7.3591-1.47017-7.81452-.67924-1.0307-1.895-1.54306-2.63681-2.54886-3.68943-5.0021-8.77253-4.12872-11.42613,2.66877-5.6687,2.861-5.72357,7.60577-2.24528,12.16916,2.2129,2.90326,2.517,6.83166,4.45862,9.93985-.1998.2557-.40756.50344-.6073.75915a91.0398,91.0398,0,0,0-9.50223,15.0538,37.8458,37.8458,0,0,0-2.25945-17.5787c-2.16269-5.21726-6.21632-9.61124-9.786-14.12152-4.2878-5.41757-13.08034-3.05326-13.83575,3.81439q-.011.09975-.02142.19943.79533.44865,1.55706.95249a3.808,3.808,0,0,1-1.53532,6.92993l-.07765.012a37.89041,37.89041,0,0,0,.99876,5.66516C69.3952,440.30279,79.282,446.75317,93.399,447.04294c.31164.15979.61531.31958.927.47143A92.92463,92.92463,0,0,0,89.324,471.05368a88.13536,88.13536,0,0,0,.06393,14.23065l-.024-.16778a23.28909,23.28909,0,0,0-7.95037-13.44764c-6.11821-5.02591-14.7621-6.87664-21.36259-10.9165a4.37072,4.37072,0,0,0-6.69422,4.252q.01328.08817.027.17643a25.57635,25.57635,0,0,1,2.86848,1.38226q.79533.44878,1.55706.95248a3.8081,3.8081,0,0,1-1.53533,6.93l-.07771.01193c-.05591.008-.10388.016-.15972.024A37.9228,37.9228,0,0,0,63.012,485.4042c2.86344,15.46021,15.162,16.927,28.31753,12.42487h.008a92.89686,92.89686,0,0,0,6.24044,18.2178h22.2928c.08-.24768.15184-.50341.22378-.75116a25.32843,25.32843,0,0,1-6.16855-.36743c1.654-2.02954,3.308-4.07513,4.962-6.10464a1.38106,1.38106,0,0,0,.10388-.11987c.839-1.03867,1.686-2.06946,2.52491-3.10816l.00045-.00128a37.10115,37.10115,0,0,0-1.08711-9.45126l-.00071-.00043Z"
            fill="#f2f2f2"
          />
          <path
            d="M146.07022,515.54742h35.97354c6.15261-20.71624.03287-69.67535,8.14252-89.68646,15.55875-38.36871,35.43533-78.85281,72.53424-97.5874,15.84012-7.99951,33.11136-11.124,50.74951-10.95383,24.36563.231,49.41629,6.77167,72.35083,15.43991,9.38175,3.53784,18.67783,7.42816,27.93726,11.40363,34.885,14.99008,69.39074,31.31744,106.90552,35.73059,47.47143,5.5802,103.28485-15.65872,116.25048-61.407,9.89551-35.02545-7.92614-71.38816-26.61627-102.66919-18.69006-31.28095-39.58191-64.75028-36.16919-100.96712.01215-.12154.02442-.25533.04889-.37688,1.2843-13.00842,9.05145-22.3572,19.90106-28.35079H546.83954c-.62378,33.78536,18.92254,63.60722,36.51178,93.05239,18.69006,31.26885,36.51172,67.64374,26.61627,102.66919-12.9657,45.73609-120.32733-7.87607-167.79877-13.4685-13.40594-1.56827,7.8895,4.01193-4.8927,0-20.04785-6.28543-22.27038,51.36316-41.78,42.90158-12.14611-5.27634-24.30447-10.51618-36.62185-15.1724-5.10062-1.93307-10.31134-3.7445-15.58322-5.42221a.03536.03536,0,0,1-.02448-.01217c-35.39868-11.20908-74.11212-15.57361-106.81979.6322-.22016.10937-.45258.21881-.67281.32818-37.09883,18.72242-56.97543,59.20652-72.53425,97.57523-12.85558,31.71866-10.83346,92.88385-17.16948,126.341Z"
            fill="#f2f2f2"
          />
          <path
            d="M359.31256,514.48614C183.74773,518.20477,179.898,441.30121,179.898,349.82913S259.957,186.80041,359.16574,186.80041s178,71.53016,178,163S458.52136,514.48614,359.31256,514.48614Z"
            fill="#fff"
          />
          <path
            d="M358.31256,515.48614C182.74773,519.20477,178.898,442.30121,178.898,350.82913s80.7125-165.88719,179.92124-165.88719,179.92127,74.41731,179.92127,165.88719S457.5213,515.48614,358.31256,515.48614Zm.50675-325.97961c-96.47959,0-173.34583,72.38168-174.97057,161.32263-1.5112,82.72626,93.80589,185.95673,174.97053,161.32483,43.52631-44.198,166.58686-91.80173,174.97059-161.32483,10.66119-88.40878-78.491-161.32261-174.97059-161.32261Z"
            fill="#3f3d56"
          />
          <path
            d="M0,515.6756a1.18647,1.18647,0,0,0,1.19006,1.19H716.48a1.19,1.19,0,0,0,0-2.38H1.19006A1.1865,1.1865,0,0,0,0,515.6756Z"
            fill="#ccc"
          />
          <polygon points="481.974 500.445 469.622 500.445 463.746 452.8 481.977 452.801 481.974 500.445" fill="#a0616a" />
          <path
            d="M482.409,513.331l-37.98423-.0014v-.48035A14.78532,14.78532,0,0,1,459.20932,498.065h.00092l6.93829-5.26376,12.94534,5.26455,3.31577.00013-.00064,15.26516Z"
            fill="#2f2e41"
          />
          <path
            d="M463.20563,390.00549a183.96221,183.96221,0,0,0-48.01349-33.6098l-9.02643,10.18955,5.74908-11.73929a183.95664,183.95664,0,0,0-151.055-.79816l4.6899,10.1865-7.20131-9.05283a183.82842,183.82842,0,0,0-50.33588,34.60638,8.34063,8.34063,0,0,0-2.62481,5.39746,7.84425,7.84425,0,0,0,2.29657,6.09174c2.26971,1.07446,5.504,3.54046,10.30957,10.30859a7.94,7.94,0,0,0,11.08093.0683,153.01148,153.01148,0,0,1,212.84424,0,7.93965,7.93965,0,0,0,11.08068-.06836c4.836-4.34936,5.96589-10.4975,10.12012-10.12012a8.241,8.241,0,0,0,2.49213-5.35125A7.88246,7.88246,0,0,0,463.20563,390.00549Z"
            fill="#5bc0de"
          />
          <polygon points="369.031 404.832 356.679 404.831 350.803 357.187 369.033 357.188 369.031 404.832" fill="#a0616a" />
          <path
            d="M369.46582,417.71768l-37.98422-.00143v-.48038a14.78535,14.78535,0,0,1,14.78451-14.7843h.00092l6.93829-5.26377,12.94535,5.26456,3.31576.00012-.00061,15.26517Z"
            fill="#2f2e41"
          />
          <path
            d="M496.09705,229.41733l-87.66,9.15851L386.898,259.858l-34.2616,11.18744a22.27825,22.27825,0,0,0-15.34411,20.23129c-.89151,20.896-2.65372,55.5885-4.51422,55.22519-2.66047-.51953,2.65744,12.4809,6.38229,11.72611s7.59817,7.7218,5.02313,9.22894,1.71451,14.401,1.71451,14.401l26.83206-4.32333c3.35871-25.33423,15.62558-52.03678,1.16794-70.67667,24.48474-7.94549,46.05481-14.58969,61.9148-18.68457L444.391,284.614h0l17.80954,189.23209,25.18631.41363c6.94965-35.75055,10.44092-67.927,5.00882-90.88113,0,0,.78037-68.95627,3.75891-68.20227s.45355-8.22916.45355-8.22916-2.60858-7.65069.59659-10.82461,1.20377-21.84131,1.20377-21.84131c5.29581-17.66611,3.70172-32.25266-2.31143-44.86389Z"
            fill="#2f2e41"
          />
          <path
            d="M506.46066,190.80437q1.30225-5.84034,2.6045-11.68164h0q3.6577-16.35645,7.30368-32.69825c3.1914-14.27441,6.39648-29.04589,3.65136-43.41015a43.13488,43.13488,0,0,0-1.72363-6.3584c-4.11133-11.75879-13.18945-22.0625-25.22852-24.23242l-1.96679-7.3291L464.238,61.78776l-5.084,7.01172c-10.94336-.875-17.43945,10.02148-24.98828,19.001l-3,3c-2.18652,2.73438-2.63477,13.00684-4,16-6.07031,13.30957,2.29492.375,2,15-.30469,15.15527-3.28488,29.86145-3.59055,45.01575-.03256,1.62608-3.53179,2.86572-4.13385,5.71653-.325,1.5387,2.38608,4.393,1.975,6.1133-.35086,1.46819,1.40088,7.36911,1.025,8.8867-1.74362,7.04018-5.69311,9.81223-5.86615,13.28347a22.34707,22.34707,0,0,1-.59481,3.96243c-.21622.92271-3.24408-.183-3.539.7541-.49557,1.57445,1.68442,5.20005,1.08692,6.77608-1.15631,3.04993-.37854,8.43677-1.08692,11.22394-2.97265,11.70117-1.83123,7.05775-8.2756,17.26772,16.49414,4.98632,23.61816,21.68554,40.585,24.6914q1.147-3.70752,2.28711-7.43457c2.249.14453,4.51172.2793,6.77441.40332h0c.127,2.15723.25489,4.31543.38282,6.46a438.51186,438.51186,0,0,0,51.65722.28125,41.03079,41.03079,0,0,1-1.3789-5.28613c-.94434-4.97949,1.08594-10.37988,1.30273-15.30859.15332-3.65137-1.583-6.75391-1.53222-10.68653.01269-.72754,4.09863-1.33984,4.08593-2.08105-.06445-6.53711-3.77929-15.28321-2.55371-21.70508,1.27637-6.60059-2.77051-12.7666-1.31543-19.32813v-.00195l0,0Z"
            fill="#3f3d56"
          />
          <path
            d="M380.8053,32.90525a8.60068,8.60068,0,0,0-8.5907,8.5907V93.25962a8.60068,8.60068,0,0,0,8.5907,8.5907h16.01437a8.60071,8.60071,0,0,0,8.59073-8.59072c2.55231-26.00986,1.61432-40.04684,0-51.76364a8.60072,8.60072,0,0,0-8.59073-8.59073H380.8053Z"
            fill="#5bc0de"
          />
          <path
            d="M378.8053,32.90525a8.60081,8.60081,0,0,0-8.59073,8.59051c-.91992,20.34766-1.55957,39.75293,0,51.76367a8.60051,8.60051,0,0,0,8.59073,8.59089h16.01437a8.60069,8.60069,0,0,0,8.5907-8.5907V41.496a8.60068,8.60068,0,0,0-8.5907-8.5907Z"
            fill="#3f3d56"
          />
          <path
            d="M420.032,108.2419s15.14664-.61431,7.43726,5.92561c-5.01682,4.25579-16.31363,33.88333-28.05057,37.4675a10.43237,10.43237,0,0,1-13.24341-7.79559L375.53613,94.25009l13.89749-2.11391,8.46967,39.248L420.032,108.24191Z"
            fill="#a0616a"
          />
          <circle cx="383.25354" cy="88.04264" r="9.82931" fill="#a0616a" />
          <path
            d="M440.57758,81.14425h0c9.00406,6.76205,11.65573,28.93625,5.99759,38.67195-10.816,18.61063-32.13944,32.93929-33.0488,30.94933-1.2453-2.72508-3.41535.93412-3.34162,2.132s-4.03411.84074-3.55551,2.10841-2.76324,1.543-4.17123,2.278c0,0-12.88547.21785-16.95078-5.66227s-4.6102-14.00591-4.6102-14.00591l-5.04672-5.93794,26.7572-15.64123L400.4024,126.594l13.34791-15.54226,2.82492-6.23551,4.50674-2.30143,1.49326-4.69857,4.15545-1.8788,16.43509-19.13694-2.58813,4.3438Z"
            fill="#3f3d56"
          />
          <path
            d="M514.51074,263.30347a9.203,9.203,0,0,0,1.6051-14.02015l1.89057-84.0848-16.61432,2.58465,1.17154,81.557a9.25289,9.25289,0,0,0,11.94711,13.96331Z"
            fill="#a0616a"
          />
          <path
            d="M515.23492,93.52463s-1.10223.833,4.5495,9.44462c1.12085,1.7078,1.88044,8.594,2.41205,17.58013.17322,2.92755,1.83985,4.98422,1.96924,8.25108.10986,2.77251-1.312,6.72263-1.2276,9.59776.045,1.53353,1.18854,2.86789,1.2276,4.40224.0163.63956-1.06958,1.48285-1.05426,2.12012q.02481,1.03086.0481,2.05557c.38836,17.07907.47161,32.50433.8573,32.24384.82244-.55552-1.22016,2.31582-.19886,2.7953s-.87219,2.85812-.38415,4.091c.5946,1.50213-.14447,3.15925-.61151,4.66714-1.34851,4.354-1.639,8.09276-2.81165,10.161a7.22977,7.22977,0,0,1,3.3703,8.19775l-23.41678-4.23364c-2.855-4.57362-2.4425-9.37378-.49707-10.21033l-.57928-14.88305-6.66916-76.99859L511.76422,94.08l3.4707-.55534Z"
            fill="#3f3d56"
          />
          <path
            d="M369.898,46.18139c-.33618,0-.60956.16016-.60956.35711v2.8569c0,.197.27338.35711.60956.35711s.60956-.16016.60956-.35711V46.5385C370.50757,46.34155,370.23419,46.18139,369.898,46.18139Z"
            fill="#3f3d56"
          />
          <path
            d="M369.898,53.75209c-.33618,0-.60956.16016-.60956.35711v5.53524c0,.19695.27338.35711.60956.35711s.60956-.16016.60956-.35711V54.1092C370.50757,53.91225,370.23419,53.75209,369.898,53.75209Z"
            fill="#3f3d56"
          />
          <path
            d="M403.898,54.75209a.6908.6908,0,0,0-.60956.74891V67.10912a.62252.62252,0,1,0,1.21912,0V55.501A.6908.6908,0,0,0,403.898,54.75209Z"
            fill="#3f3d56"
          />
          <path
            d="M368.898,62.60856c-.33618,0-.60956.16016-.60956.35711v5.53524c0,.197.27338.35711.60956.35711s.60956-.16016.60956-.35711V62.96567C369.50757,62.76872,369.23419,62.60856,368.898,62.60856Z"
            fill="#3f3d56"
          />
          <circle cx="475.78857" cy="34.08717" r="25.1041" fill="#a0616a" />
          <path
            d="M503.819,45.62181c-1.95984,3.92214-3.82553,6.37518-7.21374,8.56767a11.974,11.974,0,0,1-.41633-1.52864c-.36068.80618-.71279,1.61052-1.08194,2.41851-2.14364,1.197.17-.64976-3.339.68864l-2.95185-5.06561c-.80847-2.40462.33722-5.02347,1.79373-7.10441,1.45835-2.07243,3.27359-3.97244,4.06412-6.38186.79059-2.40938.089-5.60139-2.28094-6.4982-2.33411-.89559-4.91242.80346-7.39353.50828-2.86352-.33772-4.812-3.33376-4.96722-6.21611-.15527-2.88239,1.02081-5.64995,2.17142-8.28761-3.46775,2.48513-14.21009,6.93748-20.058,7.1502a7.23989,7.23989,0,0,0-2.35937-4.74807A6.58972,6.58972,0,0,1,458.6557,23.847c-5.72525-.2936-6.80145,8.02235-9.75766,5.01106.92279-.46434-2.478-10.79906-1.55524-11.2634q-1.06525-2.91853-2.1268-5.82011c1.31546.33155,2.6413.66978,3.9653.99951a5.66454,5.66454,0,0,1,.63392-2.02033,13.70744,13.70744,0,0,1,2.9917-1.26307c.33633-.08985.68112-.1815,1.02963-.25616a13.76992,13.76992,0,0,1,1.56323-.25485c-.76123-.44138-1.52917-.87249-2.29221-1.32232-.09109-.05162-.17361-.10507-.26467-.15668a29.29276,29.29276,0,0,1,3.35174-1.98914c4.89191-2.5591,9.969-5.16671,15.483-5.48569a15.50688,15.50688,0,0,1,10.04752,2.89233,13.90236,13.90236,0,0,0,8.6445,2.60046,9.90168,9.90168,0,0,1,4.34583.96792A13.92638,13.92638,0,0,1,492.686,8.17465c1.59116.04137,3.1738.08457,4.76673.13446a21.9753,21.9753,0,0,1,4.45312,5.64176c5.58014,9.53378,6.85541,21.79421,1.91324,31.67094Z"
            fill="#2f2e41"
          />
          <path
            d="M423.20172,436.7262a7.96034,7.96034,0,0,0-4.27148-5.90528,181.168,181.168,0,0,0-33.20673-13.04907L374.9888,431.9235l7.93393-14.93235a183.00688,183.00688,0,0,0-89.80652-1.274l4.128,11.38229-6.74377-10.73092a181.60483,181.60483,0,0,0-38.43744,14.45239,8.02516,8.02516,0,0,0-2.02136,12.81348c4.641.28522,7.70575,4.59723,10.76465,10.76465a7.98489,7.98489,0,0,0,9.0736,1.51074,152.76969,152.76969,0,0,1,131.23389,0,7.9815,7.9815,0,0,0,9.07239-1.51074c1.839-4.98651,5.62921-7.93506,10.76562-10.76465A7.93483,7.93483,0,0,0,423.20172,436.7262Z"
            fill="#5bc0de"
          />
          <path
            d="M363.89362,485.46057q-3.52606-.55371-7.09491-.96674L347.47314,499.333l6.94855-15.09229a186.32792,186.32792,0,0,0-33.977-.35748l7.1131,15.44977-9.575-15.23615q-5.48172.51966-10.88312,1.36377a6.62371,6.62371,0,0,0-5.354,4.58008,6.812,6.812,0,0,0,1.66125,6.95807c3.85071,3.7724,8.09656,9.01074,15.07422,15.07422a6.69756,6.69756,0,0,0,5.2547,1.97552c3.8667-.28613,7.82373-.43164,11.76074-.43164h.00006c3.937,0,7.894.14551,11.76075.43164a6.71784,6.71784,0,0,0,5.255-1.97552c7.20557-6.15076,10.8468-11.79047,15.07422-15.07422a6.811,6.811,0,0,0,1.661-6.95807A6.6224,6.6224,0,0,0,363.89362,485.46057Z"
            fill="#5bc0de"
          />
        </svg>
        <div class="mt-20 text-3xl">Hmm... no internet connection 😔</div>
        <div class="mt-5 text-lg">Avoid re-loading this page, as you may loose your work.</div>
      </div>
    </template>
  </v-offline>

  <div
    v-if="userStore.isLoggedIn && userStore.accessAllowed.regions.length"
    class="layout-wrapper layout-content-width-boxed"
    :class="['layout-main-menu-' + state]"
  >
    <MainMenu :is-mobile="isMobile" />

    <div class="layout-content-wrapper">
      <header class="layout-navbar" :class="{ 'app-expanded-menu': state == 'lockedOpen' }">
        <NavBar />
      </header>
      <main class="layout-page-content" :class="{ 'app-expanded-menu': state == 'lockedOpen' }">
        <router-view />
      </main>
      <!-- Phone -->
      <Phone v-if="can('make_calls')" />
    </div>
  </div>
  <!-- <div v-else-if="1"> -->
  <div v-else class="h-[100vh] flex">
    <Skeleton v-if="!isMobile" :width="state == 'lockedOpen' ? '260px' : '80px'" height="100%" border-radius="0" />
    <Skeleton width="100%" height="64px" class="m-[1rem]" />
  </div>
</template>

<script>
import Skeleton from 'primevue/skeleton'

import MainMenu from '@/components/main-menu/MainMenu.vue'
import NavBar from '@/components/navbar/NavBar.vue'
import { state } from '@/components/main-menu/state'

import { useRoute } from 'vue-router'

import { ref, watch, onMounted } from 'vue'
import { useAbility } from '@casl/vue'
import { VOffline } from 'v-offline'
import socket from '@/services/socket.service.js'
import Phone from '@/components/phone/Phone.vue'

import { useUserStore } from '@/stores/UserStore'

import { getSunrise, getSunset } from 'sunrise-sunset-js'
import dayjs from 'dayjs'

export default {
  components: {
    Skeleton,
    MainMenu,
    NavBar,
    VOffline,
    Phone
  },
  setup() {
    const { can } = useAbility()
    const route = useRoute()
    const userStore = useUserStore()

    let windowWidth = ref(window.innerWidth)

    const isMobile = ref(false)

    //Initially set darkmode setting
    setDarkMode(userStore.userSettings.darkmode)

    //Watch for darkmode change, and apply changes to app if required
    watch(
      () => userStore.userSettings.darkmode,
      function () {
        // console.log('Change in darkmode setting')

        let newSetting = userStore.userSettings.darkmode

        //Update theme based on new setting
        if (newSetting == 'auto') {
          //Sun set sun rise
          let sunData = ref({})
          let currentTime = dayjs()

          navigator.geolocation.getCurrentPosition(function (position) {
            sunData.value = {
              rise: dayjs(getSunrise(position.coords.latitude, position.coords.longitude)),
              set: dayjs(getSunset(position.coords.latitude, position.coords.longitude))
            }
            // console.log(sunData.value)

            if (currentTime.isBetween(sunData.value.rise, sunData.value.set)) {
              // console.log('NO DARKMODE PLEASE!')
              setDarkMode('light')
            } else {
              setDarkMode('dark')
            }
          })
        } else {
          setDarkMode(newSetting)
        }
      }
    )

    //Set darkmode settings
    function setDarkMode(value) {
      if (value == 'dark') {
        document.body.classList.add('dark')
      } else {
        document.body.classList.remove('dark')
      }
    }

    const onWidthChange = () => {
      windowWidth.value = window.innerWidth

      if (windowWidth.value < 1280) {
        isMobile.value = true
        document.body.classList.add('mobile')
      } else {
        isMobile.value = false
        document.body.classList.remove('mobile')
      }
    }

    const handlePageReload = () => {
      // console.log('Page Reloaded')
      socket.emit('user-page-reload', {
        userID: userStore.details.id,
        routeName: route.name,
        routePath: route.fullPath
      })
    }

    onMounted(async () => {
      onWidthChange()
      window.addEventListener('resize', onWidthChange)
      window.addEventListener('beforeunload', handlePageReload)
    })

    //Listen for user input/activity
    let userPresenceTimer

    function userTriggerActivity(action = '', currentRoute) {
      clearTimeout(userPresenceTimer)
      userPresenceTimer = setTimeout(() => {
        // console.log('User Activity Detected: ' + action)
        socket.emit('user-activity', {
          userID: userStore.details.id,
          action: action,
          routeName: currentRoute.name,
          routePath: currentRoute.fullPath
        })
      }, 2000)
    }

    window.addEventListener('click', function () {
      userTriggerActivity('click', route)
    })
    window.addEventListener('keyup', function (event) {
      userTriggerActivity('keyup', route)

      if (event.ctrlKey && event.code) {
        // IDEA: Global quick action shortcuts such as: New Note, New Appointment, etc
      }
    })

    //Network Online status
    const online = ref(true)
    const onNetworkChange = status => {
      online.value = status
    }
    return {
      can,
      state,
      isMobile,
      online,
      onNetworkChange,
      userStore
    }
  }
}
</script>

<style>
body,
html {
  min-height: 100vh;
}

/* @media (max-width: 1400px) {
  body,
  html {
    font-size: 15px;
  }
} */

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--app-bg-color);
  box-sizing: border-box;
  min-height: 100vh;
  /* transition: 0.3s ease-in-out; */
}

.dark #app,
body.dark {
  background: var(--gray-950);
}

.layout-content-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-block-size: calc(var(--vh, 1vh) * 100);
  transition: padding-inline-start 0.2s ease-in-out;
  will-change: padding-inline-start;
  padding-inline-start: 80px;
}
.mobile .layout-content-wrapper {
  padding-inline-start: 0px;
}
.layout-navbar {
  inset-block-start: 1rem;
  position: sticky;
  padding-inline: 1.5rem;
  z-index: 11;
}
.layout-content-width-boxed .layout-navbar {
  inline-size: 100%;
  margin-inline: auto;
  max-inline-size: 1440px;
}

.mobile .layout-navbar {
  margin-left: 0px;
  /* flex-grow: 1; */
  /* padding-block: 1.5rem; */
}

.layout-page-content {
  padding-inline: 1.5rem;
  margin-block-start: 1rem;
  padding-block: 1.5rem;
  z-index: 1;
}
.layout-content-width-boxed .layout-page-content {
  inline-size: 100%;
  margin-inline: auto;
  max-inline-size: 1440px;
}
.layout-wrapper.layout-main-menu-lockedOpen .layout-content-wrapper {
  padding-inline-start: 260px;
}

/* .app-expanded-menu {
    margin-left: 260px !important;
} */

.mobile .layout-page-content {
  margin-left: 0px;
}

/* Online Offline overlay */
.network-offline {
  background-color: var(--bg-tertiary);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}

/* Transitions */
.page-content-enter-from {
  opacity: 0;
}
.page-content-enter-to {
  opacity: 1;
}
.page-content-enter-active {
  transition: all 1000ms ease;
}
.page-content-leave-from {
  opacity: 1;
}
.page-content-leave-to {
  opacity: 0;
}
.page-content-leave-active {
  transition: all 1000ms ease;
}
</style>
