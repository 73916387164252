// Create an array of roles, each having: id, defaultDashboard, dashboardsAllowed
// Roles are ordered by weight, with the lowest weight being the highest priority (eg. Admin is 1, Call Agent is 6)
export const roles = [
  {
    id: 1, // Admin
    defaultDashboard: 'retain-admin',
    dashboardsAllowed: ['all'],
    roleWeight: 1
  },
  {
    id: 5, // Leader
    defaultDashboard: 'retain-company',
    dashboardsAllowed: ['retain-company', 'dealership', 'reloop'],
    roleWeight: 2
  },
  {
    id: 4, // Manager
    defaultDashboard: 'retain-call-center',
    dashboardsAllowed: ['retain-call-center-agent', 'retain-call-center', 'retain-call-center-team'],
    roleWeight: 3
  },
  {
    id: 6, // Dealer Development Manager
    defaultDashboard: 'retain-company',
    dashboardsAllowed: ['retain-company', 'dealership', 'dealership-sales-executive', 'reloop'],
    roleWeight: 4
  },
  {
    id: 9, // Finance Assistant
    defaultDashboard: 'retain-finance',
    dashboardsAllowed: ['retain-finance'],
    roleWeight: 4
  },
  {
    id: 3, // Team Leader
    defaultDashboard: 'retain-call-center-team',
    dashboardsAllowed: ['retain-call-center-agent', 'retain-call-center-team'],
    roleWeight: 5
  },
  {
    id: 2, // Call Agent
    defaultDashboard: 'retain-call-center-agent',
    dashboardsAllowed: ['retain-call-center-agent'],
    roleWeight: 6
  },
  {
    id: 30, // Manufacturer - Regional
    defaultDashboard: 'dealership',
    dashboardsAllowed: ['dealership', 'reloop'], // TODO: add dealership-group
    roleWeight: 30
  },
  {
    id: 31, // Manufacturer - National
    defaultDashboard: 'dealership',
    dashboardsAllowed: ['dealership', 'reloop'], // TODO: add dealership-group
    roleWeight: 31
  },
  {
    id: 12, // Group Sales Manager
    defaultDashboard: 'dealership',
    dashboardsAllowed: ['dealership', 'reloop'], // TODO: add dealership-group
    roleWeight: 32
  },
  {
    id: 11, // Sales Manager
    defaultDashboard: 'dealership',
    dashboardsAllowed: ['dealership', 'reloop'], // TODO: add sales-executive dashboard once able to filter by dealership
    roleWeight: 33
  },
  {
    id: 10, // Sales Executive
    defaultDashboard: 'dealership-sales-executive',
    dashboardsAllowed: ['dealership-sales-executive'],
    roleWeight: 34
  }
]

// Create a function which receive a dashboard id and return an array of role ids which are allowed to access it
export const getRolesForDashboard = dashboard => {
  return roles
    .filter(role => {
      return role.dashboardsAllowed.includes(dashboard) || role.dashboardsAllowed.includes('all')
    })
    .map(role => role.id)
}

export const dashboards = [
  {
    id: 'retain-admin',
    title: 'Admin',
    route: '/dashboard/admin',
    component: './dashboards/admin/AdminDashboard.vue',
    componentFolder: 'admin',
    componentName: 'AdminDashboard',
    localeKey: 'ui.entities.user.role.admin',
    localePluralization: 1,
    rolesAllowed: getRolesForDashboard('retain-admin')
  },
  {
    id: 'retain-call-center-agent',
    title: 'Call Agent',
    route: '/dashboard/call-center/agent',
    component: './dashboards/call-center/AgentDashboard.vue',
    componentFolder: 'call-center',
    componentName: 'AgentDashboard',
    localeKey: 'ui.entities.user.role.call-agent',
    localePluralization: 1,
    rolesAllowed: getRolesForDashboard('retain-call-center-agent')
  },
  {
    id: 'retain-call-center-team',
    title: 'Team',
    route: '/dashboard/call-center/team',
    component: './dashboards/call-center/TeamDashboard.vue',
    componentFolder: 'call-center',
    componentName: 'TeamDashboard',
    localeKey: 'ui.entities.team.label',
    localePluralization: 1,
    rolesAllowed: getRolesForDashboard('retain-call-center-team')
  },
  {
    id: 'retain-call-center',
    title: 'Call Center',
    route: '/dashboard/call-center',
    component: './dashboards/call-center/CallCenterDashboard.vue',
    componentFolder: 'call-center',
    componentName: 'CallCenterDashboard',
    localeKey: 'ui.entities.user.role.manager',
    localePluralization: 1,
    rolesAllowed: getRolesForDashboard('retain-call-center')
  },
  {
    id: 'retain-company',
    title: 'Company',
    route: '/dashboard/company',
    component: './dashboards/retain/CompanyDashboard.vue',
    componentFolder: 'retain',
    componentName: 'CompanyDashboard',
    localeKey: 'ui.common.company',
    localePluralization: 1,
    rolesAllowed: getRolesForDashboard('retain-company')
  },
  {
    id: 'retain-finance',
    title: 'Finance',
    route: '/dashboard/finance',
    component: './dashboards/finance/FinanceDashboard.vue',
    componentFolder: 'finance',
    componentName: 'FinanceDashboard',
    localeKey: 'ui.entities.finance.label',
    localePluralization: 1,
    rolesAllowed: getRolesForDashboard('retain-finance')
  },
  {
    id: 'reloop',
    title: 'ReLoop',
    route: '/dashboard/reloop',
    component: './dashboards/reloop/ReLoopDashboard.vue',
    componentFolder: 'reloop',
    componentName: 'ReLoopDashboard',
    localeKey: 'ui.entities.reloop.label',
    localePluralization: 1,
    rolesAllowed: getRolesForDashboard('reloop')
  },
  {
    id: 'dealership-sales-executive',
    title: 'Sales Executive',
    route: '/dashboard/dealership/sales-executive',
    component: './dashboards/dealership/SalesExecutiveDashboard.vue',
    componentFolder: 'dealership',
    componentName: 'SalesExecutiveDashboard',
    localeKey: 'ui.entities.user.role.sales-executive',
    localePluralization: 1,
    rolesAllowed: getRolesForDashboard('dealership-sales-executive')
  },
  {
    id: 'dealership',
    title: 'Dealership',
    route: '/dashboard/dealership',
    component: './dashboards/dealership/DealershipDashboard.vue',
    componentFolder: 'dealership',
    componentName: 'DealershipDashboard',
    localeKey: 'ui.entities.dealership',
    localePluralization: 1,
    rolesAllowed: getRolesForDashboard('dealership')
  },
  {
    id: 'dealership-group',
    title: 'Group',
    route: '/dashboard/dealership/group',
    component: './dashboards/dealership/GroupDashboard.vue',
    componentFolder: 'dealership',
    componentName: 'GroupDashboard',
    localeKey: 'ui.entities.group',
    localePluralization: 1,
    rolesAllowed: getRolesForDashboard('dealership-group')
  }
]

// Create a function which will return the dashboards which a certain role is allowed to access
export const getDashboardsForRoles = roles => {
  return dashboards.filter(dashboard => {
    return roles.some(role => dashboard.rolesAllowed.includes(role))
  })
}

// Function which accepts an array of role ids, and returns the default dashboard for the role with the highest weight
export const getDefaultDashboardForRoles = roleIds => {
  const rolesToCheck = roles.filter(role => roleIds.includes(role.id))
  const sortedRoles = rolesToCheck.sort((a, b) => {
    return a.roleWeight - b.roleWeight
  })
  return dashboards.find(dashboard => dashboard.id === sortedRoles[0].defaultDashboard)
}

// Function which accepts a role id, and returns boolean if the role has access to more than one dashboard
export const hasMultipleDashboards = roleIds => {
  return getDashboardsForRoles(roleIds).length > 1
}

// Function which accepts a dashboard route, and returns the dashboard object
export const getDashboardByRoute = route => {
  return dashboards.find(dashboard => dashboard.route === route)
}
