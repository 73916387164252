<template>
  <main class="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
    <div class="text-center">
      <Chip label severity="danger" dot outlined>403</Chip>
      <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">No Access</h1>
      <p class="mt-6 text-base leading-7 text-gray-600">Sorry, you don't seem to have access to this.</p>
      <div class="mt-10 flex items-center justify-center gap-x-6">
        <Button label="Back" severity="secondary" outlined icon="arrow-left" @click="retry" />
        <Button v-if="userStore.isLoggedIn" label="Sign Out" severity="primary" outlined @click="signOut" />
        <a href="mailto:support@retain.group" class="text-sm font-semibold text-gray-900">
          <Button plain label="Contact support" severity="secondary" />
        </a>
      </div>
    </div>
  </main>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/UserStore'
import socket from '@/services/socket.service.js'

import Button from '@/components/button/Button.vue'
import Chip from '@/components/chip/Chip.vue'
import updateAbility from '@/config/update-ability.js'

const router = useRouter()
const userStore = useUserStore()

socket.emit('user-logged-out')

async function retry() {
  const response = await userStore.verify()
  if (response.success) {
    updateAbility(null, router)
    router.go(-1)
  } else {
    userStore.signOut()
  }
}

async function signOut() {
  userStore.signOut()
}
</script>
