<template>
  <PageHeader
    :title="$t('ui.entities.status-timeline')"
    breadcrumb
    :items="[
      { label: $t('ui.entities.report.label', 2), to: '/reports' },
      { label: $t('ui.entities.user.role.call-agent', 2) },
      { label: $t('ui.entities.status-timeline') }
    ]"
  >
    <template #right>
      <div class="flex gap-3">
        <div v-tippy="'Timeline Scale'" class="border border-primary px-6 py-2 rounded flex items-center">
          <Slider v-model="timelineScale" :min="1" :max="10" class="w-32" />
        </div>

        <DatePicker
          v-model="dateSelected"
          position="bottom-right"
          :max-date="new Date()"
          :number-of-months="1"
          @change-applied="onDateSelectChange"
        />
      </div>
    </template>
  </PageHeader>

  <Card>
    <div class="flex overflow-hidden">
      <div class="w-[250px] border-r border-secondary">
        <div v-for="user in data" :key="user.id" class="h-[60px] border-b border-secondary flex items-center p-4">
          <AvatarLabelGroup :label="user.forename + ' ' + user.surname" :avatar-url="user.profilePicUrl" :avatar-text="user.initials" />
        </div>
      </div>
      <div class="w-full overflow-x-auto">
        <div v-for="user in data" :key="user.id" class="h-[60px] w-full">
          <ActivityTimeline v-model="user.periods" v-model:scale="timelineScale" :show-times="true" start-time="08:00" end-time="21:00" />
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import { ref } from 'vue'
import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import DatePicker from '@/components/date-picker/DatePicker.vue'
import AvatarLabelGroup from '@/components/avatar/AvatarLabelGroup.vue'
import Label from '@/components/forms/Label.vue'

import Slider from 'primevue/slider'

import useApiRequest from '@/composables/useApiRequest'

import dayjs from 'dayjs'

import ActivityTimeline from '@/components/user-activity/UserActivityTimeline.vue'

export default {
  components: { PageHeader, Card, ActivityTimeline, DatePicker, AvatarLabelGroup, Label, Slider },
  setup() {
    const dateSelected = ref(dayjs().format('YYYY-MM-DD'))
    const data = ref([])

    const timelineScale = ref(3)

    const apiRequest = useApiRequest()

    function getData() {
      apiRequest
        .sendRequest({ endpoint: '/v1/users/activity/timelines', params: { date: dayjs(dateSelected.value).format('YYYY-MM-DD') } })
        .then(response => {
          console.log(response)
          console.log(response.data.response.status)
          if (response.data.response.status === 'success') {
            data.value = response.data.response.data
          }
        })
    }
    getData()

    function onDateSelectChange() {
      getData()
    }
    return { data, timelineScale, dateSelected, onDateSelectChange }
  }
}
</script>

<style></style>
