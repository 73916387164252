// src/timerUtil.js
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

export function calculateElapsedTime(timestamp, returnAs = 'seconds') {
  const elapsedMilliseconds = Date.now() - timestamp
  const durationObj = dayjs.duration(elapsedMilliseconds)
  const elapsedSeconds = Math.floor(durationObj.asSeconds())
  const hours = durationObj.hours()
  const minutes = durationObj.minutes()
  const seconds = durationObj.seconds()

  if (returnAs == 'seconds') {
    return elapsedSeconds
  }

  let result = String(seconds).padStart(2, '0')

  // Always include minutes in the format
  result = `${String(minutes).padStart(2, '0')}:${result}`

  if (hours > 0) {
    result = `${hours}:${result}`
  }

  return result
}
