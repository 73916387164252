import axios from 'axios'
import { ref } from 'vue'
import notification from '@/utilities/notification'

const sendInvoices = () => {
  let sendCount = ref(null)
  let sendProgress = ref(null)

  function sendInvoice(invoices) {
    // Invoices passes an array of objects. {id: 0, reference: BA10-S271-20220102}

    sendProgress.value = 0
    sendCount.value = invoices.length

    invoices.forEach(invoice => {
      // console.log('Sending: ' + invoice.id)

      axiosSendInvoice(invoice)
    })
  }

  async function axiosSendInvoice(invoice) {
    try {
      const url = import.meta.env.VITE_API_BASE_URL + '/v1/finance/invoices/send?invoiceAsAttachment=true&invoiceID=' + invoice.id

      let headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
      let data = await axios(url, { headers, withCredentials: true })

      if (data.status != 200) {
        if (data.status === 401) {
          throw Error('401 Unauthorized')
        } else {
          throw Error('Error fetching data')
        }
      }

      //If everything is fine
      sendProgress.value++

      // console.log(sendProgress.value + '/' + sendCount.value)
      if (sendCount.value === sendProgress.value) {
        sendCount.value = null
        sendProgress.value = null
        // console.log('Send Completed')
        notification('Success', 'All Invoices Sent')
      }
    } catch (err) {
      console.log(err.message)
      notification('Error', err.message)
    }
  }

  return { sendCount, sendProgress, sendInvoice }
}

export default sendInvoices
