<template>
  <teleport to="#modals-container">
    <transition name="modal-backdrop" appear>
      <div v-if="isOpen" class="modal-backdrop">
        <div class="modal-wrapper" :class="{ 'pin-to-top': pinToTop }" @mousedown.self="closeTrigger">
          <transition name="modal" appear>
            <div v-if="isOpen" ref="modalRef" class="modal" :class="{ 'pin-to-top': pinToTop }" :style="['overflow: ' + overflowBehaviour]">
              <div class="mb-[20px]">
                <div v-if="icon" class="border border-primary rounded-xl p-3 inline-block text-2xl mb-3 relative">
                  <Icon :type="icon" class="text-tertiary" />
                  <div class="opacity-80 absolute -top-6 top -left-6 w-[160px] h-[160px] overflow-hidden rounded-xl">
                    <div class="w-24 h-24 border border-secondary rounded-full absolute top-1 left-1" />
                    <div class="w-24 h-24 border border-secondary rounded-full absolute top-1 left-1 scale-[1.5] opacity-50" />
                    <div class="w-24 h-24 border border-secondary rounded-full absolute top-1 left-1 scale-[2] opacity-25" />
                    <div class="w-24 h-24 border border-secondary rounded-full absolute top-1 left-1 scale-[2.5] opacity-15" />
                  </div>
                </div>
                <div>
                  <div v-if="title" class="text-lg font-semibold">{{ title }}</div>
                  <div v-if="description" class="text-sm text-secondary">{{ description }}</div>
                </div>
                <div v-if="showDismissButton" class="modal-close-btn">
                  <Button icon="times" severity="secondary" plain @click="closeTrigger" />
                </div>
              </div>
              <slot></slot>
              <div v-if="buttons.length" class="modal-footer">
                <Button
                  v-if="buttons.indexOf('close') !== -1"
                  :label="closeText"
                  :severity="buttonColors.close"
                  outlined
                  class="mr-3"
                  block
                  @click="closeTrigger"
                />
                <Button
                  v-if="buttons.indexOf('ok') !== -1"
                  v-tippy="{ content: okTooltip }"
                  :severity="buttonColors.ok"
                  :label="okText"
                  :is-loading="isConfirming"
                  block
                  @click="!okIsDisabled ? okTrigger() : ''"
                />
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { onMounted, onBeforeUnmount, ref, watch, nextTick } from 'vue'

import Icon from '@/components/icon/Icon.vue'
import Button from '@/components/button/Button.vue'

import Swal from 'sweetalert2'

export default {
  name: 'Modal',
  components: {
    Icon,
    Button
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    buttons: {
      type: Array,
      default: () => ['close', 'ok']
    },
    buttonColors: {
      type: Object,
      default: () => ({ close: 'secondary', ok: 'primary' })
    },
    okText: {
      type: String,
      default: 'Ok'
    },
    okIsDisabled: {
      type: Boolean,
      default: false
    },
    okTooltip: {
      type: String,
      default: ''
    },
    closeText: {
      type: String,
      default: 'Close'
    },
    showLoaderOnConfirm: {
      type: Boolean,
      default: false
    },
    disableClose: {
      type: Boolean,
      default: false
    },
    isConfirming: {
      type: Boolean,
      default: false
    },
    hasUnsavedChanges: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: null
    },
    minWidth: {
      type: [Number, String],
      default: 300
    },
    maxWidth: {
      type: [Number, String],
      default: null
    },
    showDismissButton: {
      type: Boolean,
      default: true
    },
    pinToTop: {
      type: Boolean,
      default: false
    },
    overflowBehaviour: {
      type: String,
      default: 'auto'
    },
    icon: {
      type: String,
      default: null
    }
  },
  emits: ['closeModal', 'closeModalBlocked', 'okModal'],
  setup(props, { emit }) {
    const modalRef = ref(null)

    const handleResize = () => {
      if (!modalRef.value) return

      const width = window.innerWidth
      if (props.minWidth && width < props.minWidth) {
        modalRef.value.style.width = '100%'
        modalRef.value.style.minWidth = '0'
      } else {
        modalRef.value.style.width = ''
        modalRef.value.style.minWidth = props.minWidth ? `${props.minWidth}px` : ''
      }

      if (props.maxWidth && width > props.maxWidth) {
        modalRef.value.style.width = `${props.maxWidth}px`
      }
    }

    onMounted(() => {
      window.addEventListener('resize', handleResize)
      handleResize() // Call it initially to set the size on load
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize)
    })

    // Watch isOpen and call handleResize after the DOM updates
    watch(
      () => props.isOpen,
      newValue => {
        if (newValue) {
          nextTick(handleResize)
        }
      }
    )

    // Watch for prop changes
    watch(() => [props.minWidth, props.maxWidth], handleResize, { immediate: true })

    function closeTrigger() {
      if (props.hasUnsavedChanges) {
        Swal.fire({
          title: 'Unsaved Changes',
          text: 'Changes made will not be saved if you continue',
          icon: 'warning',
          reverseButtons: true,
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: 'Discard',
          confirmButtonColor: 'var(--bg-error-solid)',
          cancelButtonText: 'Cancel'
        }).then(function (response) {
          if (response.isConfirmed) {
            emit('closeModal')
          }
        })
      } else if (!props.disableClose) {
        // console.log('Closing Modal')
        emit('closeModal')
      }
    }

    function okTrigger() {
      emit('okModal')
    }

    return { modalRef, closeTrigger, okTrigger }
  }
}
</script>

<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background: rgb(6, 5, 11);
  background: -moz-linear-gradient(0deg, rgba(6, 5, 11, 0.9) 0%, rgba(6, 5, 11, 0.7) 100%);
  background: -webkit-linear-gradient(0deg, rgba(6, 5, 11, 0.9) 0%, rgba(6, 5, 11, 0.7) 100%);
  background: linear-gradient(0deg, rgba(6, 5, 11, 0.9) 0%, rgba(6, 5, 11, 0.7) 100%);
  left: 0;
  z-index: 120;
  backdrop-filter: blur(6px);
}
.modal-wrapper {
  z-index: 101;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: var(--s-6) var(--s-6);
}
.modal-wrapper.pin-to-top {
  align-items: flex-start;
}
.modal-wrapper.pin-to-top .modal {
  margin-top: var(--s-6);
}
.modal {
  background: var(--bg-primary_alt);
  z-index: 102;
  padding: var(--s-6);
  border-radius: var(--rounded-xl);
  position: relative;
  min-width: var(--min-width);
  max-height: calc(100% - (var(--s-6)) * 2);
}
/* minWidth maxWidth prop sizing */
@media screen and (max-width: var(--min-width)) {
  .modal {
    width: 100%;
    min-width: 0; /* Reset min-width */
  }
}

@media screen and (min-width: var(--max-width)) {
  .modal {
    width: var(--max-width); /* Set width to max-width */
  }
}
.modal.modal-size-fullscreen {
  width: 100%;
  height: 100%;
}

.modal-title {
  text-align: left;
  margin-bottom: var(--s-6);
}
.modal-close-btn {
  position: absolute;
  top: var(--s-3);
  right: var(--s-3);

  transition: all 0.2s ease-in-out;
}

.modal-footer {
  margin-top: var(--s-6);
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

/* Transitions */
.modal-enter-from {
  transform: translateY(20px);
  opacity: 0;
}
.modal-enter-to {
  transform: translateY(0px);
  opacity: 1;
}
.modal-enter-active {
  transition: all 0.3s ease-in-out;
}
.modal-leave-from {
  transform: translateY(0px);
  opacity: 1;
}
.modal-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
.modal-leave-active {
  transition: all 0.3s ease-in-out;
}

.modal-backdrop-enter-from {
  /* opacity: 0; */
  background: rgba(0, 0, 0, 0);
}
.modal-backdrop-enter-to {
  /* opacity: 1; */
  background: rgba(0, 0, 0, 0.451);
}
.modal-backdrop-enter-active {
  transition: all 0.3s ease-in-out;
}
.modal-backdrop-leave-from {
  background: rgba(0, 0, 0, 0.451);
  opacity: 1;
}
.modal-backdrop-leave-to {
  opacity: 0;
  background: rgba(0, 0, 0, 0);
}
.modal-backdrop-leave-active {
  transition: all 0.3s ease-in-out;
}
</style>
