// Environment
let currentEnv = import.meta.env.MODE
let currentVersion = packageInfo.version

// console.log('Current Environment:', currentEnv)
console.log('v' + currentVersion)

// Function to get the package version and check against version.txt
async function checkVersion() {
  if (currentEnv === 'development') {
    return
  }

  try {
    let pathToVersionFile = '/version.txt'
    if (currentEnv === 'development') {
      pathToVersionFile = '/dist/version.txt'
    }
    const versionFileResponse = await fetch(pathToVersionFile)
    if (versionFileResponse.ok) {
      const latestVersion = await versionFileResponse.text()

      if (currentVersion !== latestVersion) {
        // Emit the version mismatch event
        emitter.emit('version-mismatch', {
          currentVersion,
          latestVersion
        })
      }
    } else {
      console.error('Failed to fetch version.txt:', versionFileResponse.statusText)
    }
  } catch (error) {
    console.error('Error fetching version.txt:', error)
  }
}

import { createApp, markRaw } from 'vue'

// Global event bus emitter
import mitt from 'mitt'
const emitter = mitt()

import './assets/css/tailwind.css'

import 'vue-toastification/dist/index.css'
import '@/assets/css/flatpickr.css' //Override stylesheet
import '@/assets/css/toastification.css' //Override stylesheet
import '@/assets/css/multiselect.css' //Override stylesheet
import 'tippy.js/dist/tippy.css' // optional for styling
import 'tippy.js/dist/backdrop.css' // optional for animation
import 'tippy.js/animations/perspective.css' // optional for animation
// import 'tippy.js/themes/light.css'
import '@/assets/css/tippy.css' //Custom themes
import '@/assets/css/apexcharts.css' //Custom themes
import '@/assets/css/vueform.css'

import 'primevue/resources/primevue.css'
import 'primeicons/primeicons.css'
import '@/assets/css/primevue/theme.css'

import 'sweetalert2/dist/sweetalert2.min.css'
import '@/assets/css/swal.css'

import './assets/css/fullcalendar.css'

import '@/assets/css/main.css' // INFO override ALL stylesheets

import { useUserStore } from '@/stores/UserStore'
import { createPinia } from 'pinia'
import App from './App.vue'
import { healthCheck } from './utilities/server-healthcheck.js'
import router from './router'
// import i18n from "./i18n"
import { createI18n } from './i18n'
import VueClickAway from 'vue3-click-away'
// import LangFlag from 'vue-lang-code-flags'
import { abilitiesPlugin } from '@casl/vue'
import ability from './config/ability.js'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import relativeTime from 'dayjs/plugin/relativeTime'
import isToday from 'dayjs/plugin/isToday'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isoWeek from 'dayjs/plugin/isoWeek'
import isBetween from 'dayjs/plugin/isBetween'
import duration from 'dayjs/plugin/duration'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Toast from 'vue-toastification'
import VueTippy from 'vue-tippy'
import VueApexCharts from 'vue3-apexcharts'
import PrimeVue from 'primevue/config'
import DialogService from 'primevue/dialogservice'
import { VueClipboard } from '@soerenmartius/vue3-clipboard'

import flatpickr from 'flatpickr'
import { english as en } from 'flatpickr/dist/l10n/default.js'
import { Spanish as es } from 'flatpickr/dist/l10n/es.js'
import { French as fr } from 'flatpickr/dist/l10n/fr.js'

// Create a mapping for the locales
const localeMapping = {
  en,
  es,
  fr
}

function setFlatpickrLocale(locale) {
  const baseLocale = locale.split('-')[0] // Extract the base locale (first part before the hyphen)

  const selectedLocale = localeMapping[baseLocale]

  if (selectedLocale) {
    flatpickr.localize(selectedLocale)
  } else {
    console.error(`(Flatpickr) Locale "${baseLocale}" not found. Using default.`)
    flatpickr.localize(en) // Default to English if the locale isn't found
  }
}

// Import google tag manager plugin
import VueGtag from 'vue-gtag'

import Hotjar from '@hotjar/browser'
const hotjarSiteId = 3248418
const hotjarVersion = 6
// if(currentEnv === 'production'){
Hotjar.init(hotjarSiteId, hotjarVersion)
// }

// // Initializing with `debug` option:
// Hotjar.init(hotjarSiteId, hotjarVersion, {
//   debug: true
// })

// Issue/bug reporting and monitoring using Sentry
import * as Sentry from '@sentry/vue'
import { CaptureConsole } from '@sentry/integrations'
// import { BrowserTracing } from '@sentry/tracing'

import packageInfo from '/package.json'

async function initApp() {
  const app = createApp(App)

  app.config.globalProperties.emitter = emitter
  app.provide('emitter', emitter)

  const pinia = createPinia()
  pinia.use(({ store }) => {
    store.router = markRaw(router)
  })

  const initLocale = localStorage.getItem('locale') || navigator.language || 'en-GB'

  localStorage.setItem('locale', initLocale)

  const i18n = createI18n(initLocale)

  app.config.globalProperties.$cdnBaseUrl = import.meta.env.VITE_CDN_BASE_URL
  app.config.globalProperties.$apiBaseUrl = import.meta.env.VITE_API_BASE_URL
  app.config.globalProperties.$frontendBaseUrl = import.meta.env.VITE_FRONTEND_BASE_URL

  app.use(pinia)
  const userStore = useUserStore()

  const connectivity = await healthCheck()

  if (!connectivity.api || !connectivity.cdn) {
    console.log(connectivity)
    alert(
      'Connection to the API or CDN failed.\n\nPlease contact your IT dept. to make sure the following URLs are not being blocked\n\nAPI: ' +
        import.meta.env.VITE_API_BASE_URL +
        '\nCDN: ' +
        import.meta.env.VITE_CDN_BASE_URL
    )
    return
  }

  if (currentEnv === 'production') {
    Sentry.init({
      app,
      dsn: 'https://2a8b9faeaba14d4c9510514786b344c9@o1239911.ingest.sentry.io/6391736',
      environment: currentEnv,
      release: 'core-v3@' + currentVersion,
      ignoreErrors: ['TWILIO DEVICE ERROR', 'AccessTokenExpired', 'AccessTokenInvalid', /Twilio/i],
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          // tracingOrigins: ['testing.retain.group', /^\//]
          tracingOrigins: ['*']
        }),
        new CaptureConsole({
          levels: ['error'] // Capture console errors and warnings
        })
        // new Sentry.Replay()
      ],
      initialScope: {
        user: {
          id: userStore.details.id,
          email: userStore.details.email
        }
      },
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0
    })
  }

  app.use(router)
  app.use(i18n)
  app.use(VueClickAway)
  app.use(abilitiesPlugin, ability, {
    useGlobalProperties: true
  })
  app.use(dayjs)
  dayjs.extend(customParseFormat)
  dayjs.extend(localizedFormat)
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(relativeTime)
  dayjs.extend(isToday)
  dayjs.extend(advancedFormat)
  dayjs.extend(weekOfYear)
  dayjs.extend(isoWeek)
  dayjs.extend(isBetween)
  dayjs.extend(duration)

  //Update DayJS locale
  dayjs.locale(initLocale)
  dayjs.utc()

  // Call the function to set the flatpickr locale
  setFlatpickrLocale(initLocale)

  let userTimezone = dayjs.tz.guess()
  // let userTimezone = 'America/Los_Angeles'
  dayjs.tz.setDefault(userTimezone)

  localStorage.setItem('TZ', userTimezone)

  // console.log(dayjs.locale(), dayjs.tz.guess(), dayjs())

  app.config.globalProperties.$dayjs = dayjs

  axios.defaults.withCredentials = true

  app.use(VueAxios, axios)
  app.provide('axios', app.config.globalProperties.axios) // provide 'axios'
  app.use(Toast)
  app.use(
    VueTippy,
    // optional
    {
      directive: 'tippy', // => v-tippy
      component: 'tippy', // => <tippy/>
      componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
      defaultProps: {
        allowHTML: true,
        placement: 'top',
        delay: 300,
        theme: 'default'
      } // => Global default options * see all props
    }
  )
  app.use(VueApexCharts)
  app.use(PrimeVue)
  app.use(DialogService)
  app.use(VueClipboard)

  app.use(VueGtag, {
    config: {
      id: 'G-6CET7FZ9P3',
      params: {
        user_id: userStore.details.id,
        user_type: userStore.details.type
      }
    }
  })

  app.mount('#app')

  //Set the title of the app to be displayed in the tab
  document.title = 'Retain'

  app.mixin({
    methods: {
      dayjs: dayjs, //TODO remove this. All instances of dayjs should be replaced with $dayjs, which is configured with timezone and locale
      getTimezone: () => {
        return localStorage.getItem('TZ')
      },
      numberFormatter: function (number = 0, type = 'normal', dp = 0, currency = 'gbp') {
        let locale = localStorage.getItem('locale')
        let options = {}

        //If Currency
        if (type === 'currency') {
          options = {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: dp,
            maximumFractionDigits: dp
          }
        } else {
          options = {
            minimumFractionDigits: dp,
            maximumFractionDigits: dp
          }
        }
        let formatter = new Intl.NumberFormat(locale, options)
        return formatter.format(number)

        // if (type === 'normal') {
        //   if (number == null) {
        //     return '0.00'
        //   }
        //   return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
        // }
      }
    }
  })

  // console.log('App initialized')
}

initApp()

// Delay the initial version check to ensure listeners are set up
setTimeout(() => {
  checkVersion()

  // Set up interval to check for version updates every 30 seconds
  setInterval(() => {
    checkVersion()
  }, 60000) // 60 seconds interval
}, 5000) // 5 seconds initial delay
