<template>
  <PageHeader
    :title="$t('ui.reports.appointed-customers')"
    breadcrumb
    :items="[
      { label: $t('ui.entities.report.label', 2), to: '/reports' },
      { label: $t('ui.reports.appointed-customers'), to: '/reports/customers/appointed-customers' }
    ]"
  >
    <template #right>
      <div class="flex items-center gap-2">
        <div v-if="dealershipsAllowed.length > 1">
          <MultiSelect
            v-model="dealershipsSelected"
            :options="dealershipsAllowed"
            display="chip"
            filter
            option-value="id"
            option-label="name"
            placeholder="Select a Dealership"
            class="max-w-[350px]"
          >
          </MultiSelect>
        </div>
        <div v-else class="flex">
          <Icon type="map-marker" class="mr-1" />
          <div>{{ dealershipsAllowed[0].name }}</div>
        </div>

        <DatePicker v-model="dateRange" selection-mode="range" position="bottom-right" :max-date="new Date()" :number-of-months="2" />
        <Button class="flex-shrink-0" label="Update" :is-loading="customerDataApiRequest.isLoading.value" @click="onDealershipChange" />
      </div>
    </template>
  </PageHeader>

  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      export-filename="Appointed Customers"
      :value="customerData"
      removable-sort
      sort-mode="multiple"
      :sort-order="-1"
      :rows="20"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :global-filter-fields="['customerName', 'dealershipName', 'model', 'vrm', 'assignedToName', 'product', 'outcome']"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-report-appointed-customers"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
      :loading="customerDataApiRequest.isLoading.value"
      :export-function="formatExportData"
    >
      <template #empty> Select a dealership </template>
      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>
        </div>
        <div class="flex justify-between mt-4">
          <span>
            <Button label="CSV" type="button" severity="secondary" outlined icon="file-excel" @click="exportCSV($event)" />
          </span>
        </div>
      </template>

      <Column field="customerName" header="Customer" :sortable="true">
        <template #body="{ data }">
          <router-link :to="'/agreement/' + data.agreementId" class="text-primary whitespace-nowrap">{{ data.customerName }}</router-link>
        </template>
      </Column>

      <Column field="dealershipId" header="Dealership" :sortable="true">
        <template #body="{ data }">
          <div class="whitespace-nowrap">{{ data.dealershipName }}</div>
        </template>
      </Column>

      <Column field="assignedToName" header="Assigned To" :sortable="true">
        <template #body="{ data }">
          <div v-if="data.assignedToName" class="whitespace-nowrap">{{ data.assignedToName }}</div>
        </template>
      </Column>

      <Column field="time" header="Appointment Date" :sortable="true">
        <template #body="{ data }">
          <router-link :to="'/agreement/' + data.agreementId + '/appointments/' + data.appointmentId">
            <div v-tippy="dayjs(data.time).format('H:mma DD/MM/YYYY')" class="text-primary whitespace-nowrap">
              {{ dayjs(data.time).format('LL') }}
            </div>
          </router-link>
        </template>
      </Column>

      <Column field="endDate" header="End Date" :sortable="true">
        <template #body="{ data }">
          <div class="whitespace-nowrap">{{ dayjs(data.endDate).format('LL') }}</div>
        </template>
      </Column>

      <!-- Hidden so that the value is exported in CSV -->
      <Column field="status" header="Status" hidden>
        <template #body="{ data }"> {{ data.status }}</template>
      </Column>

      <Column field="outcome" header="Outcome">
        <template #body="{ data }">
          <div v-if="data.outcome" class="flex gap-1">
            <Icon
              v-tippy="$t(getProperties('appointmentOutcome', data.outcome)?.localeKey)"
              :type="$t(getProperties('appointmentOutcome', data.outcome)?.icon)"
              :class="'text-' + getProperties('appointmentOutcome', data.outcome)?.color"
            />
            <div v-if="data.decision">{{ $t(getProperties('appointmentDecision', data.decision)?.localeKey) }}</div>
            <!-- <div>{{ getProperties('appointmentDecision', data.decision)?.localeKey }}</div> -->
            <Icon v-if="data.outcomeNote" v-tippy="data.outcomeNote" type="note" />
          </div>
          <div v-else class="flex gap-1">
            <div class="text-secondary whitespace-nowrap italic">No Outcome</div>
            <!-- <Icon v-if="data.status" v-tippy="data.status" type="info-circle" fa-style="far" /> -->
          </div>
        </template>
      </Column>

      <Column field="decision" header="Decision" hidden>
        <template #body="{ data }">
          <span v-if="data.decision">{{ $t(getProperties('appointmentDecision', data.decision)?.localeKey) }}</span>
        </template>
      </Column>

      <Column field="vrm" :header="$t('ui.entities.vehicle.vrm')">
        <template #body="{ data }">
          <LicensePlate :plate="data.vrm" country="uk" :design="data.fuelType == 'electric' ? 'ev' : ''" greyscale />
        </template>
      </Column>

      <!-- Hidden so that the value is exported in CSV -->
      <Column field="make" header="Make" hidden>
        <template #body="{ data }">
          {{ data.make }}
        </template>
      </Column>

      <Column field="model" header="Vehicle" filter-field="model" :sortable="true">
        <template #body="{ data }">
          <div class="flex items-center">
            <Avatar :url="data.manufacturerLogo" is-logo />
            <div class="ml-3">
              <div class="font-semibold flex items-center whitespace-nowrap">
                {{ data.model }}
                <Chip v-if="data.newUsed" size="sm" class="mx-2">{{ data.newUsed }}</Chip>
              </div>
              <div class="text-md text-secondary whitespace-nowrap" style="margin-top: -3px">
                {{ data.derivative }}
              </div>
            </div>
          </div>
        </template>
      </Column>

      <!-- Hidden so that the value is exported in CSV -->
      <Column field="derivative" header="Derivative" hidden>
        <template #body="{ data }">
          {{ data.derivative }}
        </template>
      </Column>

      <Column field="productType" header="Product" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <!-- <Chip>{{ findProduct(data.productType) || data.productType }}</Chip> -->
          <Chip>{{ data.productType }}</Chip>
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="productOptions"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="All"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.value }}
            </template>
          </MultiSelect>
        </template>
      </Column>

      <Column field="estimatedDeliveryDate" header="Delivery ETA" :sortable="true">
        <template #body="{ data }">
          <div v-if="data.estimatedDeliveryDate" v-tippy="dayjs(data.estimatedDeliveryDate).format('DD/MM/YYYY')" class="whitespace-nowrap">
            {{ dayjs().to(dayjs(data.estimatedDeliveryDate)) }}
          </div>
        </template>
      </Column>
    </DataTable>
  </Card>
</template>

<script setup>
import Card from '@/components/card/Card.vue'
import PageHeader from '@/components/page/PageHeader.vue'
import DatePicker from '@/components/date-picker/DatePicker.vue'
import Icon from '@/components/icon/Icon.vue'
import Input from '@/components/forms/Input.vue'
import Button from '@/components/button/Button.vue'
import Avatar from '@/components/avatar/Avatar.vue'
import Chip from '@/components/chip/Chip.vue'

import LicensePlate from '@/components/license-plate/LicensePlate.vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'
import MultiSelect from 'primevue/multiselect'

import dayjs from 'dayjs'

import useApiRequest from '@/composables/useApiRequest'
import { useUserStore } from '@/stores/UserStore'
import { getProperties } from '@/utilities/dataMapper'

import { ref } from 'vue'

const dt = ref()
const customerData = ref(null)

const dateRange = ref([dayjs().startOf('month').toDate(), dayjs().toDate()])

const productOptions = [
  { value: 'PCP', label: 'PCP' },
  { value: 'HP', label: 'HP' },
  { value: 'BCH', label: 'BCH' },
  { value: 'CH', label: 'CH' }
]

const userStore = useUserStore()

const dealershipsSelected = ref([])
const dealershipsSelectedLS = JSON.parse(localStorage.getItem('report-appointed-customers-dealerships-selected'))
if (dealershipsSelectedLS) {
  dealershipsSelected.value = dealershipsSelectedLS
}
const dealershipsAllowed = ref(userStore.accessAllowed.dealerships)

// If dealershipsSelected has values which aren't in dealershipsAllowed, remove them
dealershipsSelected.value = dealershipsSelected.value.filter(d => dealershipsAllowed.value.map(da => da.id).includes(d))
localStorage.setItem('report-appointed-customers-dealerships-selected', JSON.stringify(dealershipsSelected.value))

const filters = ref([])

function initFilters() {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    productType: { value: null, matchMode: FilterMatchMode.IN }
  }
}
initFilters()

function clearFilters() {
  //remove from session storage
  sessionStorage.removeItem('dt-report-appointed-customers')
  initFilters()
}

const customerDataApiRequest = useApiRequest()

function onDealershipChange() {
  localStorage.setItem('report-appointed-customers-dealerships-selected', JSON.stringify(dealershipsSelected.value))

  fetchCustomerData()
}

function fetchCustomerData() {
  if (dateRange.value[0] && dateRange.value[1] && dealershipsSelected.value) {
    customerDataApiRequest
      .sendRequest({
        endpoint: '/v1/reports/appointments/appointed-customers',
        params: {
          start: dayjs(dateRange.value[0]).format('YYYY-MM-DD 00:00:00'),
          end: dayjs(dateRange.value[1]).format('YYYY-MM-DD 23:59:59'),
          dealerships: dealershipsSelected.value.join(',')
        }
      })
      .then(response => {
        if (response.data.success) {
          customerData.value = response.data.data
        }
      })
  }
}
if (dealershipsSelected.value.length) {
  fetchCustomerData()
}

function formatExportData({ data, field }) {
  switch (field) {
    case 'dealershipId':
      return dealershipsAllowed.value.find(d => d.id === data)?.name
    default:
      return data
  }
}

const exportCSV = () => {
  dt.value.exportCSV()
}
</script>

<style></style>
