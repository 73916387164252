<template>
  <div>
    <component :is="dashboardComponent" />
  </div>
</template>

<script>
import { ref, shallowRef, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { dashboards, getDashboardsForRoles, getDefaultDashboardForRoles, hasMultipleDashboards } from '@/utilities/dashboards'

import { useUserStore } from '@/stores/UserStore'

export default {
  name: 'Home',
  setup() {
    const router = useRouter()
    const userStore = useUserStore()

    const userRoleIds = ref(userStore.roles)

    const dashboardComponent = shallowRef(null)

    onMounted(async () => {
      // If the user has multiple dashboards, route to the dashboard selector
      if (hasMultipleDashboards(userRoleIds.value)) {
        //Route to the default dashboard route
        router.replace(getDefaultDashboardForRoles(userRoleIds.value).route)
      }

      // Get default dashboard route using getDefaultDashboardForRole(userRoleId.value).route
      const dashboardObj = getDefaultDashboardForRoles(userRoleIds.value)

      // console.log(`Loading: ${defaultDashboard.component}`)

      //Dynamically import the dashboard component
      const dashboard = await import(`./dashboards/${dashboardObj.componentFolder}/${dashboardObj.componentName}.vue`)

      dashboardComponent.value = dashboard.default
    })

    return { userRoleIds, dashboardComponent, dashboards, getDashboardsForRoles, getDefaultDashboardForRoles, hasMultipleDashboards }
  }
}
</script>
