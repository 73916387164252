<template>
  <ConversationsTimeline style="height: 100%" :conversations="conversations" @reply-to="replyTo" />

  <Modal
    :is-open="modalIsOpen"
    :title="newComm.modalTitle"
    :description="'Send an ' + newComm.type + ' to ' + agreement.customer.name"
    :icon="newComm.type == 'call' ? 'phone' : newComm.type == 'email' ? 'envelope' : 'message'"
    :ok-text="newComm.isSending ? 'Sending...' : 'Send'"
    close-text="Cancel"
    :min-width="newComm.type == 'email' ? '600' : '400'"
    overflow-behaviour="visible"
    @close-modal="closeModal"
    @ok-modal="submitNewComm"
  >
    <div v-if="newComm.type == 'call'">To: {{ agreement.customer.contact.mobile }}</div>
    <div v-if="newComm.type == 'email'">
      <div class="flex items-center py-2 border-b border-b-separator">
        <div class="mr-3 text-secondary" :class="{ '!text-danger': v$.email.from.$error }">From</div>
        <input
          v-model="newComm.email.from"
          v-tippy="{ content: 'Automatically set', placement: 'top-start' }"
          readonly
          :class="{ '!text-danger': v$.email.from.$error }"
          class="!bg-transparent w-full cursor-not-allowed"
        />
      </div>

      <div class="flex items-center py-2 border-b border-b-separator">
        <div class="mr-3 text-secondary" :class="{ '!text-danger': v$.email.to.$error }">To</div>
        <input v-model="newComm.email.to" class="w-full" :class="{ '!text-danger': v$.email.to.$error }" />
      </div>

      <div class="flex items-center py-2 border-b border-b-separator">
        <div class="mr-3 text-secondary" :class="{ '!text-danger': v$.email.subject.$error }">Subject</div>
        <input v-model="newComm.email.subject" class="w-full" />
        <Dropdown class="mt-0" position="bottom-right">
          <template #triggerContent>
            <div class="flex items-center cursor-pointer">
              <Icon type="sparkles" class="mr-1" />Templates<Icon type="chevron-down" class="ml-1" />
            </div>
          </template>
          <DropdownItemGroup v-for="category in commTemplatesOrdered" :key="category.name" :text="category.name">
            <DropdownItem v-for="template in category.items" :key="template.id" @item-clicked="applyTemplate(template.id)">{{
              template.name
            }}</DropdownItem>
          </DropdownItemGroup>
        </Dropdown>
      </div>

      <div class="py-2">
        <textarea
          v-model="newComm.email.body"
          :class="{ '!placeholder-danger !text-danger': v$.email.body.$error }"
          class="w-full"
          placeholder="Body"
          :rows="10"
        ></textarea>
      </div>
    </div>
    <div v-if="newComm.type == 'sms'">
      <div class="grid grid-cols-2 gap-8">
        <FormGroup>
          <Label>From</Label>
          <Input v-model="newComm.sms.from" readonly :has-error="v$.sms.from.$error">{{ newComm.sms.from }}</Input>
          <InputError :has-error="v$.sms.from.$error">{{ v$.sms.from.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup>
          <Label>To</Label>
          <Input v-model="newComm.sms.to" :has-error="v$.sms.to.$error">{{ newComm.sms.to }}</Input>
          <InputError :has-error="v$.sms.to.$error">{{ v$.sms.to.$errors[0]?.$message }}</InputError>
        </FormGroup>
      </div>
      <FormGroup>
        <Label>Message</Label>
        <Textarea v-model="newComm.sms.body" :has-error="v$.sms.body.$error">{{ newComm.sms.body }}</Textarea>
        <InputError :has-error="v$.sms.body.$error">{{ v$.sms.body.$errors[0]?.$message }}</InputError>
      </FormGroup>
      <Dropdown class="mt-3">
        <template #triggerContent>
          <Button label="Templates" severity="secondary" outlined icon="sparkles" is-dropdown />
        </template>
        <DropdownItemGroup v-for="category in commTemplatesOrdered" :key="category.name" :text="category.name">
          <DropdownItem v-for="template in category.items" :key="template.id" @item-clicked="applyTemplate(template.id)">{{
            template.name
          }}</DropdownItem>
        </DropdownItemGroup>
      </Dropdown>
    </div>
    <InputError :has-error="v$.email.$error" class="text-center w-full">{{ v$.email.$errors[0]?.$message }}</InputError>

    <!-- {{ newComm }} -->
  </Modal>
</template>

<script>
import { ref, inject, toRef, watch, computed } from 'vue'
import ConversationsTimeline from '@/components/conversations/timeline/ConversationsTimeline.vue'
import Modal from '@/components/modal/Modal.vue'

import useVuelidate from '@vuelidate/core'
import { email, requiredIf, helpers } from '@vuelidate/validators'

import Input from '@/components/forms/Input.vue'
import InputError from '@/components/forms/InputError.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Label from '@/components/forms/Label.vue'
import Textarea from '@/components/forms/TextArea.vue'
import Button from '@/components/button/Button.vue'
import Icon from '@/components/icon/Icon.vue'

import Dropdown from '@/components/dropdown/Dropdown.vue'
import DropdownItem from '@/components/dropdown/DropdownItem.vue'
import DropdownItemGroup from '@/components/dropdown/DropdownItemGroup.vue'

import getCommTemplates from '@/composables/getCommTemplates'

import dayjs from 'dayjs'
import axios from 'axios'
import notification from '../../utilities/notification'

import usePhoneValidator from '@/composables/usePhoneValidator'

import { useI18n } from 'vue-i18n'

import { useUserStore } from '@/stores/UserStore'

export default {
  components: {
    ConversationsTimeline,
    Modal,
    Input,
    InputError,
    FormGroup,
    Label,
    Textarea,
    Button,
    Icon,
    Dropdown,
    DropdownItem,
    DropdownItemGroup
  },
  props: {
    agreement: {
      type: Object,
      required: true,
      default: null
    },
    conversations: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['new-conversation-submitted'],
  setup(props, { emit }) {
    const emitter = inject('emitter')
    const userStore = useUserStore()

    const fromNumberValidator = usePhoneValidator()

    const agreement = toRef(props, 'agreement')

    fromNumberValidator.validatePhoneNumber(
      '+' + agreement.value?.dealership?.commsConfig.phone?.countryCode + '' + agreement.value?.dealership?.commsConfig.phone?.number
    )

    const newComm = ref({ type: null, call: {}, email: { to: '', from: '', subject: '', body: '' }, sms: { to: '', from: '', body: '' } })

    const { commTemplates, loadCommTemplates } = getCommTemplates({
      types: ['email', 'sms']
      // dealershipID: props.agreement.dealership.id
    })
    loadCommTemplates()

    const commTemplatesOrdered = computed(() => {
      // Remove templates which are not equal to the selected contact method (newComm.type)
      let filtered = commTemplates.value.filter(template => template.type == newComm.value.type)

      // Remove irrelevant categories. If PCP, remove HP and CH. If HP, remove PCP and CH. If CH, remove PCP and HP.
      if (agreement.value?.productType == 'PCP') {
        filtered = filtered.filter(template => template.category !== 'HP' && template.category !== 'CH')
      }
      if (agreement.value?.productType == 'HP') {
        filtered = filtered.filter(template => template.category !== 'PCP' && template.category !== 'CH')
      }
      if (agreement.value?.productType == 'CH') {
        filtered = filtered.filter(template => template.category !== 'PCP' && template.category !== 'HP')
      }

      let categories = [...new Set(filtered.map(item => item.category))]
      let ordered = []
      categories.forEach(category => {
        let items = filtered.filter(item => item.category == category)
        ordered.push({ name: category, items: items })
      })
      return ordered
    })

    const inputRules = {
      email: {
        from: {
          required: helpers.withMessage(
            'Sender is required',
            requiredIf(function () {
              return newComm.value.type == 'email'
            })
          ),
          email: helpers.withMessage(
            'Invalid sender email address',
            requiredIf(function () {
              return newComm.value.type == 'email'
            })
          )
        },
        to: {
          required: helpers.withMessage(
            'Recipient is required',
            requiredIf(function () {
              return newComm.value.type == 'email'
            })
          ),
          email: helpers.withMessage('Invalid recipient email address', email)
        },
        subject: {
          required: helpers.withMessage(
            'Email subject is required',
            requiredIf(function () {
              return newComm.value.type == 'email'
            })
          )
        },
        body: {
          required: helpers.withMessage(
            'Email body is required',
            requiredIf(function () {
              return newComm.value.type == 'email'
            })
          ),
          containsPlaceholders: helpers.withMessage('The email body contains placeholders {{ }}', value => {
            // If the type is not email, always return true (meaning no error for this rule)
            if (newComm.value.type !== 'email') {
              return true
            }

            // If the type is email and the value includes {{ return false (meaning there's an error)
            if (value.includes('{{')) {
              return false
            }

            // Otherwise, return true (meaning no error for this rule)
            return true
          })
        }
      },
      sms: {
        from: {
          required: helpers.withMessage(
            'Sender number is required',
            requiredIf(function () {
              return newComm.value.type == 'sms'
            })
          )
        },
        to: {
          required: helpers.withMessage(
            'Recipient number is required',
            requiredIf(function () {
              return newComm.value.type == 'sms'
            })
          )
        },
        body: {
          required: helpers.withMessage(
            'SMS message is required',
            requiredIf(function () {
              return newComm.value.type == 'sms'
            })
          ),
          containsPlaceholders: helpers.withMessage('The SMS message contains placeholders {{ }}', value => {
            if (newComm.value.type !== 'sms') {
              return true
            }
            if (value.includes('{{')) {
              return false
            }
            return true
          })
        }
      }
    }

    const v$ = useVuelidate(inputRules, newComm)

    function brToNewLine(str) {
      return str?.replace(/<br\s*\/?>/gm, '\n')
    }

    const placeholders = ref([
      {
        placeholder: '{{email_greeting}}',
        value: agreement.value?.dealership?.commsConfig?.email?.greeting
      },
      {
        placeholder: '{{email_sign_off}}',
        value: agreement.value?.dealership?.commsConfig?.email?.signOff
      },
      {
        placeholder: '{{on_behalf_of}}',
        value: agreement.value?.dealership?.commsConfig?.email?.onBehalfOf
      },
      {
        placeholder: '{{sms_greeting}}',
        value: 'Hi {{customer_name}},'
        // value: ''
      },
      {
        placeholder: '{{sms_sign_off}}',
        value: '{{user_forename}} - {{dealership_name}}'
      },
      {
        placeholder: '{{customer_name}}',
        value: agreement.value?.customer?.name ?? 'customer'
      },
      {
        placeholder: '{{customer_forename}}',
        value: agreement.value?.customer?.isCompany ? agreement.value?.customer?.companyName : agreement.value?.customer?.forename
      },
      {
        placeholder: '{{customer_surname}}',
        value: agreement.value?.customer?.isCompany ? agreement.value?.customer?.companyName : agreement.value?.customer?.surname
      },
      {
        placeholder: '{{agreement_id}}',
        value: agreement.value?.id
      },
      {
        placeholder: '{{agreement_end_date}}',
        value: dayjs(agreement.value?.endDate).format('DD/MM/YYYY')
      },
      {
        placeholder: '{{agreement_final_repayment}}',
        value: agreement.value?.finalRepayment
      },
      {
        placeholder: '{{agreement_product_type}}',
        value: agreement.value?.productType
      },
      {
        placeholder: '{{vehicle_category}}',
        value: agreement.value?.vehicle?.category
      },
      {
        placeholder: '{{vehicle_vrm}}',
        value: agreement.value?.vehicle?.vrm
      },
      {
        placeholder: '{{vehicle_make}}',
        value: agreement.value?.vehicle?.make ?? ''
      },
      {
        placeholder: '{{vehicle_model}}',
        value: agreement.value?.vehicle?.model ?? ''
      },
      {
        placeholder: '{{odometer_allowance_yearly}}',
        value: useI18n().n(agreement.value?.odometer?.allowanceYearly, 'default') ?? ''
      },
      {
        placeholder: '{{odometer_unit}}',
        value: agreement.value?.odometer?.unit ?? ''
      },
      {
        placeholder: '{{odometer_expected}}',
        value: useI18n().n(agreement.value?.odometer?.expected, 'default') ?? ''
      },
      {
        placeholder: '{{dealership_name}}',
        value: agreement.value?.dealership.displayName ?? 'the dealership'
      },
      {
        placeholder: '{{dealership_assigned_phone_number}}',
        value: fromNumberValidator?.validatedData?.value.number.national
      },
      {
        placeholder: '{{dealership_assigned_email}}',
        value: agreement.value?.dealership?.commsConfig.email.emailAddress
      },
      {
        placeholder: '{{dealership_assigned_sms_number}}',
        value: agreement.value?.dealership?.commsConfig.sms
      },
      {
        placeholder: '{{user_name}}',
        value: userStore.details.name ?? 'Engagement Team'
      },
      {
        placeholder: '{{user_forename}}',
        value: userStore.details.forename ?? 'Engagement Team'
      },
      {
        placeholder: '{{user_surname}}',
        value: userStore.details.surname ?? 'Engagement Team'
      }
    ])

    function replacePlaceholders(value) {
      let newValue = value
      let placeholdersFound = false

      placeholders.value.forEach(placeholder => {
        if (newValue?.includes(placeholder.placeholder)) {
          newValue = newValue.replace(placeholder.placeholder, placeholder.value)
          placeholdersFound = true
        }
      })

      // If any placeholders were found and replaced, check for nested placeholders.
      if (placeholdersFound) {
        newValue = replacePlaceholders(newValue)
      }

      return newValue
    }

    function applyTemplate(templateID) {
      // Find template which is to be applied
      let template = commTemplates.value.find(t => t.id === templateID)
      if (template) {
        // Template found
        if (template.type == 'email') {
          newComm.value.email.subject = template.subject
          newComm.value.email.body = template.body
        }
        if (template.type == 'sms') {
          newComm.value.sms.body = template.body
        }

        // Replace subject and body placeholders with true values
        if (template.type == 'email') {
          if (template.subject) {
            newComm.value.email.subject = replacePlaceholders(newComm.value.email.subject)
          }
          newComm.value.email.body = brToNewLine(replacePlaceholders(newComm.value.email.body))
        }
        if (template.type == 'sms') {
          newComm.value.sms.body = brToNewLine(replacePlaceholders(newComm.value.sms.body))
        }
      }
    }

    watch(
      () => newComm.value.email.body,
      newValue => {
        // console.log('Change', oldValue, newValue)
        newComm.value.email.body = brToNewLine(replacePlaceholders(newValue))
      }
    )

    const modalIsOpen = ref(false)

    function replyTo(item) {
      // console.log('Replying to comm: ' + item)
      if (item.type == 'email') {
        openNewCommModal('email-reply', props.agreement, item)
      }
    }

    function openNewCommModal(commType, agreement, replyToItem = null) {
      //ReplToComm should be an object of the commItem which is being replied to
      // console.log(commType)
      // console.log(agreement)
      // console.log(replyToItem)

      newComm.value.agreement = agreement

      //set newComm data for modal to use
      switch (commType) {
        case 'call':
          newComm.value.type = 'call'
          newComm.value.modalTitle = 'Select Number to Call'
          break
        case 'sms':
          newComm.value.type = 'sms'
          newComm.value.modalTitle = 'Send SMS'
          newComm.value.sms.from = agreement.dealership.commsConfig.sms
          newComm.value.sms.to = agreement.customer.contact.mobile
          break
        case 'email':
          newComm.value.type = 'email'
          newComm.value.modalTitle = 'Send Email'
          newComm.value.email.from = agreement.dealership.commsConfig.email.emailAddress
          newComm.value.email.to = agreement.customer.contact.email
          break
        case 'email-reply':
          newComm.value.type = 'email'
          newComm.value.replyToItem = replyToItem
          newComm.value.email.subject = replyToItem.emailSubject
          newComm.value.modalTitle = 'Reply To ' + replyToItem.emailSubject
          newComm.value.email.from = agreement.dealership.commsConfig.email.emailAddress
          newComm.value.email.to = agreement.customer.contact.email
          break

        default:
          newComm.value.modalTitle = 'New Conversation'
          break
      }

      modalIsOpen.value = true
    }

    emitter.on('agreement-new-conversation', commType => {
      // *Listen* for event
      openNewCommModal(commType, props.agreement)
    })

    function closeModal() {
      // Do some checks to make sure that the modal isn't closed when there have been changes made by the user

      //Close the modal if all is ok
      v$.value.$reset()
      modalIsOpen.value = false
      resetNewCommObject()
    }

    function resetNewCommObject() {
      Object.assign(newComm.value, { type: null, call: {}, email: {}, sms: {} })
    }

    async function submitNewComm() {
      // console.log('Submitting new communication item')
      const isFormCorrect = await v$.value.$validate()

      if (!isFormCorrect) {
        return
      }
      newComm.value.isSending = true

      if (newComm.value.type == 'email') {
        let data = {
          agreementID: props.agreement.id,
          from: {
            email: newComm.value.email.from,
            name: props.agreement.dealership.name
          },
          to: {
            email: newComm.value.email.to,
            name: props.agreement.customer.name
          },
          subject: newComm.value.email.subject,
          body: newComm.value.email.body
        }

        //Check if this email is in reply to another email
        if (newComm.value.replyToItem) {
          data.replyTo = {
            messageID: newComm.value.replyToItem.emailMessageID,
            threadReferences: newComm.value.replyToItem.emailReferences
          }
        }

        let jsonData = JSON.stringify(data)
        // console.log(jsonData)
        try {
          axios.post(import.meta.env.VITE_API_BASE_URL + '/v1/comms/create/email', jsonData, { withCredentials: true }).then(response => {
            // console.log(response.data)

            newComm.value.isSending = false

            if (response.data.response.status != 'success') {
              notification('Error', 'Email failed to send', 'danger')

              return
            }

            notification('Success', 'Email Sent', 'success')

            //Emit to parent, to add the new communication to the array
            emit('new-conversation-submitted', data)

            closeModal()
            resetNewCommObject()
          })
        } catch (err) {
          if (err.response) {
            console.log(err.response.status)
            console.log(err.response.data)
            newComm.value.isSending = false
            notification('Error', 'Email failed to send', 'danger')
          }
        }
      }
      if (newComm.value.type == 'sms') {
        let data = {
          agreementId: props.agreement.id,
          from: newComm.value.sms.from,
          to: newComm.value.sms.to,
          body: newComm.value.sms.body
        }

        let jsonData = JSON.stringify(data)
        // console.log(jsonData)
        try {
          axios.post(import.meta.env.VITE_API_BASE_URL + '/v1/comms/create/sms', jsonData, { withCredentials: true }).then(response => {
            // console.log(response.data)

            if (response.data.response.status == 'error') {
              notification('Error', response.data.response.message, 'danger')
            } else {
              notification('Success', 'SMS Sent', 'success')

              //Emit to parent, to add the new communication to the array
              emit('new-conversation-submitted', data)
              closeModal()
              resetNewCommObject()
            }
            newComm.value.isSending = false
          })
        } catch (err) {
          if (err.response) {
            console.log(err.response.status)
            console.log(err.response.data)
            newComm.value.isSending = false
            notification('Error', 'SMS failed to send', 'danger')
          }
        }
      }
    }

    const commErrors = computed(() => {
      let formErrors = []
      if (newComm.value.type == 'email') {
        if (newComm.value.email?.subject == '') {
          formErrors.push('Missing Subject')
        }
        if (newComm.value.email?.body == '') {
          formErrors.push('Missing Body')
        }
        if (newComm.value.email?.body?.includes('{{')) {
          formErrors.push('Placeholder left in email body')
        }
      }
      return formErrors
    })
    return {
      newComm,
      v$,
      commErrors,
      modalIsOpen,
      replyTo,
      closeModal,
      submitNewComm,
      commTemplatesOrdered,
      applyTemplate,
      fromNumberValidator
    }
  }
}
</script>

<style>
.ql-container {
  height: 200px;
}
</style>
