<template>
  <div class="bg-container">
    <img class="blob blob-1" :src="$cdnBaseUrl + '/img/graphics/blobs/blob-1.svg'" />
    <img class="blob blob-2" :src="$cdnBaseUrl + '/img/graphics/blobs/blob-2.svg'" />
    <img class="blob blob-3" :src="$cdnBaseUrl + '/img/graphics/blobs/blob-3.svg'" />
  </div>
  <div class="auth-wrapper">
    <div class="auth-card">
      <div class="mb-5 h-20 py-3">
        <img style="height: 100%; margin: 0 auto" :src="$cdnBaseUrl + '/img/logos/retain/logo.svg'" alt="" />
      </div>

      <!-- <h4 class="my-5 font-medium text-gray-600">Welcome to Core! 👋</h4> -->

      <form @submit.prevent="submitLogin">
        <div class="flex flex-col">
          <label class="block text-gray-700 text-sm mb-2">Email</label>
          <input
            v-model="email"
            required
            type="email"
            class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div class="mt-3">
          <label class="block text-gray-700 text-sm mb-2">Password</label>
          <input
            v-model="password"
            required
            type="password"
            class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div v-if="error.length > 0" class="text-danger bg-red-100 py-2 px-5 mt-5 rounded-md">
          <Icon type="circle-exclamation" />
          {{ error }}
        </div>
        <Button label="Sign In" type="submit" block :is-loading="userStore.isAuthenticating" class="mt-5" />
      </form>

      <hr class="my-6" />

      <router-link to="/forgot-password" class="text-primary">Forgot Password?</router-link>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/icon/Icon.vue'
import { ref } from 'vue'
import updateAbility from '@/config/update-ability.js'
import { useUserStore } from '@/stores/UserStore'

import Button from '@/components/button/Button.vue'

import { useRouter } from 'vue-router'

export default {
  name: 'LogIn',
  components: {
    Button,
    Icon
  },
  setup() {
    const userStore = useUserStore()
    const router = useRouter()
    const email = ref()
    const password = ref()
    if (import.meta.env.DEV) {
      email.value = import.meta.env.VITE_DEV_USER_EMAIL
      password.value = import.meta.env.VITE_DEV_USER_PASSWORD
    } else {
      email.value = ''
      password.value = ''
    }
    const error = ref('')

    async function submitLogin() {
      // console.log('Starting login process')

      const result = await userStore.login({ e: email.value, p: password.value })

      if (result.success) {
        const redirectRef = router.currentRoute.value.query.ref
        let redirectTo = '/home'

        if (redirectRef != null) {
          redirectTo = new URL(redirectRef).pathname
        }

        // console.log('logged in, now redirect to: ' + redirectTo)

        updateAbility(redirectTo, router)
      } else {
        error.value = result.message
      }
    }

    return { email, password, error, submitLogin, userStore }
  }
}
</script>

<style scoped>
body,
html {
  height: 100%;
  font-size: 14px;
}

#app {
  height: 100%;
}

.auth-wrapper {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ecf8fc;
  height: 100vh;
}

.auth-card {
  background: #fff;
  min-width: 400px;
  max-width: 500px;
  margin: 1rem 0rem;
  padding: 3rem;
  box-shadow: var(--shadow-md);
  border-radius: 5px;
  z-index: 1;
  box-shadow: 0px 10px 10px 10px rgba(0, 0, 0, 0.04);
}

.bg-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.blob {
  position: absolute;
}

.blob-1 {
  top: -20%;
  right: 0;
  width: 40%;
  animation: moveBlobs1 8s infinite;
}

.blob-2 {
  top: -20%;
  left: 0;
  width: 20%;
  animation: moveBlobs2 7s infinite;
}

.blob-3 {
  bottom: -50%;
  left: 0;
  width: 80%;
  animation: moveBlobs3 6s infinite;
}

@keyframes moveBlobs1 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(10px, 10px) scale(1.01);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes moveBlobs2 {
  0% {
    transform: translate(0, 0) scale(1.01);
  }
  50% {
    transform: translate(-10px, 10px);
  }
  100% {
    transform: translate(0, 0) scale(1.01);
  }
}

@keyframes moveBlobs3 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(20px, -10px) scale(1.01);
  }
  100% {
    transform: translate(0, 0);
  }
}
</style>
