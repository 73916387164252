<template>
  <main class="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
    <div class="text-center">
      <Chip label severity="warning" dot outlined>403</Chip>
      <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
      <p class="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn’t find the page you’re looking for.</p>
      <div class="mt-10 flex items-center justify-center gap-x-6">
        <Button label="Back" severity="secondary" outlined icon="arrow-left" @click="$router.go(-1)" />
        <a href="mailto:support@retain.group" class="text-sm font-semibold text-gray-900">
          <Button plain label="Contact support" severity="secondary" />
        </a>
      </div>
    </div>
  </main>
</template>

<script setup>
import Button from '@/components/button/Button.vue'
import Chip from '@/components/chip/Chip.vue'
import * as Sentry from '@sentry/vue'

Sentry.captureMessage('404')
</script>

<style></style>
