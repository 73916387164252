<template>
  <PageHeader
    :title="$t('ui.entities.status-summary')"
    breadcrumb
    :items="[
      { label: $t('ui.entities.report.label', 2), to: '/reports' },
      { label: $t('ui.entities.user.role.call-agent', 2) },
      { label: $t('ui.entities.status-summary') }
    ]"
  >
    <template #right>
      <DatePicker
        v-model="dateSelected"
        position="bottom-right"
        :max-date="new Date()"
        :number-of-months="1"
        @change-applied="onDateSelectChange"
      />
    </template>
  </PageHeader>

  <Card>
    <div v-for="user in data" :key="user.id" class="table-record grid grid-cols-6">
      <div class="table-record-cell">
        <div class="flex items-center">
          <Avatar size="sm" :url="user.profilePicUrl" :text="user.initials" :user-presence-user-id="user.id" />
          <span class="ml-3">{{ user.forename }} {{ user.surname }}</span>
        </div>
      </div>

      <div class="col-span-5 mt-4">
        <ActivityTimeline v-model="user.periods" mode="summary" />
      </div>
    </div>
  </Card>
</template>

<script>
import { ref } from 'vue'
import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import DatePicker from '@/components/date-picker/DatePicker.vue'
import Avatar from '@/components/avatar/Avatar.vue'

import useApiRequest from '@/composables/useApiRequest'

import dayjs from 'dayjs'

import ActivityTimeline from '@/components/user-activity/UserActivityTimeline.vue'

export default {
  components: { PageHeader, Card, ActivityTimeline, DatePicker, Avatar },
  setup() {
    const dateSelected = ref(dayjs().format('YYYY-MM-DD'))
    const data = ref([])
    const apiRequest = useApiRequest()

    function getData() {
      apiRequest
        .sendRequest({ endpoint: '/v1/users/activity/timelines', params: { date: dayjs(dateSelected.value).format('YYYY-MM-DD') } })
        .then(response => {
          console.log(response)
          console.log(response.data.response.status)
          if (response.data.response.status === 'success') {
            data.value = response.data.response.data
          }
        })
    }
    getData()

    function onDateSelectChange() {
      getData()
    }
    return { data, dateSelected, onDateSelectChange }
  }
}
</script>

<style>
.table-record {
  border-bottom: 1px solid var(--border-secondary);
  overflow: hidden;
}
.table-record > .table-record-cell {
  padding: 1rem;
}
</style>
