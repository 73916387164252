<template>
  <div class="icon">
    <Badge rounded :size="badgeSize" :content="badgeContent">
      <i v-if="featured" class="far fa-fw fa-circle absolute mt-[2px] scale-[1.8] opacity-15" />
      <i v-if="featured" class="fal fa-fw fa-circle absolute mt-[2px] scale-[2.8] opacity-5" />
      <i
        :class="[{ 'fa-spin': spinner, 'fa-pulse': pulse }, faStyle, 'fa-fw fa-' + iconType]"
        :style="'color: var(--' + iconStyle + ')!important'"
      ></i>
    </Badge>
  </div>
</template>

<script>
import Badge from '@/components/badge/Badge.vue'
import { watch, ref } from 'vue'

export default {
  name: 'Icon',
  components: {
    Badge
  },
  props: {
    type: {
      type: String,
      default: null
    },
    iconStyle: {
      type: String,
      default: ''
    },
    badgeContent: {
      type: String,
      default: ''
    },
    spinner: {
      type: Boolean,
      default: false
    },
    pulse: {
      type: Boolean,
      default: false
    },
    faStyle: {
      type: String,
      default: 'fad'
    },
    badgeSize: {
      type: String,
      default: 'md'
    },
    featured: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const iconType = ref(props.type)

    // Watch for changes to type
    watch(
      () => props.type,
      newVal => {
        if (newVal) {
          iconType.value = newVal
        }
      },
      { immediate: true }
    )

    return { iconType }
  }
}
</script>

<style>
.icon {
  display: inline-flex;
  position: relative;
}
</style>
