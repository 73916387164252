<!-- eslint-disable vue/no-v-html -->
<template>
  <teleport to="#modals-container">
    <div v-if="isOpen" to="#modals-container" class="search-background" @mousedown.self="closeSearch">
      <div class="search-wrapper sm:w-full md:w-[700px]">
        <div class="search-card">
          <div class="flex items-center p-4">
            <Icon type="search" class="mr-2" />
            <input ref="searchInput" v-model="searchQuery" placeholder="Type to search..." class="w-full bg-transparent" />
          </div>
          <div v-if="searchResults.estimatedTotalHits > 0" class="search-results-card">
            <!-- <div>
              Found <span>{{ searchResults.estimatedTotalHits }}</span>
            </div> -->
            <div v-for="(hit, resultIndex) in searchResults.hits" :key="resultIndex" class="search-result-item">
              <div class="flex items-start">
                <div class="flex flex-col w-1/3">
                  <!-- <Highlighter :content=""></Highlighter> -->
                  <div class="font-medium" v-html="hit._formatted.name"></div>
                  <div class="text-sm flex flex-col text-quaternary mt-1">
                    <div v-if="hit.mobile && hit.mobile != '' && hit.mobile != 0" class="mr-3">
                      <Icon type="mobile" /> <span v-html="hit._formatted.mobile"></span>
                    </div>
                    <div v-if="hit.email && hit.email != ''" class="mr-3">
                      <Icon type="at" /> <span v-html="hit._formatted.email"></span>
                    </div>
                    <div v-if="hit.addressPostCode && hit.addressPostCode != ''">
                      <Icon type="location-dot" /> <span v-html="hit._formatted.addressPostCode"></span>
                    </div>
                  </div>
                </div>
                <div class="w-2/3 border border-seconondary rounded p-1">
                  <router-link
                    v-for="(agreement, agreementIndex) in hit._formatted.agreements"
                    :key="agreement.agreementID"
                    :to="'/agreement/' + hit.agreements[agreementIndex].agreementID"
                    class="search-item-agreement"
                    :class="{ 'focused-result': focusedResult === resultIndex && focusedAgreement === agreementIndex }"
                    @click="closeSearch()"
                  >
                    <img
                      :src="
                        $cdnBaseUrl +
                        '/img/logos/manufacturers/' +
                        hit.agreements[agreementIndex].vehicleMake?.toLowerCase() +
                        '-square.svg'
                      "
                      class="h-5 mr-2"
                    />
                    <!-- <Icon type="arrow-turn-down-right" class="mr-2" /> -->

                    <div class="w-full">
                      <!-- <span v-html="agreement.vehicleMake"></span> -->
                      <span class="text-tertiary" v-html="agreement.vehicleModel"></span>
                    </div>

                    <div class="search-number-plate font-number-plate-uk" v-html="agreement.vrm"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="search-card mt-5 !p-3 text-sm flex items-center gap-4">
          <div class="flex items-center">
            <div class="border border-primary rounded-lg mr-1 p-1">
              <Icon type="arrow-up" fa-style="fas" />
            </div>
            <div class="border border-primary rounded-lg mr-1 p-1">
              <Icon type="arrow-down" fa-style="fas" />
            </div>
            <div class="ml-1 font-medium text-quaternary">to navigate</div>
          </div>
          <div class="flex items-center">
            <div class="border border-primary rounded-lg mr-1 p-1">
              <Icon type="turn-down-left" fa-style="fas" />
            </div>
            <div class="ml-1 font-medium text-quaternary">to select</div>
          </div>
          <div class="flex items-center">
            <div class="border border-primary rounded-lg mr-1 p-1 px-2">esc</div>
            <div class="ml-1 font-medium text-quaternary">to close</div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { ref, onMounted, watch, watchEffect, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { MeiliSearch } from 'meilisearch'

import { onKeyStroke } from '@vueuse/core'
import { useUserStore } from '@/stores/UserStore'

import Icon from '@/components/icon/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeSearch'],
  setup(props, { emit }) {
    const router = useRouter()
    const userStore = useUserStore()

    const searchInput = ref(null)
    const searchQuery = ref('')
    const searchResults = ref([])
    const client = ref()

    const focusedResult = ref(0)
    const focusedAgreement = ref(0)

    const dealershipIdsString = ref(null)

    onMounted(() => {
      // console.log('Search Mounted')
      client.value = new MeiliSearch({
        host: import.meta.env.VITE_MEILISEARCH_URL,
        apiKey: import.meta.env.VITE_MEILISEARCH_API_KEY
      })
      // client.value.index('customers').updateRankingRules(['exactness', 'words', 'typo', 'proximity', 'attribute', 'sort'])
    })

    const search = async query => {
      let dealershipIds = userStore.accessAllowed.dealerships.map(dealership => dealership.id)
      dealershipIdsString.value = dealershipIds.join(',')

      // console.log(query)
      if (query) {
        searchResults.value = await client.value.index('customers').search(query, {
          filter: 'agreements.dealershipID IN [' + dealershipIdsString.value + ']',
          attributesToHighlight: ['*'],
          highlightPreTag: '<span class="search-results-highlight">',
          highlightPostTag: '</span>'
        })
        // console.log(searchResults.value)
      } else {
        searchResults.value = []
      }
    }

    function closeSearch() {
      searchQuery.value = null
      focusedResult.value = 0
      focusedAgreement.value = 0
      emit('closeSearch')
    }

    watchEffect(() => {
      search(searchQuery.value)
    })

    watch(
      () => props.isOpen,
      newValue => {
        if (newValue == 1) {
          nextTick(() => {
            searchInput.value.focus()
          })
        }
      }
    )
    onKeyStroke('ArrowDown', e => {
      if (!props.isOpen || !searchResults.value.hits) {
        return
      }
      e.preventDefault()
      // console.log('Down')
      if (focusedAgreement.value < searchResults.value.hits[focusedResult.value].agreements.length - 1) {
        focusedAgreement.value++
      } else if (focusedResult.value < searchResults.value.hits.length - 1) {
        focusedResult.value++
        focusedAgreement.value = 0
      }
    })
    onKeyStroke('ArrowUp', e => {
      if (!props.isOpen) {
        return
      }
      e.preventDefault()
      // console.log('Up')
      if (focusedAgreement.value > 0) {
        focusedAgreement.value--
      } else if (focusedResult.value > 0) {
        focusedResult.value--
        focusedAgreement.value = searchResults.value.hits[focusedResult.value].agreements.length - 1
      }
    })
    onKeyStroke('Enter', e => {
      // console.log(searchResults.value.hits + ' Hits')
      if (!props.isOpen || !searchResults.value.hits || searchResults.value.hits?.length === 0) {
        return
      }
      e.preventDefault()
      const hit = searchResults.value.hits[focusedResult.value]
      const agreement = hit.agreements[focusedAgreement.value]
      const route = `/agreement/${agreement.agreementID}`
      router.push(route)
      closeSearch()
    })
    onKeyStroke('Escape', e => {
      if (!props.isOpen) {
        return
      }
      e.preventDefault()
      closeSearch()
    })

    return { searchQuery, searchResults, searchInput, closeSearch, focusedResult, focusedAgreement, dealershipIdsString }
  }
}
</script>

<style>
.search-background {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  z-index: 9999;
  display: flex;
  justify-content: center;
}
.search-wrapper {
  margin: var(--s-10);
  align-self: flex-start;
}
.search-card {
  background-color: var(--bg-primary_alt);
  border-radius: var(--rounded-xl);
  max-height: 90vh;
}
.search-results-card {
  padding: var(--s-6) var(--s-6) 0 var(--s-6);
  border-top: 1px solid var(--border-secondary);
  max-height: 82vh;
  overflow-y: auto;
}
.search-result-item {
  /* border-bottom: 1px solid var(--border-secondary); */
  padding: var(--s-3) 0;
}
.search-results-card > .search-result-item:last-child {
  border-bottom: none;
}
.search-results-highlight {
  color: var(--fg-brand);
  font-style: none;
  font-weight: 500;
  font-family: inherit;
}
.search-item-agreement {
  display: flex;
  align-items: center;
  border-radius: var(--rounded-md);
  padding: var(--s-1) var(--s-2);
  cursor: pointer;
  font-size: var(--text-md);
}
.search-number-plate {
  background-color: var(--bg-tertiary);
  border-left: var(--s-2) solid var(--border-secondary);
  padding: 0 var(--s-2);
  white-space: nowrap;
  border-radius: 0.2rem;
  font-size: var(--text-lg);
  color: black;
  font-weight: 500;
}

/* Focuses */
.focused-result {
  background-color: var(--bg-secondary_alt);
}
.focused-result .search-number-plate {
  border-color: #406290;
  background-color: #fff700d3;
}
</style>
