<template>
  <li class="todo-list-item" :class="{ 'text-quaternary line-through': state.isDeleted || state.isCompleted }" @click="taskClicked(task)">
    <div class="flex mr-3 items-start text-md w-full">
      <Checkbox v-model="state.isCompleted" @click.stop @change="toggleComplete()" />
      <div class="flex items-start flex-grow">
        <div>
          <div class="leading-[1.1]">
            <Icon v-if="state.isDeleted" v-tippy="'Deleted'" type="trash" class="mr-1 text-sm" />
            {{ state.title }}
            <Icon v-if="state.isStarred" type="star" class="text-warning ml-1" />
          </div>
          <div v-if="descriptionLength" class="text-tertiary text-sm font-light">
            {{ truncatedDescription }}
          </div>
          <div
            v-if="state.dueDate"
            class="flex items-center gap-1 mt-1"
            :class="[dayjs(state.dueDate).isToday() || dayjs(state.dueDate).isBefore() ? 'text-error' : 'text-tertiary']"
          >
            <Icon type="calendar" class="text-sm" />
            <div v-if="!state.isDeleted && !state.isCompleted" v-tippy="{ content: dayjs(state.dueDate).format('DD/MM/YYYY HH:mm') }">
              <div v-if="dayjs(state.dueDate).isToday()" class="mr-3 text-error text-sm whitespace-nowrap">Today</div>
              <div
                v-else
                class="mr-3 text-sm whitespace-nowrap flex items-center"
                :class="{ 'text-error': dayjs(state.dueDate).isBefore() }"
              >
                {{ dayjs(state.dueDate).format('h:mma') }} <Icon type="circle" class="text-[6px] mx-1" />
                {{ dayjs(state.dueDate).format('MMM D') }}
              </div>
            </div>
            <div
              v-else
              v-tippy="{ content: dayjs(state.dueDate).format('DD/MM/YYYY HH:mm') }"
              class="mr-3 text-sm whitespace-nowrap flex items-center"
            >
              {{ dayjs(state.dueDate).format('h:mma') }} <Icon type="circle" class="text-[6px] mx-1" />
              {{ dayjs(state.dueDate).format('MMM D') }}
            </div>
          </div>
        </div>
        <router-link v-if="chips.indexOf('link') !== -1 && state.linkType === 'agreement'" :to="'/agreement/' + state.linkID">
          <Chip class="ml-5" size="sm"><Icon type="link" /> {{ state.linkTitle }}</Chip>
        </router-link>

        <Chip v-if="chips.indexOf('createdBy') !== -1 && state.createdBy != lsUserID" class="ml-5">Created By {{ state.createdBy }}</Chip>
      </div>
      <div class="task-item-actions -my-2.5">
        <Button v-tippy="'Edit Task'" icon="pencil" plain severity="secondary" size="sm" />
        <Button
          v-if="!state.isDeleted"
          v-tippy="'Delete Task'"
          icon="trash"
          plain
          severity="secondary"
          size="sm"
          @click.stop="toggleDelete()"
        />
        <Button v-else v-tippy="'Restore Task'" icon="trash-undo" plain severity="secondary" size="sm" @click.stop="toggleDelete()" />
      </div>
    </div>
  </li>
</template>

<script>
import { ref, watch, computed } from 'vue'
import Icon from '@/components/icon/Icon.vue'
import Chip from '@/components/chip/Chip.vue'
import Checkbox from '@/components/forms/Checkbox.vue'
import Button from '@/components/button/Button.vue'

import useApiRequest from '@/composables/useApiRequest'
import { useUserStore } from '@/stores/UserStore'

export default {
  components: {
    Icon,
    Chip,
    Checkbox,
    Button
  },
  props: {
    task: {
      type: Object,
      default: () => {},
      required: true
    },
    chips: {
      type: Array,
      default: () => ['link']
    },
    descriptionLength: {
      type: Number,
      default: 100
    }
  },
  emits: ['updateTask', 'toggleCompleted', 'taskClicked', 'deleteTask'],
  setup(props, { emit }) {
    const userStore = useUserStore()
    const lsUserID = userStore.details.id
    const completeTaskApiRequest = useApiRequest()

    const state = ref({ ...props.task })

    // Watch for changes in the task prop and update the state
    watch(
      () => props.task,
      newTask => {
        state.value = { ...newTask }
      },
      { deep: true }
    )

    function toggleComplete() {
      // Update in DB
      const jsonData = JSON.stringify({
        isCompleted: state.value.isCompleted
      })
      let config = { endpoint: import.meta.env.VITE_API_BASE_URL + '/v1/todos/' + state.value.id, method: 'PATCH', data: jsonData }
      completeTaskApiRequest.sendRequest(config)
      emit('toggleCompleted', state.value)
      emit('updateTask', state.value)
    }

    function toggleDelete() {
      state.value.isDeleted = !state.value.isDeleted
      // Update in DB
      const jsonData = JSON.stringify({
        isDeleted: state.value.isDeleted
      })

      let config = { endpoint: import.meta.env.VITE_API_BASE_URL + '/v1/todos/' + state.value.id, method: 'PATCH', data: jsonData }
      completeTaskApiRequest.sendRequest(config)

      emit('deleteTask', state.value)
      emit('updateTask', state.value)
    }

    function taskClicked(task) {
      emit('taskClicked', task)
    }

    const truncatedDescription = computed(() => {
      if (state.value.description && state.value.description.length > 100) {
        return state.value.description.substring(0, props.descriptionLength) + '...'
      } else {
        return state.value.description
      }
    })

    return { state, lsUserID, truncatedDescription, toggleComplete, toggleDelete, taskClicked }
  }
}
</script>

<style>
.todo-list-item {
  padding: var(--s-4) 0 var(--s-4) var(--s-6);
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--border-secondary);
  align-items: center;
  transition:
    transform 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  cursor: pointer;
  background-color: white;
}
.dark .todo-list-item {
  background-color: transparent;
}
.todo-list-item:last-child {
  border-bottom: none;
}

.dark .todo-list-item {
  border-bottom-color: var(--darkmode-3);
}

.task-item-actions {
  display: none;
}
.todo-list-item:hover .task-item-actions {
  display: flex;
}
</style>
