<template>
  <!-- {{ state }} -->

  <InputGroup>
    <Multiselect
      v-model="state.countryCode"
      mode="single"
      :close-on-select="true"
      :searchable="true"
      :hide-selected="false"
      :options="countriesList"
      :can-deselect="true"
      :is-object="true"
      track-by="name"
      label="name"
      value-prop="dialCode"
      :caret="true"
      :can-clear="false"
      class="inputPhoneMultiselect"
      :class="[
        dropdownIsOpen ? '!w-full' : '!min-w-[100px] !max-w-[100px]',
        { 'has-error': !inputValidity.isValid || (!state.isValid && state.number > 0) }
      ]"
      :disabled="readonly"
      @open="dropdownIsOpen = true"
      @close="dropdownIsOpen = false"
    >
      <template #singlelabel="{ value }">
        <div class="multiselect-single-label !pr-4">
          <img v-if="showFlags" :src="value.image" width="25" class="mr-1" />
          +{{ value.dialCode }}
        </div>
      </template>
      <template #option="{ option }">
        <img :src="option.image" width="25" class="mr-3" />
        <span class="w-full">{{ option.name }}</span>
        <span class="text-secondary">+{{ option.dialCode }}</span>
      </template>
    </Multiselect>
    <Input
      v-model="state.number"
      class="w-full"
      :class="[dropdownIsOpen ? '!hidden' : '']"
      :has-error="!inputValidity.isValid || (!state.isValid && state.number > 0)"
      :readonly="readonly"
    />
    <InputGroupAddon v-if="state.type" :readonly="readonly" class="whitespace-nowrap">
      <span class="text-secondary">{{ $t(getProperties('phoneNumberTypes', state.type)?.localeKey) }}</span>
    </InputGroupAddon>
  </InputGroup>
  <InputError :has-error="!inputValidity.isValid">{{ inputValidity.message }}</InputError>
  <InputError :has-error="!state.isValid && state.number > 0">Invalid Number</InputError>
</template>

<script>
import { ref, watch, computed } from 'vue'

import Multiselect from '@vueform/multiselect'
import InputGroup from '@/components/forms/InputGroup.vue'
import InputGroupAddon from '@/components/forms/InputGroupAddon.vue'
import Input from '@/components/forms/Input.vue'
import InputError from '@/components/forms/InputError.vue'

import { useI18n } from 'vue-i18n'
import parsePhoneNumber from 'libphonenumber-js'

import { getProperties } from '@/utilities/dataMapper'

export default {
  components: { Multiselect, InputGroup, Input, InputError, InputGroupAddon },
  props: {
    modelValue: {
      type: String,
      default: null
    },
    numberDetails: {
      type: Object,
      default: () => ({ countryCode: '', number: '', isValid: false })
    },
    showFlags: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'update:numberDetails'],
  setup(props, { emit }) {
    const state = ref(props.numberDetails)

    const countriesList = [
      {
        name: useI18n().t('meta.countries.gb.label'),
        code: 'GB',
        emoji: '🇬🇧',
        dialCode: 44,
        unicode: 'U+1F1EC U+1F1E7',
        image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg'
      },
      {
        name: useI18n().t('meta.countries.us.label'),
        code: 'US',
        emoji: '🇺🇸',
        dialCode: 1,
        unicode: 'U+1F1FA U+1F1F8',
        image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg'
      },
      {
        name: useI18n().t('meta.countries.es.label'),
        code: 'ES',
        emoji: '🇪🇸',
        dialCode: 34,
        unicode: 'U+1F1EA U+1F1F8',
        image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg'
      },
      {
        name: useI18n().t('meta.countries.mx.label'),
        code: 'MX',
        emoji: '🇲🇽',
        dialCode: 52,
        unicode: 'U+1F1F2 U+1F1FD',
        image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MX.svg'
      },
      {
        name: useI18n().t('meta.countries.fr.label'),
        code: 'FR',
        emoji: '🇫🇷',
        dialCode: 33,
        unicode: 'U+1F1EB U+1F1F7',
        image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg'
      }
    ]

    const dropdownIsOpen = ref(false)

    // When state changes, emit the new value
    watch(
      state,
      () => {
        emit('update:modelValue', `+${state.value.countryCode}${state.value.number}`)
        emit('update:numberDetails', state.value)
      },
      { deep: true }
    )

    function validateNumber(number) {
      if (!number) {
        state.value.isValid = false
        state.value.isPossible = false
        return
      }
      const parsedNumber = parsePhoneNumber(number)
      if (parsedNumber) {
        state.value.countryCode = parsedNumber.countryCallingCode
        state.value.number = parsedNumber.nationalNumber
        state.value.isValid = parsedNumber.isValid()
        state.value.isPossible = parsedNumber.isPossible()
        state.value.type = parsedNumber.getType()
      } else {
        state.value.isValid = false
        state.value.isPossible = false
      }
    }
    validateNumber(props.modelValue)

    // Watch for changes in either state.countryCode and state.number
    watch([() => state.value.countryCode, () => state.value.number], () => {
      validateNumber(`+${state.value.countryCode}${state.value.number}`)
    })

    watch([() => props.modelValue], () => {
      validateNumber(props.modelValue)
    })

    // Computed property to check the validity of the input field - if required and/or if the phone number is valid
    const inputValidity = computed(() => {
      let isValid = true
      let message = ''
      // If required, check that the number is not empty, null or undefined
      if (props.required) {
        // Check that the number is not empty, null or undefined
        if (state.value.number === '' || state.value.number === null || state.value.number === undefined) {
          isValid = false
          message = 'This field is required'
        } else {
          // Has value, check state.value.isValid
          if (!state.value.isValid) {
            isValid = false
            message = 'Invalid phone number'
          }
        }
      }

      return { isValid, message }
    })

    return { getProperties, state, countriesList, dropdownIsOpen, inputValidity }
  }
}
</script>

<style>
.inputPhoneMultiselect .multiselect-option.is-selected::after {
  content: '';
  font-family: 'Font Awesome 6 Pro';
  right: var(--s-3);
  position: absolute;
  color: var(--fg-brand);
}
</style>
