<template>
  <DataTable
    ref="dt"
    v-model:selection="selectedInvoices"
    v-model:filters="filters"
    selection-mode="multiple"
    export-filename="Invoices"
    :value="invoicesComputed"
    :meta-key-selection="true"
    removable-sort
    :rows="20"
    sort-field="dateDue"
    :sort-order="-1"
    data-key="id"
    column-resize-mode="fit"
    responsive-layout="scroll"
    :paginator="true"
    filter-display="menu"
    :global-filter-fields="['id', 'billingAccountName', 'reference', 'dealershipNames']"
    :rows-per-page-options="[10, 20, 50]"
    state-key="dt-invoicing"
    state-storage="session"
    current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
  >
    <template #loading> Loading invoices. Please wait. </template>
    <template #empty> No Invoices Found </template>
    <template #header>
      <div class="flex justify-between">
        <span class="flex justify-between gap-4">
          <span>
            <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
          </span>
          <Button
            :label="$t('ui.actions.clear', 1)"
            severity="secondary"
            type="button"
            outlined
            icon="filter-slash"
            @click="clearFilter()"
          />
        </span>
      </div>
      <div class="flex justify-between mt-4">
        <span>
          <ButtonGroup v-tippy="{ content: selectedInvoices.length == 0 ? 'Select invoices first' : '' }">
            <Button
              :label="$t('ui.actions.send', 1)"
              type="button"
              severity="secondary"
              outlined
              icon="envelope"
              :disabled="selectedInvoices.length == 0"
              :is-loading="sendProgress != null"
              @click="sendSelectedInvoice()"
            />
            <Button
              label="PDF"
              type="button"
              severity="secondary"
              outlined
              icon="file-pdf"
              :disabled="selectedInvoices.length == 0"
              :is-loading="downloadProgress != null"
              @click="downloadSelectedPDF()"
            />
          </ButtonGroup>
          <Button label="CSV" type="button" class="ml-5" severity="secondary" outlined icon="file-excel" @click="exportCSV($event)" />
        </span>
      </div>
    </template>

    <Column selection-mode="multiple" header-style="width: 3em"></Column>
    <Column field="id" header="Invoice" :sortable="true" class="whitespace-nowrap">
      <template #body="{ data }">
        <a class="link" :href="'/finance/invoice/' + data.id">C-{{ data.id }}</a>
      </template>
    </Column>
    <Column
      field="billingAccountName"
      filter-field="billingAccountName"
      :header="$t('ui.common.account')"
      :sortable="true"
      :show-filter-match-modes="false"
      :show-filter-operator="false"
    >
      <template #filter="{ filterModel }">
        <div class="mb-3 font-bold">Account Picker</div>
        <MultiSelect v-model="filterModel.value" :options="billingAccounts" placeholder="Any" class="p-column-filter">
          <template #option="slotProps">
            <div class="p-multiselect-representative-option">
              <span class="image-text">{{ slotProps.option }}</span>
            </div>
          </template>
        </MultiSelect>
      </template>
    </Column>
    <Column field="reference" :header="$t('ui.common.reference')" :sortable="true">
      <template #body="{ data }">
        <a class="link" :href="'/finance/invoice/' + data.id">{{ data.reference }}</a>
      </template>
    </Column>
    <Column field="dealershipNames" :header="$t('ui.entities.dealership', 2)" :sortable="true" :hidden="true">
      <template #body="{ data }">
        {{ data.dealershipNames }}
      </template>
    </Column>
    <Column field="dateSent" :header="$t('ui.common.date-time.date')" :sortable="true" class="whitespace-nowrap">
      <template #body="{ data }">
        {{ dayjs(data.dateSent).format('DD-MM-YYYY') }}
      </template>
    </Column>
    <Column field="dateDue" :header="$t('ui.common.due')" :sortable="true" data-type="date" class="whitespace-nowrap">
      <template #body="{ data }">
        {{ dayjs(data.dateDue).format('DD-MM-YYYY') }}
      </template>
    </Column>
    <Column field="isSent" :header="$t('ui.common.sent')" :sortable="true">
      <template #body="{ data }">
        <Icon v-if="data.isSent" type="paper-plane" icon-style="success" />
      </template>
    </Column>
    <Column
      field="paymentStatus"
      :header="$t('ui.entities.finance.paid')"
      :sortable="true"
      :show-filter-match-modes="false"
      :show-filter-operator="false"
    >
      <template #body="{ data }">
        <Chip v-if="data.paymentStatus == 'Paid'" severity="success" size="sm">{{ $t('ui.entities.finance.paid') }}</Chip>
        <Chip v-else-if="data.paymentStatus == 'Not Paid'" severity="danger" size="sm">{{ $t('ui.entities.finance.not-paid') }}</Chip>
        <Chip v-else-if="data.paymentStatus == 'Awaiting'" size="sm">Awaiting</Chip>
      </template>
      <template #filter="{ filterModel }">
        <div class="mb-3 font-bold">Payment Status</div>
        <MultiSelect v-model="filterModel.value" :options="paymentStatuses" placeholder="Any" class="p-column-filter">
          <template #option="slotProps">
            <div class="p-multiselect-representative-option">
              <span class="image-text">{{ slotProps.option }}</span>
            </div>
          </template>
        </MultiSelect>
      </template>
    </Column>
    <Column field="invoiceTotal" :header="$t('ui.common.total')" :sortable="true">
      <template #body="slotProps">
        {{ numberFormatter(slotProps.data.invoiceTotal, 'currency') }}
        <div class="text-xs text-gray-500">{{ numberFormatter(addVat(slotProps.data.invoiceTotal), 'currency') }} (incl. TAX)</div>
      </template>
    </Column>
    <Column>
      <template #body="{ data }">
        <!-- <Button @click="downloadPDF([{ id: data.id, fileName: data.reference }])">Download</Button> -->
      </template>
    </Column>

    <ColumnGroup type="footer">
      <Row>
        <Column :colspan="5" footer-style="text-align:left">
          <template #footer>
            <span v-if="selectedInvoices.length > 0"
              >{{ $t('ui.common.item', 2) }} <span>({{ $t('ui.common.selected', 2) }})</span>: {{ selectedInvoices.length }}
            </span>
            <span v-else-if="dtRendered.renderedCount > 0">{{ $t('ui.common.item', 2) }}: {{ dtRendered.renderedCount }} </span>
          </template>
        </Column>
        <Column :colspan="3" footer-style="text-align:right">
          <template #footer>
            <span
              >{{ $t('ui.common.total', 1) }} <span v-if="selectedInvoices.length > 0">({{ $t('ui.common.selected', 1) }})</span>:
            </span>
          </template>
        </Column>
        <Column>
          <template #footer>
            <span v-if="selectedInvoices.length === 0">
              {{ numberFormatter(dtRendered.renderedSum, 'currency', 2) }}
              <!-- <div class="text-xs text-gray-500">{{ numberFormatter(addVat(dtRendered.renderedSum), 'currency') }} (incl. TAX)</div> -->
            </span>
            <span v-else>
              {{ numberFormatter(invoicesTotal, 'currency', 2) }}
              <!-- <div class="text-xs text-gray-500">{{ numberFormatter(addVat(invoicesTotal), 'currency') }} (incl. TAX)</div> -->
            </span>
          </template>
        </Column>
        <Column />
      </Row>
    </ColumnGroup>
    <!-- <template #paginatorstart>
            <div v-if="selectedInvoices.length > 0">Selected: {{selectedInvoices.length}}/100</div>
          </template> -->
    <!-- <template #paginatorend>
            <Button type="button" icon="pi pi-cloud" class="p-button-text" />
          </template> -->
  </DataTable>
</template>

<script>
import Button from '@/components/button/Button.vue'
import ButtonGroup from '@/components/button/ButtonGroup.vue'
import Icon from '@/components/icon/Icon.vue'
import Input from '@/components/forms/Input.vue'
import Chip from '@/components/chip/Chip.vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup' //optional for column grouping
import Row from 'primevue/row' //optional for row
import { FilterMatchMode } from 'primevue/api'
import MultiSelect from 'primevue/multiselect'

import downloadInvoice from '@/composables/downloadInvoicePDF'
import sendInvoices from '@/composables/sendInvoice'
import getInvoices from '@/composables/getInvoices'
import { ref, computed } from 'vue'
import Swal from 'sweetalert2'
import { useRoute, useRouter } from 'vue-router'

export default {
  components: {
    MultiSelect,
    Input,
    Button,
    Icon,
    DataTable,
    Column,
    ColumnGroup,
    Row,
    Chip,
    ButtonGroup
  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const dt = ref({ processedData: [] })

    const { invoices, error, load } = getInvoices()
    load()

    const { downloadCount, downloadProgress, downloadPDF } = downloadInvoice()
    const { sendCount, sendProgress, sendInvoice } = sendInvoices()

    const selectedInvoices = ref([])
    const onRowSelect = event => {
      // console.log('Selected ' + event.data.name)
    }

    const onRowUnselect = event => {
      // console.log('Unselected ' + event.data.name)
    }

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      billingAccountName: { value: null, matchMode: FilterMatchMode.IN },
      paymentStatus: { value: ['Not Paid', 'Awaiting'], matchMode: FilterMatchMode.IN }
    })

    const clearFilter = () => {
      // console.log('Clearing Filter')
      router.replace({ query: null })
      filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        billingAccountName: { value: null, matchMode: FilterMatchMode.IN },
        paymentStatus: { value: null, matchMode: FilterMatchMode.IN }
      }
    }
    const exportCSV = () => {
      dt.value.exportCSV()
    }

    function downloadSelectedPDF() {
      // notification('Error', 'Could not download invoice', 'danger', 'user')
      let arr = []
      selectedInvoices.value.forEach(invoice => {
        arr.push({
          id: invoice.id,
          fileName: invoice.reference
        })
      })

      downloadPDF(arr)
    }

    function sendSelectedInvoice() {
      Swal.fire({
        title: 'Are you sure?',
        text: 'These invoices will be sent to the notification contacts for each billing account.',
        icon: 'question',
        reverseButtons: true,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Send',
        confirmButtonColor: 'var(--fg-brand)',
        cancelButtonText: 'Cancel',
        cancelButtonColor: 'var(--bg-secondary)'
      }).then(function (response) {
        if (response.isConfirmed) {
          // notification('Error', 'Could not download invoice', 'danger', 'user')
          let arr = []
          selectedInvoices.value.forEach(invoice => {
            arr.push({
              id: invoice.id,
              fileName: invoice.reference
            })
          })

          sendInvoice(arr)
        }
      })
    }

    const invoicesComputed = computed(() => {
      invoices.value.forEach(invoice => {
        let dealershipNames = []
        //Loop through dealerships in invoice and add the name to a seperate key value
        invoice.dealerships.forEach(dealership => {
          dealershipNames.push(dealership.name)
        })

        //Push the names to the invoice
        // invoice.push({ 'dealerships': dealershipNames })
      })
      return invoices.value
    })

    const invoicesTotal = computed(() => {
      let sum = 0
      if (selectedInvoices.value.length === 0) {
        invoices.value.forEach(invoice => {
          sum += parseFloat(invoice.invoiceTotal)
        })
      } else {
        selectedInvoices.value.forEach(invoice => {
          sum += parseFloat(invoice.invoiceTotal)
        })
      }
      return sum
    })

    const billingAccounts = computed(() => {
      let accounts = []
      invoices.value.forEach(invoice => {
        accounts.push(invoice.billingAccountName)
      })

      return [...new Set(accounts)].sort()
    })

    const paymentStatuses = ['Awaiting', 'Not Paid', 'Paid']

    //If router filter isset
    if (route.query.paymentStatus) {
      localStorage.removeItem('dt-invoicing')
      filters.value.paymentStatus.value = route.query.paymentStatus
    }

    const dtRendered = computed(() => {
      let renderedCount = 0
      let renderedSum = 0

      if (dt.value.processedData.length > 0) {
        renderedCount = dt.value.processedData.length
        dt.value.processedData.forEach(invoice => {
          renderedSum += parseFloat(invoice.invoiceTotal)
        })
      }

      return { renderedCount: renderedCount, renderedSum: renderedSum }
    })

    function addVat(value) {
      value = parseFloat(value)
      return value * 0.2 + value
    }

    return {
      dt,
      invoicesComputed,
      filters,
      dtRendered,
      exportCSV,
      downloadPDF,
      downloadSelectedPDF,
      sendSelectedInvoice,
      sendProgress,
      clearFilter,
      selectedInvoices,
      onRowSelect,
      onRowUnselect,
      billingAccounts,
      invoicesTotal,
      downloadProgress,
      downloadCount,
      paymentStatuses,
      addVat
    }
  }
}
</script>

<style></style>
