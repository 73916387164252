<template>
  <PageHeader
    :title="$t('ui.entities.finance.invoicing')"
    breadcrumb
    :items="[
      { label: $t('ui.entities.finance.label'), to: '/finance' },
      { label: $t('ui.entities.finance.invoicing'), to: '/finance/invoicing' }
    ]"
  >
    <template #right>
      <div class="flex gap-4">
        <!-- <Button label="Statements" severity="secondary" outlined @click="$router.push('/finance/invoicing/statements')" /> -->
        <Button label="New Invoice" severity="primary" icon="plus" />
      </div>
    </template>
  </PageHeader>

  <Card>
    <InvoicesTable />
  </Card>
</template>

<script>
import PageHeader from '@/components/page/PageHeader.vue'
import Card from '@/components/card/Card.vue'
import Button from '@/components/button/Button.vue'

import InvoicesTable from '@/components/unique/tables/InvoicesTable.vue'

export default {
  components: {
    PageHeader,
    Card,
    InvoicesTable,
    Button
  },
  setup() {
    return {}
  }
}
</script>

<style></style>
