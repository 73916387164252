<template>
  <Steps
    ref="formWizard"
    :steps="formSteps"
    :is-submitting="postNewUser.isLoading.value"
    @before-move="validateStep"
    @on-complete="submitnewUser"
  >
    <template #step-1>
      <FormGroup v-if="userTypeOptions.length > 1">
        <RadioBoxed v-model="newUser.type" :options="userTypeOptions" />
        <InputError :has-error="v$.type.$error">{{ v$.type.$errors[0]?.$message }}</InputError>
      </FormGroup>

      <FormGroup :label="$t('ui.entities.comms.email')" :is-required="v$.email.required">
        <Input v-model="newUser.email" :has-error="v$.email.$error" />
        <InputError :has-error="v$.email.$error">{{ v$.email.$errors[0]?.$message }}</InputError>
      </FormGroup>

      <div v-if="!v$.email.required.$invalid && !v$.email.email.$invalid">
        <div v-if="emailAvailabilityChecking" class="flex">
          <Icon pulse type="spinner" />
          <div class="ml-2">Checking Email Availability</div>
        </div>
        <div v-else-if="!emailAvailabilityChecking" class="flex" :class="v$.email.isAvailable.$invalid ? 'text-danger' : 'text-success'">
          <Icon :type="v$.email.isAvailable.$invalid ? 'circle-xmark' : 'check'" fa-style="fas" />
          <div class="ml-2">Email {{ v$.email.isAvailable.$invalid ? ' not available' : ' available!' }}</div>
        </div>
      </div>
    </template>

    <template #step-2>
      <div class="grid sm:grid-cols-2 sm:gap-8">
        <FormGroup :label="$t('ui.entities.profile.first-name')" :is-required="v$.forename.required">
          <Input v-model="newUser.forename" :has-error="v$.forename.$error" />
          <InputError :has-error="v$.forename.$error">{{ v$.forename.$errors[0]?.$message }}</InputError>
        </FormGroup>
        <FormGroup :label="$t('ui.entities.profile.last-name')" :is-required="v$.surname.required">
          <Input v-model="newUser.surname" :has-error="v$.surname.$error" />
          <InputError :has-error="v$.surname.$error">{{ v$.surname.$errors[0]?.$message }}</InputError>
        </FormGroup>
      </div>
      <div class="grid sm:grid-cols-2 sm:gap-8">
        <FormGroup :label="$t('ui.common.job-title')" :is-required="v$.jobTitle.required">
          <Input v-model="newUser.jobTitle" :has-error="v$.jobTitle.$error" />
          <InputError :has-error="v$.jobTitle.$error">{{ v$.jobTitle.$errors[0]?.$message }}</InputError>
          <div class="italic text-xs text-secondary cursor-pointer mt-1">
            <div v-if="newUser.type == 1">
              <span class="hover:text-primary" @click="newUser.jobTitle = 'Engagement Specialist'">Engagement Specialist</span>,
              <span class="hover:text-primary" @click="newUser.jobTitle = 'Team Leader'">Team Leader</span>
            </div>
            <div v-if="newUser.type == 2">
              <span class="hover:text-primary" @click="newUser.jobTitle = 'Sales Executive'">Sales Executive</span>,
              <span class="hover:text-primary" @click="newUser.jobTitle = 'Sales Manager'">Sales Manager</span>,
              <span class="hover:text-primary" @click="newUser.jobTitle = 'Group Sales Manager'">GSM</span>
            </div>
          </div>
        </FormGroup>
        <FormGroup :label="$t('ui.common.pronouns')" :is-required="v$.pronouns.required">
          <Multiselect
            v-model="newUser.pronouns"
            mode="single"
            :close-on-select="true"
            :searchable="false"
            :hide-selected="false"
            :options="['he/him', 'she/her', 'they/them']"
            :can-deselect="false"
            :class="{ 'has-error': v$.pronouns.$error }"
          >
          </Multiselect>
          <InputError :has-error="v$.pronouns.$error">{{ v$.pronouns.$errors[0]?.$message }}</InputError>
        </FormGroup>
      </div>
    </template>

    <template #step-3>
      <div :class="{ 'grid sm:grid-cols-2 sm:gap-8': newUser.type == 2 }">
        <FormGroup
          v-if="newUser.type == 2"
          :label="$t('ui.common.level')"
          info="How the user is seen and handled.<br><br>E.g. a user with a level set to 'dealership' will be able to be assigned to appointments, whilst a user with level 'Group', won't"
          :is-required="v$.level.required"
        >
          <Multiselect
            v-model="newUser.level"
            mode="single"
            :close-on-select="true"
            :searchable="false"
            :hide-selected="false"
            :options="levelOptions"
            :can-deselect="false"
            :class="{ 'has-error': v$.level.$error }"
          >
          </Multiselect>
          <InputError :has-error="v$.level.$error">{{ v$.level.$errors[0]?.$message }}</InputError>
        </FormGroup>

        <FormGroup
          :label="$t('ui.common.role', 2)"
          info="Roles define what the user is permitted to do<br><br>E.g. The Sales Executive role can't create users, but the Sales Manager role can"
          :is-required="v$.roles.required"
        >
          <Multiselect
            v-model="newUser.roles"
            mode="tags"
            :close-on-select="true"
            :searchable="false"
            :hide-selected="false"
            track-by="value"
            label="label"
            :options="roleOptions"
            :can-deselect="false"
            :class="{ 'has-error': v$.roles.$error }"
          >
          </Multiselect>
          <InputError :has-error="v$.roles.$error">{{ v$.roles.$errors[0]?.$message }}</InputError>
        </FormGroup>
      </div>
      <UserAccessSelector v-model="newUser.access" />
    </template>
  </Steps>
  <!-- <InputError :has-error="v$.$error">{{ v$.$errors[0]?.$message }}</InputError> -->
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useRouter } from 'vue-router'

import Steps from '@/components/steps/Steps.vue'

import Icon from '@/components/icon/Icon.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Input from '@/components/forms/Input.vue'
import RadioBoxed from '@/components/forms/RadioBoxed.vue'
import InputError from '@/components/forms/InputError.vue'
import Multiselect from '@vueform/multiselect'
import notification from '@/utilities/notification.js'

import useVuelidate from '@vuelidate/core'
import { required, requiredIf, email } from '@vuelidate/validators'

import useApiRequest from '@/composables/useApiRequest'
import { useUserStore } from '@/stores/UserStore'

import { debounce } from 'lodash'
import UserAccessSelector from '../users/UserAccessSelector.vue'

export default {
  components: { Icon, Steps, FormGroup, Input, RadioBoxed, InputError, Multiselect, UserAccessSelector },
  props: {
    rolesList: {
      type: Array,
      required: true
    }
  },
  emits: ['form-updated', 'is-submitting', 'completed'],
  setup(props, { emit }) {
    const router = useRouter()
    const userStore = useUserStore()

    const checkEmailAvailable = useApiRequest()
    const emailAvailabilityChecking = ref(false)
    const postNewUser = useApiRequest()

    let currentPromise = null

    const debouncedApiCall = debounce((value, resolve, reject) => {
      if (!v$.value.email.required.$invalid && !v$.value.email.email.$invalid) {
        checkEmailAvailable
          .sendRequest({
            endpoint: '/v1/new-account-check',
            method: 'GET',
            params: { email: value }
          })
          .then(() => {
            const isAvailable = checkEmailAvailable.response.value.data.available
            console.log('API Call - Resolved(' + isAvailable + ')')
            resolve(isAvailable)
          })
          .catch(error => {
            console.error('API Call Error:', error)
            console.log('API Call rejected: false')
            reject()
          })
          .finally(() => {
            emailAvailabilityChecking.value = false
          })
      } else {
        console.log('Email validation failed - Reject()')
        reject()
      }
    }, 1000)

    function checkEmailAvailability(v$, value, resolve, reject) {
      console.log('checkEmailAvailability')
      if (v$.value.email.required.$invalid || v$.value.email.email.$invalid) {
        console.log('Resolve(true)')
        resolve(true)
        return
      }
      try {
        emailAvailabilityChecking.value = true

        if (currentPromise) {
          // Resolve or reject the current promise before cancelling
          // Decide whether to resolve or reject based on your logic
          currentPromise.resolve(false) // or currentPromise.reject()
          debouncedApiCall.cancel()
        }

        currentPromise = { resolve, reject }

        debouncedApiCall(value, resolve, reject)
      } catch {
        console.log('Exception caught - returning false')
        reject()
        return
      }
    }

    const formWizard = ref(null) // Reference to the FormWizard component

    const formSteps = [
      {
        label: 'Account',
        title: 'User type and Account email',
        icon: null
      },
      {
        label: 'User Details',
        title: 'Name, Job Title, Profile Picture, etc.',
        icon: null
      },
      {
        label: 'Roles & Access',
        title: 'What is their address?',
        icon: null
      }
    ]

    const newUser = ref({
      type: null,
      email: null,
      forename: null,
      surname: null,
      pronouns: null,
      jobTitle: null,
      profilePic: null,
      roles: [],
      level: null,
      access: null
    })

    // set user type to 2, if session user is of type 2
    newUser.value.type = userStore.details.type == 2 ? 2 : null

    const inputRules = {
      formStep1: {
        type: { required },
        email: {
          required,
          email,
          // isAvailable: true
          isAvailable: {
            $async: true,
            $validator: value => {
              return new Promise((resolve, reject) => {
                checkEmailAvailability(v$, value, resolve, reject)
              })
            }
          }
        }
      },
      formStep2: {
        forename: {
          required
        },
        surname: {
          required
        },
        profilePic: {},
        jobTitle: { required },
        pronouns: { required }
      },
      formStep3: {
        level: {
          required: requiredIf(() => {
            return newUser.value.type == 2
          })
        },
        roles: {
          required
        },
        access: {}
      }
    }

    watch(
      () => newUser.value,
      () => {
        emit('form-updated')
      },
      { deep: true }
    )

    const stepValidationRules = computed(() => {
      // console.log('stepValidationRules ' + formWizard.value?.currentStep)
      if (formWizard.value?.currentStep) {
        // console.log(`formStep${formWizard.value?.currentStep}`)
        return inputRules[`formStep${formWizard.value?.currentStep + 1}`]
      }
      return inputRules.formStep1
    })

    const v$ = useVuelidate(stepValidationRules, newUser)

    async function validateStep(step) {
      console.log('Step: ' + step)
      const isStepCorrect = await v$.value.$validate()
      // console.log('Is Valid: ' + isStepCorrect)
      if (isStepCorrect) {
        formWizard.value.move(1) // Move to the next step
      }
    }

    async function submitnewUser() {
      const isFormCorrect = await v$.value.$validate()

      console.log('FormCorrect:' + isFormCorrect, v$.value)

      if (isFormCorrect) {
        emit('is-submitting', true)
        // Create a copy of newUser.value, as to not affect the original
        let newUserData = JSON.parse(JSON.stringify(newUser.value))

        // replace access with the correct format
        let access = newUserData.access
        let newAccessFormat = {
          regions: access.regionsAll ? '*' : access.regions,
          manufacturers: access.manufacturersAll ? '*' : access.manufacturers,
          groups: access.groupsAll ? '*' : access.groups,
          dealerships: access.dealershipsAll ? '*' : access.dealerships
        }
        newUserData.access = newAccessFormat

        newUserData.createdBy = userStore.details.id

        console.log(JSON.stringify(newUserData))

        postNewUser
          .sendRequest({ method: 'POST', endpoint: '/v1/users', data: JSON.stringify(newUserData) })
          .then(response => {
            if (!response) {
              console.error('No response from API, endpoint failed: /v1/users')
              notification('Error', 'Failed to add new user')
              return
            }
            if (postNewUser.response.value.response.status == 'success') {
              emit('is-submitting', false)
              emit('completed')
              // router.push('/settings/users/' + response.data.id)
            }
          })
          .catch(err => {
            notification('Error', err.message)
          })
      }
    }

    const userTypeList = [
      { label: 'Internal', value: 1, icon: 'home', description: 'Retain Automotive Staff', classes: 'col-span-3' },
      { label: 'External', value: 2, icon: 'globe', description: 'Sales Exec, Manager, Brand, etc.', classes: 'col-span-3' }
    ]

    const userTypeOptions = computed(() => {
      if (userStore.details.type == 1) {
        return userTypeList
      } else {
        return userTypeList.filter(userType => userType.value == 2)
      }
    })

    const levelsList = [
      {
        value: 'dealership',
        label: 'Dealership',
        weight: 1
      },
      {
        value: 'group',
        label: 'Group',
        weight: 2
      },
      {
        value: 'manufacturer',
        label: 'Manufacturer',
        weight: 3
      },
      {
        value: 'region',
        label: 'Region',
        weight: 4
      }
    ]

    const levelOptions = computed(() => {
      if (userStore.details.type == 1) {
        return levelsList
      }

      let currentLevel = levelsList.find(level => level.value == userStore.details.level)

      console.log('Current Level: ' + currentLevel.weight)

      let options = levelsList.filter(level => level.weight <= currentLevel.weight)
      return options
    })

    // Watch for changes to newUser.value.level, and reset newUser.value.roles to an empty array
    watch(
      () => newUser.value.level,
      () => {
        newUser.value.roles = []
      }
    )

    const roleOptions = computed(() => {
      let options = []
      props.rolesList.forEach(role => {
        options.push({
          value: role.id,
          label: role.name,
          level: role.level,
          userType: role.userType,
          color: role.color
        })
      })

      if (newUser.value.type == 1) {
        options = options.filter(role => role.userType == 1)
      } else {
        // Filter out any roles and type that do not equal newUser.level && newUser.type
        options = options.filter(role => role.level == newUser.value.level && role.userType == newUser.value.type)
      }

      options = options.filter(role => role.value != 1)

      return options
    })

    return {
      newUser,
      userStore,
      v$,
      formWizard,
      validateStep,
      submitnewUser,
      formSteps,
      postNewUser,
      userTypeOptions,
      emailAvailabilityChecking,
      levelOptions,
      roleOptions
    }
  }
}
</script>

<style></style>
