<template>
  <div class="list-group" :class="{ bordered: bordered }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    bordered: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    return {}
  }
}
</script>

<style>
.list-group.bordered {
  border: 1px solid var(--border-secondary);
  border-radius: var(--rounded-md);
}

.list-group .list-group-item:first-of-type {
  border-top-left-radius: var(--rounded-md);
  border-top-right-radius: var(--rounded-md);
}
.list-group .list-group-item:last-of-type {
  border-bottom-left-radius: var(--rounded-md);
  border-bottom-right-radius: var(--rounded-md);
}
</style>
