<!-- TODO: Refactor Card to accept a slot for the title, and a slot for the actions section (right side) -->

<template>
  <div class="card" :class="{ 'h-full': fullHeight, 'card-no-margin': noMargin }">
    <div v-if="ribbonText" class="card-ribbon" :data-ribbon-text="ribbonText">{{ ribbonText }}</div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Card',
  components: {},
  props: {
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    fullHeight: {
      type: Boolean,
      default: false
    },
    noMargin: {
      type: Boolean,
      default: false
    },
    ribbonText: {
      type: String,
      default: null
    }
  }
}
</script>

<style>
.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;

  background-color: var(--bg-primary_alt);
  background-clip: border-box;
  border-radius: var(--rounded-xl);

  margin-bottom: var(--s-6);
  /* box-shadow: var(--shadow-md); */

  border: 1px solid var(--border-secondary);
}
.card-no-margin {
  margin-bottom: 0px;
}

.dark .card {
  border-color: transparent;
  /* background: var(--gray-900); */
}

.card-text {
  margin-bottom: 1rem;
}

.card-ribbon {
  position: absolute;
  background: var(--fg-info);
  top: 0;
  right: 0;
  color: var(--white);
  padding: 3px 10px;
  border-radius: 0px var(--rounded-md) 0px var(--rounded-md);
  box-shadow: var(--shadow-md);
}
</style>
