import { ref } from 'vue'
import { parsePhoneNumber } from 'awesome-phonenumber'

export default function usePhoneValidator() {
  const isValid = ref(false)
  const formattedNumber = ref('')
  const validatedData = ref()

  const validatePhoneNumber = (phoneNumber, returnType = 'e164') => {
    const pn = parsePhoneNumber(phoneNumber)
    validatedData.value = pn

    isValid.value = pn.valid

    formattedNumber.value = pn.number[returnType] || pn.number.e164
  }

  return {
    validatePhoneNumber,
    validatedData
  }
}
