<template>
  <div class="p-5">
    <Card class="p-5">
      <table>
        <tr class="text-left">
          <th>Agent ID</th>
          <th>Agent Name</th>
          <th>Date</th>
          <th>Time</th>
          <th>Direction</th>
          <th>From</th>
          <th>To</th>
          <th>Status</th>
          <th>Duration</th>
          <th>QueueResult</th>
          <th>AMD</th>
          <th><Icon type="arrow-turn-up" /></th>
        </tr>
        <template v-for="(agentCalls, agentName) in sortedCallsByAgent">
          <tr v-for="(call, index) in agentCalls" :key="call.CallSid">
            <td v-if="index === 0" :rowspan="agentCalls.length">{{ call.agentID }}</td>
            <td v-if="index === 0" :rowspan="agentCalls.length">{{ agentName }}</td>
            <td>{{ dayjs(call.timestampInitiated).format('ddd D MMM') }}</td>
            <td>{{ dayjs(call.timestampInitiated).format('H:mm') }}</td>
            <td>{{ call.direction }}</td>
            <td>{{ call.from }}</td>
            <td>{{ call.to }}</td>
            <td>{{ call.status }}</td>
            <td>
              <a :href="call.recordingUrl" target="_BLANK">{{ call.duration }}</a>
            </td>
            <td>{{ call.queueResult }}</td>
            <td>
              <Icon v-if="call.amdOutcome?.includes('machine')" type="voicemail" />
              <Icon v-else-if="call.amdOutcome?.includes('human')" type="user" />
              <Icon v-else-if="call.amdOutcome?.includes('unknown')" type="question" />
              <div v-else>{{ call.amdOutcome }}</div>
            </td>
            <td>{{ call.wrappedUp }}</td>
          </tr>
        </template>
      </table>
    </Card>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import Card from '@/components/card/Card.vue'
import Icon from '@/components/icon/Icon.vue'
import socket from '@/services/socket.service.js'
import dayjs from 'dayjs'
import getUsers from '@/composables/getUsers'

export default {
  components: {
    Card,
    Icon
  },
  setup() {
    const calls = ref([])
    const { users, errorUsers, loadUsers } = getUsers({
      userType: 'retainUsers',
      showDisabled: false
    })
    loadUsers()

    socket.on('phone-calls-all', activeCalls => {
      calls.value = activeCalls
    })

    const getAgentName = id => {
      const user = users.value.find(user => user.id == id)
      return user ? `${user.forename} ${user.surname}` : 'Unknown'
    }

    const sortedCallsByAgent = computed(() => {
      const callsByAgent = calls.value.reduce((acc, call) => {
        const agentName = getAgentName(call.agentID)
        if (!acc[agentName]) {
          acc[agentName] = []
        }
        acc[agentName].push(call)
        return acc
      }, {})

      for (const agent in callsByAgent) {
        callsByAgent[agent].sort((a, b) => (dayjs(b.timestampInitiated).isAfter(dayjs(a.timestampInitiated)) ? 1 : -1))
      }

      const sortedKeys = Object.keys(callsByAgent).sort((a, b) => (a > b ? 1 : -1))

      return sortedKeys.reduce((acc, key) => {
        acc[key] = callsByAgent[key]
        return acc
      }, {})
    })

    return { sortedCallsByAgent, getAgentName }
  }
}
</script>

<style scoped>
table td {
  border-bottom: 1px solid rgb(207, 207, 207);
}
</style>
