<template>
  <li class="dropdown-menu-item" @click.stop="handleClick">
    <slot />

    <Icon v-if="hasSubMenu" class="dropdown-submenu-chevron" type="chevron-right" />

    <ul v-if="hasSubMenu" v-show="isSubMenuOpen" class="dropdown-menu dropdown-menu-submenu">
      <slot name="submenu"></slot>
    </ul>
  </li>

  <!-- <li v-else-if="link">
    <a :href="link" target="_blank" class="dropdown-menu-item">
      <slot />
    </a>
  </li>
  <li v-else-if="route">
    <router-link :to="route" class="dropdown-menu-item">
      <slot />
    </router-link>
  </li>
  <li v-else-if="!link && !route" @click="handleClick">
    <a href="#" class="dropdown-menu-item">
      <slot />
    </a>
  </li> -->
</template>

<script>
import { inject, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import Icon from '@/components/icon/Icon.vue'

export default {
  name: 'DropdownItem',
  components: {
    Icon
  },
  props: {
    route: {
      type: String,
      default: null
    },
    link: {
      type: String,
      default: null
    },
    closeOnClick: {
      type: Boolean,
      default: true
    },
    hasSubMenu: {
      type: Boolean,
      default: false
    },
    onClick: {
      type: Function,
      default: () => {}
    }
  },
  emits: ['itemClicked'],
  setup(props, { emit }) {
    const router = useRouter()
    const dropdownIsOpen = inject('isOpen')
    const closeDropdown = inject('closeDropdown')

    const isSubMenuOpen = ref(true)

    watch(
      dropdownIsOpen,
      (oldVal, newVal) => {
        // console.log('change from: ' + oldVal + ' to:' + newVal)
        if (!newVal) {
          isSubMenuOpen.value = false
        }
      },
      { immediate: true }
    )

    const handleClick = () => {
      emit('itemClicked')

      if (props.hasSubMenu) {
        isSubMenuOpen.value = !isSubMenuOpen.value
      } else {
        if (props.link) {
          window.location = props.link
        } else if (props.route) {
          router.push(props.route)
        } else {
          props.onClick()
          closeDropdown()
        }
      }
    }

    return { isSubMenuOpen, handleClick, dropdownIsOpen }
  }
}
</script>

<style>
.dropdown-menu-item {
  margin: 0px 6px;
  padding: var(--s-2) 0.625rem;
  clear: both;
  text-align: inherit;
  background-color: transparent;
  /* background-color: grey; */
  border-radius: var(--rounded-md);
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */
  font-weight: 400;
  white-space: nowrap;
  position: relative;
}

.dropdown-menu-item:hover {
  background-color: var(--bg-primary_hover);
  cursor: pointer;
}

.dropdown-menu-item > .icon:not(.dropdown-submenu-chevron) {
  margin-right: var(--s-3);
}
.dropdown-menu-submenu {
  /* display: none; */
  padding: var(--s-2) 0;
  margin: 0px 6px;
  position: absolute;
  left: 100%;
  top: 0;
  min-width: 160px;
}
</style>
