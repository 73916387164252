export default function numberFormatter(number = 0, type = 'normal', dp = 0, currency = 'gbp') {
  let locale = localStorage.getItem('locale')
  let options = {}

  if (isNaN(number)) {
    return null
  }

  //If Currency
  if (type === 'currency') {
    options = {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: dp,
      maximumFractionDigits: dp
    }
  } else {
    options = {
      minimumFractionDigits: dp,
      maximumFractionDigits: dp
    }
  }
  let formatter = new Intl.NumberFormat(locale, options)
  return formatter.format(number)
}
