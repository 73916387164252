<template>
  <div class="timeline">
    <slot></slot>
  </div>
</template>

<script setup></script>

<style>
.timeline {
  position: relative;
}
.timeline > .timeline-item:last-of-type .timeline-line {
  display: none;
}
</style>
