<template>
  <div
    class="notification-item px-5 py-3 flex items-center text-sm"
    :class="{ 'is-viewed': isViewed, 'is-clickable': options.isClickable }"
    @click="notificationClick(options)"
  >
    <Avatar :url="avatarUrl" :icon="options.avatarIcon" :text="avatarText" :color="options.avatarColor"></Avatar>
    <div class="pl-4">
      <div class="font-semibold">{{ title == '' ? options.title : title }}</div>
      <p>{{ description == '' ? options.description : description }}</p>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/avatar/Avatar.vue'
import notificationTypes from '@/components/notifications/notificationTypes.json'
import { useRouter } from 'vue-router'
import { ref } from 'vue'
export default {
  components: {
    Avatar
  },
  props: {
    title: {
      //If isset, it will override the default found in notificationTypes.json
      type: String,
      default: ''
    },
    description: {
      //If isset, it will override the default found in notificationTypes.json
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'Error'
    },
    avatarUrl: {
      type: String,
      default: ''
    },
    avatarText: {
      type: String,
      default: ''
    },
    isViewed: {
      type: Boolean,
      default: false
    },
    path: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const router = useRouter()

    let options = notificationTypes.filter(type => type.name === props.type)
    options = ref(options[0]) // Cast as an object, instead of an array

    // If is clickable
    if ((options.value.path != null && options.value.path.length > 0) || (options.value.url != null && options.value.url.length > 0)) {
      options.value.isClickable = true
    } else {
      options.value.isClickable = false
    }

    //Function to handle notification click
    function notificationClick(options) {
      if (options.isClickable) {
        router.push(options.path)
      }
    }

    return { options, notificationClick }
  }
}
</script>

<style>
.notification-item h5 {
  font-weight: 500;
}

.notification-item:hover {
  background-color: rgb(249, 249, 249);
}

.notification-item.is-clickable:hover {
  cursor: pointer;
}

.dark .notification-item:hover {
  background-color: var(--darkmode-3);
}

.notification-item.is-viewed h5 {
  font-weight: 500;
}

.notification-item.is-viewed {
  background-color: rgb(249, 249, 249);
  font-weight: 500;
}

.dark .notification-item.is-viewed {
  background-color: var(--darkmode-2);
}

.dark .notification-item.is-viewed:hover {
  background-color: var(--darkmode-3);
}
</style>
