<template>
  <label :class="{ '!mb-0': noMargin }" :for="labelFor" class="block text-secondary text-sm font-medium leading-6 mb-1">
    <slot></slot>
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    labelFor: {
      type: String,
      default: null
    },
    noMargin: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
/* label {
  display: block;
  font-size: var(--text-sm);
  margin-bottom: var(--s-1);
  font-weight: 500;
  color: var(--text-secondary);
} */
</style>
