<template>
  <div class="flex justify-between gap-4 mb-2 mx-4 text-sm text-quaternary">
    <div class="w-full">Item</div>
    <div class="flex gap-4">
      <div class="w-20">Rate</div>
      <div class="w-20">Qty</div>
      <div class="w-40">Discount</div>
      <div class="w-16 mr-20">Total</div>
    </div>
  </div>
  <div class="flex flex-col">
    <InvoiceItem
      v-for="item in modelValue"
      :key="item.id"
      :item="item"
      :read-only="readOnly"
      @item-updated="itemUpdated"
      @item-validated="itemValidated"
      @item-removed="itemRemoved"
    />
    <InvoiceItemNew :invoice-template-id="invoiceTemplateId" :dealership-id="dealershipId" @item-added="newItemAdded" />
  </div>
</template>

<script>
import InvoiceItem from './InvoiceItem.vue'
import InvoiceItemNew from './InvoiceItemNew.vue'

import notification from '@/utilities/notification'

import useApiRequest from '@/composables/useApiRequest'
export default {
  components: {
    InvoiceItem,
    InvoiceItemNew
  },
  props: {
    modelValue: {
      type: Array,
      required: true,
      default: () => []
    },
    invoiceTemplateId: {
      type: Number,
      required: true
    },
    dealershipId: {
      type: Number,
      required: false,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['itemRemoved', 'update:modelValue', 'itemValidated'],
  setup(props, { emit }) {
    function itemUpdated(item) {
      // console.log('itemUpdated', item)
      // update the modelValue with the updated item
      let itemFound = props.modelValue.find(i => i.id === item.id)
      let index = props.modelValue.indexOf(itemFound)
      let newItems = [...props.modelValue]
      newItems[index] = item
      emit('update:modelValue', newItems)
    }

    function itemValidated(valid) {
      emit('itemValidated', valid)
    }

    function itemRemoved(id) {
      useApiRequest()
        .sendRequest({
          endpoint: '/v1/finance/invoice-templates/' + props.invoiceTemplateId + '/items/' + id,
          method: 'DELETE'
        })
        .then(response => {
          if (response.data.response.status === 'success') {
            notification('Item removed')
            // remove item from the list
            let newItems = props.modelValue.filter(item => item.id !== id)
            emit('update:modelValue', newItems)
          }
        })

      emit('itemRemoved', id)
    }

    function newItemAdded(item) {
      // emit('itemAdded', item)
      // console.log('newItemAdded', item)
      // Add the new item to the items array
      let newItems = [...props.modelValue, item]
      emit('update:modelValue', newItems)
    }

    return { itemUpdated, itemRemoved, newItemAdded, itemValidated }
  }
}
</script>

<style>
/* Add any additional styling here */
</style>
