<template>
  <div class="input-group">
    <slot></slot>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  setup() {
    return {}
  }
}
</script>

<style>
.input-group {
  display: flex;
  /* padding: 0; */
}
/* first item in group */
.input-group > div:first-of-type,
.input-group > div:first-of-type .form-control {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

/* middle items in group */
.input-group > div:not(:first-of-type):not(:last-of-type),
.input-group > div:not(:first-of-type):not(:last-of-type) .form-control {
  border-radius: 0 !important;
  border-left: 0;
}

/* last item in group */
.input-group > div:last-of-type,
.input-group > div:last-of-type .form-control {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: 0;
}
</style>
