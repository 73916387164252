<template>
  <PageHeader
    title="KPI"
    breadcrumb
    :items="[
      { label: $t('ui.entities.report.label', 2), to: '/reports' },
      { label: $t('ui.entities.user.role.call-agent', 2) },
      { label: 'KPI', to: '/reports/call-agents/kpis' }
    ]"
  >
    <template #right>
      <div class="flex items-center gap-2">
        <DatePicker
          v-model="dateRange"
          selection-mode="range"
          position="bottom-right"
          :max-date="new Date()"
          :number-of-months="useBreakPoints({ xs: 1, sm: 2 })"
          @change-applied="onDateRangeChange"
        />
      </div>
    </template>
  </PageHeader>
  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      export-filename="Agent KPIs"
      :value="agentKpis"
      removable-sort
      :rows="100"
      data-key="id"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :rows-per-page-options="[30, 50]"
      state-key="dt-agent-kpis"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
    >
      <template #empty> No records found </template>

      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>
        </div>
        <div class="flex justify-between mt-4">
          <span>
            <Button label="CSV" type="button" severity="secondary" outlined icon="file-excel" @click="exportCSV($event)" />
          </span>
        </div>
      </template>
      <Column field="userId" header="Agent" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          {{ data.userName }}
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="usersList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Any"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="userName" hidden> </Column>
      <Column field="kpi.productivity" header="Productivity" :sortable="true">
        <template #body="{ data }">
          <Chip :severity="getConversionColour(data.kpi.productivity)">
            {{ numberFormatter(data.kpi.productivity * 100, 'percent') }}%
          </Chip>
        </template>
      </Column>
      <Column field="kpi.answerRate" header="Answer Rate" :sortable="true">
        <template #body="{ data }">
          <Chip :severity="getConversionColour(data.kpi.answerRate)"> {{ numberFormatter(data.kpi.answerRate * 100, 'percent') }}% </Chip>
        </template>
      </Column>
      <Column field="kpi.opportunityRate" header="Opportunity Rate" :sortable="true">
        <template #body="{ data }">
          <Chip :severity="getConversionColour(data.kpi.opportunityRate)">
            {{ numberFormatter(data.kpi.opportunityRate * 100, 'percent') }}%
          </Chip>
        </template>
      </Column>
      <Column field="kpi.conversionRate" header="Conversion Rate" :sortable="true">
        <template #body="{ data }">
          <Chip :severity="getConversionColour(data.kpi.conversionRate)">
            {{ numberFormatter(data.kpi.conversionRate * 100, 'percent') }}%
          </Chip>
        </template>
      </Column>
      <Column field="kpi.appVsCallConversion" header="App vs Call Conversion" :sortable="true">
        <template #body="{ data }">
          <Chip :severity="getConversionColour(data.kpi.appVsCallConversion)">
            {{ numberFormatter(data.kpi.appVsCallConversion * 100, 'percent') }}%
          </Chip>
        </template>
      </Column>
      <Column field="kpi.totalKpisHit" header="Total KPIs Hit" :sortable="true">
        <template #body="{ data }">
          <div :class="'text-' + getConversionColour(data.kpi.totalKpisHit)">
            {{ numberFormatter(data.kpi.totalKpisHit * 100, 'percent') }}%
          </div>
        </template>
      </Column>
      <Column field="kpi.totalScore" header="Total Score" :sortable="true">
        <template #body="{ data }">
          <div :class="'text-' + getConversionColour(data.kpi.totalScore)">
            {{ numberFormatter(data.kpi.totalScore * 100, 'percent') }}%
          </div>
        </template>
      </Column>
    </DataTable>
  </Card>
</template>

<script>
import Card from '@/components/card/Card.vue'
import PageHeader from '@/components/page/PageHeader.vue'
import DatePicker from '@/components/date-picker/DatePicker.vue'
import Input from '@/components/forms/Input.vue'
import Button from '@/components/button/Button.vue'
import Chip from '@/components/chip/Chip.vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'
import numberFormatter from '@/scripts/numberFormatter.js'

import MultiSelect from 'primevue/multiselect'

import dayjs from 'dayjs'

import useApiRequest from '@/composables/useApiRequest'
import { useBreakPoints } from '@/composables/useWindowSize'
import { useUserStore } from '@/stores/UserStore'

import { ref, computed } from 'vue'

export default {
  components: {
    Card,
    PageHeader,
    DataTable,
    Column,
    DatePicker,
    Input,
    Button,
    Chip,
    MultiSelect
  },
  setup() {
    const dt = ref()
    const userStore = useUserStore()

    const dateRange = ref([dayjs().startOf('month').toDate(), dayjs().toDate()])
    const statisticsApiRequest = useApiRequest()

    const agentKpis = ref([])
    const users = ref([])
    const filters = ref([])

    function initFilters() {
      filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        userId: { value: null, matchMode: FilterMatchMode.IN }
      }
    }
    initFilters()

    function clearFilters() {
      initFilters()
    }

    function fetchStatistics() {
      statisticsApiRequest
        .sendRequest({
          endpoint: '/v1/reports/call-agents/statistics',
          params: {
            start: dayjs(dateRange.value[0]).format('YYYY-MM-DD'),
            end: dayjs(dateRange.value[1]).format('YYYY-MM-DD'),
            userType: userStore.details.type
          }
        })
        .then(response => {
          agentKpis.value = response.data.data
        })
    }
    fetchStatistics()

    function onDateRangeChange() {
      if (dateRange.value[0] && dateRange.value[1]) {
        fetchStatistics()
      }
    }

    // Users List
    const usersApiRequest = useApiRequest()
    usersApiRequest.sendRequest({ endpoint: '/v1/users?userType=retain' }).then(response => {
      if (response.status == 200) {
        users.value = response.data.data
      }
    })

    const usersList = computed(() => {
      // if no users, return empty array
      if (!users.value) return []

      // filter out users who are not featured in the agentKpi list
      return users.value
        .filter(user => agentKpis.value.find(u => u.userId == user.id))
        .map(u => {
          return {
            value: u.id,
            label: u.fullName,
            profilePicUrl: u.profilePicURL,
            initials: u.initials
          }
        })
    })

    const getConversionColour = num => {
      let percent = num * 100
      return percent >= 90 ? 'success' : percent >= 70 ? 'warning' : 'danger'
    }

    const exportCSV = () => {
      dt.value.exportCSV()
    }

    return {
      dt,
      useBreakPoints,
      dateRange,
      agentKpis,
      onDateRangeChange,
      filters,
      exportCSV,
      clearFilters,
      getConversionColour,
      usersList,
      numberFormatter
    }
  }
}
</script>

<style></style>
