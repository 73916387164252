<template>
  <transition name="sidebar-backdrop" appear>
    <div v-if="isOpen" class="sidebar-backdrop">
      <div class="sidebar-wrapper" @mousedown.self="closeTrigger">
        <transition name="sidebar" appear>
          <div v-if="isOpen" class="sidebar">
            <slot v-if="isOpen"></slot>
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
import { watch } from 'vue'
import Swal from 'sweetalert2'

export default {
  name: 'Sidebar',
  components: {},
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    hasChanges: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeSidebar'],
  setup(props, { emit }) {
    function toggleBodyScroll() {
      if (props.isOpen) {
        document.body.style.overflow = 'hidden'
        if (document.body.scrollHeight > window.innerHeight) {
          document.body.style.marginRight = '10px'
        }
      } else {
        document.body.style.overflow = 'auto'
        document.body.style.marginRight = '0px'
      }
    }
    function closeTrigger() {
      if (props.hasChanges) {
        Swal.fire({
          title: 'Unsaved Changes',
          text: 'Changes made will not be saved if you continue',
          icon: 'warning',
          reverseButtons: true,
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: 'Discard',
          confirmButtonColor: 'var(--bg-error-solid)',
          cancelButtonText: 'Cancel'
        }).then(function (response) {
          if (response.isConfirmed) {
            emit('closeSidebar')
          }
        })
      } else {
        emit('closeSidebar')
      }
    }

    // Watch isOpen prop
    watch(
      () => props.isOpen,
      value => {
        console.log('isOpen Change', value)
        toggleBodyScroll()
      }
    )
    toggleBodyScroll()

    return { closeTrigger }
  }
}
</script>

<style>
.sidebar-backdrop {
  position: fixed;
  top: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background: rgba(0, 0, 0, 0.451);
  /* backdrop-filter: blur(4px); */
  left: 0;
  z-index: 110;
}

.sidebar-wrapper {
  z-index: 101;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.sidebar {
  background: var(--bg-primary_alt);
  z-index: 102;
  width: 400px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.sidebar-title {
  margin-bottom: 30px;
}

.sidebar-close-btn {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  border-radius: 0.4rem;

  transition: all 0.2s;
}

.sidebar-close-btn:hover {
  color: var(--fg-brand);
  cursor: pointer;
}

.sidebar-footer {
  margin-top: 30px;
  text-align: center;
}

/* Transitions */
.sidebar-enter-from {
  transform: translateX(100px);
  opacity: 0;
}

.sidebar-enter-to {
  transform: translateX(0px);
  opacity: 1;
}

.sidebar-enter-active {
  transition: all 0.3s ease-in-out;
}

.sidebar-leave-from {
  transform: translateX(0px);
  opacity: 1;
}

.sidebar-leave-to {
  transform: translateX(100px);
  opacity: 0;
}

.sidebar-leave-active {
  transition: all 0.3s ease-in-out;
}

.sidebar-backdrop-enter-from {
  /* opacity: 0; */
  background: rgba(0, 0, 0, 0);
}

.sidebar-backdrop-enter-to {
  /* opacity: 1; */
  background: rgba(0, 0, 0, 0.451);
}

.sidebar-backdrop-enter-active {
  transition: all 0.3s ease-in-out;
}

.sidebar-backdrop-leave-from {
  background: rgba(0, 0, 0, 0.451);
  opacity: 1;
}

.sidebar-backdrop-leave-to {
  opacity: 0;
  background: rgba(0, 0, 0, 0);
}

.sidebar-backdrop-leave-active {
  transition: all 0.3s ease-in-out;
}
</style>
