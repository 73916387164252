<template>
  <Card class="h-full" full-height no-margin>
    <div class="full-calendar-wrapper">
      <!-- Main Calendar -->
      <div class="w-full">
        <FullCalendar ref="fullCalendarRef" :options="computedCalendarOptions" />
      </div>
    </div>
  </Card>

  <Modal
    :is-open="dealershipSelectModalIsOpen"
    title="Select Dealership"
    :buttons="[]"
    min-width="500"
    overflow-behaviour="visible"
    @close-modal="dealershipSelectModalIsOpen = false"
  >
    <Multiselect
      v-model="selectedDealership"
      :options="dealershipsList"
      mode="single"
      value-prop="id"
      label="name"
      :can-clear="false"
      :close-on-select="false"
      :searchable="true"
      :hide-selected="true"
      @select="onDealershipSelect"
    />
  </Modal>
</template>

<script>
import { ref, computed } from 'vue'

import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import allLocales from '@fullcalendar/core/locales-all'

import dayjs from 'dayjs'

import Card from '@/components/card/Card.vue'
import Modal from '@/components/modal/Modal.vue'
import Multiselect from '@vueform/multiselect'
import { useRoute } from 'vue-router'

import useApiRequest from '@/composables/useApiRequest'
import { useUserStore } from '@/stores/UserStore'

export default {
  components: {
    FullCalendar,
    Card,
    Multiselect,
    Modal
  },
  setup() {
    const route = useRoute()
    const userStore = useUserStore()

    const fullCalendarRef = ref(null)

    const dealershipsList = ref([])

    const dealershipSelectModalIsOpen = ref(false)
    const selectedDealership = ref(null)

    const events = ref([])

    const viewAllowed = userStore.accessAllowed
    dealershipsList.value = viewAllowed.dealerships

    // If only 1 dealership, set it as selected
    if (dealershipsList.value.length === 1) {
      selectedDealership.value = dealershipsList.value[0].id
      onDealershipSelect()
    }

    //Set default view and date
    let calendarInitView = 'dayGridMonth'
    let calendarInitDate = dayjs().format('YYYY-MM-DD')
    //Check if view and dates are in localstorage
    if (localStorage.getItem('availabilityCalendarInitView')) {
      calendarInitView = localStorage.getItem('availabilityCalendarInitView')
      calendarInitDate = localStorage.getItem('calendarInitDate')
    }

    //If view and/or date isset in the route query, override previous values
    if (route.query.view) {
      calendarInitView = route.query.view
    }
    if (route.query.date) {
      calendarInitDate = route.query.date
    }

    //Set ls calendarStartDate to null to force getEvent on first load
    localStorage.setItem('calendarStartDate', '')

    const calendarOptions = ref({
      plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
      // height: 10,
      contentHeight: 100,
      initialView: calendarInitView,
      initialDate: calendarInitDate,
      datesSet: function (dateInfo) {
        // console.log('datesSet Ran')

        //If start and end dates do NOT match ones in Local Stroage, fetch events again for new date range
        if (dateInfo.start.toISOString() !== localStorage.getItem('calendarStartDate')) {
          // console.log('Must fetch events')
          getEvents(dateInfo.start.toISOString(), dateInfo.end.toISOString())
        }

        // console.log(dateInfo.start.toISOString() + " = " + dateInfo.end.toISOString(), dateInfo.view.type);
        localStorage.setItem('calendarInitView', dateInfo.view.type)
        localStorage.setItem('calendarInitDate', dateInfo.start.toISOString())
        localStorage.setItem('calendarStartDate', dateInfo.start.toISOString())
        localStorage.setItem('calendarEndDate', dateInfo.end.toISOString())
      },
      customButtons: {
        dealershipSelector: {
          text: 'Select Dealership',
          click: function () {
            dealershipSelectModalIsOpen.value = true
          }
        }
      },
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dealershipSelector'
      },
      showNonCurrentDates: false,
      eventOverlap: false,
      slotEventOverlap: false,
      locales: allLocales,
      locale: localStorage.getItem('locale'),
      firstDay: 1,
      navLinks: true, // can click day/week names to navigate views
      editable: false, //Disallow moving of events
      nowIndicator: true,
      dayMaxEvents: true, // allow "more" link when too many events
      eventTimeFormat: {
        // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
        meridiem: false,
        hour12: false
      },
      fixedWeekCount: false,
      events: events.value,
      eventClick: function () {}
    })

    // Function to fetch events within the current view
    function getEvents(start, end) {
      // console.log('Running getEvents() for dealership: ' + selectedDealership.value)

      if (!selectedDealership.value) {
        // console.log('No dealership selected')
        return
      }

      //Get events within the specified date range
      try {
        useApiRequest()
          .sendRequest({
            endpoint: '/v1/availability',
            params: { dealershipId: selectedDealership.value, startDate: start, endDate: end, returnAsEvents: true }
          })
          .then(response => {
            if (events.value.length != response.data.data.length) {
              events.value = response.data.data
            }
          })
      } catch (err) {
        if (err.response) {
          console.log(err.response.status)
          console.log(err.response.data)
        }
      }
    }

    const computedCalendarOptions = computed(() => {
      // Set button text to selected dealership
      if (selectedDealership.value) {
        // console.log(selectedDealership.value)
        let dealership = dealershipsList.value.find(d => d.id === selectedDealership.value)
        // console.log(dealership)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        calendarOptions.value.customButtons.dealershipSelector.text = dealership.name
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        calendarOptions.value.customButtons.dealershipSelector.text = 'Select Dealership'
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      calendarOptions.value.events = events.value

      return calendarOptions.value
    })

    function onDealershipSelect() {
      // console.log('onDealershipSelect(). Selected: ' + selectedDealership.value)
      getEvents(localStorage.getItem('calendarStartDate'), localStorage.getItem('calendarEndDate'))

      dealershipSelectModalIsOpen.value = false
    }

    return {
      fullCalendarRef,
      computedCalendarOptions,
      dealershipSelectModalIsOpen,
      dealershipsList,
      selectedDealership,
      onDealershipSelect
    }
  }
}
</script>

<style>
/* DayGrid Custom Styling */
.fc-style-availability.fc-daygrid-event {
  background-color: var(--extra-light);
  padding-left: 2px;
  justify-content: center;
}
.fc-style-availability .fc-daygrid-event-dot {
  display: none;
}
.fc-style-availability .fc-event-title {
  display: none;
}

.fc-style-availability.fc-style-availability-unavailable {
  background-color: transparent;
  opacity: 0.4;
  text-decoration: line-through;
}

.fc-style-closed,
.fc-style-closed .fc-event-title-container {
  background-color: var(--bg-tertiary) !important;
  color: black;
}
</style>
