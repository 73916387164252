<template>
  <PageHeader
    title="SMS"
    breadcrumb
    :items="[
      { label: $t('ui.entities.report.label', 2), to: '/reports' },
      { label: 'Engagement' },
      { label: 'SMS', to: '/reports/engagement/sms' }
    ]"
  >
    <template #right>
      <div class="flex items-center gap-2">
        <DatePicker
          v-model="dateRange"
          selection-mode="range"
          max-range="31"
          position="bottom-right"
          :max-date="new Date()"
          :number-of-months="useBreakPoints({ xs: 1, sm: 2 })"
          @change-applied="onDateRangeChange"
        />
      </div>
    </template>
  </PageHeader>
  <Card>
    <DataTable
      ref="dt"
      v-model:filters="filters"
      export-filename="SMS"
      :value="smsData"
      removable-sort
      data-key="id"
      sort-field="timestamp"
      :sort-order="-1"
      column-resize-mode="fit"
      :paginator="true"
      responsive-layout="scroll"
      filter-display="menu"
      :global-filter-fields="['dealershipId', 'dealershipName', 'user.name', 'customer.name', 'from', 'to']"
      :rows="20"
      :rows-per-page-options="[10, 20, 50]"
      state-key="dt-report-engagement-sms"
      state-storage="session"
      current-page-report-template="Showing {startRecord}-{endRecord} out of {totalRecords}"
    >
      <template #empty> No records found </template>

      <template #header>
        <div class="flex justify-between">
          <span class="flex justify-between gap-4">
            <span>
              <Input v-model="filters['global'].value" :placeholder="$t('ui.common.search', 1)" icon="search" />
            </span>
            <Button
              :label="$t('ui.actions.clear', 1)"
              severity="secondary"
              type="button"
              outlined
              icon="filter-slash"
              @click="clearFilters()"
            />
          </span>
        </div>
        <div class="flex justify-between mt-4">
          <span>
            <Button label="CSV" type="button" severity="secondary" outlined icon="file-excel" @click="exportCSV($event)" />
          </span>
        </div>
      </template>

      <Column field="dealershipName" header="Dealership" hidden> </Column>
      <Column field="dealershipId" header="Dealership" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <div class="flex items-center gap-3">
            <Avatar v-if="data.dealershipId" :url="'https://cdn.retain.group/img/logos/manufacturers/' + data.manufacturerLogo" is-logo />
            <Avatar v-else icon="dash" />
            <div>
              <div class="font-semibold">
                {{ data.dealershipName || '-' }}
              </div>
              <div class="text-sm text-quaternary">{{ data.direction == 'outbound' ? data.from : data.to }}</div>
            </div>
          </div>
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="dealershipsList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Any"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="direction">
        <template #body="{ data }">
          <Icon v-if="data.direction == 'inbound'" type="arrow-left" />
          <Icon v-if="data.direction == 'outbound'" type="arrow-right" />
        </template>
      </Column>
      <Column field="customer.name" header="Customer" :sortable="true">
        <template #body="{ data }">
          <router-link :to="data.customer.agreementId ? '/agreement/' + data.customer.agreementId : ''">
            <div class="flex items-center gap-3">
              <Avatar :icon="data.customer.agreementId ? 'user' : 'dash'" />
              <div>
                <div v-if="data.customer.name" class="font-semibold whitespace-nowrap">
                  {{ data.customer.name || '-' }}
                </div>

                <div v-else class="mb-2">
                  <Dropdown>
                    <template #triggerContent>
                      <Button label="Link to customer" size="xs" outlined />
                    </template>
                    <Select
                      v-model="selectedAgreement"
                      :items="agreementsList"
                      value-key="agreementID"
                      label-key="name"
                      :is-object="true"
                      :search="true"
                      :handle-search="true"
                      :search-term="searchTerm"
                      :can-clear="true"
                      class="px-2 min-w-64"
                      @select="linkToCustomer(data)"
                      @update:search-term="searchTerm = $event"
                    >
                      <template #option="{ option }">{{ option.name }} [{{ option.vrm }}]</template>
                    </Select>
                  </Dropdown>
                </div>
                <div class="text-sm text-quaternary">{{ data.direction == 'outbound' ? data.to : data.from }}</div>
              </div>
            </div>
          </router-link>
        </template>
      </Column>

      <Column field="from" header="From" hidden> </Column>
      <Column field="to" header="To" hidden> </Column>

      <Column field="message" header="Message">
        <template #body="{ data }">
          <div class="text-secondary text-sm leading-snug">{{ data.message }}</div>
        </template>
      </Column>
      <Column field="user.name" header="User" hidden> </Column>
      <Column field="user.id" header="User" :sortable="true" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <Avatar
            v-if="data.user"
            v-tippy="{ content: data.user.name }"
            :url="'https://cdn.retain.group/img/profile-pics/' + data.user.profilePic"
            :text="data.user.initials"
          />
          <Avatar v-else-if="!data.user && data.direction == 'outbound'" v-tippy="{ content: 'Automated' }" icon="message-bot" />
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="usersList"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Any"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="timestamp" header="Sent" :sortable="true">
        <template #body="{ data }">
          <div v-tippy="dayjs(data.timestamp).format('h:mma Do MMM YYYY')" class="whitespace-nowrap">
            {{ dayjs().to(dayjs(data.timestamp)) }}
          </div>
        </template>
      </Column>
      <Column field="deliveryStatus" header="Delivered" :show-filter-match-modes="false" :show-filter-operator="false">
        <template #body="{ data }">
          <Icon v-if="data.deliveryStatus == 'delivered'" v-tippy="'Delivered'" type="message-check" class="text-success" />
          <Icon v-if="data.deliveryStatus == 'sent'" v-tippy="'Sent'" type="message-arrow-up-right" class="text-warning" />
          <Icon v-if="data.deliveryStatus == 'failed'" v-tippy="'Failed'" type="message-xmark" class="text-error" />
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="[
              { value: 'sent', label: 'Sent' },
              { value: 'failed', label: 'Failed' },
              { value: 'delivered', label: 'Delivered' }
            ]"
            filter
            display="chip"
            option-value="value"
            option-label="label"
            placeholder="Any"
            class="max-w-[300px]"
          >
            <template #option="slotProps">
              {{ slotProps.option.label }}
            </template>
          </MultiSelect>
        </template>
      </Column>

      <Column v-if="$can('manage_conversations')">
        <template #body="{ data }">
          <Dropdown position="bottom-right">
            <template #triggerContent>
              <Button icon="ellipsis-vertical" plain severity="secondary"></Button>
            </template>
            <DropdownItem @item-clicked="deleteSms(data.id)">
              <Icon type="trash" class="text-error" />
              <span class="w-full">{{ $t('ui.actions.delete') }}</span>
            </DropdownItem>
          </Dropdown>
        </template>
      </Column>
    </DataTable>
  </Card>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'

import Card from '@/components/card/Card.vue'
import PageHeader from '@/components/page/PageHeader.vue'
import DatePicker from '@/components/date-picker/DatePicker.vue'
import Input from '@/components/forms/Input.vue'
import Button from '@/components/button/Button.vue'
import Avatar from '@/components/avatar/Avatar.vue'
import Icon from '@/components/icon/Icon.vue'
import Dropdown from '@/components/dropdown/Dropdown.vue'
import DropdownItem from '@/components/dropdown/DropdownItem.vue'
import Select from '@/components/forms/Select.vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import MultiSelect from 'primevue/multiselect'
import Swal from 'sweetalert2'
import { FilterMatchMode } from 'primevue/api'
import dayjs from 'dayjs'
import { MeiliSearch } from 'meilisearch'

import useApiRequest from '@/composables/useApiRequest'
import { useBreakPoints } from '@/composables/useWindowSize'
import { useUserStore } from '@/stores/UserStore'
import notification from '@/utilities/notification'

const dt = ref()
const userStore = useUserStore()
const dealershipsAllowed = ref(userStore.accessAllowed.dealerships)
const dateRange = ref([dayjs().startOf('week').toDate(), dayjs().toDate()])
const smsApiRequest = useApiRequest()
const deleteSmsApiRequest = useApiRequest()
const linkAgreementApiRequest = useApiRequest()

const smsData = ref([])
const users = ref([])
const filters = ref([])

const dealershipIDs = userStore.accessAllowed.dealerships.map(dealership => dealership.id)
const dealershipIDsString = dealershipIDs.join(',')
const searchTerm = ref('')
const meiliSearchResults = ref([])
const meilisearchClient = ref()
const selectedAgreement = ref(null)

onMounted(() => {
  meilisearchClient.value = new MeiliSearch({
    host: import.meta.env.VITE_MEILISEARCH_URL,
    apiKey: import.meta.env.VITE_MEILISEARCH_API_KEY
  })
})

const search = async query => {
  if (query) {
    meiliSearchResults.value = await meilisearchClient.value.index('customers').search(query, {
      filter: 'agreements.dealershipID IN [' + dealershipIDsString + ']'
    })
  } else {
    meiliSearchResults.value = []
  }
}

// Create a computed property to transform the data
const agreementsList = computed(() => {
  const transformedAgreements = []

  if (meiliSearchResults.value.hits != undefined) {
    for (const result of meiliSearchResults.value.hits) {
      for (const agreement of result.agreements) {
        const transformedAgreement = {
          ...result,
          ...agreement
        }

        delete transformedAgreement.agreements

        transformedAgreements.push(transformedAgreement)
      }
    }
  }

  return transformedAgreements
})

watch(searchTerm, newSearchTerm => {
  search(newSearchTerm)
})

function initFilters() {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    dealershipId: { value: null, matchMode: FilterMatchMode.IN },
    'user.id': { value: null, matchMode: FilterMatchMode.IN },
    deliveryStatus: { value: null, matchMode: FilterMatchMode.IN }
  }
}
initFilters()

function clearFilters() {
  initFilters()
}

function fetchSmsData() {
  smsApiRequest
    .sendRequest({
      endpoint: '/v1/conversations/sms?',
      params: {
        start: dayjs(dateRange.value[0]).format('YYYY-MM-DD'),
        end: dayjs(dateRange.value[1]).format('YYYY-MM-DD')
      }
    })
    .then(response => {
      if (response.data.success) {
        smsData.value = response.data.data
      }
    })
}
fetchSmsData()

function onDateRangeChange() {
  if (dateRange.value[0] && dateRange.value[1]) {
    fetchSmsData()
  }
}

// Users List
const usersApiRequest = useApiRequest()
usersApiRequest.sendRequest({ endpoint: '/v1/users?userType=retain' }).then(response => {
  if (response.status == 200) {
    users.value = response.data.data
  }
})

function deleteSms(id) {
  Swal.fire({
    title: 'Are you sure?',
    text: 'This action cannot be undone - not even by an admin!',
    icon: 'warning',
    reverseButtons: true,
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonText: 'Yes, delete this SMS',
    confirmButtonColor: 'var(--bg-error-solid)',
    cancelButtonText: 'Cancel'
  }).then(function (swalResponse) {
    if (swalResponse.isConfirmed && id) {
      try {
        deleteSmsApiRequest.sendRequest({ endpoint: `/v1/conversations/sms/${id}`, method: 'DELETE' }).then(response => {
          if (response.data.success) {
            notification('SMS deleted', null, 'success')

            // Remove sms from table data
            let index = smsData.value.indexOf(sms => sms.id == id)
            if (index) {
              smsData.value.splice(index, 1)
            }
          }
        })
      } catch (err) {
        notification('Error', err.message)
      }
    }
  })
}

function linkToCustomer(comm) {
  if (comm?.id && selectedAgreement.value.agreementID) {
    try {
      linkAgreementApiRequest
        .sendRequest({
          endpoint: '/v1/conversations/sms/' + comm.id,
          data: {
            agreementId: selectedAgreement.value.agreementID
          },
          method: 'PATCH'
        })
        .then(response => {
          if (response.data.success) {
            comm.customer.agreementId = selectedAgreement.value.agreementID
            comm.customer.customerName = selectedAgreement.value.name
            notification('Customer linked successfully', null, 'success')
          } else {
            notification('Error', 'Failed to link customer', 'danger')
          }
          selectedAgreement.value = null
        })
    } catch (err) {
      notification('Error', err.message, 'danger')
    }
  } else {
    notification('Error', 'Failed to link customer', 'danger')
  }
}

const usersList = computed(() => {
  // if no users, return empty array
  if (!users.value) return []

  return users.value.map(u => {
    return {
      value: u.id,
      label: u.fullName,
      profilePicUrl: u.profilePicURL,
      initials: u.initials
    }
  })
})

const dealershipsList = computed(() => {
  return dealershipsAllowed.value.map(d => {
    return {
      value: d.id,
      label: d.name
    }
  })
})

const exportCSV = () => {
  dt.value.exportCSV()
}
</script>

<style></style>
